<div class="item participant-component" *ngFor="let participant of participantData; let participantIndex = index;">
  <div class="d-flex align-items-center m-b-4" [ngClass]="{'m-t-20': participantIndex !== 0}">
    <p class="strong m-b-0">{{participant.name}}</p>
    <ng-container *ngIf="participant.documents.length == 0">
      <p *ngIf="!participant.requiredForEnrolment" class="m-b-0 m-l-5">
        <em>({{resourceStrings['Aon.Common.Optional']}})</em>
      </p>
      <p *ngIf="participant.requiredForEnrolment" class="m-b-0 m-l-5">
        <em>({{resourceStrings['Aon.Common.Required']}})</em>
      </p>
    </ng-container>
    <ng-container *ngIf="participant.documents.length > 0">
      <span class="m-t-0 m-b-0 strong tag green-tag-wrapper">{{resourceStrings['Aon.Documents.Complete']}}</span>
    </ng-container>
  </div>
  <p *ngIf="participant.instruction != null" [innerHTML]="participant.instruction | safeHtml"></p>
  <p class="card-text m-b-0" *ngFor="let doc of participant.documents">
    <ng-container *ngIf="doc.document_FileName != null">
      <div class="d-flex align-items-center">
        <a tabindex="0"
          [attr.aria-label]="resourceStrings['Aon.Profile.Documents.Download.Label'] +' '+  doc?.document_FileName"
          class="m-b-0" (keydown.enter)="downloadFile(doc.document_RecordID)"
          (keydown.space)="downloadFile(doc.document_RecordID)"
          (click)="downloadFile(doc.document_RecordID)">{{doc.document_FileName}}</a>
        <a class="d-flex align-items-center text-decoration-none" role="button"
          [attr.aria-label]="resourceStrings['Aon.Profile.Documents.Edit.Label']" tabindex="0"
          (keydown.enter)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);"
          (keydown.space)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);"
          (click)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=doc.employeeCorrespondence_RecordID; setParticipantIndex(participantIndex);">
          <i aria-hidden="true" class="fal fa-edit m-l-8"></i>
        </a>
      </div>
      <div *ngIf="errorMessage[participantIndex]" class="alert contextual error m-t-20" role="alert" aria-live="polite">
        <div class="message">
          <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
          {{errorMessage[participantIndex]}}
        </div>
      </div>
      <div id="save-success-alert" class="alert contextual success m-t-20"
        *ngIf="successMessage[participantIndex] && !errorMessage[participantIndex]" role="alert" aria-live="polite">
        <div class="message justify-content-between">
          <div class="content-left d-flex align-items-center">
            <i aria-hidden="true" class="fas fa-check-circle"></i>
            {{doc.document_FileName }} {{successMessage[participantIndex]}}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{participantIndex: participantIndex}"
      *ngIf="doc.document_FileName == null"></ng-container>
  </p>
  <ng-container [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{participantIndex: participantIndex}"
    *ngIf="participant.documents == null || (participant.documents != null && participant.documents.length == 0)">
  </ng-container>
</div>
<ng-template #itemTemplate let-participantIndex="participantIndex">
  <p class="card-text">{{resourceStrings['Aon.UploadDocument.NoDocuments']}}</p>
  <div *ngIf="errorMessage[participantIndex]" class="alert contextual error"><i class="fas fa-exclamation-triangle"
      aria-hidden="true"></i>{{errorMessage[participantIndex]}}</div>

  <div class="m-t-20">
    <button type="button"
      (click)="triggerFileClick(upload); selectedEmployeeCorrespondence_RecordID=null; setParticipantIndex(participantIndex);">{{resourceStrings['Aon.UploadDocument.UploadDocumentsText']}}</button>
  </div>
</ng-template>

<kendo-fileselect #upload (select)="selectEventHandler($event)" [showFileList]="false" style="display:none;"
  name="selectfile">
</kendo-fileselect>