<div class="benefit-details">

  <div [ngClass]="fromBenefitDetails ? '' : 'basic-card'" *ngIf="showStandardBenefitCard">
    <div class="row m-b-4 prevent-split">
      <div class="col-12 col-md-7">
        <span *ngIf="productThumbnail && productCategory && uiConfig.uiConfiguration.showIconAndBenefitGroupLabel"
          class="category-name">
          <img [src]="productThumbnail" alt="" class="m-r-8" />{{productCategory}}
        </span>
        <div class="d-flex align-items-center m-b-20">
          <ng-container *ngIf="!fromBenefitDetails">
            <h2 *ngIf="product?.name" class="m-b-0 linename-for-print h3" [innerHTML]="product?.name | striphtml"></h2>
            <span class="tag green-tag-wrapper"
              *ngIf="product?.defaultedChange?.isNew">{{resourceStrings['Aon.Tag.NewBenefit']}}</span>
            <span class="tag red-tag-wrapper"
              *ngIf="product?.defaultedChange?.isDeleted || product?.userChange?.isDeleted">{{resourceStrings['Aon.Tag.Removed']}}</span>
            <span class="tag teal-tag-wrapper"
              *ngIf="!product?.defaultedChange?.isDeleted && !product?.userChange?.isDeleted && !product?.defaultedChange?.isNew && (product?.defaultedChange?.costChanged || product?.defaultedChange?.dependantChanged || product?.defaultedChange?.planChanged)">{{resourceStrings['Aon.Tag.Updated']}}</span>
            <span class="tag green-tag-wrapper" *ngIf="ssoBenefittag">{{resourceStrings['Aon.SSO.BenefitTag']}}</span>
            <span role="button" class="tag yellow-tag-wrapper focus-visibility" *ngIf="product.eoiInformation"
              placement="bottom"
              [attr.aria-label]="resourceStrings['Aon.Tag.Pending']+ ' ' +resourceStrings['Aon.Arialabel.Info']"
              ngbPopover="{{resourceStrings['Aon.Tooltip.PendingEOI']}}" popoverClass="popover-gray" tabindex="0"
              #popover="ngbPopover"
              (keyup.enter)="helperService.openPop(popover)">{{resourceStrings['Aon.Tag.Pending']}}</span>
          </ng-container>
        </div>

        <ng-container *ngTemplateOutlet="costSection;"></ng-container>

        <!-- new changes -->
        <div *ngIf="!isMultiInstance" class="m-b-20 m-t-20 benefit-details-coverage-display">
          <app-coverage-display-info [product]="product"
            [ShowDataByAmountType]="helperService.ShowDataByAmountType(product?.employeeVolume,product)"
            [isProfileDisplay]="isProfileDisplay" [benefitPlanNameHeading]="{
              tagLevel: 3,
              class: 'h4'
            }" (benefitInfoPopup)="benefitInfoPopup($event)"></app-coverage-display-info>
        </div>
        <div *ngIf="product?.employeeElectionFundAllocations?.length > 0"  class="m-b-20 m-t-20">
          <app-fund-allocation-display [EmployeeElectionFundAllocation]="product?.employeeElectionFundAllocations"></app-fund-allocation-display>

        </div>
        <ng-container>
          <div class="m-b-20 m-t-20" *ngIf="product?.coveredDependants?.length > 0">
            <app-plan-dependent-info [product]="product" [whosCoveredLabel]="true"
              [dependantsData]="product.coveredDependants" [displayInBlock]="true"></app-plan-dependent-info>

            <!-- Beneficiaries -->
            @if (product?.allocationType?.allocationBeneficiaries?.length > 0) {
            <div class="m-t-20">
              <div>
                <b class="d-block">{{ resourceStrings['Aon.BenefitDetails.Beneficiaries']}}</b>
                @for (primary of this | functionExecutor:'getPrimarySecondaryBeneficiaries':[product, 1]; track $index;
                let isfirst = $first; let islast = $last) {
                <div class="primary d-inline">
                  @if (isfirst) {
                  <span class="strong d-block">{{resourceStrings['Aon.Beneficiary.Primary']}}:</span>
                  }
                  <p class="m-b-0 d-inline">
                    <span>{{primary?.beneficiaryName?.trim()}}&nbsp;({{primary.relationshipName}}):&nbsp;{{primary?.allocationPercentage}}%</span>
                    @if (primary?.trusteeFirstName && primary?.trusteeLastName && primary?.trusteeRelationshipType_Name)
                    {
                    <span>&nbsp;-&nbsp;<span class="strong"
                        [innerHTML]="resourceStrings['Aon.Beneficiary.NominatedTrustee'] + ': ' | safeHtml"></span>{{primary?.trusteeFirstName?.trim()
                      + ' ' + primary?.trusteeLastName?.trim()}}
                      ({{primary?.trusteeRelationshipType_Name?.trim()}})</span>
                    }
                    @if (!islast) {<span>,&nbsp;</span>}
                  </p>
                </div>
                }
                @for (secondary of this | functionExecutor:'getPrimarySecondaryBeneficiaries':[product, 2]; track
                $index;
                let isfirst = $first; let islast = $last) {
                <div class="secondary d-inline">
                  @if (isfirst) {
                  <span class="strong d-block">{{resourceStrings['Aon.Beneficiary.Contingent']}}:</span>
                  }
                  <p class="m-b-0 d-inline">
                    <span>{{secondary?.beneficiaryName?.trim()}}&nbsp;({{secondary.relationshipName}}):&nbsp;{{secondary?.allocationPercentage}}%</span>
                    @if (secondary?.trusteeFirstName && secondary?.trusteeLastName &&
                    secondary?.trusteeRelationshipType_Name) {
                    <span>&nbsp;-&nbsp;<span class="strong"
                        [innerHTML]="resourceStrings['Aon.Beneficiary.NominatedTrustee'] + ': ' | safeHtml"></span>{{secondary?.trusteeFirstName?.trim()
                      + ' ' + secondary?.trusteeLastName?.trim()}}
                      ({{secondary?.trusteeRelationshipType_Name?.trim()}})</span>
                    }
                    @if (!islast) {<span>,&nbsp;</span>}
                  </p>
                </div>
                }
              </div>
            </div>
            }
          </div>
        </ng-container>
      </div>
      <ng-container *ngTemplateOutlet="costSection; context: {
        isLargeDeviceView: true
      }"></ng-container>
    </div>
    <ng-template #costSection let-isLargeDeviceView="isLargeDeviceView">
      @if (showOutstandingCost || showEmployeeCostOnMainCardCostDisplay || showPaymentSources || showEmployerCost ||
      showFundingRelease ||
      showFundingForfeiture) {
      <div
        [ngClass]="isLargeDeviceView ? 'col-12 col-md-5 d-lg-block d-md-block d-none' : 'd-lg-none d-md-none d-block'">
        <div class="cost-container" [ngClass]="isLargeDeviceView ? 'cost-container-for-print-alignment' : ''">
          @if (showOutstandingCost) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label" [innerHTML]="resourceStrings['Aon.BenefitDetails.OutstandingCost']">
              </p>
            </div>
            <div class="col-12">
              <p class="headline-cost theme-color">
                {{outstandingCost | currency: product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showEmployeeCostOnMainCardCostDisplay) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label"
                [innerHTML]="showAdjustmentCosts ? (product?.employeeAdjustedCostLabel != null && product?.employeeAdjustedCostLabel != '' ? product?.employeeAdjustedCostLabel : resourceStrings['Aon.Benefit.YourCost.Adjusted']) : (product?.employeeCostLabel != null && product?.employeeCostLabel != '' ? product?.employeeCostLabel : resourceStrings['Aon.BenefitDetails.YourCost'])">
              </p>
            </div>
            <div class="col-12">
              <p class="headline-cost theme-color">
                {{ getEmployeeCost | currency: product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showEmployeeTaxOnMainCardCostDisplay) {
          <ng-template #taxInfo>
            @for (tax of taxes?.employee; track tax) {
            <div class="xs">
              <span>{{(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])}}<ng-container
                  *ngIf="tax?.alternateFlexTaxTypeName != ''">:</ng-container></span>
              <span>
                {{ (isPerPay ? tax.employeePayPeriodTaxAmount : tax.employeeAnnualTaxAmount) | currency:
                product?.currencyCode
                }}
              </span>
            </div>
            }
          </ng-template>
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label" [ngClass]="{'has-info-icon': taxes?.employee?.length > 1}">
                {{showAdjustmentCosts ? resourceStrings['Aon.Benefit.EmployeeTaxes.Adjusted'] :
                resourceStrings['Aon.Benefit.EmployeeTaxes']}}
                @if (taxes?.employee?.length > 1) {
                <i tabindex="0" class="fal fa-info-circle focus-visibility ignore-from-media-print" placement="top left"
                  popoverTitle="{{resourceStrings['Aon.EETaxSummary.Popover.Title']}}" #popover="ngbPopover"
                  [attr.aria-label]="resourceStrings['Aon.EETaxSummary.Popover.Title']" [ngbPopover]="taxInfo"
                  (keyup.enter)="helperService.openPop(popover)" popoverClass="tax-summary-popover" role="button"></i>
                }
              </p>
            </div>
            <div class="col-12">
              <p>
                {{ getEmployeeTax | currency: product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showPaymentSources) {
          @for (ps of product.paymentSources; track ps) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label"
                [innerHTML]="showAdjustmentCosts ? (resourceStrings['Aon.PaymentSource.YourCost.Adjusted'] | textTransform: { PaymentSourceName: ps.name }) : (resourceStrings['Aon.PaymentSource.YourCost'] | textTransform: { SourceName: ps.name })">
              </p>
            </div>
            <div class="col-12">
              <p [ngClass]="getPaymentSourceCostClass">
                {{ (isPerPay ? ps?.value_PayPeriod : ps?.value_Annual) | currency: product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          }
          @if (showEmployerCost) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label"
                [innerHTML]="showAdjustmentCosts ? (product?.employerAdjustedCostLabel  != null && product?.employerAdjustedCostLabel  != '' ? product?.employerAdjustedCostLabel  : resourceStrings['Aon.Benefit.EmployerCost.Adjusted']) : (product?.employerCostLabel != null && product?.employerCostLabel != '' ? product?.employerCostLabel : resourceStrings['Aon.BenefitDetails.EmployerCost'])">
              </p>
            </div>
            <div class="col-12">
              <p
                [ngClass]="{'headline-cost theme-color': !showEmployeeCostOnMainCardCostDisplay && !showPaymentSources}">
                {{
                getEmployerCost
                | currency: product?.currencyCode }}</p>
            </div>
          </div>
          }
          @if (showEmployerTaxOnMainCardCostDisplay) {
          <ng-template #taxInfo>
            @for (tax of taxes?.employer; track tax) {
            <div class="xs">
              <span>{{(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])}}<ng-container
                  *ngIf="tax?.alternateFlexTaxTypeName != ''">:</ng-container></span>
              <span>
                {{ (isPerPay ? tax.employerPayPeriodTaxAmount : tax.employerAnnualTaxAmount) | currency:
                product?.currencyCode
                }}
              </span>
            </div>
            }
          </ng-template>
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label" [ngClass]="{'has-info-icon': taxes?.employer?.length > 1}">
                {{showAdjustmentCosts ? resourceStrings['Aon.Benefit.EmployerTaxes.Adjusted'] :
                resourceStrings['Aon.Benefit.EmployerTaxes']}}
                @if (taxes?.employer?.length > 1) {
                <i tabindex="0" class="fal fa-info-circle focus-visibility ignore-from-media-print" placement="top left"
                  popoverTitle="{{resourceStrings['Aon.ERTaxSummary.Popover.Title']}}" #popover="ngbPopover"
                  [attr.aria-label]="resourceStrings['Aon.ERTaxSummary.Popover.Title']" [ngbPopover]="taxInfo"
                  (keyup.enter)="helperService.openPop(popover)" popoverClass="tax-summary-popover" role="button"></i>
                }
              </p>
            </div>
            <div class="col-12">
              <p>
                {{ getEmployerTax | currency: product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showTotalCostSection) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label"
                [innerHTML]="displayERCostAsFunding ? resourceStrings['Aon.BenefitDetails.BenefitCost']+':' : product?.totalCostLabel != null && product?.totalCostLabel != '' ? product?.totalCostLabel : resourceStrings['Aon.BenefitDetails.TotalCost']">
              </p>
            </div>
            <div class="col-12">
              <p>
                {{getTotalCost | currency:
                product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showERCostAsFunding) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label" [innerHTML]="resourceStrings['Aon.BenefitDetails.Funding']+':'"></p>
            </div>
            <div class="col-12">
              <p>
                {{ (isPerPay ? product?.fundedCoverage_PayPeriod : product?.fundedCoverage_Annual) | currency:
                product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showFundingForfeiture) {
          <div class="row">
            <div class="col-12">
              <p class="sm m-b-4 error"
                [innerHTML]="(product?.fundingForfeitureLabel != null && product?.fundingForfeitureLabel != '' ? product?.fundingForfeitureLabel : resourceStrings['Aon.Benefit.FundingForfeit']) + ':'">
              </p>
            </div>
            <div class="col-12">
              <p class="error">
                {{ (isPerPay ? product?.fundingForfeiture_PayPeriod : product?.fundingForfeiture_Annual) | currency:
                product?.currencyCode }}
              </p>
            </div>
          </div>
          }
          @if (showFundingRelease) {
          <div class="row">
            <div class="col-12">
              <p class="sm cost-label"
                [innerHTML]="(product?.fundingReleaseLabel != null && product?.fundingReleaseLabel != '' ? product?.fundingReleaseLabel : resourceStrings['Aon.Benefit.FundingRelease']) + ':'">
              </p>
            </div>
            <div class="col-12">
              <p>
                {{ (isPerPay ? product?.fundingRelease_PayPeriod : product?.fundingRelease_Annual) | currency:
                product?.currencyCode }}
              </p>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </ng-template>
    <div ngbAccordion id="benefit-details-for-print{{product?.line_RecordID}}" WatchDomTree
      (dom-changed)="removeTheRoleTabFromPanelHeader()">
      @if (showTaxDetailsPanel) {
      <div [ngbAccordionItem]="'cost-details-p' + index" #accordion="ngbAccordionItem"
        class="accordion-item expansion-panel">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span class="header-text">{{resourceStrings['Aon.BenefitDetails.TaxDetails']}}</span>
            <span class="header-controls">
              @if (!accordion.collapsed) {
              <i class="fal fa-minus" aria-hidden="true"></i>
              } @else {
              <i class="fal fa-plus" aria-hidden="true"></i>
              }
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div>
                @if (showEmployeeCostOnTaxDetails) {
                <div class="cost-breakdown">
                  <p
                    [innerHTML]="showAdjustmentCosts ? (product?.employeeAdjustedCostLabel != null && product?.employeeAdjustedCostLabel != '' ? product?.employeeAdjustedCostLabel : resourceStrings['Aon.Benefit.YourCost.Adjusted']) : (product?.employeeCostLabel != null && product?.employeeCostLabel != '' ? product?.employeeCostLabel : resourceStrings['Aon.BenefitDetails.YourCost'])">
                  </p>
                  <p>
                    {{ getEmployeeCost | currency: product?.currencyCode }}
                  </p>
                </div>
                }
                @if (showEmployeeTaxOnTaxDetails) {
                @for (tax of taxes.employee; track tax) {
                <div class="cost-breakdown">
                  <p [innerHTML]="(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                      tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])">
                  </p>
                  <p>{{ (isPerPay ? tax?.employeePayPeriodTaxAmount : tax?.employeeAnnualTaxAmount) | currency:
                    product?.currencyCode
                    }}
                  </p>
                </div>
                }
                }
                @if (showEmployerCost) {
                <div class="cost-breakdown">
                  <p
                    [innerHTML]="showAdjustmentCosts ? (product?.employerAdjustedCostLabel  != null && product?.employerAdjustedCostLabel  != '' ? product?.employerAdjustedCostLabel  : resourceStrings['Aon.Benefit.EmployerCost.Adjusted']) : (product?.employerCostLabel != null && product?.employerCostLabel != '' ? product?.employerCostLabel : resourceStrings['Aon.BenefitDetails.EmployerCost'])">
                  </p>
                  <p>{{getEmployerCost | currency: product?.currencyCode}}</p>
                </div>
                }
                @if (showEmployerTaxOnTaxDetails) {
                @for (tax of taxes.employer; track tax) {
                <div class="cost-breakdown">
                  <p [innerHTML]="(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                      tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])">
                  </p>
                  <p>{{ (isPerPay ? tax?.employerPayPeriodTaxAmount : tax?.employerAnnualTaxAmount) | currency:
                    product?.currencyCode
                    }}
                  </p>
                </div>
                }
                }
                <div class="cost-breakdown total">
                  <p
                    [innerHTML]="product?.totalCostLabel != null && product?.totalCostLabel != '' ? product?.totalCostLabel : resourceStrings['Aon.BenefitDetails.TotalCost']">
                  </p>
                  <p>
                    {{ getTotalCostForTaxPanel | currency:
                    product?.currencyCode }}
                  </p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      }

      @if (isMultiInstance) {
      @for (employeeBenefitType of product.employeeBenefitTypes; track employeeBenefitType; let i = $index) {
      @if (!employeeBenefitType.isNoCoverage || employeeBenefitType.eoiInformation ||
      employeeBenefitType.eOIInformation) {
      <div [ngbAccordionItem]="'m' + i" #accordion="ngbAccordionItem" class="accordion-item expansion-panel">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span id="{{'accordionExpansionPanelMultiInstance' + i + index}}"
              class="header-text d-flex align-items-center">
              {{employeeBenefitType.benefitTitle}}
              @if (employeeBenefitType.eoiInformation || employeeBenefitType.eOIInformation) {
              <span class="tag yellow-tag-wrapper" id="pendingTag" aria-labelledby="pendingTag" placement="bottom"
                [attr.aria-label]="resourceStrings['Aon.Tag.Pending'] +' '+resourceStrings['Aon.Arialabel.Info']"
                ngbPopover="{{resourceStrings['Aon.Tooltip.PendingEOI']}}" popoverClass="popover-gray"
                (keyup.enter)="helperService.openPop(popover)" #popover="ngbPopover"
                tabindex="0">{{resourceStrings['Aon.Tag.Pending']}}
              </span>
              }
            </span>
            <span class="header-controls">
              @if (!accordion.collapsed) {
              <i class="fal fa-minus" aria-hidden="true"></i>
              } @else {
              <i class="fal fa-plus" aria-hidden="true"></i>
              }
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <app-benefit-details [product]="employeeBenefitType"
                [transformNegativeEmployeeCost]="transformNegativeEmployeeCost" [isPerPay]="isPerPay"
                [fromBenefitDetails]="true" [showDeleteIcon]="showDeleteIcon" [fromEndPurchase]="fromEndPurchase"
                (goSableFlow)="emitGoSableFlow($event)" [showCustomerService]="showCustomerService" [index]="i + index"
                [isProfileDisplay]="isProfileDisplay" [displayERCostAsFunding]="displayERCostAsFunding">
              </app-benefit-details>
            </ng-template>
          </div>
        </div>
      </div>
      }
      }
      } @else {
      @if (product.eoiInformation && !showTaxDetailsPanel) {
      <div [ngbAccordionItem]="'benefit-details-p' + 1" #accordion="ngbAccordionItem"
        class="accordion-item expansion-panel">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span class="header-text">{{resourceStrings['Aon.BenefitDetails.CoverageDetails']}}</span>
            <span class="header-controls">
              @if (!accordion.collapsed) {
              <i class="fal fa-minus" aria-hidden="true"></i>
              } @else {
              <i class="fal fa-plus" aria-hidden="true"></i>
              }
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div>
                @if (showOutstandingCost) {
                <div class="cost-breakdown">
                  <p [innerHTML]="resourceStrings['Aon.BenefitDetails.OutstandingCost']"></p>
                  <p>{{ outstandingCost | currency: product?.currencyCode }}</p>
                </div>
                }
                @if (showEmployeeCostOnCoverageDetails) {
                <div class="cost-breakdown">
                  <p
                    [innerHTML]="showAdjustmentCosts ? (product?.employeeAdjustedCostLabel != null && product?.employeeAdjustedCostLabel != '' ? product?.employeeAdjustedCostLabel : resourceStrings['Aon.Benefit.YourCost.Adjusted']) : (product?.employeeCostLabel != null && product?.employeeCostLabel != '' ? product?.employeeCostLabel : resourceStrings['Aon.BenefitDetails.YourCost'])">
                  </p>
                  <p>
                    {{ getEmployeeCost | currency: product?.currencyCode }}
                  </p>
                </div>
                }
                @if (showEmployeeTaxOnCoverageDetails) {
                @for (tax of taxes.employee; track tax) {
                <div class="cost-breakdown">
                  <p [innerHTML]="(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                    tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])">
                  </p>
                  <p>{{ (isPerPay ? tax?.employeePayPeriodTaxAmount : tax?.employeeAnnualTaxAmount) | currency:
                    product?.currencyCode
                    }}
                  </p>
                </div>
                }
                }
                <div class="cost-breakdown">
                  <p
                    [innerHTML]="showAdjustmentCosts ? (product?.employerAdjustedCostLabel  != null && product?.employerAdjustedCostLabel  != '' ? product?.employerAdjustedCostLabel  : resourceStrings['Aon.Benefit.EmployerCost.Adjusted']) : (product?.employerCostLabel != null && product?.employerCostLabel != '' ? product?.employerCostLabel : resourceStrings['Aon.BenefitDetails.EmployerCost'])">
                  </p>
                  <p>{{getEmployerCost | currency: product?.currencyCode}}</p>
                </div>
                @if (showEmployerTaxOnCoverageDetails) {
                @for (tax of taxes.employer; track tax) {
                <div class="cost-breakdown">
                  <p [innerHTML]="(tax?.alternateFlexTaxTypeName && tax?.alternateFlexTaxTypeName?.trim() != '' ?
                    tax?.alternateFlexTaxTypeName?.trim() : resourceStrings['Aon.Common.NotAvailable'])">
                  </p>
                  <p>{{ (isPerPay ? tax?.employerPayPeriodTaxAmount : tax?.employerAnnualTaxAmount ) | currency:
                    product?.currencyCode
                    }}
                  </p>
                </div>
                }
                }
                <div class="cost-breakdown total">
                  <p
                    [innerHTML]="product?.totalCostLabel != null && product?.totalCostLabel != '' ? product?.totalCostLabel : resourceStrings['Aon.BenefitDetails.TotalCost']">
                  </p>
                  <p>
                    {{ getTotalCostForCoveragePanel | currency:
                    product?.currencyCode }}
                  </p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      }
      <div [ngbAccordionItem]="'benefit-details-p' + 2" #accordion="ngbAccordionItem" class="expansion-panel"
        *ngIf="product.eoiInformation">
        <div class="header-sleeve focus-visibility" ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span class="header-text">{{resourceStrings['Aon.BenefitDetails.PendingCoverage']}}</span>
            <span class="header-controls">
              <i *ngIf="!accordion.collapsed" class="fal fa-minus" aria-hidden="true"></i>
              <i *ngIf="accordion.collapsed" class="fal fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <app-eoi-information [coverage]="coverageForEOIInformation" [participants]="participantsForEOIInformation"
                [options]="{
                currencyCode: product?.currencyCode
              }"></app-eoi-information>
            </ng-template>
          </div>
        </div>
      </div>
      }
      <div [ngbAccordionItem]="'benefit-details-p' + 3" #accordion="ngbAccordionItem"
        class="accordion-item expansion-panel"
        *ngIf="customerService && !lifestyleReview && !fromBenefitDetails && showCustomerService">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility">
            <span class="header-text">{{customerService.contentItemTitle}}</span>
            <span class="header-controls">
              <i *ngIf="!accordion.collapsed" class="fal fa-minus" aria-hidden="true"></i>
              <i *ngIf="accordion.collapsed" class="fal fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="customerService.contentItemBody | safeHtml"></div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
    <div class="alert contextual error m-t-20 m-b-0" *ngIf="product?.errorMessage">
      <div class="message">
        <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        <span [innerHTML]="product?.errorMessage | safeHtml"></span>
      </div>
    </div>
    <div class="alert contextual warning m-t-20 m-b-0" *ngIf="product?.warningMessage">
      <div class="message">
        <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
        <span [innerHTML]="product?.warningMessage | safeHtml"></span>
      </div>
    </div>
    <div id="submit-alert" [ngClass]="messages.length > 1 ? 'banner' : 'contextual'" class="alert info m-t-20 m-b-0"
      *ngIf="showMessagePanel(product)">
      <div class="message justify-content-between">
        <div [ngClass]="messages.length === 1 ? 'd-flex' : ''" class="content-left align-items-center">
          <i aria-hidden="true" class="fas fa-bell">
          </i>
          <p role="status" *ngIf="messages.length === 1; else messageTemplate;" [attr.aria-label]="messages[0]">
            {{ messages[0] }}
          </p>
          <ng-template #messageTemplate>
            <span role="status" class="strong" [attr.aria-label]="resourceStrings['Aon.Benefit.Message.Header']">
              {{resourceStrings['Aon.Benefit.Message.Header']}}
            </span>
            <p role="status" *ngFor="let message of messages" [attr.aria-label]="message">
              {{ message }}
            </p>
          </ng-template>
        </div>
      </div>
    </div>
    <div
      *ngIf="product?.canInteract && !lifestyleReview && checkLockInPeriod(product?.lockoutEndDate) && !isBenefitMatrixCard && !fromBenefitDetails && !showBuildPolicyButton && (product?.anytimeEntityInformationType?.sableFlow_RecordID || lifeStyleFlow?.sableFlow_RecordID)"
      class="m-t-20">
      <button class="prominent hidePrint no-export focus-visibility"
        (click)="triggerLifeStyleFlow(lifeStyleFlow?.sableFlow_RecordID,product)"
        (keyup.enter)="triggerLifeStyleFlow(lifeStyleFlow?.sableFlow_RecordID,product)"
        [attr.aria-label]="resourceStrings['Aon.Profile.Benefit.UpdateBenefit'] + ' ' +  product?.name | striphtml"
        [innerHTML]="resourceStrings['Aon.Profile.Benefit.UpdateBenefit'] | safeHtml"></button>
    </div>

    <div *ngIf="!isBenefitMatrixCard && fromBenefitDetails">
      <div class="col-sm-12 m-t-20 no-export" *ngIf="product.canInteract">
        <div class="align-items-center">
          <button tabindex="0"
            class="subtle aon-icon aon-edit m-r-20 hidePrint no-export focus-visibility d-inline-flex"
            (click)="goToSableFlow(product)" (keyup.enter)="goToSableFlow(product)"
            [attr.aria-label]="resourceStrings['Aon.ActiveBenefits.EditPolicyLabel'] + ' ' +  product?.benefitTitle | striphtml">
            {{ resourceStrings['Aon.ActiveBenefits.EditPolicyLabel'] }}
          </button>
          <i role="button" *ngIf="showDeleteIcon && fromEndPurchase" class="fal fa-trash-alt focus-visibility"
            tabindex="0" (click)="onClickDelete(product)" (keyup.enter)="onClickDelete(product)"
            [attr.aria-label]="resourceStrings['Aon.Common.Delete'] + ' ' +  product?.benefitTitle | striphtml"></i>
        </div>
      </div>
    </div>
    <!-- Change btn -->
    <div class="button-controls m-t-20"
      *ngIf="(isBenefitMatrixCard && !showBuildPolicyButton && product.canInteract) || (isSSOCoverageActivityCard && product?.canDeselect)">
      <div class="btn-prominent" *ngIf="!isSSOCoverageActivityCard">
        <button class="focus-visibility" [innerHTML]="resourceStrings['Aon.BenefitMatrix.Update'] | safeHtml"
          [attr.aria-label]="resourceStrings['Aon.BenefitMatrix.Update'] + ' ' +  product?.name | striphtml"
          (click)="goToSableFlow(product)" (keyup.enter)="goToSableFlow(product)"></button>
      </div>
      <div *ngIf="product?.canDeselect" [ngClass]="isSSOCoverageActivityCard ? 'btn-prominent' : 'btn-ghost'">
        <button class="focus-visibility" [innerHTML]="resourceStrings['Aon.BenefitMatrix.Deselect'] | safeHtml"
          (click)="deselect(product)" (keyup.enter)="deselect(product)"
          [attr.aria-label]="resourceStrings['Aon.BenefitMatrix.Deselect'] + ' ' +  product?.name | striphtml"
          [ngClass]="{'ghost' : !isSSOCoverageActivityCard}"></button>
      </div>
    </div>
    <div *ngIf="!showCustomerService && fromEndPurchase">
      <h3 [innerHTML]="resourceStrings['Aon.BenefitCard.NoPoliciesToDisplayHeading'] | safeHtml"></h3>
      <p [innerHTML]="resourceStrings['Aon.BenefitCard.NoPoliciesToDisplay'] | safeHtml"></p>
    </div>
    <div class="row m-t-20" *ngIf="showBuildPolicyButton && (product.canInteract || fromEndPurchase)">
      <div class="col-sm-12">
        <button class="focus-visibility hidePrint no-export"
          [innerHTML]="resourceStrings['Aon.BenefitMatrix.BuildAnother'] | safeHtml"
          [attr.aria-label]="resourceStrings['Aon.BenefitMatrix.BuildAnother'] + ' ' +  product?.name | striphtml"
          (click)="goToSableFlow(product, true)" (keyup.enter)="goToSableFlow(product, true)"></button>
      </div>
    </div>
  </div>

  <div class="basic-card"
    *ngIf="isBenefitMatrixCard && product.isNoCoverage && (!product.eoiInformation) && hideAlsoAvailableSection == 'False' && !showStandardBenefitCard">
    <app-benefit-details-available [product]="product" (benefitInfoPopup)="benefitInfoPopup($event)"
      [ssoBenefittag]="ssoBenefittag" (goSableFlow)="goToSableFlow($event)"></app-benefit-details-available>
  </div>

</div>
