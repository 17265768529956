@if (megaMenuContents) {
  <div [ngClass]="isDesktopView ? 'd-lg-block d-md-none' : 'd-lg-none d-md-block'">
    @for (megaMenuContent of megaMenuContents; track megaMenuContent) {
      <div class="page-content-sleeve">
        <p class="col-md-8 m-b-40 p-t-36 p-l-0 xl" [innerHTML]="megaMenuContent.itemSummary | safeHtml"></p>
      </div>
      @if (isMobileView) {
        <hr class="m-b-20" aria-hidden="true">
      }
      @if (megaMenuContent.contentItems) {
        <div class="page-content-sleeve">
          <div class="row">
            @for (megaMenuContentItem of megaMenuContent.contentItems; track megaMenuContentItem; let megaMenuIndex = $index) {
              <div id="megaMenu-{{megaMenuIndex}}" [ngClass]="adjustContentView(megaMenuContent.contentItems)"
                >
                <p class="lg m-b-0">
                  @for (list of megaMenuContentItem.ctAsList; track list) {
                    <app-aon-cta class="mega-menu-cta-wrapper" (click)="closeModal(list)"
                      [fragment]="list.linkUrl.split('#')[1]" [callToAction]="list"
                      [redirectUrl]="list.linkUrl.includes('#') ? list.linkUrl.split('#')[0] : list.linkUrl"
                    (ctaCustomFunc)="ctaCustomFn.ctaCustomFuncHandler($event)"></app-aon-cta>
                  }
                </p>
                <span [innerHTML]="megaMenuContentItem.contentItemBody | safeHtml"></span>
              </div>
            }
          </div>
        </div>
      }
    }
  </div>
}