import { Component, Inject, Injector, Input, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { CommonService } from '../../core/services';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { NotificationService } from '../../core/services/notification.service';
import { JwtHelper } from '../../core/guards/jwt.helper';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../login';
import { HelperService } from '../services/helper.service';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { HomeService } from '../../modules/home/home.service';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { takeUntil, take, filter } from 'rxjs/operators';
import { ExpressLoginSteps, SessionStorageKey } from '../models/constants';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../modules/profile/profile.service';
import { SableFlowEventBusService } from '@aon/sable-flow';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html'
})
export class LoginModalComponent extends LoginComponent implements OnDestroy {
  @Input() public returnUrl: string;
  @Input() public productType: string;
  loginSucessInfoMsgKey: string;
  localExpressLoginSteps = ExpressLoginSteps;
  curExpressLoginStep: string;
  uiConfig: any;
  additionalContextData: any;

  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected commonService: CommonService,
    protected jwtHelper: JwtHelper,
    public helperService: HelperService,
    public globalOjectsService: GlobalObjectsService,
    protected homeService: HomeService,
    protected notificationService: NotificationService,
    protected uiconfigrationService: UiconfigrationService,
    protected mfaService: MFAService,
    protected profileService: ProfileService,
    public activeModal: NgbActiveModal,
    protected ngxService: NgxUiLoaderService,
    protected busservice: SableFlowEventBusService,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
    @Inject(AON_LOCALE) protected AON_LOCALE,
    public injector: Injector
  ) {
    super(
      formBuilder,
      route,
      router,
      authenticationService,
      commonService,
      jwtHelper,
      helperService,
      globalOjectsService,
      homeService,
      uiconfigrationService,
      notificationService,
      mfaService,
      profileService,
      ngxService,
      bootstrapRequestContext,
      AON_LOCALE,
      injector
    );
    this.addResourceStringKeys([
      'Aon.Login.LogInToContinue',
      'Aon.Login.NotRegisteredUser',
      'Aon.Login.CreateAccount',
      'Aon.Account.NeedHelp',
      'Aon.Login.ContactHelpDesk',
      'Aon.Login.Forgotpassword',
      'Aon.Login.ForgotUserName',
      'Aon.Common.Submit',
      'Aon.Common.EmailLabel',
      'Aon.Login.LoginTitle',
      'Aon.Login.ConnectWithAdvisor',
      'Aon.Common.PhoneNumber',
      'Aon.Common.Cancel',
      'Aon.Login.UserName',
      'Aon.Login.UserNameIsRequired',
      'Aon.Login.Password',
      'Aon.Login.PasswordIsRequired',
      'Aon.Login.LoginText',
      'Aon.Login.SuccessHeader',
      'Aon.Login.SucessInfo',
      'Aon.Login.Done',
      'Aon.Common.Close',
      'Aon.Login.ContinueAsGuest',
      'Aon.Login.ExpressCheckout',
      'Aon.Login.PasswordVisibilityControl'
    ]);
  }
  public showSucessStep: boolean;
  public hideCloseButton: boolean;
  ngOnInit(): void {
    this.isModalLogin = true;
    this.loginSucessInfoMsgKey = "Aon.Login.SucessInfo." + this.productType;
    this.stringKeys.push(this.loginSucessInfoMsgKey);
    this.uiConfig = this.authenticationService.getUIConfig();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });

    //Here we cannot use pipe takeUnitl(this.unsubscribe) because the this.authenticationService.expressLoginComplete
    //emits value after login-modal's ngOnDestroy. 
    //when user email is successfully verified, express-login.afterSubmit gets called.
    //and then login-modal.afterSubmit gets called by express-login output event emitter, 
    //but the login service overwrites the login-modal.afterSubmit which close the modal that get component destroyed
    //express-login.onSubmit -> express-login.afterSubmit -> login-modal.afterSubmit (overwritten by login-service open method)
    //While authenticationService.expressLoginComplete is emitted in express-login.completeValidation which gets called after express-login.afterSubmit
    this.authenticationService.expressLoginComplete
      .pipe(
        filter((data) => data),
        take(1))
      .subscribe(data => {
        this.completeValidation();
      });

    super.ngOnInit(this.returnUrl);
    if (this.helperService.isExpressCheckoutEnabled()) {
      this.curExpressLoginStep = this.localExpressLoginSteps.ProvideEmailAddress;
    }
  }

  linkClick(): void {
    this.activeModal.dismiss();
  }

  afterInit(): void { }

  afterSumbit(): void {
    this.showSucessStep = true;
  }

  onSuccess(): void {
    this.activeModal.close('success');
  }

  onClose(): void {
    this.activeModal.dismiss();
  }

  goToLogin(): void {
    this.isExpressLogin = false;
    this.curExpressLoginStep = "";
  }

  goToExpressLogin(): void {
    this.isExpressLogin = true;
    this.curExpressLoginStep = this.localExpressLoginSteps.ProvideEmailAddress;
  }

  createAccount(): void {
    this.activeModal.close('createAccount');
    this.createAccountcallback();
  }

  closeExpressLogin(): void {
    this.onClose();
  }

  createAccountcallback(): void { }

  isExpressCheckoutEnabled(): boolean {
    return this.helperService.isExpressCheckoutEnabled();
  }

  isNormalLogin(): boolean {
    return (this.helperService.isExpressCheckoutEnabled() == false || (this.helperService.isExpressCheckoutEnabled() && this.curExpressLoginStep == ""));
  }

  completeValidation(): void {
    let additionalContextData: any = {
      ...this.additionalContextData,
      SupressProgressInfo: false
    }

    this.busservice.next({ flag: true, saveData: { IsParticipantsMismatched: sessionStorage.getItem(SessionStorageKey.HasMismatchedParticipants) ? JSON.parse(sessionStorage.getItem(SessionStorageKey.HasMismatchedParticipants)) : false }, additionalContextData: additionalContextData });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
}
