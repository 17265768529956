import { Component, OnInit, Input } from '@angular/core';
import { SableDataViewControl, SableDataViewItemList } from '@aon/sable-dataview';

@Component({
  selector: 'lib-dataview-unorderedlist',
  templateUrl: './sable-dataview-unorderedlist.component.html'
})
export class SableDataViewUnorderedListComponent implements SableDataViewControl, OnInit {
  @Input() data: SableDataViewItemList;

  options: SableDataViewItemList;

  ngOnInit() {
    this.options = this.data;
  }
}
