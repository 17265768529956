import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { take } from 'rxjs/operators';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';
import { CommonService } from 'src/app/core/services';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { ContentItemModel } from 'src/app/shared/models/contentItem.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html'
})
export class SplashScreenComponent extends TBSBaseComponent implements OnInit {
  content: ContentItemModel;
  isImpersonation: string;

  constructor(private injector: Injector,
    private ngxService: NgxUiLoaderService,
    private commonService: CommonService,
    public uiconfigrationService: UiconfigrationService,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit(): void {
    const jwtHelper = new JwtHelper();
    const user = JSON.parse(sessionStorage.getItem('currentUser'));
    this.isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
    this.ngxService.start();
    this.commonService.getContentItem("Aon-Splash-Page").pipe(take(1)).subscribe(r => {
      this.content = r;
      this.logPageView();
      this.ngxService.stop();
    });
    let showSplashPage = this.uiconfigrationService.getUIConfiguration()?.uiConfiguration?.showSplashPage;
    //if user try to hit the splashscreen url diretly - check is added below
    if (!showSplashPage || this.isImpersonation == "True") {
      this.logPageView();
      this.router.navigate([this.helperService.getBaseUrl() + '/']);
    }
  }

}
