import { Component, Injector, ElementRef } from '@angular/core';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-aon-skip-links',
  templateUrl: './aon-skip-links.component.html'
})
export class AonSkipLinksComponent extends TBSBaseComponent {
  resourceStrings = {};
  constructor(private injector: Injector,
    private elementRef: ElementRef) {
    super(injector);
    this.addResourceStringKeys(['Aon.Common.SkipToMainContent']);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.getResouceStringsAsObject(res);
      });
  }

  skipToMainContent() {
    const element = this.elementRef.nativeElement.parentElement.parentElement.querySelector('[role="main"]');
    element.setAttribute('tabindex', '-1');
    element.focus();
  }

  ngAfterViewInit() {
    const body = document.querySelector('body');
    body.removeAttribute('tabindex');
  }
}
