import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BenefitInfoModalComponent } from 'src/app/modules/benefit/benefit-info-modal/benefit-info-modal.component';
import { HelperService } from "../services/helper.service";
import { SessionStorageKey } from '../models/constants';
import { CTAModel } from '../models/callToAction.model';

@Injectable({
  providedIn: 'root'
})
export class CTACustomFunctionService {
  baseUrl: string;
  constructor(
    private modalService: NgbModal,
    private router: Router,
    private helperService: HelperService
  ) {
    this.baseUrl = this.helperService.getBaseUrl();
  }


  private goToFlow(flowId: string): void {
    if (flowId) {
      sessionStorage.setItem('activeEventFlowId', flowId);
      this.router.navigate([this.baseUrl + '/quote/eventflow-' + flowId + '/details/']);
    }
  }

  openBenefitInfoPopup(lineId: string, lineName: string): void {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      windowClass: 'show-popup-full',
      ariaLabelledBy: 'app-modal-anchor'
    };
    ngbModalOptions.container = '#app-modal-anchor';
    let modalRef = this.modalService.open(BenefitInfoModalComponent, ngbModalOptions);
    modalRef.componentInstance.removeRoleDialog = true;
    modalRef.componentInstance.benefitId = lineId;
    modalRef.componentInstance.benefitName = lineName;
  }

  ctaCustomFuncHandler(customFunctionData: CTAModel.AdditionalParameter): void {
    if (customFunctionData.category == "product") {
      this.openBenefitInfoPopup(customFunctionData.lineRecordID, customFunctionData.lineName);
    }
    if (customFunctionData.category == "flow") {
      this.goToFlow(customFunctionData.flowID);
    }
  }

}
