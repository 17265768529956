import { Component, OnInit, ViewChild, TemplateRef, Injector, ElementRef, Inject, HostListener } from '@angular/core';
import { GridDataResult, PageChangeEvent, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { SortDescriptor, process, State } from '@progress/kendo-data-query';
import { CommonService } from 'src/app/core/services';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EmployeeMessage } from 'src/app/shared/models/employeeMessage.model';
import { take, takeUntil } from 'rxjs/operators';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { IntlService } from '@progress/kendo-angular-intl';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from '../../services/uiconfigration.service';

@Component({
  selector: 'app-masthead-inbox',
  templateUrl: './masthead-inbox.component.html'
})
export class MastheadInboxComponent extends TBSBaseComponent implements OnInit {
  @ViewChild('inboxMessage', { static: false }) private inboxMessage: any;
  public gridData: EmployeeMessage[] = [];
  public gridView: GridDataResult;
  public mySelection: any[] = [];
  public pageSize = 20;
  public skip = 0;
  public selectedDataItems;

  sort: SortDescriptor[] = [
    {
      field: "received",
      dir: "desc"
    }
  ];
  state: State = {
    skip: 0,
    take: 10
  };
  selectedItem: any = [];
  resourcestrings = {};
  items: any = [];
  selectedItemSubject: string = '';

  public clickedRowItem;
  placeholder: string = '';
  uiConfig: UIConfigurationType;
  constructor(private commonService: CommonService, private modalService: NgbModal, private injector: Injector,
    @Inject(AON_LOCALE) public AON_LOCALE, private intlService: IntlService, private uiconfigrationService: UiconfigrationService,
    private elementRef: ElementRef) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.MastheadInbox.PageTitle.Notification',
      'Aon.MastheadInbox.PageSubTitle.Mailbox',
      'Aon.MastheadInbox.Delete',
      'Aon.MastheadInbox.subject',
      'Aon.MastheadInbox.Received',
      'Aon.MastheadInbox.close',
      'Aon.MastheadInbox.filter.IsEqualTo',
      'Aon.MastheadInbox.filter.Isnotequalto',
      'Aon.MastheadInbox.filter.Contains',
      'Aon.MastheadInbox.filter.DoesNotContain',
      'Aon.MastheadInbox.filter.StartsWith',
      'Aon.MastheadInbox.filter.EndsWith',
      'Aon.MastheadInbox.filter.IsNull',
      'Aon.MastheadInbox.filter.IsNotNull',
      'Aon.MastheadInbox.filter.IsEmpty',
      'Aon.MastheadInbox.filter.IsNotEmpty',
      'Aon.MastheadInbox.filter.IsAfterorEqualTo',
      'Aon.MastheadInbox.filter.IsAfter',
      'Aon.MastheadInbox.filter.IsBeforeorequalTo',
      'Aon.MastheadInbox.filter.IsBefore',
      'Aon.MastheadInbox.NoData',
      'Aon.MastheadInbox.PageOf',
      'Aon.MastheadInbox.Item',
      'Aon.MastheadInbox.filter.Clear',
      'Aon.MastheadInbox.filter.Filter'
    ]);

  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
    this.loadItems();
    this.constructDatePlaceHolder();
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
  }

  loadItems(): void {
    this.commonService.GetEmployeeMessages().pipe(take(1)).subscribe((data) => {
      let employeeData: EmployeeMessage[] = [];

      // Deleted items removed
      data.forEach((element) => {
        if (!element.deleted) {
          employeeData.push(element);
        }
      });

      this.gridData = employeeData.map((item) => {
        item.receivedDate = new Date(item.received);
        item.receivedDate.setHours(0, 0, 0, 0);
        return item;
      });
      this.gridView = {
        data: this.gridData.slice(this.skip, this.skip + this.pageSize),
        total: this.gridData.length
      };
      this.logPageView();
    });
  }

  //paging
  pageChange(event: PageChangeEvent): void {
    this.skip = event.skip;
    this.loadItems();
  }

  //sorting
  sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadItems();
  }

  //Filter
  public dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.gridView = process(this.gridData, this.state);
  }

  //On cell click dataitem selected
  keyChange(event = null, fromKeyboard = false) {
    if (fromKeyboard) {
      this.openInbox();
    } else {
      const selectedRecordId = event[event.length - 1];
      this.selectedDataItems = this.gridData.filter(data => data.recordID === selectedRecordId);
      this.mySelection = [selectedRecordId];
    }
  }

  // On Double click event
  openInbox() {
    if (this.selectedDataItems.length == 1) {
      this.clickedRowItem = this.selectedDataItems[0];
      this.selectedItemSubject = this.clickedRowItem.subject;
      this.selectedItem = this.clickedRowItem.message;
      this.clickedRowItem.read = true;
      this.commonService.PostEmployeeMessage(this.clickedRowItem).pipe(take(1)).subscribe();
      this.showMessagePopup(this.inboxMessage);
    }
  }


  //Delete - set deleted true
  removeHandler() {
    if (this.selectedDataItems.length > 0) {
      this.selectedDataItems.forEach((item) => {
        item.deleted = true;
        this.commonService.PostEmployeeMessage(item).pipe(take(1)).subscribe();
      });
      this.mySelection = [];
    } 
    this.loadItems();    
  }

  //Popup
  showMessagePopup(content: TemplateRef<any>) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      ariaLabelledBy: 'modal-basic-title',
      ariaDescribedBy: 'modal-basic-title',
      windowClass: 'masthead-inbox'
    };

    ngbModalOptions.container = '#app-modal-anchor';
    this.modalService.open(content, ngbModalOptions);
    setTimeout(() => {
      let closebtn = document.getElementById('closeBtn');
      closebtn.focus();
    }, 100)
  }
  ngAfterViewInit() {
    let element = this.elementRef.nativeElement.querySelectorAll('.k-grid-filter-menu');
    element.forEach(ele => {
      ele?.setAttribute('tabindex', '0')
    });
    let role = this.elementRef.nativeElement.querySelectorAll("div[role='presentation']");
    role.forEach(ele => {
      ele?.removeAttribute('role')
    });
    let tab = this.elementRef.nativeElement.querySelectorAll("div[tabindex='-1']");
    tab.forEach(ele => {
      ele?.removeAttribute('tabindex')
    });
    let tableHead = this.elementRef.nativeElement.querySelectorAll("th[aria-colindex]");
    tableHead.forEach(ele => {
      ele.removeAttribute('aria-colindex');
    });
  }

  domChanged(event): void {
    try {
      let tab = this.elementRef.nativeElement.querySelectorAll("th[tabindex='-1']");
      tab.forEach(ele => {
        ele.setAttribute('tabindex', 0);
      });
    } catch { }
  }

  constructDatePlaceHolder() {
    let displayFormat = this.AON_LOCALE?.calendar?.patterns?.d || 'd';
    let split = this.intlService.splitDateFormat(displayFormat);
    split.forEach((s) => {
      if (s.type === 'literal') {
        this.placeholder += s.pattern;
      } else {
        this.placeholder += this.AON_LOCALE?.calendar?.placeholders[s.type] || s.pattern;
      }
    });
  }

  @HostListener('keydown.enter')
  triggerInboxOnKeyEnter() {
    setTimeout(() => {
      this.keyChange(null, true);
    }, 500);
  }
}
