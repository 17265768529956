<div [ngClass]="displayInBlock ? '' : 'align-items-center'" *ngIf="dependantsData">
  <div *ngIf="currentProduct == null; else checkwhosCoveredMode">
    <p class="strong m-b-0 d-inline" *ngIf="!whosCoveredLabel">{{resourceStrings['Aon.Dependents.Header']}}</p>
    <p class="strong m-b-0 pdf-allow-apostrophe" *ngIf="whosCoveredLabel">
      {{resourceStrings['Aon.BenefitDetails.CoveredTitle']}}</p>
    <span class="m-b-0" *ngFor="let dependant of dependantsData; let islast = last">
      {{dependant.firstName + ' ' + dependant.lastName + ' (' + dependant.relationshipType_Name + ')'}}
      <ng-container
        *ngIf="dependant.futureDeletedDate">({{resourceStrings['Aon.Dependants.FutureExpire']}}&nbsp;{{dependant.futureDeletedDate
        | customdate}})</ng-container>
      <ng-container *ngIf="!islast">,&nbsp; </ng-container>
    </span>
  </div>
  <ng-template #checkwhosCoveredMode>
    <ng-container
      *ngIf="currentProduct !== null && currentProduct.whosCoveredMode != 3 && currentProduct.whosCoveredMode != null">
      <p class="strong m-b-0" *ngIf="!whosCoveredLabel">{{resourceStrings['Aon.Dependents.Header']}}</p>
      <p class="strong m-b-0 pdf-allow-apostrophe"
        *ngIf="whosCoveredLabel && !displayInBlock && (!product?.isNoCoverage || product?.eoiInformation)">
        {{resourceStrings['Aon.BenefitDetails.CoveredTitle']}}</p>
      <label
        *ngIf="whosCoveredLabel && displayInBlock && dependantsData?.length >0 && (!product?.isNoCoverage || product?.eoiInformation)"
        [innerHTML]="resourceStrings['Aon.BenefitDetails.CoveredTitle'] | safeHtml"
        class="pdf-allow-apostrophe"></label>
      <ng-container class="m-t-20"
        *ngIf="(dependantsData?.length >0 && (!product?.isNoCoverage || product?.eoiInformation))">
        <p *ngIf="currentProduct.whosCoveredMode == 1" class="m-b-0">
          <ng-container *ngFor="let dependant of dependantsData; let islast = last">
            {{dependant.firstName + ' ' + dependant.lastName + ' (' + dependant.relationshipType_Name + ')'}}
            <ng-container
              *ngIf="dependant.futureDeletedDate">({{resourceStrings['Aon.Dependants.FutureExpire']}}&nbsp;{{dependant.futureDeletedDate
              | customdate}})</ng-container>
            <ng-container *ngIf="!islast">,&nbsp; </ng-container>
          </ng-container>
        </p>
        @if (currentProduct.whosCoveredMode == 2 && product.benefitPrinciple) {
        <span class="lg-para" [innerHTML]="product.benefitPrinciple | safeHtml"></span>
        } @else if(currentProduct.whosCoveredMode == 2 && product.benefitPrinciples?.length > 0) {
        <span class="lg-para">
          @for(benefitPrinciple of product?.benefitPrinciples; track $index; let lastIndex = $last) {
          {{benefitPrinciple?.trim() | striphtml}}@if (!lastIndex) {,}
          }
        </span>
        }
      </ng-container>
    </ng-container>
  </ng-template>
</div>