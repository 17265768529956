<div class="d-flex logo-container m-l-20 p-t-16 p-b-16">
  <a class="d-flex" [class.unclickable]="!helperService.IsValidClientCode()" href="javascript:;" (click)="logoAction()"
    [attr.aria-label]="resourcestrings['Aon.Logo.Label'] + ' ' + resourcestrings['Aon.Arialabel.Home']">
    @if (aonTbsLogo) {
      <img id="aonLogo" role="img" class="tbs-logo masthead-logo d-none d-lg-block m-r-16"
        src="../../../../assets/images/template/aon-logo.svg" alt="{{resourcestrings['Aon.Logo.Label']}}"
        [attr.aria-label]="resourcestrings['Aon.Logo.Label']">
        <img id="aonLogo" role="img" class="tbs-logo masthead-logo d-lg-none m-r-16"
          src="../../../../assets/images/footer/aon-logo.svg" alt="{{resourcestrings['Aon.Logo.Label']}}"
          [attr.aria-label]="resourcestrings['Aon.Logo.Label']">
        }
        <ng-container>
          @if (currentLogoPath) {
            <img id="clientLogo" role="img" class="client-logo masthead-logo m-r-16"
              src="{{currentLogoPath}}" alt="{{resourcestrings['Aon.Logo.Label']}}"
              [attr.aria-label]="resourcestrings['Aon.Logo.Label']">
          }
        </ng-container>
      </a>
      <div class="vl m-r-16 d-none d-sm-block"></div>
      <h3 class="d-none d-sm-block m-b-0"
        [ngClass]="{'italic' : uiConfig.uiConfiguration.italicizeClientBrandNameInMasthead}">{{
        resourcestrings['Aon.Navigation.LogoLabel']}}
      </h3>
    </div>