<div class="profile-dropdown-menu masthead-profile">
  <ul class="nav-dropdown-list">
    @if (hasDelegateForEmployees) {
      <li ngbDropdown
        class="drop-down-selector"
        [ngClass]="{ 'nav-item-dropdown': true, 'nav-item-menu': myUser.isOpen() }"
        #myUser="ngbDropdown">
        <a class="nav-link text-info" [attr.aria-label]="resourcestrings['Aon.Navigation.Profile.SwitchProfile']"
          href="javascript:void(0)" [attr.aria-expanded]="myUser.isOpen()"
          role="button" ngbDropdownToggle>
          <div class="icon-badge-container d-flex align-items-center">
            <span [ngbTooltip]="resourcestrings['Aon.Navigation.Profile.SwitchProfile']">
              {{ resourcestrings['Aon.Navigation.Profile.SwitchProfile']}}
            </span>
            @if (!myUser.isOpen()) {
              <i class="flex-grow-1 text-end aon-icon aon-menu-collapse m-l-4"
              aria-hidden="true"></i>
            }
            @if (myUser.isOpen()) {
              <i class="flex-grow-1 text-end aon-icon aon-angle-up m-l-4"
              aria-hidden="true"></i>
            }
          </div>
        </a>
        <div ngbDropdownMenu class="profile-dropdown">
          <app-masthead-delegate-employees [resourcestrings]="resourceStrings">
          </app-masthead-delegate-employees>
        </div>
      </li>
    }
    <li>
      <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="aboutme"
      class="dropdown-item p-l-20 p-r-20">{{ resourcestrings['Aon.Navigation.AboutMe']}}</a>
    </li>
    <li>
      @if (profileService.showMyBenefits) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="activebenefits"
          class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Profile.Benefits']}}</a>
      }
    </li>
    <li>
      @if (profileService.showLifeEvents) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="lifeevents"
          class="dropdown-item p-l-20 p-r-20">
        {{resourcestrings['Aon.Profile.LifeEvents']}}</a>
      }
    </li>
    <li>
      @if (profileService.showSaveQuotes) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="savedquote"
          class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Navigation.SavedQuotes']}}</a>
      }
    </li>
    <li>
      @if (!profileService.hideProfileAccount) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()"
          fragment="account" class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Navigation.Account']}}</a>
      }
    </li>
    <li>
      @if (profileService.showDependantsAndBeneficiaries) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="dependentsandbeneficiaries"
          class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Profile.DependentsAndBeneficiaries']}}</a>
      }
    </li>
    <li>
      @if (profileService.showDocuments) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="documents"
          class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Profile.Documents']}}</a>
      }
    </li>
    <li>
      @if (profileService.showPayments) {
        <a [routerLink]="[baseUrl+'/profile']" (click)="closeModal()" fragment="payment"
          class="dropdown-item p-l-20 p-r-20">{{
        resourcestrings['Aon.Profile.Payment']}}</a>
      }
    </li>
    <li>
      <a (click)="logOut()" class="logout dropdown-item p-l-20 p-r-20" fragment="logout" href="javascript:void(0);">{{
      resourcestrings['Aon.Navigation.Logout']}}</a>
    </li>
  </ul>
</div>
