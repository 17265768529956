import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[AlertBannerCloseButtonFocus]'
})
export class AlertBannerCloseButtonFocusDirective {

  constructor(private elementRef: ElementRef) { }

  ngOnInit() {
    let closeBtn = this.elementRef.nativeElement.querySelector('.fal.fa-times');
    if (closeBtn !== undefined && closeBtn !== null) {
      closeBtn.style.display = "none";
      this.setFocusOnBannerCloseButton(closeBtn);
    }
  }

  setFocusOnBannerCloseButton(button) {
    setTimeout(() => {
      this.elementRef?.nativeElement?.removeAttribute('role');
      button.style.display = "block";
      button.focus();
    }, 300);
  }
}