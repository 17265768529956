import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { TBSBaseComponent } from '../models/tbsBaseComponent';

@Component({
  selector: 'app-client-code-not-found',
  templateUrl: './client-code-not-found.component.html'
})
export class ClientCodeNotFoundComponent extends TBSBaseComponent implements OnInit, OnDestroy {

  constructor(public injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Common.NoClientCodeHeader',
      'Aon.Common.NoClientCodeInfo'
    ]);

  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
}
