import { Component, EventEmitter, HostListener, Injector, OnDestroy, OnInit, Output, Inject } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';
import { LanguageSelectionData } from 'src/app/shared/models';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { CTACustomFunctionService } from 'src/app/shared/services/ctacustom-function.service';
import { BootstrapRequestContext } from 'tbs-typings';

@Component({
  selector: 'app-masthead-articles',
  templateUrl: './masthead-articles.component.html'
})
export class MastheadArticlesComponent extends TBSBaseComponent implements OnInit, OnDestroy {

  @Output() hasMegaMenuContent = new EventEmitter(false);
  megaMenuContents: any;
  isMobileView: boolean;
  isTabletView: boolean;
  isDesktopView: boolean;
  screenWidgth: number;
  statusofclick: boolean;
  @Output() onCloseClick = new EventEmitter();
  constructor(private injector: Injector,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    public ctaCustomFn: CTACustomFunctionService,
    private commonService: CommonService) {
    super(injector);
    this.addResourceStringKeys([

    ]);
  }
  ngOnInit(): void {
    if (!this.bootstrapRequestContext.enableArticlePreAuthentication) {
      this.getOurValuesMenu();
    }
    else {
      this.commonService.resetOpenAccessDetails.subscribe(value => {
        if (value === true) {
          this.getOurValuesMenu();
        }
      });
    }

    this.screenWidgth = window.innerWidth;
    this.onResize();
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
  adjustContentView(content) {
    if (this.isDesktopView) {
      if (content.length > 3) {
        return 'col-lg-3 col-sm-12 col-md-6';
      } else if (content.length == 3) {
        return 'col-lg-4 col-sm-12 col-md-4';
      } else if (content.length == 2) {
        return 'col-lg-6 col-sm-12 col-md-4';
      } else if (content.length == 1) {
        return 'col-lg-12 col-sm-12 col-md-6';
      }
    } else if (this.isTabletView) {
      if (content.length >= 2) {
        return 'col-lg-6 col-sm-12 col-md-6';
      } else if (content.length == 1) {
        return 'col-lg-12 col-sm-12 col-md-6';
      }
    } else {
      return 'col-lg-12 col-sm-12 col-md-6'
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any = null) {
    if (event && event?.target && event?.target?.innerWidth) {
      this.screenWidgth = event?.target?.innerWidth;
    }
    if (this.screenWidgth >= 992) {
      this.isDesktopView = true;
      this.isTabletView = false;
      this.isMobileView = false;
    }
    if (this.screenWidgth < 992 && this.screenWidgth >= 768) {
      this.isDesktopView = false;
      this.isTabletView = true;
      this.isMobileView = false;
    }
    if (this.screenWidgth < 768) {
      this.isDesktopView = false;
      this.isTabletView = false;
      this.isMobileView = true;
    }
  }

  closeModal(cta): void {
    if (cta.additionalParameter != "" || cta.linkUrl != "") {
      this.statusofclick = true;
      this.onCloseClick.emit(this.statusofclick);
    }
  }

  private getOurValuesMenu() {
    let curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
    this.commonService.getCommonPageWithKey(curLanguageCode.cultureCode, "/Our-Values-Menu", "OurValuesMegaMenuItems", true)
      .subscribe(result => {
        this.megaMenuContents = result;
        if (this.megaMenuContents.length > 0) {
          let hasContent = false;
          this.megaMenuContents.forEach(megaMenuContent => {
            if (megaMenuContent.contentItems.length > 0 && !hasContent) {
              this.hasMegaMenuContent.emit(true);
              hasContent = true;
            }
            if (!hasContent) {
              this.hasMegaMenuContent.emit(false);
            }
          });
        } else {
          this.hasMegaMenuContent.emit(false);
        }
      });
  }
}
