export class CxoneParamsModel {

  constructor(
    cxoneNiceInstanceNo: string,
    cxoneGuideTemplateGuid: string,
    tenantId: string,
    preferredName: string,
    lastName: string,
    employeeId: string,
    employeeNumberId: string,
    culture: string,
    regionCode: string,
    isExecutive: string

  ) {
    this.cxoneNiceInstanceNo = cxoneNiceInstanceNo;
    this.cxoneGuideTemplateGuid = cxoneGuideTemplateGuid;
    this.tenantId = tenantId;
    this.preferredName = preferredName;
    this.lastName = lastName;
    this.employeeId = employeeId;
    this.employeeNumberId = employeeNumberId;
    this.culture = culture;
    this.regionCode = regionCode;
    this.isExecutive = isExecutive;
  }

  cxoneNiceInstanceNo: string;
  cxoneGuideTemplateGuid: string;
  tenantId: string;
  preferredName: string;
  lastName: string;
  employeeId: string;
  employeeNumberId: string;
  culture: string;
  regionCode: string;
  isExecutive: string;
}
