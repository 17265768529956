import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, Injector, ViewChild } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AccountService } from '../../modules/account/account.service';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { PasswordCharLength } from '../models/constants';
import { GlobalObjectsService } from '../services/global-objects.service';
import { NgModel, Validators } from '@angular/forms';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html'
})
export class NewPasswordComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Input() NewPassword: string;
  @Output() NewPasswordChange = new EventEmitter<string>();

  @Input() NewPasswordRepeat: string;
  @Output() NewPasswordRepeatChange = new EventEmitter<string>();

  @Input() IsValid: boolean;
  @Output() IsValidChange = new EventEmitter<boolean>();

  @Input() IsResetSuccess: boolean = false;
  @Input() showNewPasswordRequiredAlert = false;
  @Input() Token: string;
  //IsPlainToken is true only when at the change password step in registration flow。
  //At the step, the token is the user name
  @Input() IsPlainToken: boolean = false;
  @ViewChild('aonInputNewPassword') aonInputNewPassword: NgModel;
  isGuest: boolean = false;
  enumPasswordCharLength = PasswordCharLength;
  cssClassForContainsUserNameValidation: string;
  public PasswordChangedSubject = new Subject<KeyboardEvent>();
  public RepeatPasswordChangedSubject = new Subject<KeyboardEvent>();
  private isPasswordContainsUserName: BehaviorSubject<boolean>;
  passwordLength = "";

  constructor(private injector: Injector,
    private authenticationService: AuthenticationService,
    private globalObjectsService: GlobalObjectsService,
    private accountService: AccountService
  ) {
    super(injector);
    this.addResourceStringKeys(['Aon.Account.SampleEmail',
      'Aon.Account.EmailSentMsg',
      'Aon.Account.ContinueLogin',
      'Aon.Account.HaveAccount',
      'Aon.Account.NeedHelp',
      'Aon.Login.ContactUs',
      'Aon.Account.ResetPassword',
      'Aon.Account.InputRecoveryEmail',
      'Aon.Account.WorkEmail',
      'Aon.Common.Continue',
      'Aon.Account.ChangePassword',
      'Aon.Account.ChangePasswordIntro',
      'Aon.Account.CurrentPassword',
      'Aon.Account.PasswordRequirements',
      'Aon.Password.TenChars',
      'Aon.Password.OneUpperCase',
      'Aon.Password.OneNumber',
      'Aon.Password.OneSpecialChar',
      'Aon.Account.PasswordRepeatMatch',
      'Aon.Account.NewPassword',
      'Aon.Account.ConfirmNewPassword',
      'Aon.Common.Submit',
      'Aon.Common.ChangePwdSuccessfully',
      'Aon.Login.UserName',
      'Aon.Login.LoginText',
      'Aon.Login.PasswordVisibilityControl',
      'Aon.Account.ResetPasswordSuccess',
      'Aon.Account.ReEnterNewPassword',
      'Aon.Password.OneLowerCase',
      'Aon.Account.PasswordShouldnotContainUsername',
      'Aon.Login.Password',
      'Aon.Password.RepetitiveChar',
      'Aon.Password.SequentialChar',
      'Aon.Password.PasswordLength',
      'Aon.Login.PasswordIsRequired'
    ]);
  }
  regexRuleCharLength = "";
  regexRuleUpperCase = "(?=.*[A-Z])";
  regexRuleLowerCase = "(?=.*[a-z])";
  regexRuleOneNumber = ".*[0-9].*";
  regexRuleSpecialChar = "[!@#$%^&+=*<>?]+";
  regexRulerepetitiveChar = "([0-9a-zA-Z])\\1{2,}";
  regexRuleAllNum = "(^\\d+$)";
  regexRuleAllLetter = "(^[a-z]+$)";
  userName = "";

  NewPasswordTouched = false;
  NewPasswordRepeatTouched = false;

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
    this.getUserName();

    this.PasswordChangedSubject
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(1000)
      ).subscribe(data => {
        this.newPasswordChanged();
      });

    this.RepeatPasswordChangedSubject
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(1000)
      ).subscribe(data => {
        this.repeatPasswordChanged();
      });

    this.isPasswordContainsUserName = new BehaviorSubject<boolean | null>(null);
    this.isPasswordContainsUserName.subscribe(data => {
      if (data == null) {
        this.cssClassForContainsUserNameValidation = "fal fa-circle validation-pending";
      } else {
        if (data) {
          this.cssClassForContainsUserNameValidation = 'fal fa-times-circle validation-failed';
        } else {
          this.cssClassForContainsUserNameValidation = 'fal fa-check-circle validation-success';
        }
      }
    })
    const CharLength = [PasswordCharLength.LengthFourteen, PasswordCharLength.LengthTwelve, PasswordCharLength.LengthTen, PasswordCharLength.LengthEight];
    this.accountService.GetPasswordStrengthOption()
      .subscribe(data => {
        this.regexRuleCharLength = CharLength.find(el => data.includes(el));
        this.passwordLength = this.regexRuleCharLength.replace(/\D+/g, '');
      });
  }

  ngOnChanges(changes: any) {
    this.validateIfContainsUserName();
    if (this.showNewPasswordRequiredAlert) {
      setTimeout(() => {
        if (this.aonInputNewPassword) {
          this.aonInputNewPassword.control.markAsTouched();
        }
      }, 200);
    }
    if (this.IsResetSuccess) {
      this.aonInputNewPassword.control.reset();
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  regexValidation(regexp: string, valuestr: string): boolean {
    valuestr = valuestr ? valuestr : '';
    const patt = new RegExp(regexp);
    return patt.test(valuestr);
  }


  resultIcon(rulePass: boolean, ruleTouched: boolean): string {
    let result = '';
    if (!this.IsResetSuccess) {
      if (!ruleTouched) {
        result = 'fal fa-circle validation-pending';
      } else if (rulePass) {
        result = 'fal fa-check-circle validation-success';
      } else if (!rulePass && ruleTouched) {
        result = 'fal  fa-times-circle validation-failed';
      }
    } else {
      result = 'fal fa-circle validation-pending';
    }
    return result;
  }

  usernamevalidation(ruleTouched: boolean): string {
    let result = '';
    if (!this.IsResetSuccess) {
      if (!ruleTouched) {
        result = 'fal fa-circle validation-pending';
      }
      else if (this.NewPassword.toLowerCase().includes(this.userName.toLowerCase())) {
        result = 'fal fa-times-circle validation-failed';
      }
      else {
        result = 'fal fa-check-circle validation-success';
      }
    } else {
      result = 'fal fa-circle validation-pending';
    }

    return result;
  }

  newPasswordChanged(): void {
    if (this.NewPassword == '') {
      this.NewPasswordTouched = false;
    } else {
      this.NewPasswordTouched = true;
    }
    this.NewPasswordChange.emit(this.NewPassword);
    this.validateEntireForm();
  }

  repeatPasswordChanged(): void {
    if (this.NewPasswordRepeat == '') {
      this.NewPasswordRepeatTouched = false;
    } else {
      this.NewPasswordRepeatTouched = true;
    }
    this.NewPasswordRepeatChange.emit(this.NewPasswordRepeat);
    this.validateIfContainsUserName();
    this.validateEntireForm();
  }

  validateEntireForm(): boolean {
    const result = this.regexValidation(this.regexRuleCharLength, this.NewPassword) &&
      this.regexValidation(this.regexRuleUpperCase, this.NewPassword) &&
      this.regexValidation(this.regexRuleOneNumber, this.NewPassword) &&
      this.regexValidation(this.regexRuleSpecialChar, this.NewPassword) &&
      !this.regexValidation(this.regexRulerepetitiveChar, this.NewPassword) &&
      !this.isSequential(this.NewPassword) &&
      !this.validateIfContainsUserName() &&
      (this.NewPassword == this.NewPasswordRepeat);
    this.IsValidChange.emit(result);
    return result;
  }

  isSequential(newPassword: string) {
    let isSeq = false;
    if (newPassword !== null && newPassword !== undefined && newPassword.length > 0) {
      let password = newPassword.toLowerCase();

      for (let i = 0; i < password.length - 2; i++) {
        let pass = password.substring(i, i + 3);
        let isNum = this.regexValidation(this.regexRuleAllNum, pass);
        let isLetter = this.regexValidation(this.regexRuleAllLetter, pass);

        if ((isLetter || isNum) && (password[i].codePointAt(0) + 1 == password[i + 1].codePointAt(0) && password[i + 1].codePointAt(0) + 1 == password[i + 2].codePointAt(0))) {
          isSeq = true;
          break;
        }
      }
    }
    return isSeq;
  }

  validateIfContainsUserName() {
    let isConatainUserName = false;
    if (this.NewPassword) {
      if (this.isGuest) {
        if (!this.IsPlainToken) {
          this.accountService.IsPasswordContainsUserName(this.Token, this.NewPassword)
            .subscribe(data => {
              this.isPasswordContainsUserName.next(data);
              isConatainUserName = data;
            });
        } else {
          if (this.NewPassword.toLowerCase().includes(this.Token.toLowerCase())) {
            this.isPasswordContainsUserName.next(true);
            isConatainUserName = true;
          } else {
            this.isPasswordContainsUserName.next(false);
          }
        }
      } else {
        this.getUserName();
        if (this.NewPassword.toLowerCase().includes(this.userName.toLowerCase())) {
          this.isPasswordContainsUserName.next(true);
          isConatainUserName = true;
        } else {
          this.isPasswordContainsUserName.next(false);
        }
      }
    }
    return isConatainUserName;
  }

  private getUserName() {
    if (sessionStorage.getItem('NewUserName') != null) {
      this.userName = sessionStorage.getItem('NewUserName');
    } else {
      if (this.authenticationService.isLogin) {
        this.isGuest = false;
        this.globalObjectsService.compactEmployeeInformation.subscribe(emp => {
          this.userName = emp?.userName;
        });
      } else {
        this.isGuest = true;
      }
    }
  }
}
