import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { catchError, finalize, map, Observable, throwError } from 'rxjs';

import { BootstrapRequestContext } from 'tbs-typings';
import { AuthenticationService } from '../authentication/authentication.service';
import { LanguageSelectionData, User } from '../../shared/models';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  apiBaseUrl: string;
  apiCalls: any = [];
  constructor(
    private authenticationService: AuthenticationService,
    private globalObjService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage')); // just use localId?

    let currentUser: User;
    if (this.authenticationService.tempUser != null) {
      currentUser = this.authenticationService.tempUser;
    } else {
      currentUser = this.authenticationService.currentUserValue;
    }

    const isLoggedIn = currentUser && currentUser.access_token;
    const isApiUrl = request.url.startsWith(this.apiBaseUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.access_token}`,
          TimeZoneOffSet: new Date().getTimezoneOffset().toString(),
          'Accept-Language': curLanguageCode == null ? 'en-US' : curLanguageCode.cultureCode
        }
      });
    }
    const requestTime = new Date().getTime();
    const updateApiCalls = (value) => {
      if (isApiUrl)
        this.apiCalls[requestTime] = value;
    }
    updateApiCalls(0);
    this.checkApiDurationInterval(requestTime);
    return next.handle(request).pipe(map((event: HttpEvent<any>) => event), catchError(err => throwError(() => err)), finalize(() => updateApiCalls(1)));
  }

  /**
   * Method to check the API completion status for the accessibility
   * Based on the completion, screen reader should announce the state as 'Page Loading' and 'Page Loaded' when it's completed
   * @param requestTime -> Current Request Time
   */
  checkApiDurationInterval(requestTime) {
    let timeIntervalSeconds: number = 0;
    let maxTimeoutSeconds: number = 60; // 1min in seconds i.e., 60*1=60
    let waitLoadingTillSeconds = 3;
    let interval = setInterval(() => {
      // Screen Reader trigger if more than waitLoadingTillSeconds eg.3s
      if (timeIntervalSeconds >= waitLoadingTillSeconds) {
        this.globalObjService.w3ScreenReader.pageLoadingAnnouncement = Object.values(this.apiCalls).includes(0) ? true : this.globalObjService.w3ScreenReader.pageLoadingAnnouncement;
      }

      /** Single API Request
       * timeIntervalSeconds == maxTimeoutSeconds => Skipping the Interval if it reached the max timeout
       * this.apiCalls[requestTime] => Stopping the interval if the request was completed
       */
      if (this.apiCalls[requestTime] || timeIntervalSeconds == maxTimeoutSeconds) {
        // If Page Loading was triggered then it should announce page loaded when all the API request was completed
        delete this.apiCalls[requestTime];
        setTimeout(() => {
          if (this.globalObjService.w3ScreenReader.pageLoadingAnnouncement && Object.values(this.apiCalls).length == 0) {
            this.globalObjService.w3ScreenReader.pageLoadingAnnouncement = false;
            this.globalObjService.w3ScreenReader.pageLoadedAnnouncement = true;
            setTimeout(() => {
              this.globalObjService.w3ScreenReader.pageLoadedAnnouncement = false;
            }, 2000)
          }
        }, 500);
        clearInterval(interval);
      }
      timeIntervalSeconds++;
    }, 1000)
  }
}
