import { Component, Injector, OnInit } from '@angular/core';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vb-global-error-page',
  templateUrl: './vb-global-error-page.component.html'
})
export class VbGlobalErrorPageComponent extends TBSBaseComponent implements OnInit  {

  constructor(private injector: Injector,) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Common.GlobalError',
      'Aon.Product.Unavailable',
    ]);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(res => {
      this.getResouceStringsAsObject(res);
    });
  }

}
