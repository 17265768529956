import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { CommonPageModel } from '../../shared/models/commonPage.model';
import { BehaviorSubject } from 'rxjs';
import { Faq } from '../../shared/models/faq.model';
import { ProductCoverageModel, ProductModel } from './product.model'
import { Article } from '../../shared/models/article.model';
import { PurchaseStatus } from '../../shared/models/PurchaseStatus.model';
import { AdditionalProductData, AdditionalProductDataRequest } from 'src/app/shared/models/product.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  apiBaseUrl: string;
  tenantFolderName: string;

  public flowID: string;
  public productArticles: BehaviorSubject<Article[]>;
  public productPurchaseStatus: BehaviorSubject<PurchaseStatus>;
  public currentBenefitID: BehaviorSubject<string>;
  constructor(
    private http: HttpClient,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.tenantFolderName = bootstrapRequestContext.tenantFolderName;
    this.productArticles = new BehaviorSubject<Article[]>([]);
    this.productPurchaseStatus = new BehaviorSubject<PurchaseStatus>(null);
    this.currentBenefitID = new BehaviorSubject<string>(null);
  }

  public updateProductArticles(data: Article[]) {
    this.productArticles.next(data);
  }

  public getCommonPage(currentCulture: string, path: string) {
    let commonPageUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/commonpage';
    let nodePath = this.tenantFolderName + path;
    return this.http.post<CommonPageModel>(commonPageUrl, { Path: nodePath });
  }



  public GetFaqs(currentCulture: string, parentPath: string) {
    let faqsUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/faqs?parentPath=' + parentPath;
    return this.http.get<Faq[]>(faqsUrl);
  }

  //public getProductName(currentCulture: string, benefitGuid: string, parentPath: string) {
  //  let productNameUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/productname/' + benefitGuid + '?parentPath=' + parentPath;
  //  return this.http.get(productNameUrl, { responseType: 'text' });
  //}
  public getproductItems(currentCulture: string, benefitGuid: string, parentPath: string, campaignKey: string) {
    let productNameUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/productItems/' + benefitGuid + '?parentPath=' + parentPath;
    if (campaignKey != null && campaignKey.length > 0) {
      productNameUrl += "&campaignKey=" + campaignKey;
    }
    return this.http.get<ProductModel>(productNameUrl);
  }
  public getcoverageDetails(currentCulture: string, benefitGuid: string, parentPath: string) {
    let productNameUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/coveragedetails/' + benefitGuid + '?parentPath=' + parentPath;
    return this.http.get<ProductCoverageModel>(productNameUrl);
  }

  public getAdditionalProductData(currentCulture: string, productIdsList: AdditionalProductDataRequest[]) {
    let additionProductDataUrl = this.apiBaseUrl + currentCulture + '/' + this.bootstrapRequestContext.siteName + '/additionProductData';
    return this.http.post<AdditionalProductData[]>(additionProductDataUrl, productIdsList);
  }

  public purchasesummary() {
    let purchasesummary = this.apiBaseUrl + 'purchasesummary';
    return this.http.get<string>(purchasesummary);
  }

  public getPurchasesummaryById(benefitID: string) {
    let purchasesummary = this.apiBaseUrl + 'purchasesummary/' + benefitID;
    return this.http.get<string>(purchasesummary);
  }

  public getArticles(benefitGuid: string, parentPath: string) {
    let productNameUrl = this.apiBaseUrl + benefitGuid + '/articles' + '?parentPath=' + parentPath;
    return this.http.get<Article[]>(productNameUrl);
  }

  public ReplaceMacroKeyword(dataModel: any, stringSource: string, macroKey: string): string {
    let keyInSource = '{&' + macroKey + '&}';
    let keyValue = dataModel[macroKey];
    return stringSource.replace(keyInSource, keyValue);
  }
}
