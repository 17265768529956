<div class="site-masthead" aria-label="navContent" role="navigation" id="nav">
  <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="{ 'navbar-collapsed': isNavbarCollapsed }"
    aria-label="site-masthead-nav" id="nav-site-masthead">
    <div class="d-md-block">
      <app-masthead-logo [resourcestrings]="resourceStrings" (onCloseClick)="closemodalcross($event)">
      </app-masthead-logo>
    </div>

    @if (showSplashPageMenu) {
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault"
        >
        <ul class="head-menu navbar-nav ms-auto">
          @if (authenticationService.isLogin && headMenuObject.profile) {
            <li ngbDropdown
              class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
              [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
              #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
              <a class="nav-link text-info" href="javascript:void(0)" id="dropdownBasic3"
                (click)="closeOtherDropdowns(myUser)" [attr.aria-expanded]="myUser.isOpen()" role="button"
                ngbDropdownToggle>
                <i class="aon-icon aon-user m-r-10" aria-hidden="true"></i><span [ngbTooltip]="preferredName">
                {{
                truncate(preferredName)
                }}@if ((isFailedPayment || hasGracePeriod)) {
                <span
                class="badge bg-danger user-badge d-inline-block p-t-0 p-b-0 p-l-0 p-r-0"></span>
              }
            </span>
            @if (!myUser.isOpen()) {
              <i class="float-end aon-icon aon-menu-collapse m-l-5" aria-hidden="true"></i>
            }
            @if (myUser.isOpen()) {
              <i class="float-end  aon-icon aon-angle-up m-l-5" aria-hidden="true"></i>
            }
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="profile-dropdown">
            <div class="profile-dropdown-menu masthead-profile">
              <a href="javascript:void(0)" (click)="logOut()" (keyup.enter)="logOut()"
                class="logout dropdown-item p-l-20 p-r-20" fragment="logout">
                {{
                resourceStrings['Aon.Navigation.Logout']
                }}
              </a>
            </div>
          </div>
        </li>
      }
    </ul>
  </div>
}

@if (!showSplashPageMenu) {
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault"
    >
    <ul class="head-menu navbar-nav ms-auto">
      @if (authenticationService.isLogin && headMenuObject.profile) {
        <li ngbDropdown
          class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
          [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
          #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
          <a class="nav-link text-info" [attr.aria-label]="resourceStrings['Aon.Delegate.Acting.As'] + preferredName + ', ' + resourceStrings['Aon.Navigation.Profile']"
            href="javascript:void(0)" (click)="closeOtherDropdowns(myUser)" [attr.aria-expanded]="myUser.isOpen()"
            role="button" ngbDropdownToggle>
            <div class="icon-badge-container d-flex align-items-center">
              <i class="aon-icon aon-user d-flex" aria-hidden="true" data-heap-redact-text></i>
              <span [ngbTooltip]="resourceStrings['Aon.Delegate.Acting.As'] + preferredName">
                {{resourceStrings['Aon.Delegate.Acting.As'] + truncate(preferredName)}}
              </span>
              @if (!myUser.isOpen()) {
                <i class="flex-grow-1 text-end aon-icon aon-menu-collapse m-l-4"
                aria-hidden="true"></i>
              }
              @if (myUser.isOpen()) {
                <i class="flex-grow-1 text-end aon-icon aon-angle-up m-l-4"
                aria-hidden="true"></i>
              }
            </div>
          </a>
          <div ngbDropdownMenu class="profile-dropdown">
            <app-masthead-profile-delegate [resourcestrings]="resourceStrings"
              (onGracePeriodUpdated)="onGracePeriodUpdated($event)"
              (onCloseClick)="closemodalcross($event); myUser.close()">
            </app-masthead-profile-delegate>
          </div>
        </li>
      }
      @if (headMenuObject.help && helperService.IsValidClientCode()) {
        <li
          class="nav-item nav-li li-order-{{ headMenuObject.help }} anaval-help"
          [ngClass]="{'nav-item-dropdown dropdown': !helperService.AllowAnonymousAccess(), 'd-none d-md-block': !showAfterLoginCheck()}">
          <a class="nav-link text-info d-flex align-items-center" (click)="isNavbarCollapsed = true"
            [routerLink]="[baseUrl + '/home/help']" routerLinkActive="active" aria-labelledby="helpLabel"
            aria-describedby="helpDescription" [attr.aria-label]="resourceStrings['Aon.Arialabel.Help']">
            <div class="icon-badge-container d-flex align-items-center">
              <i class="aon-icon aon-help d-flex" aria-hidden="true"></i>
              <span class="sr-only" id="helpDescription">{{ resourceStrings["Aon.Help.Description"] }}</span>
              <span [ngClass]="{'d-visible d-lg-none': isOlb()}" id="helpLabel">
                {{
                resourceStrings["Aon.Navigation.Help"]
                }}
              </span>
            </div>
          </a>
        </li>
      }
    </ul>
  </div>
}
</nav>

</div>
