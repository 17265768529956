<div id="marketingpreference" class="marketing-preference"
  *ngIf="uiConfig.uiConfiguration.enableMarketingConsent && employeeServiceCommsPreferences?.length > 0">
  <div class="row">
    <div class="col-12">
      <h4 class="m-r-10 h5" *ngIf="resourceStrings['Aon.Profile.CommunicationPreferences'] !== undefined"
        [innerHTML]="resourceStrings['Aon.Profile.CommunicationPreferences'] | safeHtml"></h4>
    </div>
  </div>
  <div class="row" *ngIf="showNoEmailProvidedAlert">
    <div class="col-12 m-b-20">
      <div class="alert contextual error m-b-0" role="alert" aria-live="polite">
        <div class="message">
          <i class="fas fa-exclamation-circle" aria-hidden="true"></i>
          <div>
            <span [innerHTML]="resourceStrings['Aon.Profile.Alert.NoEmailProvided']"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="saveEmployeeServiceCommsPreferencesSuccess">
    <div class="col-12 m-b-20">
      <div class="alert contextual success m-b-0" role="alert" aria-live="polite">
        <div class="message">
          <i class="fas fa-check-circle" aria-hidden="true"></i>
          <div>
            <span
              [innerHTML]="resourceStrings['Aon.Profile.SaveCommunicationPreferencesSuccessPreAboutMe'] | safeHtml"></span>
            <a class="about-me" [routerLink]="[]" (click)="goToAboutMe()">{{
              resourceStrings['Aon.Profile.AboutMe']}}</a> <span
              [innerHTML]="resourceStrings['Aon.Profile.SaveCommunicationPreferencesSuccessAfterAboutMe'] | safeHtml"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-b-20"
    *ngIf="saveEmployeeServiceCommsPreferencesError && employeeServiceCommsPreferencesErrorMessage">
    <div class="col-12">
      <div class="alert contextual error">
        <i class="fas fas fa-exclamation-triangle"
          aria-hidden="true"></i><span>{{employeeServiceCommsPreferencesErrorMessage}}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngFor="let escp of employeeServiceCommsPreferences; let i = index">
      <p class="strong" [innerHTML]="resourceStrings['Aon.Profile.Comms.SubscriptionStatus.Description'] | safeHtml">
      </p>
      <div class="form-group">
        <input (change)="escp.isOptedOutOfComms = !escp.isOptedOutOfComms" id="optOutComns" name="optOutComns"
          type="checkbox" [checked]="!escp.isOptedOutOfComms" />
        <label for="optOutComns" [innerHtml]="resourceStrings['Aon.Profile.Marketing.Selection'] | safeHtml"></label>
      </div>
      <div class="button-sleeve">
        <button class="spinner save" (click)="saveElectedEmployeeServiceCommsPreferences(escp)">
          {{resourceStrings['Aon.Common.Save']}}<span *ngIf="commsPrefDataSaveInProgress" class="spinner-border spinner-border-sm"></span>
        </button>
      </div>
    </div>
  </div>
</div>
