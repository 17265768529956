<div class="site-masthead" aria-label="navContent" role="navigation" id="nav">
  <nav class="navbar navbar-expand-lg navbar-light" [ngClass]="{ 'navbar-collapsed': isNavbarCollapsed }"
    aria-label="site-masthead-nav" id="nav-site-masthead">
    <div class="d-md-block">
      <app-masthead-logo [resourcestrings]="resourceStrings" (onCloseClick)="closemodalcross($event)">
      </app-masthead-logo>
    </div>
    <div class="main-navbar-controls d-flex align-items-center">

      @if (headMenuObject.message && authenticationService.isLogin && showInbox && !showSplashPageMenu) {
        <div
          ngbDropdown class="message-badge d-lg-none d-flex align-items-center" [autoClose]="dropDownAutoClose">
          <a class="nav-link text-info focus-visibility" [routerLink]="[baseUrl + '/inbox']" data-toggle="tooltip" data-placement="bottom"
            title="Messages" [attr.aria-label]="resourceStrings['Aon.Arialabel.Inbox']">
            <div class="icon-badge-container">
              <i class="aon-icon aon-message d-flex align-items-center" aria-hidden="true"></i>
              @if (commonService.unreadCount) {
                <i [ngClass]="{'message-notification': commonService.unreadCount}"></i>
              }
            </div>
          </a>
        </div>
      }

      @if (headMenuObject.cart && isMobile) {
        <div display="dynamic" ngbDropdown class="cart-badge d-md-none"
          [ngClass]="{ 'nav-cart-mobile': myCart.isOpen() }" #myCart="ngbDropdown" [autoClose]="dropDownAutoClose">
          <a class="nav-link text-info focus-visibility" id="dropdownBasic6" (click)="closeOtherDropdowns(myCart)"
            [attr.aria-expanded]="myCart.isOpen()" role="button" ngbDropdownToggle>
            <i class="aon-icon aon-cart m-r-8" [ngClass]="{ 'cart-icon-mobile': myCart.isOpen() }" aria-hidden="true"></i>
            @if (authenticationService.isLogin && this.productsCount>0) {
              <div class="icon-badge">
                <span class="badge bg-danger">{{this.productsCount}}</span>
              </div>
            }
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic6" class="cart-dropdown">
            <app-masthead-cart [resourcestrings]="resourceStrings" (onCartUpdated)="onCartUpdated($event)"
            (hideCartPopUp)="myCart.close()"></app-masthead-cart>
          </div>
        </div>
      }

      @if (headMenuObject.help && !showAfterLoginCheck()) {
        <div class="d-md-none text-end flex-grow-1">
          <a class="nav-link text-info focus-visibility" (click)="isNavbarCollapsed = true" [routerLink]="[baseUrl + '/home/help']"
            routerLinkActive="active" [attr.aria-label]="resourceStrings['Aon.Arialabel.Help']">
            <i class="aon-icon aon-help d-flex" aria-hidden="true"></i>
          </a>
        </div>
      }
      <div class="navbar-toggler" data-target="#navbarsDefault" aria-controls="navbarsDefault">
        @if (isNavbarCollapsed) {
          <i tabindex="0" class="fal fa-bars focus-visibility" (click)="toggleNavBar()"
          (keyup.enter)="toggleNavBar()" role="button" [attr.aria-label]="resourceStrings['Aon.Arialabel.Toggle']"></i>
        }
        @if (!isNavbarCollapsed) {
          <i tabindex="0" class="fal fa-times focus-visibility" (click)="toggleNavBar()"
          (keyup.enter)="toggleNavBar()" role="button" [attr.aria-label]="resourceStrings['Aon.Arialabel.Toggle']"></i>
        }
      </div>
    </div>
    @if (showSplashPageMenu) {
      <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault"
        >
        <ul class="head-menu navbar-nav ms-auto">
          @if (authenticationService.isLogin && headMenuObject.profile) {
            <li ngbDropdown
              class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
              [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
              #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
              <a class="nav-link text-info focus-visibility" href="javascript:void(0)" id="dropdownBasic3"
                (click)="closeOtherDropdowns(myUser)" [attr.aria-expanded]="myUser.isOpen()" role="button"
                ngbDropdownToggle>
                <i class="aon-icon aon-user m-r-10" aria-hidden="true"></i><span [ngbTooltip]="preferredName">
                {{
                truncate(preferredName)
                }}@if ((isFailedPayment || hasGracePeriod)) {
                <span
                class="badge bg-danger user-badge d-inline-block p-t-0 p-b-0 p-l-0 p-r-0"></span>
              }
            </span>
            @if (!myUser.isOpen()) {
              <i class="float-end aon-icon aon-menu-collapse m-l-5" aria-hidden="true"></i>
            }
            @if (myUser.isOpen()) {
              <i class="float-end  aon-icon aon-angle-up m-l-5" aria-hidden="true"></i>
            }
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic3" class="profile-dropdown">
            <div class="profile-dropdown-menu masthead-profile">
              <a href="javascript:void(0)" (click)="logOut()" (keyup.enter)="logOut()"
                class="logout dropdown-item p-l-20 p-r-20" fragment="logout">{{
              resourceStrings['Aon.Navigation.Logout']}}</a>
            </div>
          </div>
        </li>
      }
    </ul>
  </div>
}

@if (!showSplashPageMenu) {
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault"
    >
    <ul class="head-menu navbar-nav ms-auto">
      @if (headMenuObject.products && showAfterLoginCheck() && helperService.IsValidClientCode()) {
        <li ngbDropdown
          class="nav-item nav-item-dropdown nav-li li-order-{{headMenuObject.products}} anaval-products"
          [ngClass]="{ 'navbar-item-expanded': myDrop.isOpen() }" #myDrop="ngbDropdown" [autoClose]="dropDownAutoClose">
          <a class="nav-link text-info focus-visibility" href="javascript:void(0)" id="dropdownBasic"
            (click)="closeOtherDropdowns(myDrop)" [attr.aria-expanded]="myDrop.isOpen()" role="button"
            ngbDropdownToggle>
            {{ resourceStrings["Aon.Navigation.Products"] }}
            @if (!myDrop.isOpen()) {
              <i class="float-end aon-icon aon-menu-collapse m-l-5" aria-hidden="true"></i>
            }
            @if (myDrop.isOpen()) {
              <i class="float-end  aon-icon aon-angle-up m-l-5" aria-hidden="true"></i>
            }
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="masthead-dropdown products-dropdown">
            <!-- For Products -->
            @if (!isCategorizationEnabled) {
              <app-masthead-products [products]="products"
                [resourcestrings]="resourceStrings" (hideProductPopUp)="myDrop.close()"
              (hideMobileNavBar)="isNavbarCollapsed = true"></app-masthead-products>
            }
            <!-- For Categories -->
            @if (isCategorizationEnabled) {
              <app-masthead-products-v2 [categories]="categories" [resourcestrings]="resourceStrings"
                (hideProductPopUp)="myDrop.close()"
              (hideMobileNavBar)="isNavbarCollapsed = true"></app-masthead-products-v2>
            }
          </div>
        </li>
      }
      @if (headMenuObject.faqs && showAfterLoginCheck() && helperService.IsValidClientCode()) {
        <li ngbDropdown
          class="nav-item nav-li nav-item-dropdown li-order-{{ headMenuObject.faqs }} anaval-faq"
          [autoClose]="dropDownAutoClose">
          <a class="nav-link text-info focus-visibility" (click)="isNavbarCollapsed = true" [routerLink]="[baseUrl + '/home/faqs']"
            routerLinkActive="active" [attr.aria-label]="resourceStrings['Aon.Arialabel.FAQ']">{{
          resourceStrings["Aon.Navigation.Faqs"] }}</a>
        </li>
      }
      @if (!authenticationService.isLogin && headMenuObject.login && helperService.IsValidClientCode()) {
        <li
          class="nav-item nav-li nav-li-login-link li-order-{{headMenuObject.login}} anaval-login">
          <a class="nav-link text-info focus-visibility" (click)="isNavbarCollapsed = true" [routerLink]="[baseUrl + '/login']"
            routerLinkActive="active" [attr.aria-label]="resourceStrings['Aon.Arialabel.Login']">
            <i class="aon-icon aon-user m-r-10" aria-hidden="true"></i>{{ resourceStrings["Aon.Navigation.Login"] }}
          </a>
        </li>
      }
      @if (headMenuObject.cart && !isMobile && helperService.IsValidClientCode()) {
        <li  display="dynamic" ngbDropdown
          class="cart-badge d-none d-lg-block m-l-12 m-r-10 nav-item nav-li nav-li-login li-order-{{headMenuObject.cart}} anaval-cart"
          [ngClass]="{ 'nav-item-menu': myCart.isOpen() }" #myCart="ngbDropdown" [autoClose]="dropDownAutoClose">
          <a [hidden]="helperService.isSingleProductCheckOutEnabled()" class="nav-link text-info focus-visibility"
            href="javascript:void(0)" id="dropdownBasic5" (click)="closeOtherDropdowns(myCart)"
            [attr.aria-expanded]="myCart.isOpen()" aria-label="Cart" role="button" ngbDropdownToggle>
            <div class="icon-badge-container">
              <i class="aon-icon aon-cart m-r-8 icon-badge-icon" aria-hidden="true">
              </i>
              @if (authenticationService.isLogin && this.productsCount>0) {
                <div class="icon-badge">
                  <span class="badge bg-danger">{{this.productsCount}}</span>
                </div>
              }
            </div>
          </a>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic5" class="cart-dropdown">
            <app-masthead-cart [resourcestrings]="resourceStrings" (onCartUpdated)="onCartUpdated($event)"
            (hideCartPopUp)="myCart.close()"></app-masthead-cart>
          </div>
        </li>
      }
      @if (headMenuObject.getquote && showAfterLoginCheck() && !isCategorizationEnabled && helperService.IsValidClientCode()) {
        <li
          class="nav-item d-none d-lg-block li-order-{{headMenuObject.getquote}} anaval-get-quote">
          <input type="button" class="prominent get-quote" value="{{ resourceStrings['Aon.Navigation.GetQuote'] }}"
            (click)="open(content)" />
          </li>
        }
        @if (headMenuObject.getquotemobile) {
          <li
            class="nav-item nav-li-button li-order-{{headMenuObject.getquotemobile}} anaval-get-quote-mobile">
            <button class="get-quote prominent inverse-button d-block d-lg-none m-t-10 m-b-10" (click)="open(content)">
              {{
              resourceStrings["Aon.Navigation.GetQuote"]
              }}
            </button>
          </li>
        }
        @if (headMenuObject.values && showArticleWrapperDetails && showMegaMenu ) {
          <li ngbDropdown
            class="nav-item-dropdown nav-li li-order-{{headMenuObject.values}} anaval-values"
            [ngClass]="{ 'navbar-item-expanded': myValues.isOpen() }" #myValues="ngbDropdown"
            [autoClose]="dropDownAutoClose" (openChange)="closeModelAddOverlay($event)">
            <a class="nav-link text-info focus-visibility" href="javascript:void(0)" id="dropdownBasic"
              (click)="closeOtherDropdowns(myValues)" [attr.aria-expanded]="myValues.isOpen()" role="button"
              ngbDropdownToggle>
              {{ resourceStrings["Aon.Navigation.OurValues"] }}
              @if (!myValues.isOpen()) {
                <i class="float-end aon-icon aon-menu-collapse m-l-5" aria-hidden="true"></i>
              }
              @if (myValues.isOpen()) {
                <i class="float-end  aon-icon aon-angle-up m-l-5 " aria-hidden="true"></i>
              }
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic" class="masthead-dropdown masthead-megamenu-dropdown">
              <app-masthead-articles (hasMegaMenuContent)="checkMegaMenuHasContent($event)"
              (onCloseClick)="closeOurValues($event, myValues)"></app-masthead-articles>
            </div>
          </li>
        }
        @if (ShowMastHeadBenefitsMenu) {
          <li ngbDropdown (openChange)="closeModelAddOverlay($event)"
            class="nav-item-dropdown nav-li li-order-{{headMenuObject.benefits}} anaval-benefits"
            [attr.aria-label]='resourceStrings["Aon.Common.Profile.BenefitMenu"]'
            [ngClass]="{ 'navbar-item-expanded': myBenefits.isOpen() }" #myBenefits="ngbDropdown"
            [autoClose]="dropDownAutoClose">
            <a class="nav-link text-info focus-visibility" href="javascript:void(0)" id="dropdownBenefits"
              (click)="closeOtherDropdowns(myBenefits)" [attr.aria-expanded]="myBenefits.isOpen()" role="button"
              ngbDropdownToggle>
              {{ resourceStrings["Aon.Common.Profile.BenefitMenu"] }}
              @if (!myBenefits.isOpen()) {
                <i class="float-end aon-icon aon-menu-collapse m-l-5  " aria-hidden="true"></i>
              }
              @if (myBenefits.isOpen()) {
                <i class="float-end  aon-icon aon-angle-up m-l-5  " aria-hidden="true"></i>
              }
            </a>
            <div ngbDropdownMenu aria-labelledby="dropdownBenefits" class="masthead-dropdown masthead-megamenu-dropdown">
              <app-masthead-benefits [closeBenefit]="closeBenefit" [benefits]="benefits"
                [resourcestrings]="resourceStrings" (hideMobileNavBar)="isNavbarCollapsed = true"
              (collapseBenefitMenu)="myBenefits.close()"></app-masthead-benefits>
            </div>
          </li>
        }
        @if (authenticationService.isLogin && headMenuObject.profile) {
          <li ngbDropdown
            class="nav-item nav-li nav-li-login li-order-{{headMenuObject.profile}} anaval-profile"
            [ngClass]="{ 'nav-item-dropdown': !helperService.AllowAnonymousAccess(), 'nav-item-menu': myUser.isOpen() }"
            #myUser="ngbDropdown" [autoClose]="dropDownAutoClose">
            <a class="nav-link text-info focus-visibility" [attr.aria-label]="preferredName + ', ' + resourceStrings['Aon.Navigation.Profile']"
              href="javascript:void(0)" (click)="closeOtherDropdowns(myUser)" [attr.aria-expanded]="myUser.isOpen()"
              role="button" ngbDropdownToggle>
              <div class="icon-badge-container d-flex align-items-center">
                <i class="aon-icon aon-user d-flex" aria-hidden="true"></i><span [ngbTooltip]="preferredName" data-heap-redact-text>
                {{truncate(preferredName)}}@if ((isFailedPayment || hasGracePeriod)) {
                <span
                class="badge bg-danger user-badge d-inline-block p-t-0 p-b-0 p-l-0 p-r-0"></span>
              }
            </span>
            @if (!myUser.isOpen()) {
              <i class="flex-grow-1 text-end aon-icon aon-menu-collapse m-l-4"
              aria-hidden="true"></i>
            }
            @if (myUser.isOpen()) {
              <i class="flex-grow-1 text-end aon-icon aon-angle-up m-l-4"
              aria-hidden="true"></i>
            }
          </div>
        </a>
        <div ngbDropdownMenu class="profile-dropdown">
          <app-masthead-profile [resourcestrings]="resourceStrings"
            [actAsDelegate]="actAsDelegate" [hasDelegateForEmployees]="hasDelegateForEmployees"
            (onGracePeriodUpdated)="onGracePeriodUpdated($event)"
            (onCloseClick)="closemodalcross($event); myUser.close()">
          </app-masthead-profile>
        </div>
      </li>
    }
    @if (headMenuObject.message && showAfterLoginCheck() ) {
      <li
        class="message-badge d-none d-lg-flex nav-item-dropdown nav-item nav-li li-order-{{ headMenuObject.message }}">
        @if (showInbox) {
          <a class="nav-link text-info focus-visibility" [routerLink]="[baseUrl + '/inbox']"
            [attr.aria-label]="resourceStrings['Aon.Arialabel.Inbox']">
            <div class="icon-badge-container">
              <i class="aon-icon aon-message" aria-hidden="true">
              </i>
              @if (commonService.unreadCount) {
                <i [ngClass]="{'message-notification': commonService.unreadCount}"></i>
              }
            </div>
          </a>
        }
      </li>
    }
    @if (headMenuObject.help && helperService.IsValidClientCode()) {
      <li
        class="nav-item nav-li li-order-{{ headMenuObject.help }} anaval-help"
        [ngClass]="{'nav-item-dropdown dropdown': !helperService.AllowAnonymousAccess(), 'd-none d-md-block': !showAfterLoginCheck()}">
        <a class="nav-link text-info focus-visibility d-flex align-items-center" (click)="isNavbarCollapsed = true"
          [routerLink]="[baseUrl + '/home/help']" routerLinkActive="active" aria-labelledby="helpLabel"
          aria-describedby="helpDescription" [attr.aria-label]="resourceStrings['Aon.Arialabel.Help']">
          <div class="icon-badge-container d-flex align-items-center">
            <i class="aon-icon aon-help d-flex" aria-hidden="true"></i>
            <span class="sr-only" id="helpDescription">{{ resourceStrings["Aon.Help.Description"] }}</span>
            <span [ngClass]="{'d-visible d-lg-none': isOlb()}" id="helpLabel">{{
            resourceStrings["Aon.Navigation.Help"] }}</span>
          </div>
        </a>
      </li>
    }
    @if (headMenuObject.language && !hideLanguageSelection) {
      <li
        class="nav-item-dropdown nav-li li-order-{{headMenuObject.language}} anaval-language-selection">
        <app-language-selection (closeOtherDropdown)="closeOtherDropdowns($event)"
          (hideLanguageSelection)="onAllowedLanguagesLoaded($event)"
        [dropDownAutoClose]="dropDownAutoClose"></app-language-selection>
      </li>
    }
  </ul>
</div>
}
</nav>

<ng-template #content let-modal>
  <div class="quote-modal">
    <div class="modal-header">
      <h3 class="modal-title">{{ resourceStrings["Aon.Navigation.GetQuote"] }}</h3>
      <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <p class="m-b-20">{{ resourceStrings["Aon.Navigation.SelectProductStartQuote"] }}</p>
      @for (product of products; track product; let i = $index) {
        <div>
          <div class="products-sleeve"
            (keydown.enter)="setActiveProduct('/product',product.line_GUID,product.documentName,product.launchQuoteFromHomePage,product.gaTag, product.checkSavedQuotesBeforeLaunchingQuote,true)"
            (click)="setActiveProduct('/product',product.line_GUID,product.documentName,product.launchQuoteFromHomePage,product.gaTag, product.checkSavedQuotesBeforeLaunchingQuote)"
            [ngClass]="{selected: product.line_GUID == selectedProductPageGUID}">
            <img [src]="product.icon" class="m-r-12" alt="" />
            <p class="m-b-0"><span>{{ product.itemTitle }}</span></p>
          </div>
        </div>
      }
      <button (click)="goToProduct()" class="get-quote prominent m-t-32"
      [disabled]="!selectedProductPageGUID">{{resourceStrings["Aon.Navigation.GetQuote"] }}</button>
    </div>
  </div>
</ng-template>
<ng-template #noEligibleProductsWarningContent let-modal>
  <div class="modal-header">
    <h3 class="modal-title" [innerHTML]="resourceStrings['Aon.Common.NoEligibleProductsTitle'] | safeHtml"
    id="modal-basic-title"></h3>
  </div>
  <div class="modal-body">
    <div>{{resourceStrings['Aon.Common.NoEligibleProductsInfo']}}</div>
  </div>
</ng-template>
</div>
