<div class="site-masthead-delegate-bar site-masthead" role="navigation" id="delegate-nav">
  <div class="d-flex">
    @if(resourceStrings['Aon.Delegate.DelegateViewFor']){
    <p>{{ resourceStrings['Aon.Delegate.DelegateViewFor'] }}</p>
    }
    @if(selectedDelegateEmployee?.firstName || selectedDelegateEmployee?.lastName){
    <p class="d-none d-sm-block m-l-4 strong">{{ selectedDelegateEmployee?.firstName + ' ' +
      selectedDelegateEmployee?.lastName }}</p>
    }
  </div>
  <div class="select-delegate">
    @if(resourceStrings['Aon.Delegate.SelectDelegate']){
    <span class="d-none d-sm-block">{{ resourceStrings['Aon.Delegate.SelectDelegate'] }}</span>
    }
    <div class="delegate-user-list">
      <ul class="p-l-0" role="list">
        <li ngbDropdown role="listitem" #delegateDropdown="ngbDropdown">
          @if(selectedDelegateEmployee?.firstName || selectedDelegateEmployee?.lastName){
          <a class="nav-link" id="delegateDropdown" href="javascript:void(0)" role="button" ngbDropdownToggle>
            {{ selectedDelegateEmployee?.firstName + ' ' + selectedDelegateEmployee?.lastName }}
            @if (delegateDropdown.isOpen()) {
            <i class="fal fa-chevron-up" aria-hidden="true"></i>
            } @else {
            <i class="fal fa-chevron-down" aria-hidden="true"></i>
            }
          </a>
          }
          @if (delegateEmployees && delegateEmployees.length>0) {
          <div ngbDropdownMenu aria-labelledby="delegateDropdown">
            <ul role="list" class="nav-dropdown-list">
              @for (item of delegateEmployees; track $index) {
              <li role="listitem">
                <a (click)="SelectDelegateEmployee(item)" ngbDropdownItem
                  [ngClass]="{'active-language': selectedDelegateEmployee?.employeeID === item.employeeID}"
                  href="javascript:void(0)">
                  {{ item?.firstName + ' ' + item?.lastName }}
                </a>
              </li>
              }
            </ul>
          </div>
          }
        </li>
      </ul>
    </div>
  </div>
</div>