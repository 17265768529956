import { Component, Input, OnInit } from '@angular/core';

import { SableDataViewStateService } from '@aon/sable-dataview';

@Component({
  selector: 'app-sable-dataview-card',
  templateUrl: './sable-dataview-card.component.html'
})
export class SableDataviewCardComponent implements OnInit {

  @Input() data: any;


  options: any;

  constructor(private viewState: SableDataViewStateService) { }

  ngOnInit() {
    this.options = this.data;
  }

  ngOnDestroy() {
  }

}
