import { Component, Injector, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs/operators';
import { SableFlowEventBusService } from '@aon/sable-flow';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Component({
  selector: 'app-deselect-benefit-confirm',
  templateUrl: './deselect-benefit-confirm.component.html'
})
export class DeselectBenefitConfirmComponent extends TBSBaseComponent implements OnInit {
  resourceStrings = {};
  @Input() public EmployeeBenefitID;
  @Input() public BenefitName: string;
  constructor(
    public modalService: NgbActiveModal,
    private injector: Injector,
    private busservice: SableFlowEventBusService,
    private globalObjectsService: GlobalObjectsService
  ) {
    super(injector);
    this.addResourceStringKeys(['Aon.DeselectPopup.Title', 'Aon.DeselectPopup.Description']);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.getResouceStringsAsObject(res);
      });
  }

  close() {
    this.modalService.close(true);
  }

  save() {
    this.deselect();
    this.modalService.close(true);
  }

  deselect() {
    this.busservice.next({
      flag: true,
      saveData: {
        Action: 'Deselect',
        EmployeeBenefitID: this.EmployeeBenefitID
      },
      goToInstruction: null,
      additionalContextData: this.globalObjectsService.additionalContextData
    });
  }
}
