import { Component, Injector, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
@Component({
  selector: 'app-registration-modal',
  templateUrl: './registration-modal.component.html'
})
export class RegistrationModalComponent extends TBSBaseComponent implements OnInit {

  constructor(
    public activeModal: NgbActiveModal,
    private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }


  Dismiss(): void {
    this.activeModal.dismiss();
  }

  onSuccess(): void {
    this.activeModal.close();
  }

  login(): void {
    this.activeModal.close('login');
    this.logincallback();
  }
  logincallback(): void {

  }
}
