import { Component, Injector, OnInit } from '@angular/core';
import { HelperService } from '../../../shared/services/helper.service';
import { BenefitService } from '../benefit.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { take, takeUntil } from 'rxjs/operators';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';

@Component({
  selector: 'app-check-out-documents',
  templateUrl: './check-out-documents.component.html'
})
export class CheckOutDocumentsComponent extends TBSBaseComponent implements OnInit {
  documentsData: any = [];
  validateError: string = '';
  siteTheme: string;
  darkModeEnabled: boolean;
  benefitIds: number[];
  _triggeredFrom = '';


  openById = {};
  ToggleThis(event) {
    this.openById[event.panelId] = event.nextState;
  }

  constructor(public helperService: HelperService,
    public activeModal: NgbActiveModal,
    private benefitService: BenefitService,
    private injector: Injector,
    private authenticationService: AuthenticationService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'AON.UploadDocument.Checkout.Instructions',
      'AON.UploadDocument.Options',
      'AON.UploadDocument.Checkout.Desc',
      'AON.UploadDocument.FileSizeError',
      'AON.UploadDocument.SaveError',
      'AON.UploadDocument.DocumentsNeeded',
      'Aon.Common.Cancel',
      'Aon.UploadDocument.Options.Des',
      'Aon.UploadDocument.Formats',
      'Aon.UploadDocument.Checkout.Instructions.Des',
      'Aon.Profile.Me',
      'Aon.UnderWriting.Complete',
      'Aon.UploadDocument.AlmostDone',
      'Aon.UploadDocument.AlmostDoneDesc',
      'Aon.UploadDocument.ContinueToTermsAndConditions',
      'Aon.Common.Continue',
      'Aon.Profile.Incomplete'
    ]);
    this.siteTheme = this.authenticationService.getUIConfig()?.uiConfiguration?.presetTheme;
    this.darkModeEnabled = this.authenticationService.getUIConfig()?.uiConfiguration?.enableDarkMode;
  }

  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
    this.benefitService.getParticipantDocuments(this.benefitIds)
      .pipe(take(1))
      .subscribe(data => {
        this.documentsData = data;
        this.documentsData.forEach(participant => {
          participant = this.helperService.checkStatus(participant);
        });
      })
  }

  updateParticipant(data) {
    this.documentsData[data.index].correspondenceTypes = data.participant;
    this.documentsData[data.index] = this.helperService.checkStatus(this.documentsData[data.index]);
  }

  enableNextButton() {
    return this.helperService.enableNextButton(this.documentsData);
  }

  cancel() {
    this.activeModal.close();
  }

  save() {
    this.activeModal.close('doneuploaddocs');
  }
}
