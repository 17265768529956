export class TextParameter {
  name: string;
  value: string | null;
  startIndex?: number;
  endIndex?: number;
  isUrl: boolean;
  prefix?: string | null;
  suffix?: string | null;
  route?: NamedRoute;

  get hasPrefix(): boolean {
    return this.prefix && this.prefix.length > 0;
  }

  get hasSuffix(): boolean {
    return this.suffix && this.suffix.length > 0;
  }

  get hasValue(): boolean {
    return this.value && this.value.length > 0;
  }

  get text(): string {
    let result = "";
    if (this.hasPrefix) {
      result = result + this.prefix;
    }

    if (this.hasValue) {
      result = result + this.value;
    }

    if (this.hasSuffix) {
      result = result + this.suffix;
    }

    return result;
  }
}

export class NamedRoute {
  path: string;
  name?: string | null;
  title: string;
  fragment?: string | null;
}
