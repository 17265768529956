import { Component, OnInit, Input } from '@angular/core';
import { SableDataViewControl } from '@aon/sable-dataview';

@Component({
  selector: 'lib-dataview-orderedlist',
  templateUrl: './sable-dataview-orderedlist.component.html'
})
export class SableDataViewOrderedListComponent implements SableDataViewControl, OnInit {
  @Input() data: any;

  options: any;

  ngOnInit() {
    this.options = this.data;
  }
}
