<div class="profile-dropdown-menu masthead-profile">
  <ul class="nav-dropdown-list">
    <li>
      <a [routerLink]="[baseUrl+'/home']" (click)="closeModal()" fragment="Self"
         class="dropdown-item p-l-20 p-r-20">{{ resourcestrings['Aon.Navigation.AboutMe'] + 'Me'}}</a>
    </li>
    <li>
      <a [routerLink]="[baseUrl+'/home/home-delegate']" (click)="closeModal()" 
         class="dropdown-item p-l-20 p-r-20">{{ resourcestrings['Aon.Navigation.AboutMe'] + 'Employee A'}}</a>
    </li>
    <li>
      <a [routerLink]="[baseUrl+'/home/home-delegate']" (click)="closeModal()" 
         class="dropdown-item p-l-20 p-r-20">{{ resourcestrings['Aon.Navigation.AboutMe'] + 'Employee B'}}</a>
    </li>
  </ul>
</div>
