import { Injectable } from '@angular/core';
import { SummaryModel, SableStep } from '../../modules/quote/quote.model';
@Injectable({
  providedIn: 'root'
})
export class SableProgressStepsService {

  progressStepsInformation: SableStep[];
  flowId: string;
  summaryData: SummaryModel[];

  constructor() {
    this.progressStepsInformation = [];
    this.flowId = null;
  }

  public getSummaryData(flowId: string) {
    if (this.flowId != null && this.flowId === flowId) {
      return this.summaryData;
    } else {
      return [];
    }
  }

  public getProgressStepsInformation(flowId: string) {
    // flowId is the product's sableFlow_RecordID
    // It needs to return product's own progressStepsInformation
    if (this.flowId != null && this.flowId === flowId) {
      return this.progressStepsInformation;
    } else {
      return [];
    }
  }

  public setProgressStepsInformation(progressStepsInform: SableStep[], flowId: string) {
    // flowId is the product's sableFlow_RecordID
    // It needs to reset progressStepsInformation when product changed
    if (this.flowId != flowId) {
      this.resetProgressStepsInformation(flowId);
    }

    if (progressStepsInform != null && progressStepsInform.length > 0) {
      const cloneItem = JSON.parse(JSON.stringify(progressStepsInform));
      this.progressStepsInformation = cloneItem;
    }
  }

  public setSummaryData(summaryList: SummaryModel[], flowId: string) {
    if (this.flowId != flowId) {
      this.resetSummaryData(flowId);
    }
    if (summaryList != null && summaryList.length > 0) {
      const cloneItem = JSON.parse(JSON.stringify(summaryList));
      this.summaryData = cloneItem;
    }
  }

  private resetSummaryData(flowId: string) {
    this.summaryData = null;
    this.flowId = flowId;
  }

  private resetProgressStepsInformation(flowId: string) {
    this.progressStepsInformation = [];
    this.flowId = flowId;
  }

}
