<div AlertBannerCloseButtonFocus class="alert banner error" id="flow-error-message" role="alert">
  <div class="title">
    <div class="title-content">
      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
    </div>
    <i role="button" class="fal fa-times  focus-visibility" tabindex="0"
      (keydown.enter)="helperService.closeInfo('flow-error-message')"
      (click)="helperService.closeInfo('flow-error-message')"
      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
  </div>
  <p [innerHTML]="getErrorText() | safeHtml"></p>
</div>
<div class="row" *ngIf="hasErrorOptions">
  <div class="col-12 m-b-40" *ngIf="!hasExitOnBackOption">
    <div class="rad-checkbox-array" *ngFor="let option of this.activityData.Response.Options">
      <div class="aon-form-radio">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="errorOptions" (change)="selOption(option)" id="{{option}}">
          <label class="form-check-label" [ngSwitch]="option" for="{{option}}">
            <span
              *ngSwitchCase="'ABORT_NEW_PURCHASE'">{{resourceStrings['Aon.ErrorActivityOptions.AbortNewPurchase']}}</span>
            <span
              *ngSwitchCase="'REPLACE_EXISTING_PURCHASE'">{{resourceStrings['Aon.ErrorActivityOptions.ReplaceExistingPurchase']}}</span>
            <span
              *ngSwitchCase="'RESUME_EXISTING_PURCHASE'">{{resourceStrings['Aon.ErrorActivityOptions.ResumeExistingPurchase']}}</span>
            <span *ngSwitchDefault>{{resourceStrings['Aon.ErrorActivityOptions.CancelPurchase']}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<button *ngIf="hasErrorOptions && !hasExitOnBackOption" type="button" class="m-r-20 prominent" (click)="confirm()">
  {{resourceStrings['Aon.Common.Confirm']}}</button>
<button *ngIf="hasErrorOptions && hasExitOnBackOption" class="m-r-20 ghost" (click)="gotoHome()">
  {{resourceStrings['Aon.Common.Back']}}</button>