import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { LoginModalComponent } from '../../shared/login-modal/login-modal.component';
import { RegistrationModalComponent } from '../../shared/registration-modal/registration-modal.component';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  instance: LoginModalComponent;
  constructor(private mfaService: MFAService, private modalService: NgbModal) { }

  open(returnUrl = '', productType = '', showSucessStep = false, callbackFunc: Function = null) {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };
    const modalRef = this.modalService.open(LoginModalComponent, ngbModalOptions);
    modalRef.componentInstance.returnUrl = returnUrl;
    modalRef.componentInstance.productType = productType;
    this.instance = modalRef.componentInstance;

    this.mfaService.returnUrl = returnUrl;

    if (!showSucessStep) {
      modalRef.componentInstance.afterSumbit = () => {
        modalRef.close();
      };
    }

    modalRef.result.then((data: any) => {
      if (callbackFunc != null) {
        callbackFunc(data);
      }
      if (data === 'createAccount') {
        this.openRegistration();
      }
    }, (reason) => {
      /*Leave empty or handle reject*/
      /*This is used to avoid the JS error when calling dismiss to close a modal*/
    });
  }

  openRegistration(returnUrl = '', callbackFunc: Function = null) {
    const modalRef = this.modalService.open(RegistrationModalComponent, { backdrop: 'static', size: 'md', windowClass: 'progress-modalbox' });
    modalRef.componentInstance.returnUrl = returnUrl;
    modalRef.result.then((data: any) => {
      if (callbackFunc != null) {
        callbackFunc(data);
      }
    }, (reason) => {
      /*Leave empty or handle reject*/
      /*This is used to avoid the JS error when calling dismiss to close a modal*/
    });
  }
}
