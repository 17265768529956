import { Component, Input, OnInit } from '@angular/core';

import { SableDataViewStateService } from '@aon/sable-dataview';
import { HelperService } from 'src/app/shared/services/helper.service';

@Component({
  selector: 'app-sable-dataview-table',
  templateUrl: './sable-dataview-table.component.html'
})
export class SableDataviewTableComponent implements OnInit {

  @Input() data: any;


  options: any;
  cultureCode: string;

  constructor(private viewState: SableDataViewStateService,
    private helperService: HelperService) { }

  ngOnInit() {
    this.options = this.data;
    this.cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    this.formatValue(this.options);
  }

  formatValue(data: any) {
    data.items.forEach(items => {
      if (items && items.displayType == 'tableHeader') {
        items.items.forEach(childItem => {
          childItem.cssClass = 'theme-color';
        });
      }
      if (items && items.displayType == 'tableRow') {
        items.items.forEach(childItem => {
          if (childItem.dataFormat == 'Currency' && childItem.label != null && childItem.label != '') {
            childItem.label = this.helperService.currencyAmountValue(Number(childItem.label), this.helperService.getCurrencyCode(null), '2');
          }
          else if (childItem.dataFormat == 'Percentage' && childItem.label != null && childItem.label != '') {
            childItem.label = this.helperService.currencyAmountValue(Number(childItem.label), this.helperService.getCurrencyCode(null), '1');
          }
          else if (childItem.dataFormat == 'Date' && childItem.label != null && childItem.label != '') {
            let date = childItem.label.split('/');
            let newDate = Number(date[2]) + '-' + ('0' + (Number(date[1]))).slice(-2) + '-' + ('0' + Number(date[0])).slice(-2);
            childItem.label = this.helperService.formatDate(newDate, this.helperService.getLocaleDateFormat());
          }
        })
      }
    })
  }

  ngOnDestroy() {
  }

}
