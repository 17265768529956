export class ResourceString {
  culture: string;
  key: string;
  value: string;
  role: string;
}

export class ResourceStringsObject {
  [key: string]: string;
}
