<div ngbAccordion class="accordion" *ngIf="data.displayType!='panel' else panelView" (shown)="Accordion($event)"
  (hidden)="Accordion($event)">
  <div [ngbAccordionItem]="'p' + data.ID" #accordion="ngbAccordionItem" class="accordion-item expansion-panel">
    <div ngbAccordionHeader class="accordion-header">
      <button ngbAccordionButton class="header-sleeve sable-view-header focus-visibility accordion-button">
        <span class="header-text">{{data.title}}</span>
        <span class="header-text m-r-20 text-end {{pdfUnicodeCharacterOverrideClasses}}" *ngIf="data.value">
          {{helperService.labelValueText(this.data, htmlElement)}}</span>
        <span class="header-controls ignore-from-media-print">
          <i *ngIf="!accordion.collapsed" class="fal fa-plus" aria-hidden="true"></i>
          <i *ngIf="accordion.collapsed" class="fal fa-plus" aria-hidden="true"></i>
        </span>
      </button>
    </div>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template appFlowContainer></ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #panelView>
  <div ngbAccordion *ngIf="data.title || data.value">
    <div [ngbAccordionItem]="'p' + data.ID" #accordion="ngbAccordionItem" class="accordion-item expansion-panel">
      <div ngbAccordionHeader>
        <button ngbAccordionButton class="header-sleeve sable-view-header focus-visibility">
          <span class="header-text">{{data.title}}</span>
          <span class="header-text m-r-30 p-r-8 text-end {{pdfUnicodeCharacterOverrideClasses}}" *ngIf="data.value">
            {{helperService.labelValueText(this.data, htmlElement)}}</span>
        </button>
        <ng-container [ngTemplateOutlet]="relatedDataLinkPointer"></ng-container>
      </div>
      <div ngbAccordionCollapse>
      </div>
    </div>
  </div>

  <!-- Related Data Link Pointer -->
  <ng-template #relatedDataLinkPointer>
    <ng-container *ngIf="data.relatedDataLinkPointer && relatedDataLinkInfo.status">
      <ng-container *ngTemplateOutlet="data.relatedDataLinkPointer; context: relatedDataLinkInfo.data"></ng-container>
    </ng-container>
  </ng-template>