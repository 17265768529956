<div class="page-not-found text-center container" aria-label="404 PAGE NOT FOUND">
    <div class="row">
        <div class="col-10 not-found-container d-flex">
            <i class="fa-light fa-triangle-exclamation" aria-hidden="true"></i>
            <h6 class="m-b-20" [innerHTML]="resourceStrings['Aon.Error.Error404'] | safeHtml"></h6>
            <img role="img" [alt]="resourceStrings['Aon.Error.Error404']"
                src="../../../assets/images/vb-global-error-page/notfound-error-illustration.svg">

            <div class="m-b-32">
                <h1 class="m-b-0" [innerHTML]="resourceStrings['Aon.Error.PageNotFound'] | safeHtml"></h1>
                <p class="xl m-t-12 m-b-0" [innerHTML]="resourceStrings['Aon.Error.PageNotFoundDetail'] | safeHtml"></p>
                <p class="m-t-12 m-b-0 errorid" [innerHTML]="resourceStrings['Aon.Error.ErrorId'] | safeHtml"></p>
            </div>

            <button [routerLink]="[baseUrl + '/']" routerLinkActive="active" class="m-b-40"
                [innerHTML]="resourceStrings['Aon.Common.Back'] | safeHtml"></button>
        </div>
    </div>
</div>