import { Component, Input, Output, OnInit, EventEmitter, OnDestroy, Injector, Inject } from '@angular/core';
import { takeUntil, take } from 'rxjs/operators';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { BootstrapRequestContext } from 'tbs-typings';
import { CommonService } from '../../services';
import { ChildComponentOptions } from 'src/app/shared/models/childComponentOptions.model';
import { UiconfigrationService } from '../../services/uiconfigration.service';
import { Router } from '@angular/router';
import { UIConfigurationType } from '../../../shared/models/uiconfigration.interface';
import { SessionStorageKey } from '../../../shared/models/constants';


@Component({
  selector: 'app-masthead-logo',
  templateUrl: './masthead-logo.component.html'
})
export class MastheadLogoComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  currentLogoPath: string;
  @Output() onCloseClick = new EventEmitter();
  @Input() resourcestrings;
  statusofclick: boolean;
  aonTbsLogo: boolean;
  uiConfig: UIConfigurationType;

  constructor(
    protected router: Router,
    private commonService: CommonService,
    private injector: Injector,
    private globalObjectsService: GlobalObjectsService,
    private uiconfigrationService: UiconfigrationService,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
  ) {
    super(injector, new ChildComponentOptions(true, true, false));
  }

  ngOnInit(): void {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.helperService.getAonSettings().pipe(takeUntil(this.unsubscribe)).subscribe((res) => {
      this.aonTbsLogo = res.showAonLogo;
    })

    this.globalObjectsService.checkHeaderFooterConfig.subscribe(value => {
      if (value) {
        this.setLogoImage();
      }
    });
  }

  private setLogoImage() {

    if (this.uiConfig.uiConfiguration.logoPath) {
      this.currentLogoPath = this.uiConfig.uiConfiguration.logoPath;
    } else {
      this.commonService.getLogo()
        .pipe(take(1))
        .subscribe(logoPathData => {
          if (logoPathData) {
            this.currentLogoPath = logoPathData;
          }
        });
    }
  }

  ngOnDestroy() {
    this.signalUnsubscribe();
  }

  closeModal(): void {
    this.statusofclick = true;
    this.onCloseClick.emit(this.statusofclick);
  }

  logoAction() {
    if (this.helperService.IsValidClientCode()) {
      const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
      if (curSelDelegateEmployee != null) {
        this.router.navigate([this.baseUrl + '/home/home-delegate']);
      } else {
        this.router.navigate([this.baseUrl + '/']);
      }
    }
    this.closeModal();
  }

}
