<div class="quote-content" *ngIf="content != null ">
  <h2 class="header-title h3" [innerHTML]="content.contentItemTitle | safeHtml"
    *ngIf="content != null && content.contentItemTitle != null"></h2>
  <div *ngIf="content != null && content.contentItemBody != null" class="m-b-20 quote-content-message">
    <p [innerHTML]="content.contentItemBody | safeHtml"></p>
  </div>
</div>

<div *ngIf="callToAction">
  <div class="row d-sm-none">
    <div class="col-12">
      <app-aon-cta [callToAction]="callToAction"></app-aon-cta>
    </div>
  </div>
  <div class="float-start d-none d-sm-block">
    <app-aon-cta [callToAction]="callToAction"></app-aon-cta>
  </div>
</div>