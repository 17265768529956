import { Component, ElementRef, EventEmitter, Inject, Injector, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../core/services/';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { HelperService } from '../services/helper.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';
import { take, takeUntil } from 'rxjs/operators';
import { forkJoin, Subscription, takeWhile, timer } from 'rxjs';
import { MFAService } from 'src/app/shared/services/mfa.service';
import { HomeService } from '../../modules/home/home.service';
import { OtpVerificationOption, PasswordValidationMessage, RegularExpression } from '../models/constants';
import { LoginComponent } from '../login';
import { NotificationService } from '../../core/services/notification.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ProfileService } from '../../modules/profile/profile.service';
import { TextTransformPipe } from '../pipes/textTransform.pipe';
import { LoginService } from '../services/login.service';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'app-express-registration',
  templateUrl: './express-registration.component.html'
})
export class ExpressRegistrationComponent extends LoginComponent implements OnInit {
  @Input() isRegistrationModal: boolean = false;
  @Output() DismissEmit = new EventEmitter<boolean>();
  uiConfig: UIConfigurationType;
  baseUrl: string;
  form: FormGroup;
  IsLoading = false;
  Employee_RecordID: string;
  currentStep = 1;
  WorkEmail = '';
  AlternateEmail = '';
  errorMessages: string[];
  PreferedEmailSourceType = 0;
  PreferedEmail = ''
  PrimaryEmail = '';
  NewUserName = '';
  NewPassword = '';
  siteName = '';
  FirstName = ""
  isAccountExists: boolean;
  startedVerifyCode = false;
  countDownTimerForDisplay = '';
  expireTimeInSeconds = 60;
  timerSubscription: Subscription;
  showResendCodeButton = false;
  isResendCodeButtonDisabled = true;
  proceedCreateAccount = false;
  CurrentPassword: string = '';
  NewPasswordRepeat: string = '';
  PasswordRestSuccess: boolean = false;
  IsNewPasswordValid: boolean = false;
  error: string;
  stepTitles = [];
  privacyPolicyUrl: string;
  constructor(
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected authenticationService: AuthenticationService,
    protected commonService: CommonService,
    protected jwtHelper: JwtHelper,
    public helperService: HelperService,
    protected homeService: HomeService,
    protected mfaService: MFAService,
    protected uiconfigrationService: UiconfigrationService,
    protected notificationService: NotificationService,
    protected profileService: ProfileService,
    private elementRef: ElementRef,
    protected ngxService: NgxUiLoaderService,
    private loginService: LoginService,
    public globalObjectsService: GlobalObjectsService,
    private textTransform: TextTransformPipe,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
    public injector: Injector
  ) {
    super(
      formBuilder,
      route,
      router,
      authenticationService,
      commonService,
      jwtHelper,
      helperService,
      globalObjectsService,
      homeService,
      uiconfigrationService,
      notificationService,
      mfaService,
      profileService,
      ngxService,
      bootstrapRequestContext,
      AON_LOCALE,
      injector); this.addResourceStringKeys([
        'Aon.Registration.Title',
        'Aon.Registration.Submit',
        'Aon.Account.NeedHelp',
        'Aon.Login.ContactUs',
        'Aon.Registration.AboutMe',
        'Aon.Registration.CreateAccountIntro',
        'Aon.Registration.PersonalInformation',
        'Aon.Registration.PersonalInformationLegend',
        'Aon.Registration.DataNotMatch',
        'Aon.Account.HaveAccount',
        'Aon.Login.LoginText',
        'Aon.Registration.ContactPreferences',
        'Aon.Registration.SelectEmailToRegister',
        'Aon.Registration.AlternateEmail',
        'Aon.Registration.AgreeToUsePersonalInfo',
        'Aon.Common.Continue',
        'Aon.Registration.BackToHome',
        'Aon.Registration.ThanksForRegistration',
        'Aon.Registration.ThanksForRegistrationAdditionalInfo',
        'Aon.Registration.EnterOneTimePassword',
        'Aon.Registration.OneTimePasswordTips',
        'Aon.Registration.PrimaryEmail',
        'Aon.Registration.OneTimePassword',
        'Aon.Registration.NotGetOneTimePassword',
        'Aon.Common.ResendEmail',
        'Aon.Registration.CreateAccount',
        'Aon.Registration.ChooseUserName',
        'Aon.Login.UserName',
        'Aon.Registration.PrimaryEmailAsUserName',
        'Aon.Login.Password',
        'Aon.Login.PasswordVisibilityControl',
        'Aon.Registration.Step',
        'Aon.Registration.StepOf',
        'Aon.Common.Success',
        'Aon.Registration.AgreeContactForProductsPefix',
        'Aon.Registration.AgreeContactForProductsSuffix',
        'Aon.Registration.AgreeContactForProducts',
        'Aon.Registration.Welcome',
        'Aon.Registration.AccountExists',
        'Aon.Registration.AccountInOtherTenant',
        'Aon.Registration.NoMatchingAccount',
        'Aon.Registration.SendOTPFailed',
        'Aon.Registration.WrongOTP',
        'Aon.Registration.UsernameOccupied',
        'Aon.Registration.PersonalInformationLegend',
        'Aon.Registration.EmailExample',
        'Aon.Common.PleaseChoose',
        'Aon.Registration.ClientName',
        'Aon.Account.Email',
        'Aon.Account.EmailIsRequired',
        'Aon.Account.InvalidEmail',
        'Aon.Login.GeneratePassword',
        'Aon.Login.VerifyEmail',
        'Aon.Login.OneTimePassword',
        'Aon.Login.ResendCode',
        'Aon.Login.InvalidCode',
        'Aon.Login.HaveAccount',
        'Aon.Login.CodeHasBeenSentSuccessfully',
        'Aon.Login.CodeExpirationSuffix',
        'Aon.Login.ContactHelpDesk',
        'Aon.Login.PasswordVisibilityControl',
        'Aon.Common.TooManyRequests',
        'Aon.Common.Edit',
        'System.Common.Error',
        'Aon.Account.PasswordBlockedKeyword',
        'Aon.Password.RepetitiveChar',
        'Aon.Password.SequentialChar',
        'Aon.Common.Error',
        'Aon.Account.NeedMeetRequirements',
        'Aon.Account.CannotBeReused',
        'Aon.Account.ReusePolicy',
        'Aon.Account.ContainsUserName',
        'Aon.Register.PersonalInformation',
        'Aon.Register.PasswordOTP',
        'Aon.Register.CreateAccount',
        'Aon.Register.AccountCreationComplete'
      ]);
  }
  ngOnInit(): void {
    this.proceedCreateAccount = true;
    this.isAccountExists = false;
    this.isPrivacyPolicyAccepted = false;
    this.showPrivacyPolicySection = true;
    this.showMarketingPreferenceSection = true;
    this.errorMessages = [];
    this.baseUrl = this.helperService.getBaseUrl();
    this.siteName = this.bootstrapRequestContext.siteName;
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
        //Welcome message post registration is also counted as a step concluding total to 4
        this.stepTitles = [this.resourceStrings['Aon.Register.PersonalInformation'], this.resourceStrings['Aon.Register.PasswordOTP'], this.resourceStrings['Aon.Register.CreateAccount'], this.resourceStrings['Aon.Register.AccountCreationComplete']]
      });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.privacyPolicyUrl = this.helperService.getPrivacyPolicyUrl();
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.email, Validators.required, Validators.pattern(RegularExpression.Email)]],
      password: ['', Validators.required],
      verificationcode: ['']
    });
  }

  submitForm(): void {
    this.errorMessages = [];
    if (this.currentStep == 1) {
      this.getOTP();
    }
    else if (this.currentStep == 2) {
      this.verifyCode();
    }
    else if (this.currentStep == 3) {
      this.createPassword();
    }
    else if (this.currentStep == 4) {
      this.registrationSuccess();
    }
  }
  getOTP(): void {
    this.IsLoading = true;
    this.error = "";
    this.loginForm.get('password').setValue("");
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }

    forkJoin({
      sendCodeResult: this.authenticationService.sendCode(OtpVerificationOption.Email, this.loginForm.get('username').value, true),
      marketingConsentResult: this.profileService.getEmployeeServiceCommsPreferences()
    })
      .subscribe(
        ({
          sendCodeResult,
          marketingConsentResult
        }) => {
          if (sendCodeResult) {
            this.isAccountExists = sendCodeResult.isAccountExists;
            this.isExpressRegistered = sendCodeResult.isExpressRegistered;
            this.isPrivacyPolicyAccepted = sendCodeResult.isPrivacyPolicyAccepted;
            this.showPrivacyPolicySection = !sendCodeResult.isPrivacyPolicyAccepted;
            this.showMarketingPreferenceSection = !sendCodeResult.isMarketingPreferenceCompleted;
            //For express users, before they set the password by themselves, the "IsExpressRegistered" is true.
            //At this time, even the account exists, the system allows them to use the express registration function to set password.
            //After user set the password, the "IsExpressRegistered" will be changed to false.
            if (sendCodeResult.isAccountExists && !sendCodeResult.isExpressRegistered) {
              this.currentStep = 1;
              if (sendCodeResult.accountInOtherTenant) {
                this.errorMessages.push(this.resourceStrings['Aon.Registration.AccountInOtherTenant']);
              } else {
                this.errorMessages.push(this.resourceStrings['Aon.Registration.AccountExists']);
              }
            }
            else {
              if (!sendCodeResult.didSend) {
                this.errorMessages.push(this.resourceStrings['Aon.Registration.SendOTPFailed']);
              } else {
                this.currentStep = 2;
              }
            }
          }
          if (marketingConsentResult?.length > 0) {
            this.isMarketingSelectionSel = !marketingConsentResult[0].isOptedOutOfComms;
            this.employeeServiceCommsPreferences = marketingConsentResult;
          }
        });

    this.startCounter(this.expireTimeInSeconds);

  }
  verifyCode(): boolean {
    this.IsLoading = true;
    this.NewUserName = this.loginForm.get('username').value;
    sessionStorage.setItem('NewUserName', this.NewUserName);

    const encryptPWD = this.helperService.encrypt(this.loginForm.get('password').value);
    if (encryptPWD != false) {

      this.authenticationService.verifyCode(OtpVerificationOption.Email, this.loginForm.get('username').value, encryptPWD, this.proceedCreateAccount, this.isMarketingSelectionSel)
        .subscribe((data) => {
          if (data.didSave) {
            this.currentStep = 3;
          }
          else {
            this.errorMessages.push(this.resourceStrings['Aon.Login.InvalidCode']);
            this.IsLoading = false;
          }
        });
      return true;
    }
    else {
      this.error = this.resourceStrings['Aon.Login.InvalidCode'];
      this.loading = false;
    }
  }

  startCounter(expireTimeInSeconds): void {
    this.countDownTimerForDisplay = "";
    this.timerSubscription = timer(1000, 1000).pipe(takeWhile((number) => this.startedVerifyCode == false && number <= expireTimeInSeconds)).subscribe((number) => {
      if (number < expireTimeInSeconds) {
        this.isResendCodeButtonDisabled = true;
        const resetTime = expireTimeInSeconds - number;
        this.countDownTimerForDisplay = "(" + resetTime.toString() + "s)";
      } else {
        this.countDownTimerForDisplay = "";
        this.isResendCodeButtonDisabled = false;
      }
    });
  }

  createPassword(): void {
    this.IsLoading = true;
    this.error = "";
    const encryptPWD = this.helperService.encrypt(this.loginForm.get('password').value);
    if (encryptPWD != false) {
      this.authenticationService.verifyandcreatepassword(this.NewUserName, encryptPWD, this.NewPassword).subscribe(
        (data) => {
          this.IsLoading = false;
          if (data.didSave) {
            this.currentStep = 4;
          }
          else {
            if (data.errorMessages != null && data.errorMessages.length > 0) {
              if (data.errorMessages[0] == PasswordValidationMessage.NeedMeetPasswordRequirement) {
                this.error = this.resourceStrings['Aon.Account.NeedMeetRequirements'];
              } else if (data.errorMessages[0] == PasswordValidationMessage.ContainsUserName) {
                this.error = this.resourceStrings['Aon.Account.ContainsUserName'];
              } else if (data.errorMessages[0] == PasswordValidationMessage.ReuseCurPassword) {
                this.error = this.resourceStrings['Aon.Account.CannotBeReused'];
              } else if (data.errorMessages[0] == PasswordValidationMessage.MayReusePassword) {
                this.error = this.resourceStrings['Aon.Account.ReusePolicy'];
              } else if (data.errorMessages[0] == PasswordValidationMessage.ContainsBlockedKeyword) {
                let msg = { blockedKeyword: data.errorMessages.length > 1 ? data.errorMessages[1] : "" };
                this.errorMessages.push(this.textTransform.transform(this.resourceStrings['Aon.Account.PasswordBlockedKeyword'], msg));
              } else if (data.errorMessages[0] == PasswordValidationMessage.ContainsSequentialkeyword) {
                this.errorMessages.push(this.getString('Aon.Password.SequentialChar'));
              } else if (data.errorMessages[0] == PasswordValidationMessage.ContainsRepetitiveChar) {
                this.errorMessages.push(this.getString('Aon.Password.RepetitiveChar'));
              }
              else {
                this.error = this.resourceStrings['Aon.Common.Error'];
              }
            } else {
              this.error = this.resourceStrings['Aon.Common.Error'];
            }
          }
        }
      )
    }
    else {
      this.error = this.resourceStrings['Aon.Login.InvalidCode'];
      this.loading = false;
    }
  }

  registrationSuccess(): void {
    if (this.authenticationService.isLogin) {
      this.router.navigate([this.baseUrl + '/home']);
    }
    else {
      if (this.isRegistrationModal) {
        this.DismissEmit.emit(true);
        let returnUrl = this.globalObjectsService.returnURL;
        //Set the returnUrl in the session storage is used to resolve the following issue.
        //FTU flow - Redirection is not happening as expected after completing First Time User flow during Pre-auth to auth transition.
        //Related work item #748591
        if (returnUrl == undefined) {
          returnUrl = location.pathname;
          sessionStorage.setItem('returnURL', returnUrl);
        }
        this.loginService.open(returnUrl, '', false, () => { });
      } else {
        //Once registration is succeed, remvoe the currentGuest to avoid use the same session id to call the ProcessPostLogin
        //Once the currentGuest is remvoed, the system will re-generted it.
        //If it comes from registration modal, it should comes from the flow, so for this scenario, the guest token should not be re-generated.
        sessionStorage.removeItem('currentGuest');
        this.authenticationService.guestToken().pipe(take(1))
          .subscribe((user) => {
          });
        this.router.navigate([this.baseUrl + '/login']);
      }
    }

  }
  isEmailvalid() {
    return this.loginForm.get('username').valid;
  }
  backForm(): void {
    this.currentStep = this.currentStep - 1;
    this.errorMessages = [];
    this.helperService.scrollToThePageTop();
  }

  acceptPrivacyPolicy() {
    if (this.isPrivacyPolicyLinkClicked) {
      this.isPrivacyPolicyAccepted = !this.isPrivacyPolicyAccepted;
    }
  }

  readPrivacyPolicy(): void {
    this.isPrivacyPolicyLinkClicked = true;
    this.isPrivacyPolicyAccepted = !this.isPrivacyPolicyAccepted;
  }


}
