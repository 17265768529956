import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TBSBaseComponent } from '../../../shared/models/tbsBaseComponent';
import { UiconfigrationService } from '../../services/uiconfigration.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../shared/services/toast.service';
import { BenefitService } from '../../../modules/benefit/benefit.service';
import { GlobalObjectsService } from '../../../shared/services/global-objects.service';
import { CommonService } from '../../services';
import { combineLatestWith, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../authentication/authentication.service';
import { DelegateForEmployeeType } from '../../../shared/models/actAsDelegateForType.model';
import { SessionStorageKey } from '../../../shared/models/constants';

@Component({
  selector: 'app-masthead-delegate-bar',
  templateUrl: './masthead-delegate-bar.component.html'
})
export class MastheadDelegateBarComponent extends TBSBaseComponent implements OnInit {
  @Input() delegateEmployees: DelegateForEmployeeType[];
  selectedDelegateEmployee: DelegateForEmployeeType;
  employeeID: string;
  constructor(
    private elRef: ElementRef,
    private injector: Injector,
    private ngxService: NgxUiLoaderService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private toastService: ToastService,
    private benefitService: BenefitService,
    private globalObjectService: GlobalObjectsService,
    private commonService: CommonService,
    private uiconfigrationService: UiconfigrationService,
    private authenticationService: AuthenticationService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Navigation.AboutMe',
      'Aon.Common.Self',
      'Aon.Delegate.DelegateViewFor',
      'Aon.Delegate.SelectDelegate'
    ]);
  }

  ngOnInit(): void {
    this.ngxService.start();
    this.baseUrl = this.helperService.getBaseUrl();

    this.loadResourceStringAsObservable()
      .pipe(
        combineLatestWith(this.globalObjectService.compactEmployeeInformation),
        takeUntil(this.unsubscribe))
      .subscribe(([res, emp]) => {
        this.getResouceStringsAsObject(res);
        if (emp) {
          this.employeeID = emp.employeeID;
          if (!this.delegateEmployees.find(d => d.employeeID == emp.employeeID)) {
            this.delegateEmployees.unshift({
              employeeID: emp.employeeID,
              employeeNumberID: emp.employeeNumberID,
              employee_RecordID: "",
              firstName: emp.firstName,
              lastName: emp.lastName + this.resourceStrings['Aon.Common.Self'],
              delegateAccess: []
            });
            const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
            if (curSelDelegateEmployee != null) {
              this.selectedDelegateEmployee = this.delegateEmployees.find(d => d.employeeID == curSelDelegateEmployee);
              this.SelectDelegateEmployee(this.selectedDelegateEmployee, true);
            }
            if (!this.selectedDelegateEmployee && this.delegateEmployees.length > 0) {
              this.SelectDelegateEmployee(this.delegateEmployees[0], true);
            }
          }
        }
        this.ngxService.stop();
      });
  }

  SelectDelegateEmployee(newDelegateEmployee: DelegateForEmployeeType, fromInit: boolean = false) {
    this.selectedDelegateEmployee = newDelegateEmployee;
    
    sessionStorage.removeItem(SessionStorageKey.ActDelegateEmployee);
    if (this.employeeID && this.employeeID == newDelegateEmployee.employeeID) {
      this.globalObjectService.delegateEmployeeSelfSelected.next(true);
      if (!fromInit) {
        this.router.navigate([this.baseUrl + '/home']);
      }
    } else {
      this.globalObjectService.delegateEmployeeSelfSelected.next(false);
      sessionStorage.setItem(SessionStorageKey.ActDelegateEmployee, this.selectedDelegateEmployee.employeeID);
      if (!fromInit) {
        this.router.navigate([this.baseUrl + '/home/home-delegate']);
      }
    }

    this.globalObjectService.delegateEmployeeUpdated.next(this.selectedDelegateEmployee);
  }
}
