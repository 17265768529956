@if (product.benefitPlanName) {
@switch (benefitPlanNameHeading?.tagLevel) {
@case (benefitPlanNameHeadingTagLevels.h5) {
<h5 class="m-b-10 color-2 {{benefitPlanNameHeading?.class}}">{{product.benefitPlanName}}</h5>
}
@case (benefitPlanNameHeadingTagLevels.h4) {
<h4 class="m-b-10 color-2 {{benefitPlanNameHeading?.class}}">{{product.benefitPlanName}}</h4>
}
@default {
<h3 class="m-b-10 color-2 {{benefitPlanNameHeading?.class}}" [ngClass]="{'h5': !benefitPlanNameHeading?.class}">
  {{product.benefitPlanName}}</h3>
}
}
}
<!-- Tiered -->
<ng-container *ngIf="product.lookupLineType_RecordID == 1">
  <ng-container *ngIf="product.addOnGroups; else PlanDetailsLinkSection">
    <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
  </ng-container>
</ng-container>
<!-- Currency -->
<ng-container
  *ngIf="product.lookupLineType_RecordID == 6 || product.lookupLineType_RecordID == 8 || product.lookupLineType_RecordID == 4">
  <!-- Plan and Coverage -->
  <div class="m-b-20">
    <span [ngClass]="{ 'strong' : product.addOnGroups && product.valueSummary}"
      *ngIf="product.benefitPlanName != product.coverageName" [innerHTML]="product.coverageName | safeHtml"></span>
    <span
      *ngIf="product.coverageName && (product.benefitPlanName != product.coverageName) && product.employeeVolume && !product?.hideCoverageVolume && !product.valueSummary">,&nbsp;</span>
    <span
      *ngIf="product.employeeVolume && !product?.hideCoverageVolume && !product.valueSummary">{{ShowDataByAmountType}}</span>
    <p class="{{helperService.getPdfUnicodeCharacterOverrideClasses(product.valueSummary)}}"
      *ngIf="product.valueSummary" [innerHTML]="product.valueSummary | safeHtml"></p>
  </div>
  <ng-container *ngIf="product.addOnGroups; else PlanDetailsLinkSection">
    <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
  </ng-container>

</ng-container>
<!-- Volume -->
<ng-container *ngIf="product.lookupLineType_RecordID == 2">
  <!-- Plan and Coverage -->
  <div>
    <!-- Condition 1 -->
    <div class="m-b-20">
      <div class="d-flex"
           *ngIf="((product.benefitPlanName || product.coverageName) && (product.benefitPlanName == product.coverageName) && !(product.hideCoverageVolume)) && ((product.employeeVolume && !product?.hideCoverageVolume) || product.valueSummary)">
        <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(ShowDataByAmountType)}}"
           *ngIf="product.employeeVolume && !product?.hideCoverageVolume">{{ShowDataByAmountType}}</p>
      </div>
      <!-- condition 2 -->
      <div class="d-flex"
           *ngIf="(product.benefitPlanName != product.coverageName) && !(product.hideCoverageVolume) && ((product.employeeVolume && !product?.hideCoverageVolume) || product.valueSummary || product.coverageName)">
        <p class="m-b-0" *ngIf="product.benefitPlanName != product.coverageName"
           [innerHTML]="product.coverageName | safeHtml"></p>
        <p class="m-b-0" *ngIf="product.coverageName && product.employeeVolume && !product?.hideCoverageVolume">
          ,&nbsp;
        </p>
        <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(ShowDataByAmountType)}}"
           *ngIf="product.employeeVolume && !product?.hideCoverageVolume">{{ShowDataByAmountType}}</p>
      </div>
      <!-- condition 3 -->
      <div class="d-flex"
           *ngIf="(product.benefitPlanName != product.coverageName) && (product.hideCoverageVolume)">
        <p class="m-b-0" *ngIf="product.benefitPlanName != product.coverageName"
           [innerHTML]="product.coverageName | safeHtml"></p>
      </div>
      <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(product.valueSummary)}}"
         *ngIf="product.valueSummary" [innerHTML]="product.valueSummary | safeHtml"></p>
    </div>
    <ng-container *ngIf="product.addOnGroups; else PlanDetailsLinkSection">
      <ng-container *ngTemplateOutlet="AddOnSection"></ng-container>
    </ng-container>
  </div>
</ng-container>

<!-- Addons Section -->
<ng-template #AddOnSection>
  @if (addOnWithIsCoverage.length > 0) {
  @for (addOn of addOnWithIsCoverage; track addOn) {
  @if (addOn.name && addOn.valueSummary) {
  <div class="m-b-20">
    <p class="strong m-b-0">{{addOn.name}}</p>
    <p class="m-b-0 {{helperService.getPdfUnicodeCharacterOverrideClasses(addOn.valueSummary)}}"
      [innerHTML]="addOn.valueSummary | safeHtml"></p>
  </div>
  }
  }
  }
  <ng-container *ngTemplateOutlet="PlanDetailsLinkSection"></ng-container>
  <ng-container *ngIf="addOnNotWithIsCoverage.length > 0">
    <label class="strong m-b-0" [innerHTML]="resourceStrings['Aon.AddOns.label'] | safeHtml"></label>
    <div class="d-flex flex-wrap">
      <p class="m-b-0" *ngFor="let addOn of addOnNotWithIsCoverage; let islast = last">
        {{addOn.name}}<ng-container
          *ngIf="!islast && (!addOn.valueSummary || addOn.valueSummary == '')">,&nbsp;</ng-container>
        <ng-container *ngIf="addOn.valueSummary">
          <span class="{{helperService.getPdfUnicodeCharacterOverrideClasses(addOn.valueSummary)}}">&nbsp;(<span
              [innerHTML]="addOn.valueSummary | safeHtml"></span>)</span>
          <ng-container *ngIf="!islast">,&nbsp;</ng-container>
        </ng-container>
      </p>
    </div>
  </ng-container>
</ng-template>

<!-- Plan Details Link section -->
<ng-template #PlanDetailsLinkSection>
  <div *ngIf="!fromBenefitStatement && product.showPlanDetails" class="m-t-20 m-b-20 no-export">
    <a (click)="openBenefitInfoPopup(product.line_RecordID, product.name, $event)" [routerLink]="[]"
      attr.aria-label="{{resourceStrings['Aon.BenefitDetails.PlanDetails']}} {{product.name | striphtml}}"
      [innerHTML]="resourceStrings['Aon.BenefitDetails.PlanDetails'] | safeHtml"></a>
  </div>
</ng-template>
