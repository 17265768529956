export class HttpError {
  static BadRequest = 400;
  static Unauthorized = 401;
  static Forbidden = 403;
  static NotFound = 404;
  static TimeOut = 408;
  static Conflict = 409;
  static TooManyRequest = 429;
  static InternalServerError = 500;
}

export class SessionStorageKey {
  //Keys for Payment
  static ReadyToCheckoutPurchases = "ReadyToCheckoutPurchases";
  static RemediationFailedTransaction = "RemediationFailedTransaction";
  static PaymentConfirmData = "PaymentConfirmData";
  static StartPaymentResult = "StartPaymentResult";
  static PaymentProviderPaymentTypeRecordID = "PaymentProviderPaymentTypeRecordID";
  static PaymentFailedAlert = "PaymentFailedAlert";
  static PaymentAutoRenewalAlert = "PaymentAutoRenewalAlert";
  static FollowUpItems = "FollowUpItems";
  static ActiveEvent = "ActiveEvent";
  static AonSettings = "AonSettings";
  static SaveCardPaymentRequest = "SaveCardPaymentRequest";
  static EnabledRolesForUiStrings = "EnabledRolesForUiStrings";
  static RolesRoutingPathsForUiStrings = "RolesRoutingPathsForUiStrings";
  // static SaveCardPaymentResult = "SaveCardPaymentResult";
  static ClaimItems = "ClaimItems";
  static HasMismatchedParticipants = "HasMismatchedParticipants";
  static MfaTempUser = "MfaTempUser";
  static TaxCode = "taxCode";
  static SsoClientList = "SsoClientList";
  static InTwoWaySSOProcess = "InTwoWaySSOProcess";
  static BenefitFlowObject = "BenefitFlowObject";
  static CachedBenefitReviewData = "CachedBenefitReviewData";
  static ClientContentPageCallToActions = "ccpcta";
  static LifeStyleReasonAdditionalData = "LSRAD";
  static PreviousPageInfo = "PreviousPageInfo";
  static isInsideAndroidApp = "isInsideAndroidApp";
  static CallbackObject = "CallbackObject";
  static SsoInteractionIncomplete = "SsoInteractionIncomplete";
  static ReferrerPathForTheQuoteFlow = "RPFQF";
  static isSableProcessFirstStep = "ISPFS";
  static IsFromMobileApp = "IsFromMobileApp";
  static IsFromAndroidApp = "IsFromAndroidApp";
  static IsExpressLoginInFlow = "IsExpressLoginInFlow";
  static TimeWarpDate = "TimeWarpDate";
  static GroupTermItems = "groupTermItems";
  static PasswordExpiredUser = "PasswordExpiredUser";
  static ClaimSelectedCurrency = "ClaimSelectedCurrency";
  static LifestyleReasons = "LifestyleReasons";
  static ActDelegateEmployee = "ActDelegateEmployee";
}

export class ContentItemKey {
  static MyBenefitsNeedHelp = "MyBenefits.NeedHelp";
  static MyBenefitsClaims = "MyBenefits.Claims";
  static MyBenefitsAonBrokerLicenseInfo = "MyBenefits.AonBrokerLicenseInfo";
  static ProductContactInfo = "Product.ContactInfo";
  static ClaimContactInfo = "Claims.ContactInfo";
  static ConfirmationStatementHeader = "Confirmation.Statement.Header";
  static ConfirmationStatementFooter = "Confirmation.Statement.Footer";
}

export class EmployeeEventStatus {
  static Pending = 1;
  static Submitted = 2;
  static Approved = 3;
  static Declined = 4;
}

export class KenticoPagePath {
  static Products = "/products";
}

export class Constants {
  static True = "True";
  static False = "False";
  static GuidEmpty = "00000000-0000-0000-0000-000000000000";
  static GuidAll = "11111111-1111-1111-1111-111111111111";
  static MaxDate = new Date("9999-12-31");
}

export class CallToActionType {
  static QuoteAction = "QuoteAction";
  static RedirectAction = "RedirectAction";
}

export class QuoteSummaryTemplate {
  static SableView = 0;
  static Standard = 1;
}

export class ThailandPaymentEnabled {
  static None = 0;
  static Demo = 1;
  static Production = 2;
}

export class ErrorOptions {
  static AbortNewPurchase = "ABORT_NEW_PURCHASE";
  static ReplaceExistingPurchase = "REPLACE_EXISTING_PURCHASE";
  static ResumeExistingPurchase = "RESUME_EXISTING_PURCHASE";
  static Cancel = "CANCEL";
  static ExitOnBack = "EXIT_ON_BACK";
}

export class ProductTypes {
  static Pet = "PET";
  static Home = "HOME";
  static Auto = "AUTO";
  static Caregiver = "CAREGIVER";
  static Life = "LIFE";
}

export class QuoteDetailsDisplay {
  static LinkOnly = 0;
  static SummaryOnly = 1;
  static Both = 2;
  static None = 3;
}

export class CoverageSelectionTemplate {
  static Default = 0;
  static Standard = 1;
  static USAdhoc = 2;
}

export class ReviewPurchaseTemplate {
  static Default = 0;
  static Standard = 1;
  static USAdhoc = 2;
}

export class PaymentCompleteTemplate {
  static Default = 0;
  static Standard = 1;
  static USAdhoc = 2;
}

export class EmployeeRelationshipType {
  static Employee = 0;
  static Dependant = 1;
  static Beneficiary = 2;
  static Trust = 3;
  static Estate = 4;
  static Organization = 5;
}

export class BeneficiaryType {
  static NonDesignated = 0;
  static Primary = 1;
  static Contingent = 2;
}

export class PageStyleMode {
  static AlmostDone = 1;
  static PersonalInfo = 2;
  static DepInfo = 3;
}

export class ProductQuoteSummaryTemplate {
  static SableView = 1;
  static Standard = 2;
  static Both = 3;
}

export class LookupBasicInputTypeRecordID {
  static Radio = 0;
  static Toggle = 1;
}

export class EmployeeRelationshipTypeNames {
  static Employee = 0; //self
  static Spouse = 1;
  static DomesticPartner = 2;
  static Child = 3;
  static Other = 4; //parent
  static Unborn = 5;
  static Parents = 6;
  static ParentsInLaw = 7;
}

export class OtpVerificationOption {
  static Email = 'Email';
  static SMS = 'Sms';
}

export class RoutingPathsSpecailCheck {
  static PathsWithID = [
    '/product/{id}',
    '/product/{id}/details/{id}',
    '/product/{id}/coverages',
    '/product/{id}/article/{key}',
    '/quote/{id}/details',
    '/quote/{id}/terms',
    '/quote/{id}/coverage',
    '/quote/{id}/review',
    '/quote/{id}/itemlist',
    '/quote/{id}/checkout',
    '/quote/{id}/complate',
    '/quote/{id}/callagent',
    '/quote/{id}/selection'
  ];
  static StartWithQuote = '/quote/';
  static StartWithProduct = '/product/';
  static StartWithCategory = '/category/';
  static StartWithClaim = '/claim/';
  static StartWithArticle = '/article/';
  static StartWithMyBenefits = '/mybenefits';
  static Id = '{id}';
  static Article = '/article';
  static CommonArticle = '{common-article}';
  static ProductArticleKey = '/article/{key}';
  static Details = '/details';
  static DetailsId = '/details/{id}';
  static Header = '{header}';
  static Footer = '{footer}';
}

export class SummaryUIKey {
  static AboutYourPetSummary = "AboutYourPetSummary";
  static PetHealthHistorySummary = "PetHealthHistorySummary";
}

export class ExpressLoginSteps {
  static ProvideEmailAddress = "ProvideEmailAddress";
  static GenerateOTP = "GenerateOTP";
}

export class RegularExpression {
  static Email = "^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$";
  static EmailWithMask = "^[a-zA-Z0-9.*'_%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$";
  static HomeMobile = "^[0-9]*$";
}

export enum PaymentProviderCode {
  ZETA = "ZETA",
  MY2C2P = "2C2P",
  AON = "AON",
  INGENICO = "INGENICO"
}

export enum PaymentProviderPaymentTypeCode {
  AON_PAYROLLDEDUCTION = "PD",
  INGENICO_VISA = "1",
  INGENICO_AMEX = "2",
  INGENICO_MASTERCARD = "3",
  INGENICO_UNIONPAY = "56",
  INGENICO_APPLE = "302",
  INGENICO_GOOGLE = "320",
  INGENICO_ALIPAY = "861",
  INGENICO_WECHAT = "863",
  INGENICO_ACH = "730",
  INGENICO_DISCOVER = "128",
  MY2C2P_MASTERCARD = "Mastercard",
  MY2C2P_VISA = "Visa",
  ZETA_CASH = "PG",
  ZETA_ZETA = "ZETA",
  ZETA_POINTS = "FLEX"
}

export enum PaymentProviderPaymentTypeName {
  AON_PAYROLLDEDUCTION = "PayrollDeduction",
  INGENICO_VISA = "VISA",
  INGENICO_AMEX = "AMEX",
  INGENICO_MASTERCARD = "MASTERCARD",
  INGENICO_UNIONPAY = "UNIONPAY",
  INGENICO_APPLE = "APPLE",
  INGENICO_GOOGLE = "GOOGLE",
  INGENICO_ALIPAY = "ALIPAY",
  INGENICO_WECHAT = "WECHAT",
  INGENICO_ACH = "ACH",
  INGENICO_DISCOVER = "DISCOVER",
  MY2C2P_MASTERCARD = "MASTERCARD",
  MY2C2P_VISA = "VISA",
  ZETA_CASH = "Cash",
  ZETA_ZETA = "Zeta",
  ZETA_POINTS = "Points"
}

export class SsoTpRequestItem {
  static SSOClientID = "SSOClientID";
  static SSOPlanID = "SSOPlanID";
  static SSOCoverageID = "SSOCoverageID";
  static SSOWindowStyle = "SSOWindowStyle";
  static SSOContentLink = "SSOContentLink";
  static SSOButtonLink = "SSOButtonLink";
  static BenefitRecordID = "BenefitRecordID";
  static EmployeeBenefitID = "EmployeeBenefitID";
}
export class LegalContentCategory {
  static OnlineSecurity = "OnlineSecurity";
  static PrivacyPolicy = "PrivacyPolicy";
  static TermsOfUse = "TermsOfUse";
  static ParticipantConsent = "ParticipantConsent";
  static BeneficiaryDisclaimer = "BeneficiaryDisclaimer";
}

export class RouteRootPrefix {
  static Product = "product";
  static Quote = "quote";
  static Profile = "profile";
}
export class ProfileTabFragements {
  static AboutMe = "aboutme";
  static Benefits = "activebenefits";
  static LifeEvents = "lifeevents";
  static SavedQuotes = "savedquote";
  static Account = "account";
  static DependentsAndBeneficiaries = "dependentsandbeneficiaries";
  static Documents = "documents";
  static Payment = "payment";
}

export class PositionOfExploreMoreBenefits {
  static AboveFAQs = 1;
  static UnderFAQs = 2;
}

export class FormDecoratorType {
  static PreText = 1;
  static PosText = 2;
  static Info = 3;
}

export class ComponentName {
  static Profile = "Profile";
  static AlmostDone = "AlmostDone";
  static BasicInfo = "BasicInfo";
}


export class GenderTypes {
  static Melded = 1;
  static Male = 2;
  static Female = 3;
}

export class SableSummaryType {
  static Step = 'step';
  static Activity = 'activity';
  static PurchaseGroup = 'purchase-group';
  static EntityGroup = 'entity-group';
}

export class PasswordValidationMessage {
  static NeedMeetPasswordRequirement = 'NeedMeetPasswordRequirement';
  static ContainsUserName = 'ContainsUserName';
  static ReuseCurPassword = 'ReuseCurPassword';
  static MayReusePassword = 'MayReusePassword';
  static ContainsBlockedKeyword = 'ContainsBlockedKeyword';
  static ContainsSequentialkeyword = 'ContainsSequentialkeyword';
  static ContainsRepetitiveChar = 'ContainsRepetitiveChar';
}
export class PasswordCharLength {
  static LengthFourteen = ".{14,}";
  static LengthTwelve = ".{12,}"
  static LengthTen = ".{10,}"
  static LengthEight = ".{8,}"
}

export class eSigningStatus {
  static Unconfirmed = 'unconfirmed';
}
export class LocalStorageKey {
 
  static ProfileDocument = "ProfileDocument";
  static ProfileDocumentAccessDate = "ProfileDocumentAccessDate";

}
export class MFAResendDisablePeriod {
  static Email = 30;
  static SMS = 30;
}
export class ServicNow {
  static FieleExt = "pdf|jpg|docx|png|mp4|doc|msg|xls|xlsx";
  static maxSize = 10;
  static error ="error"
}
export class DelegateAccess {
  static SubmitClaim = 1;
  static ViewClaimHistory = 2;
  static ViewSpendingAccountBalance = 3;
  static PrintClaimHistory = 4;
}
export enum EmailType 
{  
  WorkEmail = 1,
  AlternateEmail = 2,
  HomeEmail = 3
}
export enum PaymentProviderPaymentTypeID{ 
  ZeroPayment = "7FB4F05D-A693-4A27-A903-C5969476775B"  
  }