import { Component, Inject, OnInit, Output, EventEmitter, Injector, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../core/services/';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DynamicFieldBase } from '../../shared/models/dynamicFieldBase.model'
import { DynamicControlService } from '../../core/services/dynamicField.service';
import { DynamicTextInput } from '../../shared/models/dynamicInputField.model';
import { SectionModel } from '../../modules/account/registration/registration.model';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { HelperService } from '../services/helper.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Constants, EmailType, PasswordValidationMessage, RegularExpression, SessionStorageKey } from '../models/constants';
import { ProfileService } from '../../modules/profile/profile.service';
import { EmployeeServiceCommsPreference } from '../../modules/profile/models/employeeServiceCommsPreference.model';
import { TextTransformPipe } from '../pipes/textTransform.pipe';
import { GlobalObjectsService } from '../services/global-objects.service';
import { MFAStep } from '../models/oktaInfoModel';
import { LoginComponent } from '../login';
import { JwtHelper } from '../../core/guards/jwt.helper';
import { HomeService } from '../../modules/home/home.service';
import { NotificationService } from '../../core/services/notification.service';
import { MFAService } from '../services/mfa.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AON_LOCALE } from '@aon/aon-angular-common';

@Component({
  selector: 'app-registration-core',
  templateUrl: './registration-core.component.html',
  providers: [DynamicControlService]
})
export class RegistrationCoreComponent extends LoginComponent implements OnInit {
  @Input() isRegistrationModal: boolean = false;
  @Output() DismissEmit = new EventEmitter<boolean>();
  uiConfig: UIConfigurationType;
  baseUrl: string;
  dynamicFields: DynamicFieldBase<string>[] = [];
  form: FormGroup;
  eMailForm: FormGroup;
  payLoad = '';
  isProcessing = false;
  EmployeeInformation: any;
  siteName = '';
  isPrivacyPolicyLinkClicked: boolean = false;
  originalEmployeeServiceCommsPreferences: EmployeeServiceCommsPreference[];
  employeeServiceCommsPreferences: EmployeeServiceCommsPreference[];
  MFAStep = MFAStep;
  errorMessages: string[];
  stepTitles = [];
  IsLoading = false;
  firstMFAStep: MFAStep;
  useSameEmailForMarketingCommunicationChecked: boolean;

  currentStep = 1;

  // Step 1
  RegistrationFormModel: any;
  Sections: SectionModel[];
  DynamicInput: DynamicTextInput;
  Employee_RecordID: string;
  PreferredName: string;

  // Step 2

  WorkEmail = '';
  homeEmail = '';
  AlternateEmail = '';
  PreferedEmailSourceType = 0;

  // Step 3
  PreferedEmail = ''
  ContactChecked = false;
  personalInfoChecked: boolean = false;

  // Step 4
  TemporaryPassword = '';
  isNewPasswordValid: boolean = false;
  newPassword: string;

  // Step 5
  UsePrimaryEmail = false;
  PrimaryEmail = '';
  NewUserName = '';
  NewPassword = '';

  // Step 6
  FirstName = ""

  constructor(
    protected commonService: CommonService,
    protected dynamicFormService: DynamicControlService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected jwtHelper: JwtHelper,
    protected homeService: HomeService,
    protected authenticationService: AuthenticationService,
    public helperService: HelperService,
    public injector: Injector,
    public globalObjectsService: GlobalObjectsService,
    protected notificationService: NotificationService,
    protected uiconfigrationService: UiconfigrationService,
    protected profileService: ProfileService,
    protected mfaService: MFAService,
    protected ngxService: NgxUiLoaderService,
    protected textTransform: TextTransformPipe,
    protected elementRef: ElementRef,
    @Inject('BootstrapRequestContext') protected bootstrapRequestContext: BootstrapRequestContext,
    @Inject(AON_LOCALE) protected AON_LOCALE ) {
    super(
      formBuilder,
      route,
      router,
      authenticationService,
      commonService,
      jwtHelper,
      helperService,
      globalObjectsService,
      homeService,
      uiconfigrationService,
      notificationService,
      mfaService,
      profileService,
      ngxService,
      bootstrapRequestContext,
      AON_LOCALE,
      injector);
    this.addResourceStringKeys([
      'Aon.Registration.Title',
      'Aon.Registration.Submit',
      'Aon.Account.NeedHelp',
      'Aon.Login.ContactUs',
      'Aon.Registration.AboutMe',
      'Aon.Registration.CreateAccountIntro',
      'Aon.Registration.PersonalInformation',
      'Aon.Registration.PersonalInformationLegend',
      'Aon.Registration.DataNotMatch',
      'Aon.Account.HaveAccount',
      'Aon.Login.LoginText',
      'Aon.Registration.ContactPreferences',
      'Aon.Registration.SelectEmailToRegister',
      'Aon.Registration.AlternateEmail',
      'Aon.Registration.AgreeToUsePersonalInfo',
      'Aon.Common.Continue',
      'Aon.Registration.BackToHome',
      'Aon.Registration.ThanksForRegistration',
      'Aon.Registration.ThanksForRegistrationAdditionalInfo',
      'Aon.Registration.EnterOneTimePassword',
      'Aon.Registration.OneTimePasswordTips',
      'Aon.Registration.PrimaryEmail',
      'Aon.Registration.OneTimePassword',
      'Aon.Registration.NotGetOneTimePassword',
      'Aon.Common.ResendEmail',
      'Aon.Registration.CreateAccount',
      'Aon.Registration.ChooseUserName',
      'Aon.Login.UserName',
      'Aon.Registration.PrimaryEmailAsUserName',
      'Aon.Login.Password',
      'Aon.Login.PasswordVisibilityControl',
      'Aon.Registration.Step',
      'Aon.Registration.StepOf',
      'Aon.Common.Success',
      'Aon.Registration.AgreeContactForProductsPefix',
      'Aon.Registration.AgreeContactForProductsSuffix',
      'Aon.Registration.AgreeContactForProducts',
      'Aon.Registration.Welcome',
      'Aon.Registration.AccountExists',
      'Aon.Registration.NoMatchingAccount',
      'Aon.Registration.SendOTPFailed',
      'Aon.Registration.WrongOTP',
      'Aon.Registration.UsernameOccupied',
      'Aon.Registration.PersonalInformationLegend',
      'Aon.Registration.EmailExample',
      'Aon.Common.PleaseChoose',
      'Aon.Registration.ClientName',
      'Aon.Registration.UserNameIsRequired',
      'Aon.Register.PersonalInformation',
      'Aon.Register.ContactPreferences',
      'Aon.Register.PasswordOTP',
      'Aon.Register.CreateAccount',
      'Aon.Register.AccountCreationComplete',
      'Aon.Account.PasswordBlockedKeyword',
      'Aon.Password.RepetitiveChar',
      'Aon.Password.SequentialChar',
      'Aon.Account.ContainsUserName',
      'Aon.Common.Back'
    ]);

  }

  ngOnInit(): void {
    this.errorMessages = [];
    this.baseUrl = this.helperService.getBaseUrl();
    this.siteName = this.bootstrapRequestContext.siteName;
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
        //Welcome message post registration is also counted as a step concluding total to 5
        this.stepTitles = [this.resourceStrings['Aon.Register.PersonalInformation'], this.resourceStrings['Aon.Register.ContactPreferences'], this.resourceStrings['Aon.Register.PasswordOTP'], this.resourceStrings['Aon.Register.CreateAccount'], this.resourceStrings['Aon.Register.AccountCreationComplete']]
      });
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();

    if (!this.isExpressCheckoutEnabled()) {
      this.loadRegistrationForm();
      this.eMailForm = this.formBuilder.group({
        alternateEmail: ['', [Validators.email, Validators.pattern(RegularExpression.Email)]]
      });
      this.selectEmailPreference(0);

      this.analyticsService.trackCustomEvent({ event: 'registration', step: 1, stepTitle: 'About Me' });
      this.getEmployeeServiceCommsPreferences();
      this.authenticationService.currentUser.pipe(filter(() => this.authenticationService.isLogin))
        .subscribe(user => {
          this.globalObjectsService.compactEmployeeInformation.subscribe(emp => {
            this.WorkEmail = emp?.workEmail;
          });
          this.currentStep = this.currentStep + 1;
          this.analyticsService.trackCustomEvent({ event: 'registration', step: 2, stepTitle: 'Email & Contact Preference' });
          this.helperService.scrollToThePageTop();
        });
    }
  }

  ngAfterViewChecked() {
    if (this.elementRef.nativeElement.querySelector('.privacy-link a')) {
      let href = this.elementRef.nativeElement.querySelector(".privacy-link a").getAttribute("href");
      if (href.includes("privacy-policy") && this.helperService.IsVBAsia()) {
        this.elementRef.nativeElement.querySelector('.privacy-link a').addEventListener('click', function () {
          this.isPrivacyPolicyLinkClicked = true;
        }.bind(this));
      } else {
        this.isPrivacyPolicyLinkClicked = true;
      }
    }
  }

  acceptTermsAndPrivacyPolicy() {
    if (this.isPrivacyPolicyLinkClicked) {
      this.personalInfoChecked = !this.personalInfoChecked;
    }
  }

  validateContinue() {
    if (!this.uiConfig.uiConfiguration.disableRegistrationPrivacyandTerms) {
      return !this.personalInfoChecked || !this.eMailForm.valid || this.isAlternateEmailInvalid()
    }
    else {
      return !this.eMailForm.valid || this.isAlternateEmailInvalid()
    }
  }


  //0: WorkEmail
  //1: Alternate Email
  selectEmailPreference(value: number) {
    if (value != this.PreferedEmailSourceType) {
      this.PreferedEmailSourceType = value;
      let alternateEmailCtrl = this.eMailForm.get('alternateEmail');
      if (value == 0) {
        alternateEmailCtrl.removeValidators([Validators.required]);
      }
      else if (value == 1) {
        alternateEmailCtrl.addValidators([Validators.required]);
      }
    }
  }

  isAlternateEmailInvalid() {
    return !this.eMailForm.get('alternateEmail').valid && this.PreferedEmailSourceType === 1;
  }

  isFieldInvalid(field: string) {
    return !this.eMailForm.get(field).valid && this.eMailForm.get(field).touched;
  }

  hasError(controlName: string, errorName: string) {
    return this.eMailForm.controls[controlName].hasError(errorName);
  }

  submitForm(): void {
    this.errorMessages = [];
    if (this.currentStep == 1) {
      this.registrationValidate();
    } else if (this.currentStep == 2) {
      this.registrationSendPassword();
    } else if (this.currentStep == 3) {
      this.registrationValidatePassword();
    } else if (this.currentStep == 4) {
      if (this.NewUserName != "") {
        this.registrationCreateAccount();
      }
      else {
        this.errorMessages.push(this.resourceStrings['Aon.Registration.UserNameIsRequired']);
        this.IsLoading = false;
      }

    } else if (this.currentStep == 5) {
      this.registrationSuccess();
    }
  }

  loadRegistrationForm(): void {
    this.IsLoading = true;
    this.commonService.getRegistrationForm(this.uiConfig.uiConfiguration.accountRegistrationFormID)
      .pipe(take(1))
      .subscribe(regformData => {
        this.RegistrationFormModel = regformData;
        this.RegistrationFormModel.userTenant_RecordID = this.helperService.IsVanityURLForMultiTenantEnabled() ? this.bootstrapRequestContext.tenantID : Constants.GuidEmpty;
        this.Sections = regformData.sections;
        this.rebuildDynamicForm();
        this.IsLoading = false;
      }, error => {
        this.IsLoading = false;
      });
  }

  registrationValidate(): void {
    this.attachDynamicValues();
    this.RegistrationFormModel.sections = this.Sections;
    this.IsLoading = true;
    this.commonService.registrationValidate(this.RegistrationFormModel)
      .pipe(take(1))
      .subscribe(validationResult => {
        if (validationResult.toProceedWithRegistration) {
          this.WorkEmail = validationResult.employeeWorkEmail;
          this.homeEmail = validationResult.employeeHomeEmail;
          this.AlternateEmail = validationResult.employeeAlternateEmail;
          this.FirstName = validationResult.firstName;
          this.PreferredName = validationResult.preferredName;
          if (this.WorkEmail == null || this.WorkEmail == '') {
            this.selectEmailPreference(1);
          }
          this.Employee_RecordID = validationResult.employee_RecordID;
          this.currentStep = this.currentStep + 1;
          this.analyticsService.trackCustomEvent({ event: 'registration', step: 2, stepTitle: 'Email & Contact Preference' });
          this.helperService.scrollToThePageTop();

          if (this.uiConfig.uiConfiguration.hideRegistrationConsentStep) {
            this.registrationSendPassword();
          }

        } else {
          if (validationResult.isKenticoAccountExists) {
            this.errorMessages.push(this.resourceStrings['Aon.Registration.AccountExists']);
          } else {
            this.errorMessages.push(this.resourceStrings['Aon.Registration.NoMatchingAccount']);
          }
        }
        this.IsLoading = false;
      }, error => {
        this.errorMessages.push(this.resourceStrings['Aon.Registration.NoMatchingAccount']);
        this.IsLoading = false;
      });
  }

  registrationSendPassword(): void {
    this.PreferedEmail = this.PreferedEmailSourceType == 0 ? this.WorkEmail : this.AlternateEmail;
    this.PrimaryEmail = this.PreferedEmail;
    this.UsePrimaryEmail = !this.uiConfig.uiConfiguration.showUserName;
    if (this.UsePrimaryEmail) {
      this.NewUserName = this.PrimaryEmail;
    }
    this.RegistrationFormModel.InitialUserName = this.PreferedEmail;
    if (this.employeeServiceCommsPreferences !== undefined && this.employeeServiceCommsPreferences[0].isOptedOutOfComms !== undefined) {
      this.ContactChecked = !this.employeeServiceCommsPreferences[0]?.isOptedOutOfComms;
    }
    const registrationSendEmailModel = {
      EmployeeRecordID: this.Employee_RecordID,
      PreferedEmail: this.PreferedEmail,
      ReceiveEmails: this.ContactChecked,
      UIConfigId: this.uiConfig.id,
      EmailType: this.PreferedEmailSourceType == 0 ? EmailType.WorkEmail : EmailType.AlternateEmail
    };


    this.IsLoading = true;
    this.commonService.registrationSendPassword(registrationSendEmailModel)
      .pipe(take(1))
      .subscribe(sendPasswordResult => {
        if (sendPasswordResult.didSave) {
          if (this.uiConfig.uiConfiguration.enableMFA) {
            this.analyticsService.trackCustomEvent({ event: 'registration', step: 3, stepTitle: 'MFA - Add new email' });
            if (sessionStorage.getItem(SessionStorageKey.MfaTempUser) != null) {
              sessionStorage.removeItem(SessionStorageKey.MfaTempUser)
            }
            this.setFirstMFAStep();
            this.currentStep = 6; //MFA step
          } else {
            this.analyticsService.trackCustomEvent({ event: 'registration', step: 3, stepTitle: 'Enter OTP' });
            this.currentStep = 3;
          }
        } else {
          this.errorMessages.push(this.resourceStrings['Aon.Registration.SendOTPFailed']);
        }
        this.IsLoading = false;
      }, error => {
        this.errorMessages.push(this.resourceStrings['Aon.Registration.SendOTPFailed']);
        this.IsLoading = false;
      });
  }

  registrationValidatePassword(): void {
    const ValidatePasswordModel = {
      TemporaryPassword: this.TemporaryPassword,
      EmployeeRecordID: this.Employee_RecordID
    };
    this.IsLoading = true;
    this.commonService.registrationValidatePassword(ValidatePasswordModel)
      .pipe(take(1))
      .subscribe(ValidatePasswordResult => {
        if (ValidatePasswordResult.didSave) {
          this.analyticsService.trackCustomEvent({ event: 'registration', step: 4, stepTitle: 'Create Account' });
          this.currentStep = this.currentStep + 1;
          this.helperService.scrollToThePageTop();
        } else {
          this.errorMessages.push(this.resourceStrings['Aon.Registration.WrongOTP']);
        }
        this.IsLoading = false;
      });
  }

  registrationCreateAccount(): void {
    const RegistrationCreateAccountModel = {
      UserName: this.NewUserName,
      Password: this.NewPassword,
      UIConfigId: this.uiConfig.id,
      EmployeeRecordID: this.Employee_RecordID,
      PrivacyPolicyAndTermsAccepted: this.personalInfoChecked,
      UseSameEmailForMarketingCommunicationChecked: this.useSameEmailForMarketingCommunicationChecked,
      RegistrationForm: this.RegistrationFormModel
    };

    this.IsLoading = true;
    this.commonService.registrationCreateAccount(RegistrationCreateAccountModel)
      .pipe(take(1))
      .subscribe(RegistrationCreateAccountResult => {
        if (RegistrationCreateAccountResult && RegistrationCreateAccountResult.access_token) {
          sessionStorage.setItem(SessionStorageKey.MfaTempUser, JSON.stringify(RegistrationCreateAccountResult));
          this.returnUrl = "";
          this.afterSubmitSuccess();
        } else if (RegistrationCreateAccountResult && RegistrationCreateAccountResult.didSave) {
          this.FirstName = RegistrationCreateAccountResult.firstName;
          this.currentStep = this.currentStep + 1;
          if (this.authenticationService.isLogin) {
            this.analyticsService.trackCustomEvent({ event: 'registration', step: 5, stepTitle: 'Account Updated' });
          }
          else {
            this.analyticsService.trackCustomEvent({ event: 'registration', step: 5, stepTitle: 'Account Created' });
          }
          this.helperService.scrollToThePageTop();
        } else {
          if (RegistrationCreateAccountResult.errorMessages[0] == PasswordValidationMessage.ContainsBlockedKeyword) {
            let msg = { blockedKeyword: RegistrationCreateAccountResult.errorMessages.length > 1 ? RegistrationCreateAccountResult.errorMessages[1] : "" };
            this.errorMessages.push(this.textTransform.transform(this.resourceStrings['Aon.Account.PasswordBlockedKeyword'], msg));
          } else if (RegistrationCreateAccountResult.errorMessages[0] == PasswordValidationMessage.ContainsSequentialkeyword) {
            this.errorMessages.push(this.getString('Aon.Password.SequentialChar'));
          } else if (RegistrationCreateAccountResult.errorMessages[0] == PasswordValidationMessage.ContainsRepetitiveChar) {
            this.errorMessages.push(this.getString('Aon.Password.RepetitiveChar'));
          } else if (RegistrationCreateAccountResult.errorMessages[0] == PasswordValidationMessage.ContainsUserName) {
            this.errorMessages.push(this.getString('Aon.Account.ContainsUserName'));
          }
          else {
            this.errorMessages.push(this.resourceStrings['Aon.Registration.UsernameOccupied']);
          }
        }
        this.IsLoading = false;
      });
  }

  registrationSuccess(): void {
    if (this.authenticationService.isLogin) {
      this.analyticsService.trackCustomEvent({ event: 'registration', step: 6, stepTitle: 'Back To Home' });
      this.router.navigate([this.baseUrl + '/']);
    }
    else {
      this.analyticsService.trackCustomEvent({ event: 'registration', step: 6, stepTitle: 'Continue To Login' });
      this.router.navigate([this.baseUrl + '/login']);
    }

    this.DismissEmit.emit(true);
  }

  resendEmail(): void {
    this.registrationSendPassword();
  }

  backForm(): void {
    this.currentStep = this.currentStep - 1;
    this.errorMessages = [];
    this.helperService.scrollToThePageTop();
  }

  rebuildDynamicForm(): void {

    this.Sections.forEach(Section => {
      Section.fields.forEach(Field => {

        let newDynamicField = new DynamicTextInput({
          key: Field.field,
          label: Field.label,
          value: null,
          required: Field.isRequired,
          order: 1,
          controlType: Field.dataType,
          type: 'text'
        });

        Field.dataValue = newDynamicField.value;
        //ONLY FOR CITIZENSHIP
        if (Field.field == "CitizenshipCountry_LookupCountry_RecordID") {
          newDynamicField.options = [];
          newDynamicField.options.push({ key: null, value: this.resourceStrings['Aon.Common.PleaseChoose'] });
          this.RegistrationFormModel.countries.forEach(singleCountry => {
            newDynamicField.options.push({ key: singleCountry.recordID, value: singleCountry.name });
          });
        }
        this.dynamicFields.push(newDynamicField);
      });
    });
    this.form = this.dynamicFormService.toFormGroup(this.dynamicFields);
  }

  attachDynamicValues(): void {
    this.Sections.forEach(Section => {
      Section.fields.forEach(Field => {
        this.dynamicFields.forEach(singleDynamicField => {
          if (singleDynamicField.key === Field.field) {
            Field.dataValue = singleDynamicField.value;
          }
        });
      });
    });
  }

  alternateSelected(): void {
    this.selectEmailPreference(1);
  }

  UsePrimaryEmailClicked(): void {
    this.UsePrimaryEmail = !this.UsePrimaryEmail;
    if (this.UsePrimaryEmail) {
      this.NewUserName = this.PrimaryEmail;
    } else {
      this.NewUserName = "";
    }
  }

  ActionDone(): void {
    this.DismissEmit.emit(true);
  }
  isExpressCheckoutEnabled(): boolean {
    return this.helperService.isExpressCheckoutEnabled();
  }

  getEmployeeServiceCommsPreferences() {
    this.profileService.getEmployeeServiceCommsPreferences()
      .pipe(take(1))
      .subscribe(data => {
        if (data != null && data.length > 0) {
          this.originalEmployeeServiceCommsPreferences = JSON.parse(JSON.stringify(data));
          this.employeeServiceCommsPreferences = data;
        }
      })
  }

  DismissFromExpressRegistration(): void {
    this.DismissEmit.emit(true);
  }

  public finishedAddEmailAndPhone(data: {
    step?: MFAStep,
    useSameEmailForMarketingCommunicationChecked?: boolean
  }) {
    this.currentStep = 4;
    this.useSameEmailForMarketingCommunicationChecked = data.useSameEmailForMarketingCommunicationChecked;
  }

  public backToTermsAndConditions() {
    this.currentStep = 2;
  }

  backToPreviousStep(): void {
    this.clearCurrentStepData();
    switch (this.currentStep) {
      case 6:
        this.currentStep = 2;
        break;
      case 4:
        if (this.uiConfig.uiConfiguration.enableMFA) {
          this.setFirstMFAStep(true);
          this.currentStep = 6;
        } else if (this.uiConfig.uiConfiguration.hideRegistrationConsentStep) {
          this.currentStep = 1;
        } else{
          this.currentStep = this.currentStep - 1;
        }
        break;
      default:
        this.currentStep = this.currentStep - 1;
        break;
    }
  }

  setFirstMFAStep(fromBackButton = false) {
    if (this.uiConfig.uiConfiguration.enableOverrideMFAData) {
      if (fromBackButton) {
        if (this.uiConfig.uiConfiguration.enableMFAMobile) {
          this.firstMFAStep = MFAStep.AddNewPhone;
        } else {
          this.firstMFAStep = MFAStep.SelectEmail; //MFAStep.AddNewEmail;
        }
      } else if (this.uiConfig.uiConfiguration.enableMFAEmail) {
        this.firstMFAStep = MFAStep.SelectEmail; //MFAStep.AddNewEmail;
      } else {
        this.firstMFAStep = MFAStep.AddNewPhone;
      }
    } else {
      this.firstMFAStep = MFAStep.SelectOption;
    }
  }

  clearCurrentStepData() {
    if (this.currentStep == 2) {
      this.employeeServiceCommsPreferences = JSON.parse(JSON.stringify(this.originalEmployeeServiceCommsPreferences));
      this.personalInfoChecked = false;
    } else if (this.currentStep == 4) {
      this.UsePrimaryEmail = false;
      this.NewUserName = "";
      this.NewPassword = "";
    }
  }
}
