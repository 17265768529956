<div class="masthead-benefits-sleeve">
  <div class="page-content-sleeve">
    @if (resourcestrings['Aon.Navigation.ProductsIntro']) {
      <h4
        class="col-md-8 m-b-40 m-t-28 d-xl-block d-lg-block d-md-none d-none color-2"
        [innerHTML]="resourcestrings['Aon.Navigation.ProductsIntro'] | safeHtml"
        [attr.aria-label]="resourceStrings['Aon.Navigation.ProductsIntro']">
      </h4>
    }
    <div class="navbar navbar-expand-lg navbar-collapsed m-l-0 p-l-0 p-r-0">
      <!--Mobile-->
      <div class="d-md-block d-lg-none w-100">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav navbar-nav p-l-0 m-l-0 w-100">
          @for (category of benefits; track category; let sectionIndex = $index) {
            <li class="nav-item-dropdown focus-visibility"
              [ngbNavItem]="sectionIndex">
              <a ngbNavLink class="align-items-center tab-link w-tab-link w-100 benefits-category-{{sectionIndex}}"
                href="javascript:void(0)" role="tab" name="benefits-category-{{sectionIndex}}"
                id="mob-benefits-category-{{sectionIndex}}" (click)="setCategoryIndex(sectionIndex)">
                <img src="{{category.icon}}" alt="" class="m-r-8">{{category.categoryName}}
                <i class="float-end aon-icon aon-menu-collapse m-l-5 d-lg-none" aria-hidden="true"></i>
              </a>
              <ng-template ngbNavContent aria-labelledby="mob-benefits-category-{{sectionIndex}}">
                @if (category) {
                  <ng-container [ngTemplateOutlet]="innerTabset" [ngTemplateOutletContext]="{currentCategory:category}"
                  ></ng-container>
                }
              </ng-template>
              @if (categoryIndex == sectionIndex && categoryToggle) {
                <div class="masthead-products-sleeve page-content-sleeve" id="benefits" role="tab"
                  >
                  <div [ngbNavOutlet]="nav"></div>
                </div>
              }
            </li>
          }
        </ul>
      </div>

      <!--Desktop-->
      <div class="d-none d-lg-block">
        <ul ngbNav #desktopNav="ngbNav" [(activeId)]="active" class="nav navbar-nav p-l-0 m-l-0 w-100">
          @for (category of benefits; track category; let sectionIndex = $index) {
            <li class="nav-item-dropdown focus-visibility"
              [ngbNavItem]="sectionIndex">
              <a ngbNavLink class="align-items-center tab-link w-tab-link w-100 benefits-category-{{sectionIndex}}"
                href="javascript:void(0)" role="tab" name="benefits-category-{{sectionIndex}}"
                id="benefits-category-{{sectionIndex}}" (click)="setCategoryIndex(sectionIndex)">
                <img src="{{category.icon}}" alt="" class="m-r-8">{{category.categoryName}}
                <i class="float-end aon-icon aon-menu-collapse m-l-5 d-lg-none" aria-hidden="true"></i>
              </a>
              <ng-template ngbNavContent aria-labelledby="benefits-category-{{sectionIndex}}">
                @if (category) {
                  <ng-container [ngTemplateOutlet]="innerTabset" [ngTemplateOutletContext]="{currentCategory:category}"
                  ></ng-container>
                }
              </ng-template>
            </li>
          }
        </ul>
      </div>

    </div>
  </div>
  <div class="divider d-lg-block d-none"></div>

  <ng-template #innerTabset let-currentCategory='currentCategory'>
    <div class="d-block d-lg-none d-flex align-items-center m-b-20">
      <i role="button" class="fal fa-chevron-left m-r-20" aria-hidden="true" (click)="showCategory(false)"></i>
      <img src="{{currentCategory.icon}}" class="m-r-8" alt="">
      <p class="strong m-b-0">{{currentCategory.categoryName}}</p>
    </div>
    <div class="product-description">
      @if (currentCategory.icon2) {
        <img src="{{currentCategory.icon2}}" class="m-r-8 d-none d-md-block d-lg-block"
          alt="">
      }
      <p [innerHTML]="resourcestrings['Aon.MastHeadBenefit.Description'] | safeHtml"></p>
    </div>
    @if (currentCategory && currentCategory.groupBenefits) {
      <div class="row">
        @for (product of currentCategory.groupBenefits; track product; let i = $index) {
          @if (currentCategory.groupBenefits.length < 7 || i<5 || currentCategory.categoryName == showAllForCategory) {
            <div class="grid-col d-flex col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12"
              >
              <div class="basic-card">
                <h2 class="product-name m-b-12 theme-color h3" [innerHTML]="product.itemTitle | safeHtml">
                  <span [innerHTML]="product.itemTitle | safeHtml" class="p-0"></span>
                </h2>
                <p class="d-inline m-r-5" [innerHTML]="product.itemSummary | safeHtml"></p>
                <a class="d-inline-block group-benefit-cards-{{i}}"
                  (keyup.enter)="navigateToProductDetail(product.line_GUID)"
                  (click)="navigateToProductDetail(product.line_GUID)" [routerLink]="" tabindex="0">
                  <span class="visually-hidden">{{product.itemTitle}} -
                  </span>{{resourcestrings['Aon.BenefitMatrix.ViewBenefitInformation']}}
                </a>
              </div>
            </div>
          }
          @if ((currentCategory.groupBenefits.length > 6 && i == 6) && currentCategory.categoryName != showAllForCategory) {
            <div class="grid-col d-flex col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12"
              >
              <div class="basic-card d-flex align-items-center">
                <p class="h3 theme-color"><a class="p-0" (keyup.enter)="showAllCards(currentCategory.categoryName, i)"
                  (click)="showAllCards(currentCategory.categoryName, i)" [routerLink]=""
                tabindex="0">{{resourcestrings['Aon.MastHeadBenefit.ViewAll']}} {{currentCategory.categoryName}}</a></p>
              </div>
            </div>
          }
        }
      </div>
    }
  </ng-template>
  <div class="d-none d-lg-block masthead-products-sleeve page-content-sleeve">
    <div [ngbNavOutlet]="desktopNav"></div>
  </div>
</div>