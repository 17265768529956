<ng-container *ngIf="!isExpressCheckoutEnabled()">
  <ngb-progressbar class="modal-only" [type]="currentStep == 5? 'success':'primary'" [value]="currentStep * 20">
  </ngb-progressbar>
  <aon-spin [IsLoading]="IsLoading">
    <div class="row">
      <div class="container" [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
        <div class="registration-core *ngIf=Loaded">
          <div class="step-info m-t-28 m-b-12 modal-only">
            <p class="sm strong d-inline" *ngIf="currentStep <= 4">
              {{resourceStrings['Aon.Registration.Step']}} {{currentStep}}
              <span
                [innerHTML]="resourceStrings['Aon.Registration.StepOf'] | textTransform : {totalSteps : (stepTitles.length)} | safeHtml"></span>
            </p>
            <p class="sm d-inline strong" *ngIf="currentStep == 5">
              <span class="m-r-4 successstep"><i class="fal fa-check-circle validation-success"
                  aria-hidden="true"></i></span>{{resourceStrings['Aon.Common.Success']}}
            </p>
            <p class="sm d-inline"> {{stepTitles[currentStep - 1]}}</p>
          </div>
          <div class="step-validation" *ngIf="currentStep == 1">
            <div class="row">
              <div class="img-sleeve">
                <img src="/assets/images/account/curves4.svg" alt="" />
              </div>
              <div class="col-12">
                <h1 class="step-title page-only" [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml">
                </h1>
                <h3 class="step-title modal-only m-b-12"
                  [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml"></h3>
                <p class="page-only" [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml">
                </p>
                <p class="modal-only m-b-0"
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml"></p>
                <div class="page-only d-flex m-t-32 m-b-12 m-l-0 m-r-0 align-items-center">
                  <h2 class="h3 m-b-0 m-r-8">
                    {{resourceStrings['Aon.Registration.PersonalInformation']}}
                  </h2>
                  <i class="fal fa-info-circle focus-visibility lg"
                    [attr.aria-label]="resourceStrings['Aon.Registration.PersonalInformationLegend']" role="button"
                    tabindex="0" ngbPopover="{{resourceStrings['Aon.Registration.PersonalInformationLegend']}}"
                    #popover="ngbPopover" (keyup.enter)="helperService.openPop(popover)"></i>
                </div>
                <div class="modal-only d-flex align-items-center m-t-20 m-b-20">
                  <h5 class="m-b-0 m-r-8">
                    {{resourceStrings['Aon.Registration.PersonalInformation']}}
                  </h5>
                  <i class="fal fa-info-circle focus-visibility lg"
                    [attr.aria-label]="resourceStrings['Aon.Registration.PersonalInformationLegend']" role="button"
                    tabindex="0" ngbPopover="{{resourceStrings['Aon.Registration.PersonalInformationLegend']}}"
                    #popover="ngbPopover" (keyup.enter)="helperService.openPop(popover)"></i>
                </div>
              </div>
            </div>
            <div class="form-row">
              <div [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
                <form>
                  <div *ngFor="let fieldItem of dynamicFields inverse" class="dynamic-inputs form-group">
                    <aon-dynamic-input [formInputField]="fieldItem" [form]="form"
                      [IsPassword]="fieldItem.key == 'NationalID'"></aon-dynamic-input>
                  </div>
                </form>
                <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-40'">
                  <div class="alert contextual error m-b-32" *ngIf="errorMessages.length>0">
                    <div role="alert">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      <span *ngFor="let errorMessage of errorMessages" [innerHtml]="errorMessage"></span>
                    </div>
                  </div>
                  <button (click)='submitForm()' [disabled]="!form.valid || isProcessing"
                    *ngIf="RegistrationFormModel != null" class="col-md-3 col-12 track registration-submit-1">
                    {{resourceStrings['Aon.Common.Continue']}}
                  </button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm">
                  {{resourceStrings['Aon.Account.HaveAccount']}} <a [routerLink]="[baseUrl+'/login']"
                    (click)="ActionDone()">{{resourceStrings['Aon.Login.LoginText']}}</a>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm" [ngClass]="(isRegistrationModal)?'m-b-0':''">
                  {{resourceStrings['Aon.Account.NeedHelp']}} <a [routerLink]="[baseUrl+'/home/help']"
                    (click)="ActionDone()">{{resourceStrings['Aon.Login.ContactUs']}}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="step-email" *ngIf="currentStep == 2">

            <div class="row">
              <div class="img-sleeve">
                <img src="/assets/images/account/curves4.svg" alt="" />
              </div>
              <div class="col-12">
                <h1 class="step-title page-only" [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml">
                </h1>
                <h3 class="step-title modal-only m-b-12"
                  [innerHTML]="resourceStrings['Aon.Registration.AboutMe'] | safeHtml"></h3>

                <p class="lg page-only" [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml">
                </p>
                <p class="modal-only m-b-20"
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccountIntro'] | safeHtml"></p>
                <h2 class="h3 page-only m-t-32 m-b-12 n-lh-28"
                  [innerHTML]="resourceStrings['Aon.Registration.ContactPreferences'] | safeHtml"> </h2>
                <h5 class="modal-only m-b-12"
                  [innerHTML]="resourceStrings['Aon.Registration.ContactPreferences'] | safeHtml"></h5>
                <p class="lg m-b-20 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.SelectEmailToRegister'] | safeHtml">
                </p>
                <p class="m-b-20 modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.SelectEmailToRegister'] | safeHtml">
                </p>
              </div>
            </div>

            <div class="form-row" *ngIf="!uiConfig.uiConfiguration.enableMFA">
              <div [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
                <form [formGroup]="eMailForm">
                  <div class="aon-form-radio" [ngClass]="(isRegistrationModal)?'m-b-20':''"
                    *ngIf="WorkEmail != null && WorkEmail != ''">
                    <input type="radio" id="WorkEmailSelected" name="emailPreference" [value]="0"
                      [checked]="PreferedEmailSourceType === 0" (click)="selectEmailPreference(0)">
                    <label for="WorkEmailSelected" class="secondary WorkEmailSelected">{{WorkEmail}}</label>
                  </div>
                  <div class=" aon-form-radio m-b-10" *ngIf="!uiConfig.uiConfiguration.hideAlternateRegistrationEmail">
                    <input type="radio" id="AlternateEmailSelected" name="emailPreference" [value]="1"
                      [checked]="PreferedEmailSourceType === 1" (click)="selectEmailPreference(1)">
                    <label for="AlternateEmailSelected" class="strong"
                      [innerHTML]="resourceStrings['Aon.Registration.AlternateEmail'] | safeHtml"></label>
                    <p class="color-2 m-l-35 m-t-5 m-b-5">{{resourceStrings['Aon.Registration.EmailExample']}}</p>
                  </div>

                  <div class="form-group p-l-0 p-r-0 col-11 col-md-12" [ngClass]="(isRegistrationModal)?'m-b-20':''"
                    *ngIf="!uiConfig.uiConfiguration.hideAlternateRegistrationEmail">
                    <input [attr.aria-label]="resourceStrings['Aon.Registration.AlternateEmail']" type="email"
                      name="alternateEmail" class="m-l-30 m-b-8" (keydown)=alternateSelected()
                      formControlName="alternateEmail" [(ngModel)]="AlternateEmail"
                      [ngClass]="{ 'error': isAlternateEmailInvalid() }" />
                  </div>
                </form>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div
                  *ngIf="uiConfig.uiConfiguration.enableMarketingConsent && !uiConfig.uiConfiguration?.disableMarketingConsentCollectionOnSelfRegistration && employeeServiceCommsPreferences?.length > 0">
                  <div class="checkbox-wrapper" *ngFor="let escp of employeeServiceCommsPreferences; let i = index">
                    <input ID="checkContact" type="checkbox" [checked]="!escp.isOptedOutOfComms"
                      (change)="escp.isOptedOutOfComms = !escp.isOptedOutOfComms" />
                    <label class="secondary" for="checkContact">
                      {{resourceStrings['Aon.Registration.AgreeContactForProducts']}}
                    </label>
                  </div>
                </div>
                <div class="checkbox-wrapper" *ngIf="!uiConfig.uiConfiguration.disableRegistrationPrivacyandTerms">
                  <input type="checkbox" (change)="acceptTermsAndPrivacyPolicy()"
                    [disabled]="!isPrivacyPolicyLinkClicked" name="checkPersonalInfo" id="checkPersonalInfo"
                    [checked]="personalInfoChecked" />
                  <label class="secondary privacy-link" for="checkPersonalInfo"
                    [innerHTML]="resourceStrings['Aon.Registration.AgreeToUsePersonalInfo'] | textTransform : {clientcode:''} | safeHtml">
                  </label>
                </div>

                <div class="controls p-b-20 n-mt-32">
                  <div class="alert contextual error m-b-32" *ngIf="errorMessages.length>0">
                    <div role="alert">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      <span *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage | safeHtml"></span>
                    </div>
                  </div>
                  <button (click)='submitForm()' (keyup.enter)="submitForm()" role="button"
                    class="col-md-3 col-12 registration-submit-2 n-btn-width" [disabled]="validateContinue()"
                    [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
                  <button *ngIf="uiConfig.uiConfiguration.enableMFA" (click)='backToPreviousStep()'
                    (keyup.enter)="backToPreviousStep()" role="button"
                    class="col-md-3 col-12 registration-back-2 n-btn-width subtle m-l-20"
                    [innerHTML]="resourceStrings['Aon.Common.Back'] | safeHtml"></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm">
                  {{resourceStrings['Aon.Account.HaveAccount']}} <a [routerLink]="[baseUrl+'/login']" class="login"
                    (click)="ActionDone()">{{resourceStrings['Aon.Login.LoginText']}}</a>
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm" [ngClass]="(isRegistrationModal)?'m-b-0':''">
                  {{resourceStrings['Aon.Account.NeedHelp']}} <a class="help" [routerLink]="[baseUrl+'/home/help']"
                    (click)="ActionDone()">{{resourceStrings['Aon.Login.ContactUs']}}</a>
                </p>
              </div>
            </div>
          </div>
          <div class="step-mfa" *ngIf="currentStep == 6">
            <app-mfa-authentication [step]="firstMFAStep" [inLoginProcess]="false"
              [employeeRecordID]="Employee_RecordID" [preferredName]="PreferredName"
              [workEmailForRegistration]="WorkEmail" [homeEmailForRegistration]="homeEmail" [alternateEmailForRegistration]="AlternateEmail"
              (finishedAddEmailAndPhone)="finishedAddEmailAndPhone($event)"
              (backToTermsAndConditions)="backToTermsAndConditions()"></app-mfa-authentication>
          </div>
          <div class="step-username" *ngIf="currentStep == 3">
            <div class="row">
              <div class="img-sleeve">
                <img src="/assets/images/account/curves4.svg" alt="" />
              </div>
              <div class="col-12">
                <h1 class="step-title page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.EnterOneTimePassword'] | safeHtml">
                </h1>
                <h3 class="step-title modal-only m-b-12"
                  [innerHTML]="resourceStrings['Aon.Registration.EnterOneTimePassword'] | safeHtml">
                </h3>
                <p class="lg m-b-40 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.OneTimePasswordTips'] | safeHtml"></p>
                <p class="m-b-20 modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.OneTimePasswordTips'] | safeHtml"></p>
              </div>
            </div>
            <div class="form-row">
              <div [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
                <label class="p-b-4" *ngIf="PreferedEmail"
                  [innerHTML]="resourceStrings['Aon.Registration.PrimaryEmail'] | safeHtml"></label>
                <label class="m-b-20 secondary" *ngIf="PreferedEmail">{{PreferedEmail}}</label>
                <div class="sub-section">
                  <div class="form-group ">
                    <label for="oneTimePassword"
                      [innerHTML]="resourceStrings['Aon.Registration.OneTimePassword'] | safeHtml"></label>
                    <aon-input-password [id]="'oneTimePassword'" [(ngModel)]="TemporaryPassword"
                      [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
                  </div>
                  <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-40'">
                    <div class="alert contextual error m-b-32" *ngIf="errorMessages.length>0">
                      <div role="alert">
                        <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                        <span *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage | safeHtml"></span>
                      </div>
                    </div>
                    <button (click)='submitForm()' (keyup.enter)='submitForm()'
                      class="registration-submit-3 m-r-20 col-md-3 col-12"
                      [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
                    <input *ngIf="!uiConfig.uiConfiguration.hideRegistrationConsentStep" type="button" value="Back"
                      (click)='backForm()' class="ghost back-btn col-md-3 col-12">
                    <button *ngIf="uiConfig.uiConfiguration.enableMFA" (click)='backToPreviousStep()'
                      (keyup.enter)="backToPreviousStep()" role="button"
                      class="col-md-3 col-12 registration-back-3 n-btn-width subtle m-l-20"
                      [innerHTML]="resourceStrings['Aon.Common.Back'] | safeHtml"></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm p-r-5" [innerHTML]="resourceStrings['Aon.Registration.NotGetOneTimePassword'] | safeHtml">
                </p>
                <a (click)='resendEmail()' href="javascript:void(0)" class="sm resend-email"
                  [innerHTML]="resourceStrings['Aon.Common.ResendEmail'] | safeHtml"></a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                  [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                </p>
                <a [routerLink]="[baseUrl+'/home/help']" (click)="ActionDone()" class="sm"
                  [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
              </div>
            </div>
          </div>
          <div class="step-password" *ngIf="currentStep == 4">
            <div class="row">
              <div class="img-sleeve">
                <img src="/assets/images/account/curves4.svg" alt="" />
              </div>
              <div class="col-12">
                <h1 class="step-title page-only "
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></h1>
                <h3 class="step-title modal-only "
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></h3>
                <p class="lg m-b-40 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ChooseUserName'] | safeHtml"></p>
                <p class="lg m-b-20 modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ChooseUserName'] | safeHtml"></p>
              </div>
            </div>

            <div class="form-row">
              <div [ngClass]="(isRegistrationModal)?'col-12 col-md-12':'col-12 col-md-6'">
                <label class="m-b-4" *ngIf="PrimaryEmail"
                  [innerHTML]="resourceStrings['Aon.Registration.PrimaryEmail'] | safeHtml"></label>
                <label class="secondary m-b-20" *ngIf="PrimaryEmail">{{PrimaryEmail}}</label>
                <label class="m-b-12 page-only" [innerHTML]="resourceStrings['Aon.Login.UserName'] | safeHtml"
                  *ngIf="uiConfig.uiConfiguration.showUserName"></label>
                <h5 class="m-b-12 modal-only" [innerHTML]="resourceStrings['Aon.Login.UserName'] | safeHtml"
                  *ngIf="uiConfig.uiConfiguration.showUserName"></h5>

                <div class="p-b-20" (click)="UsePrimaryEmailClicked()" *ngIf="uiConfig.uiConfiguration.showUserName">
                  <input type="checkbox" name="checkPersonalInfo" [checked]="UsePrimaryEmail" />
                  <label class="secondary" for="checkPersonalInfo"
                    [innerHTML]="resourceStrings['Aon.Registration.PrimaryEmailAsUserName'] | safeHtml">
                  </label>
                </div>
                <input type="text" [(ngModel)]="NewUserName" [disabled]="UsePrimaryEmail" class="form-input m-b-32"
                  *ngIf="uiConfig.uiConfiguration.showUserName" />

                <div class="sub-section">
                  <h3 class="m-b-12 page-only" [innerHTML]="resourceStrings['Aon.Login.Password'] | safeHtml"></h3>
                  <h5 class="m-b-12 modal-only" [innerHTML]="resourceStrings['Aon.Login.Password'] | safeHtml"></h5>
                  <app-new-password [(NewPassword)]="NewPassword" [(IsValid)]="isNewPasswordValid" [Token]="NewUserName"
                    [IsPlainToken]="true"></app-new-password>
                </div>
              </div>
              <div class="controls button-sleeve" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-40'">
                <div class="alert contextual error m-b-32" *ngIf="errorMessages.length>0">
                  <div role="alert">
                    <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                    <span *ngFor="let errorMessage of errorMessages" [innerHTML]="errorMessage | safeHtml"></span>
                  </div>

                </div>
                <button (click)='submitForm()' (keyup.enter)='submitForm()' [disabled]="!isNewPasswordValid"
                  class="registration-submit-4 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></button>
                <button (click)='submitForm()' [ngClass]="(isRegistrationModal)?'':'col-12'"
                  (keyup.enter)='submitForm()' [disabled]="!isNewPasswordValid" class="registration-submit-4 modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.CreateAccount'] | safeHtml"></button>
                <button *ngIf="uiConfig.uiConfiguration.enableMFA" (click)='backToPreviousStep()'
                  (keyup.enter)="backToPreviousStep()" role="button" class="registration-back-4 subtle"
                  [innerHTML]="resourceStrings['Aon.Common.Back'] | safeHtml"></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm p-r-5">
                  {{resourceStrings['Aon.Account.HaveAccount']}}
                </p>
                <a [routerLink]="[baseUrl+'/login']"
                  (click)="ActionDone()">{{resourceStrings['Aon.Login.LoginText']}}</a>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                  [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                </p>
                <a [routerLink]="[baseUrl+'/home/help']" class="sm help" (click)="ActionDone()"
                  [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
              </div>
            </div>
          </div>
          <div class="step-success" *ngIf="currentStep == 5">
            <div class="row">
              <div class="img-sleeve">
                <img src="/assets/images/account/curves4.svg" alt="" />
              </div>
              <div class="col-12">
                <h1 class="step-title page-only ">
                  {{ resourceStrings['Aon.Registration.Welcome'] }} {{resourceStrings['Aon.Registration.ClientName']}},
                  {{FirstName}}!
                </h1>
                <h3 class="step-title modal-only ">
                  {{ resourceStrings['Aon.Registration.Welcome'] }} {{resourceStrings['Aon.Registration.ClientName']}},
                  {{FirstName}}!
                </h3>
                <p class="lg m-b-20 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistration'] | safeHtml">
                </p>
                <p class="modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistration'] | safeHtml">
                </p>
                <p class="lg m-b-40 page-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistrationAdditionalInfo'] | safeHtml"></p>
                <p class="m-b-32 modal-only"
                  [innerHTML]="resourceStrings['Aon.Registration.ThanksForRegistrationAdditionalInfo'] | safeHtml"></p>
              </div>
            </div>
            <div class="form-row">
              <div class="col-12">
                <div class="controls" [ngClass]="(isRegistrationModal)?'p-b-20':'p-b-40'">
                  <button (click)='submitForm()' (keyup.enter)='submitForm()' class="registration-submit-5"
                    [innerHTML]="resourceStrings['Aon.Registration.BackToHome'] | safeHtml"></button>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 d-flex col-12 align-items-baseline">
                <p class="sm p-r-5" [ngClass]="(isRegistrationModal)?'m-b-0':''"
                  [innerHTML]="resourceStrings['Aon.Account.NeedHelp'] | safeHtml">
                </p>
                <a [routerLink]="[baseUrl+'/home/help']" class="sm help" (click)="ActionDone()"
                  (keyup.enter)="ActionDone()" [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </aon-spin>
</ng-container>

<ng-container *ngIf="isExpressCheckoutEnabled()">
  <aon-spin [IsLoading]="IsLoading">
    <app-express-registration (DismissEmit)="DismissFromExpressRegistration()"
      [isRegistrationModal]="isRegistrationModal"></app-express-registration>
  </aon-spin>
</ng-container>
