import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'functionExecutor'
})
export class FunctionExecutorPipe implements PipeTransform {

  transform(instance: any, callbackFnName: string, args = []): any {
    if (instance[callbackFnName] instanceof Function) {
      return instance[callbackFnName](...args);
    }
  }

}
