<div [formGroup]="form" class="form-group">
  <label [attr.for]="formInputField.key"
    [ngClass]="{ 'error': currentControl.invalid && currentControl.touched }">{{formInputField.label}}
    {{formInputField.required?'*':''}}</label>

  <div [ngSwitch]="formInputField.controlType">

    <div *ngIf="currentControl.invalid && currentControl.touched" class="alert contextual error">
      <div *ngIf="currentControl.errors.required" role="alert">
        <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
        <span
          [innerHTML]="resourceStrings['Aon.Validation.Required.ErrorMessage'] | textTransform: {fieldName: formInputField.label}"></span>
      </div>
    </div>

    <input [attr.aria-invalid]="false" type="email" class="text-input" *ngSwitchCase="'email'"
      [formControlName]="formInputField.key" [required]="formInputField.required ? 'required' : null"
      [minlength]="formInputField.min" [maxlength]="formInputField.max" [pattern]="formInputField.pattern"
      [(ngModel)]="formInputField.value" [id]="formInputField.key">

    <input *ngIf="!IsPassword && formInputField.controlType=='string'" type="text" [attr.aria-invalid]="false"
      [formControlName]="formInputField.key" [required]="formInputField.required ? 'required' : null"
      [minlength]="formInputField.min" [maxlength]="formInputField.max" [pattern]="formInputField.pattern"
      [(ngModel)]="formInputField.value" [id]="formInputField.key">

    <aon-input-password *ngIf="IsPassword && formInputField.controlType=='string'"
      [formControlName]="formInputField.key" [required]="formInputField.required ? 'required' : null"
      [minlength]="formInputField.min" [maxlength]="formInputField.max" [pattern]="formInputField.pattern"
      [(ngModel)]="formInputField.value" [id]="formInputField.key"
      [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>

    <input *ngSwitchCase="'number'" type="number" [formControlName]="formInputField.key" [attr.aria-invalid]="false"
      [required]="formInputField.required ? 'required' : null" [minlength]="formInputField.min"
      [maxlength]="formInputField.max" [pattern]="formInputField.pattern" [(ngModel)]="formInputField.value"
      [id]="formInputField.key">

    <div *ngSwitchCase="'dropdown'" class="aon-form-input-wrapper select m-t-20">
      <select class="aon-form-input" [formControlName]="formInputField.key" [formControlName]="formInputField.key"
        [(ngModel)]="formInputField.value" [required]="formInputField.required ? 'required' : null">
        <option *ngFor="let opt of formInputField.options" [value]="opt.key"
          [attr.aria-label]="opt.value + ' ' + resourceStrings['Aon.Common.Selected']">{{opt.value}}</option>
      </select>
    </div>

    <div *ngSwitchCase="'date'">
      <aon-input-date [minDate]="minDate" [maxDate]="maxDate" [formControlName]="formInputField.key"
        [(ngModel)]="formInputField.value"> </aon-input-date>
    </div>
  </div>
</div>