<div class="delegate-no-access-alert-modal">
  <div class="modal-header">
    <h1 class="h3 modal-title" id="delegate-no-access-alert-title"
        [innerHTML]="resourceStrings['Aon.Delegate.NoDelegateAccessTitle'] | safeHtml"></h1>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
            (click)="close()">
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body p-b-20">
    <div [innerHTML]="resourceStrings['Aon.Delegate.NoDelegateAccess'] | safeHtml">
    </div>
  </div>
  <div class="button-sleeve">
    <button (click)="close()" class="prominent" [innerHTML]="resourceStrings['Aon.Common.OK'] | safeHtml"></button>
  </div>
</div>
