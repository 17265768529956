<div class="sable-label-pair  d-flex {{data.cssClass}}" *ngIf="data.value != null && data.value != ''">
  <div class="icon-col"></div>
  <p class="sable-label d-flex m-r-5" [class.with-desc]="data.title != null && data.title != ''" *ngIf="data.label"
    [ngClass]="{'tooltip': data.title != null && data.title != ''}">
    {{data.label}}
    <span class="tooltiptext" *ngIf="data.title != null && data.title != ''">{{data.title}}</span>
  </p>
  <p class="sable-value d-flex m-r-5" *ngIf="labelValueText(data) !== ''">
    <span class="sable-label-value-item">{{labelValueText(data)}}</span>
  </p>
</div>