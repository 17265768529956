<ng-container *ngIf="isNormalLogin()">
  <div *ngIf="!showSucessStep">
    <div class="modal-header">
      <h3 class="modal-title" *ngIf="!curExpressLoginStep">{{resourceStrings['Aon.Login.LogInToContinue']}}</h3>
      <button type="button" class="close" title=""
        [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']" (click)="onClose()"
        *ngIf="!hideCloseButton">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <ng-container *ngIf="!curExpressLoginStep">
        <div>
          <form [formGroup]="loginForm">
            <div class="form-group">
              <label for="username"
                [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Login.UserName']}}</label>
              <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)"
                class="alert contextual error">
                <div *ngIf="f.username.errors.required" role="alert">
                  <i class="fas fa-exclamation-triangle"
                    aria-hidden="true"></i>{{resourceStrings['Aon.Login.UserNameIsRequired']}}
                </div>
              </div>
              <input type="text" formControlName="username" id="username" class="m-b-8"
                [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
              <p *ngIf="uiConfig?.uiConfiguration?.showForgotUsernameLinkonLoginPage"><a
                  [routerLink]="[baseUrl+'/account/forgotusername']"
                  (click)="linkClick()">{{resourceStrings['Aon.Login.ForgotUserName']}}</a></p>
            </div>
            <div class="form-group">
              <label for="password"
                [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.Password']}}</label>
              <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                class="alert contextual error">
                <div *ngIf="f.password.errors.required" role="alert">
                  <i class="fas fa-exclamation-triangle"
                    aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                </div>
              </div>
              <aon-input-password formControlName="password" [id]="'password'"
                [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
                [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
              <p class="m-t-8" *ngIf="!isFromForgotPassword"><a [routerLink]="[baseUrl+'/account/forgotpassword']"
                  (click)="linkClick()">{{resourceStrings['Aon.Login.Forgotpassword']}}</a></p>
            </div>
          </form>
        </div>
        <div class="row m-b-40">
          <div class="col-md-6" *ngIf="isExpressCheckoutEnabled()">
            <button class="login-continue-as-guest spinner button-style subtle" (click)='goToExpressLogin()'>
              {{resourceStrings['Aon.Login.ExpressCheckout']}}
            </button>
          </div>
          <div class="col-md-6">
            <button class="login-submit spinner" [disabled]="loading" (click)='onSubmit()'>
              {{resourceStrings['Aon.Login.LoginText']}}
              <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p class="sm" id="not-registered">{{resourceStrings['Aon.Login.NotRegisteredUser']}} <a id="create-account"
                href="javascript:void(0);" (click)="createAccount()"
                aria-labelledby="not-registered">{{resourceStrings['Aon.Login.CreateAccount']}}</a></p>
          </div>
          <div class="col-md-12">
            <p class="sm" id="need-help">{{resourceStrings['Aon.Account.NeedHelp']}} <a id="contact-help"
                [routerLink]="[baseUrl+'/home/help']" (click)="linkClick()"
                aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a></p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="showSucessStep">
    <div class="modal-header">
      <h3 class="modal-title" tabindex="0"> {{resourceStrings['Aon.Login.SuccessHeader']}}</h3>
      <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Common.Close']" (click)="onClose()">
        <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
      </button>
    </div>
    <div class="modal-body">
      <div [innerHTML]="resourceStrings[loginSucessInfoMsgKey] | safeHtml"></div>
      <button class="login-done spinner" [disabled]="loading" (click)='onSuccess()'>
        {{resourceStrings['Aon.Login.Done']}}
        <span *ngIf="loading" class="spinner-border spinner-border-sm m-l-4"></span>
      </button>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="!isNormalLogin()">
  <app-express-login [curExpressLoginStep]="curExpressLoginStep" [hideCloseButton]="hideCloseButton"
    (goToLoginScreen)="goToLogin()" (closeExpressLoginCallback)="closeExpressLogin()"
    (afterSumbitCallback)="afterSumbit()"></app-express-login>
</ng-container>