import { Component, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { take, takeUntil } from 'rxjs/operators';
import { QuoteService } from '../../quote/quote.service';
import { ViewportScroller } from '@angular/common';


@Component({
  selector: 'app-benefit-save-alert',
  templateUrl: './benefit-save-alert.component.html'
})
export class BenefitSaveAlertComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  uiConfig: UIConfigurationType;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  removePurchaseErrorMessage: boolean = false;
  constructor(
    public modalService: NgbActiveModal,
    public activeModal: NgbActiveModal,
    private uiconfigrationService: UiconfigrationService,
    private globalObjectsService: GlobalObjectsService,
    private authenticationService: AuthenticationService,
    private quoteService: QuoteService,
    private scroller: ViewportScroller,
    private injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {

    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });

    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
  }
  close() {
    this.modalService.close(false);
    this.globalObjectsService.logoutProfile = false;
    this.scrollToTop();
  }
  save() {
    this.globalObjectsService.stopBenefitSaveAlertTrigger = true;
    if (this.globalObjectsService.additionalContextData?.employeeBenefitID && !this.authenticationService.isLogin) {
      this.quoteService.removePurchase(this.globalObjectsService.additionalContextData?.employeeBenefitID)
        .pipe(take(1))
        .subscribe(response => {
          if (response.didSave === true) {
            this.getLogoutProfile();
          } else {
            this.removePurchaseErrorMessage = response.errorMessages?.length > 0;
          }
        })
    } else {
      this.getLogoutProfile();
    }
  }

  getLogoutProfile() {
    if (this.globalObjectsService.logoutProfile) {
      this.authenticationService.logout().subscribe(() => {
        this.modalService.close(true);
        this.globalObjectsService.logoutProfile = false;
      });
    } else {
      this.modalService.close(true);
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private scrollToTop() {
    this.scroller.scrollToPosition([0, 0]); //Scroll to top to show error message
  }

}
