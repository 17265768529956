import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, take, tap, mergeMap } from 'rxjs/operators';
import { JwtHelper } from '../guards/jwt.helper';
import { User } from '../../shared/models';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from '../services/uiconfigration.service';
import { GlobalObjectsService } from '../../shared/services/global-objects.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Constants, SessionStorageKey } from 'src/app/shared/models/constants';
import { HomeService } from '../../modules/home/home.service';
import { CommonService } from '../services';
import { SaveResult } from '../../shared/models/saveResult.model';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { SendCodeResultModel } from '../../shared/models/sendCodeResultModel.model';
import { AON_LOCALE } from '@aon/aon-angular-common';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUser: Observable<User>;
  public hasHighPriorityPage: BehaviorSubject<boolean>;
  public expressLoginComplete: Subject<boolean>;
  public currentUserSubject: BehaviorSubject<User>;
  private oAuthURL: string;
  apiBaseUrl: string;
  tempUser: User;
  uiConfig: UIConfigurationType;

  constructor(
    private http: HttpClient,
    private uiconfigrationService: UiconfigrationService,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private globalojectService: GlobalObjectsService,
    private helperService: HelperService,
    private commonService: CommonService,
    private homeService: HomeService,
    private ngxService: NgxUiLoaderService,
    private analyticsService: AnalyticsService,
    @Inject(AON_LOCALE) protected AON_LOCALE
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(this.getCurrentUserFromStorage));
    this.hasHighPriorityPage = new BehaviorSubject<boolean>(false);
    this.expressLoginComplete = new Subject<boolean>();
    this.currentUser = this.currentUserSubject.asObservable();
    this.apiBaseUrl = this.bootstrapRequestContext.apiBaseUrl;
    this.oAuthURL = this.bootstrapRequestContext.apiBaseUrl + 'connect/token';
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  public get isLogin(): boolean {
    return sessionStorage.getItem('currentUser') != null;
  }
  public get isSso(): boolean {
    const jwtHelper = new JwtHelper();
    let isSso = false;
    if (sessionStorage.getItem('currentUser') != null) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      const isSsoStr = jwtHelper.decodeToken(user.id_token).IsSso;
      if (isSsoStr == 'True') {
        isSso = true;
      }
    }
    return isSso;
  }

  public get hasMismatchedParticipants(): boolean {
    const jwtHelper = new JwtHelper();
    let hasMismatchedParticipants = false;
    if (sessionStorage.getItem('currentUser') != null) {
      const user = JSON.parse(sessionStorage.getItem('currentUser'));
      const hasMismatchedParticipantsStr = jwtHelper.decodeToken(user.id_token).HasMismatchedParticipants;
      if (hasMismatchedParticipantsStr == 'True') {
        hasMismatchedParticipants = true;
        sessionStorage.setItem(SessionStorageKey.HasMismatchedParticipants, hasMismatchedParticipants.toString());
      }
    }
    return hasMismatchedParticipants;
  }

  login(username: string, password: string, currentCulture: string, isExpressLogin: boolean = false, isMarketingSelectionSel: boolean | null = null) {
    const jwtHelper = new JwtHelper();
    let sessionId = '';
    if (sessionStorage.getItem('currentGuest') != null) {
      const user = JSON.parse(sessionStorage.getItem('currentGuest'));
      sessionId = jwtHelper.decodeToken(user.id_token).SessionID;
    }

    let params = new HttpParams();
    if (isExpressLogin) {
      params = params.append('grant_type', 'onetimepassword');
    } else {
      params = params.append('grant_type', 'password');
    }
    params = params.append('username', username);
    params = params.append('password', password);
    params = params.append('sessionid', sessionId);
    params = params.append('sitename', this.bootstrapRequestContext.siteName);
    params = params.append('clientcode', this.bootstrapRequestContext.clientCode);
    params = params.append('culture', currentCulture);
    params = params.append('timezoneoffset', new Date().getTimezoneOffset().toString());
    if (isMarketingSelectionSel != null) {
      params = params.append('marketingSelectionSelected', isMarketingSelectionSel);
    }
    return this.http.post<any>(this.oAuthURL, params.toString(), {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    });
  }

  public afterLoginCall(user: any) {
    if (user.success == undefined) {
      this.startRefreshTokenTimer();

      // const parentPath = '/Categories'; //TODO, need add tenant folder name for mutiple tenant
      //For SSO users, the masthead onInit will load the products
      // if (!this.isSSO(user) && this.bootstrapRequestContext.allowAnonymousAccess) {
      //   this.commonService.getCategories(parentPath).pipe(take(1)).subscribe((data) => {
      //     this.globalojectService.Categories.next(this.commonService.getDefinedCategories(data));
      //     this.globalojectService.Products.next(this.commonService.getProductsFromVirtualCategory(data));
      //     this.globalojectService.CategorizationEnabled.next(this.commonService.isCategorizationEnabled(data));
      //   });
      // }
    }
  }

  public setAuthenticatedUser(user: User) {
    sessionStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUserSubject.next(user);
  }

  public getUIConfig() {
    return this.uiconfigrationService.getUIConfiguration();
  }
  checkTokenValid() {
    const jwtHelper = new JwtHelper();

    const parsedToken = jwtHelper.decodeToken(this.currentUserSubject.value.id_token);
    if (parsedToken.TenantID != this.bootstrapRequestContext.tenantID && this.bootstrapRequestContext.tenantID != null) {
      sessionStorage.removeItem('currentGuest');
      location.reload();
      return;
    }
    const requestUrl = this.apiBaseUrl + 'guestvalid';
    return this.http.get(requestUrl).pipe(
      map((data) => {
        return data;
      }),
      catchError(() => {
        sessionStorage.removeItem('currentGuest');
        location.reload();
        return EMPTY;
      })
    );
  }

  guestToken(appInit: boolean = false) {
    if (appInit) {
      this.commonService.appInitInProgress = true;
    }
    if (this.helperService.getClientCode() == '') return of(null);

    let params = new HttpParams();
    params = params.append('grant_type', 'guest');
    params = params.append('sitename', this.bootstrapRequestContext.siteName);
    params = params.append('siteid', this.bootstrapRequestContext.siteID);
    params = params.append('timezone', new Date().getTimezoneOffset().toString());
    params = params.append('clientid', this.bootstrapRequestContext.tenantID);
    params = params.append('clientcode', this.bootstrapRequestContext.clientCode);
    params = params.append('culture', this.bootstrapRequestContext.currentCulture);
    return this.http
      .post<any>(this.oAuthURL, params.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .pipe(
        map((user) => {
          if (user.success == undefined) {
            sessionStorage.setItem('currentGuest', JSON.stringify(user));

            this.currentUserSubject.next(user);
            this.startRefreshTokenTimer();
          }
          return user;
        })
      );
  }

  osso(
    id: string,
    acessToken: string,
    culture = 'en-us',
    stateData: string = null,
    tPRequestItem: any = [],
    employee_EventRecordID: string = '',
    showAsNewTab: boolean = false,
    appLinksForAndroid: string = '',
    appLinksForIOS: string = ''
  ) {
    let clientSsoId = id;
    let takeOverBrowser = false;
    if (id) {
      let idArr = id.split('|');
      if (idArr.length > 1) {
        clientSsoId = idArr[0];
        takeOverBrowser = idArr[1] == 'True';
      }
    }
    if (acessToken) {
      const OssoViewModel = {
        culture: culture,
        stateData: stateData,
        TPRequestItem: tPRequestItem,
        ssoID: clientSsoId,
        userToken: acessToken,
        employee_EventRecordID: employee_EventRecordID
      };
      let url = '/SSO/OSSOInner?SSOID=' + clientSsoId;
      if (sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) == 'true') {
        takeOverBrowser = true;
        const appLinkForMobile = this.setCTALinkAsAppLinkForMobile(appLinksForAndroid, appLinksForIOS);
        if (appLinkForMobile) {
          url = appLinkForMobile;
        }
      }
      const stateModel = JSON.stringify(OssoViewModel);
      const form = document.createElement('form');
      form.setAttribute('method', 'post');
      form.setAttribute('action', url);
      if (takeOverBrowser) {
        form.setAttribute('target', '_self');
      } else {
        form.setAttribute('target', 'OutboundExchange');
      }
      let el = document.createElement('input');
      el.setAttribute('type', 'hidden');
      el.setAttribute('name', 'ssoModelData');
      el.setAttribute('value', stateModel);
      form.append(el);
      document.querySelector('#ssoFormContainer').append(form);
      if (!takeOverBrowser && !showAsNewTab) {
        this.helperService.openNewWindow('', 'OutboundExchange', 'height=600,width=1000,top=0,left=0,toolbar=no,menubar=no,scrollbars=yes, resizable=yes,location=no, status=no');
      }
      form.submit();
      form.remove();
      this.helperService.resetStringCache();
    }
  }

  setCTALinkAsAppLinkForMobile(appLinksForAndroid: string = '', appLinksForIOS: string = '') {
    let appLinkForMobile = '';
    if (this.helperService.isFromAndroidApp()) {
      if (appLinksForAndroid) {
        appLinkForMobile = appLinksForAndroid + '&Applink';
      }
    } else if (appLinksForIOS) {
      appLinkForMobile = appLinksForIOS + '&Applink';
    }
    return appLinkForMobile;
  }

  logout(): Observable<any> {
    this.helperService.setCustomLoggedOffSetting();
    this.analyticsService.resetIdentity();
    this.commonService.logoutInProgress = true;
    try {
      const id_Customsettings: {
        IsEnableApiTokenLog: boolean;
      } = JSON.parse(sessionStorage.getItem("Id_CustomSettings"));
      if (id_Customsettings?.IsEnableApiTokenLog && sessionStorage.getItem('currentUser')) {
        this.ngxService.start('__APP_LOGOUT__');
        return this.clearApiTokenLogs().pipe(mergeMap(status => {
          this.tempUser = null;
          return this.clearBrowserSessionAndGenerageGuestToken().pipe(tap(() => {
            this.ngxService.stop('__APP_LOGOUT__');
          }));
        }),
          catchError(() => {
            return this.clearBrowserSessionAndGenerageGuestToken().pipe(tap(() => {
              this.ngxService.stop('__APP_LOGOUT__');
            }));
          }));
      } else {
        return this.clearBrowserSessionAndGenerageGuestToken();
      }
    } catch {
      return this.clearBrowserSessionAndGenerageGuestToken();
    }
  }

  private clearBrowserSessionAndGenerageGuestToken(): Observable<any> {
    // remove user from local storage to log user out

    sessionStorage.removeItem('currentUser');
    this.currentUserSubject.next(JSON.parse(this.getCurrentUserFromStorage));
    sessionStorage.removeItem('uiConfigPerRole');
    sessionStorage.removeItem('eeBenefitIds');
    sessionStorage.removeItem('NewUserName');
    sessionStorage.removeItem(SessionStorageKey.TaxCode);
    sessionStorage.setItem('IsCaptureMarketingPreferenceRequired', 'False');
    sessionStorage.removeItem('AllowedLanguages');
    sessionStorage.removeItem(SessionStorageKey.ActiveEvent);
    sessionStorage.removeItem('additionalContextData');
    sessionStorage.removeItem('benefitSelectionAdditionalContextData');
    sessionStorage.removeItem(SessionStorageKey.EnabledRolesForUiStrings);
    sessionStorage.removeItem(SessionStorageKey.HasMismatchedParticipants);
    sessionStorage.removeItem(SessionStorageKey.RolesRoutingPathsForUiStrings);
    sessionStorage.removeItem(SessionStorageKey.MfaTempUser);
    sessionStorage.removeItem(SessionStorageKey.SsoClientList);
    sessionStorage.removeItem(SessionStorageKey.BenefitFlowObject);
    sessionStorage.removeItem(SessionStorageKey.CachedBenefitReviewData);
    sessionStorage.removeItem(SessionStorageKey.InTwoWaySSOProcess);
    sessionStorage.removeItem(SessionStorageKey.LifeStyleReasonAdditionalData);
    sessionStorage.removeItem(SessionStorageKey.SsoInteractionIncomplete);
    sessionStorage.removeItem(SessionStorageKey.CallbackObject);
    sessionStorage.removeItem(SessionStorageKey.isSableProcessFirstStep);
    sessionStorage.removeItem(SessionStorageKey.IsExpressLoginInFlow);
    sessionStorage.removeItem(SessionStorageKey.TimeWarpDate);
    sessionStorage.removeItem(SessionStorageKey.GroupTermItems);
    sessionStorage.removeItem('IsFromBenefitNavMegaMenu');
    sessionStorage.removeItem(SessionStorageKey.ClaimSelectedCurrency);
    sessionStorage.removeItem(SessionStorageKey.ActDelegateEmployee);
    this.helperService.clearPaymentSessionStorage();
    this.homeService.hideHomeBanners.next(true);
    if (!this.commonService.loginInProgress) {
      this.helperService.resetStringCache();

      if (!this.commonService.logoutInProgress) {
        this.globalojectService.checkHeaderFooterConfig.next(true);
      }

      this.commonService.resetClientBrandStyle.next(true);
      this.globalojectService.headerFooterConfigRefresh = true;
    }
    this.globalojectService.reloadShoppingCart.next(true);
    this.globalojectService.reloadProfileGracePeriod.next(true);
    this.globalojectService.delegateEmployeeSelfSelected.next(true);
    if (!this.helperService.AllowAnonymousAccess()) {
      this.globalojectService.resetNiceAfterLogout.next(true);
    }
    if (!this.commonService.appInitInProgress) {
      sessionStorage.removeItem('currentGuest');
      //It must have a subscribe, otherwise no request will get triggered
      return this.guestToken().pipe(take(1), tap(user => {
        this.logoutAfterTokenDone();
      }));
    }
    else {
      this.logoutAfterTokenDone();
      return of(null);
    }
  }

  private logoutAfterTokenDone() {
    this.commonService.logoutInProgress = false;
    this.commonService.logoutAndGuestTokenBack.next(true);
    this.commonService.resetOpenAccessDetails.next(true);
    if (this.bootstrapRequestContext.allowAnonymousAccess) {
      const parentPath = '/Categories'; //TODO, need add tenant folder name for mutiple tenant
      this.commonService
        .getCategories(parentPath)
        .pipe(take(1))
        .subscribe((data) => {
          this.globalojectService.Categories.next(this.commonService.getDefinedCategories(data));
          this.globalojectService.Products.next(this.commonService.getProductsFromVirtualCategory(data));
          this.globalojectService.CategorizationEnabled.next(this.commonService.isCategorizationEnabled(data));
        });
    }
  }

  clearApiTokenLogs(): Observable<boolean> {
    const requestUrl = this.apiBaseUrl + 'token/log/clear';
    return this.http.get<boolean>(requestUrl);
  }

  refreshToken() {
    let params = new HttpParams();
    params = params.append('grant_type', 'refresh_token');
    params = params.append('refresh_token', this.currentUserValue.refresh_token);
    return this.http
      .post<any>(this.oAuthURL, params.toString(), {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .pipe(
        map((user) => {
          if (this.isLogin) {
            sessionStorage.setItem('currentUser', JSON.stringify(user));
          } else {
            sessionStorage.setItem('currentGuest', JSON.stringify(user));
          }

          user.fromRefresh = true;
          this.currentUserSubject.next(user);
          this.startRefreshTokenTimer();

          return user;
        })
      );
  }

  sendCode(verificationOption, verificationOptionValue, isFromExpressRegistration = false) {
    if (!this.uiConfig) {
      this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    }
    const otpInfoModel = {
      VerificationOption: verificationOption,
      Email: verificationOptionValue,
      UIConfigId: this.uiConfig.id,
      IsFromExpressRegistration: isFromExpressRegistration
    };
    let requestUrl = this.bootstrapRequestContext.clientCode ? this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/' + this.bootstrapRequestContext.clientCode + '/sendcode' : this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/sendcode';
    return this.http.post<SendCodeResultModel>(requestUrl, otpInfoModel);
  }
  verifyCode(
    verificationOption,
    verificationOptionValue,
    code: string,
    proceedCreateAccount: boolean = false,
    marketingPreferenceCompleted: boolean = false
  ) {
    const otpInfoModel = {
      VerificationOption: verificationOption,
      Email: verificationOptionValue,
      Code: code,
      ProceedCreateAccount: proceedCreateAccount,
      MarketingPreferenceCompleted: marketingPreferenceCompleted,
      FromExpressRegistration: true
    };
    let requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/verifycode/' + this.bootstrapRequestContext.clientCode;
    return this.http.post<SaveResult>(requestUrl, otpInfoModel);
  }

  verifyandcreatepassword(verificationOptionValue: string, code: string, newPassword: string) {
    if (!this.uiConfig) {
      this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    }
    const createPasswordModel = {
      Email: verificationOptionValue,
      Code: code,
      UIConfigId: this.uiConfig.id,
      NewPassword: newPassword
    };

    let requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/verifyandcreatepassword/' + this.bootstrapRequestContext.clientCode;
    return this.http.post<SaveResult>(requestUrl, createPasswordModel);
  }

  isSSO(user: User) {
    let result = false;
    let jwtHelper = new JwtHelper();
    if (user && user.id_token) {
      let idToken = jwtHelper.decodeToken(user.id_token);
      if (idToken.IsSso === Constants.True) {
        result = true;
      }
    }

    return result;
  }

  private get getCurrentUserFromStorage(): string {
    if (sessionStorage.getItem('currentUser') != null) {
      return sessionStorage.getItem('currentUser');
    }
    return sessionStorage.getItem('currentGuest');
  }

  // helper methods

  private refreshTokenTimeout: any;

  public startRefreshTokenTimer() {
    const jwtToken = this.currentUserValue;

    // set a timeout to refresh the token a minute before it expires
    const expires = jwtToken.expires_in * 1000;
    const timeout = expires - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
  public UpdateAonLocaleByRole(parsedToken: any) {
    const stringKeys = [
      'AON_LOCALE.calendar.placeholders.day',
      'AON_LOCALE.calendar.placeholders.month',
      'AON_LOCALE.calendar.placeholders.year',
      'AON_LOCALE.calendar.patterns.d',
      'AON_LOCALE.calendar.messages.today',
      'AON_LOCALE.calendar.messages.toggle',
      'AON_LOCALE.calendar.messages.prevButtonTitle',
      'AON_LOCALE.calendar.messages.nextButtonTitle'
    ];
    const cdnStaticFileShareBaseURL = this.bootstrapRequestContext.cdnStaticFileShareBaseURL;

    if (parsedToken.RolesForUIStrings && JSON.parse(parsedToken.RolesForUIStrings).length > 0) {
      let rolesRoutingPathsForUiStrings = sessionStorage.getItem(SessionStorageKey.RolesRoutingPathsForUiStrings)
      let combinedRolesString: string = null;
      if (rolesRoutingPathsForUiStrings) {
        combinedRolesString = JSON.parse(rolesRoutingPathsForUiStrings).combinedRolesString;
      }
      else {
        let roles = JSON.parse(parsedToken.RolesForUIStrings);
        combinedRolesString = this.helperService.getCombinedRolesString(roles);
      }
      return this.helperService.loadResourceStringWithAdditionalParamAsObservable(combinedRolesString, stringKeys, JSON.parse(parsedToken.RolesForUIStrings)).pipe(
        map((data) => {
          const resourceString = {};
          data.forEach((res) => {
            if (res.value != res.key) {
              resourceString[res.key] = res.value;
            }
          });
          let uiConfig = this.uiconfigrationService.getUIConfiguration();

          const newAonLocale = {
            calendar: {
              patterns: {
                d: resourceString['AON_LOCALE.calendar.patterns.d']
              },
              placeholders: {
                year: resourceString['AON_LOCALE.calendar.placeholders.year'],
                month: resourceString['AON_LOCALE.calendar.placeholders.month'],
                day: resourceString['AON_LOCALE.calendar.placeholders.day']
              },
              messages: {
                today: resourceString['AON_LOCALE.calendar.messages.today'],
                nextButtonTitle: resourceString['AON_LOCALE.calendar.messages.nextButtonTitle'],
                prevButtonTitle: resourceString['AON_LOCALE.calendar.messages.prevButtonTitle'],
                toggle: resourceString['AON_LOCALE.calendar.messages.toggle']
              }
            },
            currency: {
              code: uiConfig?.uiConfiguration?.customCurrencyCode,
              symbol: uiConfig?.uiConfiguration?.customCurrencySymbol
            },
            cdn: {
              staticFileShareBaseURL: cdnStaticFileShareBaseURL
            }
          };
          Object.assign(this.AON_LOCALE, newAonLocale);
        })
      );
    }
    return new Observable<null>();
  }
}
