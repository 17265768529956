/// <reference types="@angular/localize" />

import { Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';

export let appInjector: Injector

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(m => appInjector = m.injector)
  .catch(err => console.error(err));
