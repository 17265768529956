<div class="profile-dropdown-menu masthead-profile">
  <ul class="nav-dropdown-list">
    <li>
      <a (click)="logOut()" class="logout dropdown-item p-l-20 p-r-20" fragment="logout" href="javascript:void(0);">
        {{
        resourcestrings['Aon.Navigation.Logout']
        }}
      </a>
    </li>
  </ul>
</div>
