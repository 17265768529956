import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { BehaviorSubject, Observable } from 'rxjs';
import { SableStep, SummaryItemModel, SummaryModel } from './quote.model';
import { SaveResult } from '../../shared/models/saveResult.model';
import { ContentItemModel } from '../../shared/models/contentItem.model';
import { ProductPlanModel } from '../../shared/models/productplan.model';
import { Participant } from '../../shared/models/participant.model';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { EmployeeCancellationType, PurchaseFlowInstanceResult, AddToCartTypeRequest, EmployeeBenefitType } from 'src/app/shared/models/benefitReview.model';
import { FollowUpItem } from '../benefit/benefit.model';
import { SaveResultWithOptions } from '../../shared/models/saveResultWithOptions.model';
import { PaymentSaveResultType } from 'src/app/shared/models/payment.model';
import { MinMaxRelationshipCounts, ParticipantType, ParticipantsType } from '../../shared/models/ParticipantsType';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { HelperService } from 'src/app/shared/services/helper.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { EnrolmentSummaryComponent } from '../benefit/enrolment-summary/enrolment-summary.component';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { BenefitInformation } from 'src/app/shared/models/Employee.BenefitInformation.model';
import { SaveElectionRequest } from "../../shared/models/saveElection.model";
import { AllocationType } from 'src/app/shared/models/allocationType.model';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {
  apiBaseUrl: string;
  tenantFolderName: string;
  public hideProgressBar: BehaviorSubject<boolean>;
  public hideQuoteSummary: BehaviorSubject<boolean>;
  public showReviewSummary: BehaviorSubject<boolean>;
  public reviewModelForSummary: BehaviorSubject<any>;
  public isPerPay: BehaviorSubject<boolean>;
  public isBenefitSelecting: BehaviorSubject<boolean>;
  public redrawProcessBar: BehaviorSubject<boolean>;
  public pageLoaded: BehaviorSubject<boolean>;
  public participantsMinMaxRelationshipCounts = new BehaviorSubject<MinMaxRelationshipCounts>(null);
  public participantsType = new BehaviorSubject<ParticipantsType>(null);
  public validateDependentsPerRelationships: BehaviorSubject<boolean>;
  public dependentsPerRelationshipsValidationResult: BehaviorSubject<boolean>;
  public dependentsNoCovertriggerDeselectionWarning: BehaviorSubject<boolean>;
  public redrawSable_RecordID: string;
  public redrawSableInstance_RecordID: string;
  public stepSummaries: any;
  public reviewPurchaseDataJSON: any;
  uiConfig: UIConfigurationType;
  refreshList = new BehaviorSubject<Boolean>(false);
  constructor(
    private http: HttpClient,
    private uiconfigrationService: UiconfigrationService,
    private router: Router,
    private helperService: HelperService,
    private globalObjectsService: GlobalObjectsService,
    private modalService: NgbModal,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.tenantFolderName = bootstrapRequestContext.tenantFolderName;
    this.hideProgressBar = new BehaviorSubject<boolean>(true);
    this.pageLoaded = new BehaviorSubject<boolean>(false);
    this.hideQuoteSummary = new BehaviorSubject<boolean>(false);
    this.showReviewSummary = new BehaviorSubject<boolean>(false);
    this.reviewModelForSummary = new BehaviorSubject<any>(null);
    this.isPerPay = new BehaviorSubject<boolean>(true);
    this.isBenefitSelecting = new BehaviorSubject<boolean>(false);
    this.redrawProcessBar = new BehaviorSubject<boolean>(false);
    this.validateDependentsPerRelationships = new BehaviorSubject<boolean>(false);
    this.dependentsPerRelationshipsValidationResult = new BehaviorSubject<boolean>(false);
    this.dependentsNoCovertriggerDeselectionWarning = new BehaviorSubject<boolean>(false);
    this.uiConfig = uiconfigrationService.getUIConfiguration();
  }

  public isPageLoaded() {
    return this.pageLoaded.asObservable();
  }

  public getBenefitInformation(employeeBenefitId: number, lineId: string, singleBenefitPlanId?: string) {
    const requestObj = {
      LineId: lineId,
      SingleBenefitPlanId: singleBenefitPlanId,
      EmployeeBenefitId: employeeBenefitId
    };
    const requestUrl = this.apiBaseUrl + 'benefit/information';
    return this.http.post<BenefitInformation>(requestUrl, requestObj);
  }

  public getPendingEmployeeBenefitsType(employeeBenefitId: number, lineId: string) {
    const requestObj = {
      LineId: lineId,
      EmployeeBenefitId: employeeBenefitId
    };
    const requestUrl = this.apiBaseUrl + 'PendingEmployeeBenefitsType';
    return this.http.post<EmployeeBenefitType>(requestUrl, requestObj);
  }

  public getBenefitInformationByAmount(employeeBenefitId: number, lineId: string, singleBenefitPlanId: string, coverageId: string, amount: string) {
    const requestObj = {
      LineId: lineId,
      SingleBenefitPlanId: singleBenefitPlanId,
      EmployeeBenefitId: employeeBenefitId,
      Amount: amount.toString(),
      CoveragId: coverageId
    };
    const requestUrl = this.apiBaseUrl + 'benefit/information';
    return this.http.post<BenefitInformation>(requestUrl, requestObj);
  }

  public saveElectedCoverage(employeeBenefitId: number, saveElectedData: SaveElectionRequest) {
    const requestUrl = this.apiBaseUrl + 'coverage/' + employeeBenefitId + '/save';
    return this.http.post<any>(requestUrl, saveElectedData);
  }

  public getPaymentProviders(request: number[]) {
    const requestUrl = this.apiBaseUrl + 'paymentprovider';
    return this.http.post<any>(requestUrl, request);
  }

  public processPaymentOrder(request: any): Observable<PaymentSaveResultType> {
    const requestUrl = this.apiBaseUrl + 'processpaymentorder';
    return this.http.post<PaymentSaveResultType>(requestUrl, request);
  }

  public addBenefitToCart(benefitId: number, isSingleProductCheckout: boolean = false) {
    return this.addBenefitsToCart([benefitId], isSingleProductCheckout);
  }

  public addBenefitsToCart(benefitIds: number[], isSingleProductCheckout: boolean = false) {
    const requestUrl = this.apiBaseUrl + 'benefits/addtocart';
    const data = {
      benefitIds: benefitIds,
      isSingleProductCheckout: isSingleProductCheckout
    } as AddToCartTypeRequest;
    return this.http.post<SaveResultWithOptions>(requestUrl, data);
  }

  public getEmployeePurchasesForCheckout(request: number[]) {
    const requestUrl = this.apiBaseUrl + 'purchasesforcheckout';
    return this.http.post<any>(requestUrl, request);
  }

  public getSableSummariesWithDataView(request: any) {
    const requestUrl = this.apiBaseUrl + 'sablesummary/dataview';
    return this.http.post<any>(requestUrl, request);
  }

  public ConfirmPurchase() { }

  public deleteEmployeeSavedQuote(eeBenefitId: number, fromSavedQuote: boolean = false) {
    const requestUrl = this.apiBaseUrl + 'quote/' + eeBenefitId + '/delete' + '?fromSavedQuote=' + fromSavedQuote;
    return this.http.post<SaveResult>(requestUrl, null);
  }

  public removePurchase(eeBenefitId: number) {
    const requestUrl = this.apiBaseUrl + 'benefits/' + eeBenefitId + '/delete';
    return this.http.post<SaveResult>(requestUrl, null);
  }

  getCarriers(carrierKeys: string[], subFolder: string = null) {
    const carrierItemsUrl = this.apiBaseUrl + 'carrieritems';
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ContentItemModel[]>(carrierItemsUrl, { Path: nodePath, ContentKeys: carrierKeys });
  }

  public saveEmployeeSableFormSelections(id: string, employeeSableFormSelection: any) {
    const requestUrl = this.apiBaseUrl + 'sableform/' + id + '/selections';
    return this.http.post<any>(requestUrl, employeeSableFormSelection);
  }

  public getProductPlans(planRecordIDs: any, subFolder: string = null) {
    let productPlans = this.apiBaseUrl + 'productplans';
    let nodePath = this.tenantFolderName;
    if (subFolder != null) {
      nodePath = nodePath + subFolder;
    }
    return this.http.post<ProductPlanModel[]>(productPlans, { Path: nodePath, ContentKeys: planRecordIDs });
  }

  private filterFlatSummary(summary: any, entityInstanceIDs: any, summarylist: any[]) {
    if (summary != null && summary.summary != null) {
      for (let item of summary.summary) {
        if (item != null) {
          this.filterFlatSummary(item, entityInstanceIDs || item.entityInstanceIDs, summarylist);
        } else {
          if (entityInstanceIDs != null) {
            item.entityInstanceIDs = entityInstanceIDs;
          }
          summarylist.push(item);
        }
      }
    }
    if (summary != null && summary.summary == null) {
      summarylist.push(summary);
    } else if (summary != null && summary.type == 'entity') {
      summary.summary.forEach((childSummary) => {
        childSummary.sableActivity_RecordID = summary.sableActivity_RecordID;
      });
    }
  }

  public HandleSummary(summary: any): SummaryModel[] {
    let summaryList: SummaryModel[] = [];
    for (let item of summary) {
      let summarymodel = {} as SummaryModel;
      summarymodel.type = item.type;
      summarymodel.title = item.title;
      summarymodel.summaryList = [];
      if (item.summary != null) {
        for (let subsummary of item.summary) {
          let lists = [];
          this.filterFlatSummary(subsummary, item.entityInstanceIDs, lists);
          let subItemMode = {} as SummaryItemModel;
          subItemMode.ItemList = lists;
          subItemMode.entityInstanceIDs = subsummary.entityInstanceIDs;
          subItemMode.sableActivity_RecordID = subsummary.sableActivity_RecordID;
          subItemMode.sableInstance_RecordID = subsummary.sableInstance_RecordID;
          this.SetSummaryEditable(subItemMode);
          //todo need show sub activity title rather than step title , this maybe need GI change later
          if (summarymodel.type == 'step' && lists.length > 0) {
            summarymodel.title = lists[0].title;
          }
          summarymodel.summaryList.push(subItemMode);
        }
      } else {
        let subItemMode = {} as SummaryItemModel;
        subItemMode.ItemList = [item];
        subItemMode.entityInstanceIDs = item.entityInstanceIDs;
        subItemMode.sableActivity_RecordID = item.sableActivity_RecordID;
        subItemMode.sableInstance_RecordID = item.sableInstance_RecordID;
        this.SetSummaryEditable(subItemMode);
        summarymodel.summaryList.push(subItemMode);
      }

      summaryList.push(summarymodel);
    }
    return summaryList;
  }
  private SetSummaryEditable(data: SummaryItemModel): void {
    let editable = false;
    for (let item of data.ItemList) {
      if (item.editable) {
        editable = true;
        break;
      }
    }
    data.edittable = editable;
  }

  public SaveCoveragePayFrequency(lineId: string, payFrequency: number, employeeBenefitId: number) {
    const requestObj = {
      lineId: lineId,
      payFrequency: payFrequency,
      employeeBenefitId: employeeBenefitId
    };
    const requestUrl = this.apiBaseUrl + 'coveragepayfrequency';
    return this.http.post<any>(requestUrl, requestObj);
  }

  getEmployeeBeneficiaries(EmployeeBenefitId, PlanRecordId) {
    const httpParams = new HttpParams().set('EmployeeBenefitId', EmployeeBenefitId).set('BenefitPlanRecordID', PlanRecordId).set('CanEdit', 'True');
    let requestUrl = this.apiBaseUrl + 'beneficiaries';
    return this.http.get<Participant[]>(requestUrl, { params: httpParams });
  }

  getEmployeeBeneficiaryAllocations(EmployeeBenefitId, LineRecordId) {
    const httpParams = new HttpParams().set('EmployeeBenefitId', EmployeeBenefitId).set('Line_RecordID', LineRecordId).set('IsProfile', 'false');
    let requestUrl = this.apiBaseUrl + 'beneficiaryallocations';
    return this.http.get<AllocationType[]>(requestUrl, { params: httpParams });
  }

  public SaveEmployeeBeneficiaryAllocation(allocationModel: any) {
    const requestUrl = this.apiBaseUrl + 'beneficiaryallocation';
    return this.http.post<any>(requestUrl, allocationModel);
  }

  public saveBeneficiary(beneficiaryModel: any, employeebenefitid: any) {
    const requestUrl = this.apiBaseUrl + 'savebeneficiary/' + employeebenefitid;
    return this.http.post<any>(requestUrl, beneficiaryModel);
  }

  public startEmployeeCoverageCancellation(employeeBeneitId: number): Observable<EmployeeCancellationType> {
    const requestUrl = `${this.apiBaseUrl}StartEmployeeCoverageCancellation/${employeeBeneitId}`;
    return this.http.post<EmployeeCancellationType>(requestUrl, {});
  }

  public cancelEmployeeCoverageCancellation(employeeBeneitId: number): Observable<SaveResult> {
    const requestUrl = `${this.apiBaseUrl}CancelEmployeeCoverageCancellation/${employeeBeneitId}`;
    return this.http.post<SaveResult>(requestUrl, {});
  }

  public saveEmployeeCoverageCancellation(employeeBeneitId: number, followUpItems: FollowUpItem[]): Observable<SaveResult> {
    const requestUrl = `${this.apiBaseUrl}SaveEmployeeCoverageCancellation/${employeeBeneitId}`;
    return this.http.post<SaveResult>(requestUrl, followUpItems);
  }

  public saveEmployeeAddOns(employeeBenefitId: number, employeeElection: any): Observable<SaveResult> {
    const requestUrl = `${this.apiBaseUrl}addons`;
    const httpParams = new HttpParams().set('employeeBenefitId', employeeBenefitId);
    return this.http.post<SaveResult>(requestUrl, employeeElection, { params: httpParams });
  }

  public SaveEmployeeBenefitParticipants(employeeBeneitId: number, participantsGuid: any) {
    const requestUrl = this.apiBaseUrl + 'Benefits/' + employeeBeneitId + '/Participants';
    return this.http.post<any>(requestUrl, participantsGuid);
  }

  public startPurchaseEdit(employeeBeneitId: number): Observable<PurchaseFlowInstanceResult> {
    const requestUrl = `${this.apiBaseUrl}StartPurchaseEdit/${employeeBeneitId}`;
    return this.http.get<PurchaseFlowInstanceResult>(requestUrl);
  }

  public startPurchaseRenewal(employeeBeneitId: number): Observable<PurchaseFlowInstanceResult> {
    const requestUrl = `${this.apiBaseUrl}StartPurchaseRenewal/${employeeBeneitId}`;
    return this.http.get<PurchaseFlowInstanceResult>(requestUrl);
  }

  setReviewPurchaseDataJSON(reviewPurchaseDataJSON) {
    this.reviewPurchaseDataJSON = reviewPurchaseDataJSON;
  }

  getReviewPurchaseDataJSON() {
    return this.reviewPurchaseDataJSON;
  }
  public deleteEndPurchaseQuote(employBenefitID: number) {
    const requestUrl = this.apiBaseUrl + 'Benefits/' + employBenefitID + '/Delete';
    return this.http.post<SaveResult>(requestUrl, null);
  }

  public deleteEndEntityQuote(id: string, employeeSableFormSelection: any) {
    const requestUrl = this.apiBaseUrl + 'sableform/' + id + '/deleteentityinstance';
    return this.http.post<SaveResult>(requestUrl, employeeSableFormSelection);
  }

  public saveParticipant(employeeBenefitId: number, isPartial: string, participant: ParticipantType) {
    const requestObj = {
      Participant: participant,
      EmployeeBenefitId: employeeBenefitId,
      IsPartial: isPartial
    };
    const requestUrl = this.apiBaseUrl + 'SaveParticipant';
    return this.http.post<SaveResult>(requestUrl, requestObj);
  }

  /**
   * Fire Anytime Flow
   */
  public triggerAnyTimeFlow(line_RecordId: string, flowId: string): void {
    try {
      const para = {
        queryParams: {
          browseBenefitsLineId: line_RecordId,
          isAnytime: true,
          isLifeStle: false
        }
      };
      sessionStorage.setItem('activeEventFlowId', flowId);
      if (sessionStorage.getItem(SessionStorageKey.ActiveEvent)) {
        let activeEventData = JSON.parse(sessionStorage.getItem(SessionStorageKey.ActiveEvent));
        if (activeEventData?.lifeStyleInfo?.event_RecordID) {
          sessionStorage.setItem('activeEventIdInMatrix', activeEventData.lifeStyleInfo.event_RecordID);
        }
      }
      const baseUrl: string = this.helperService.getBaseUrl();
      const redirectUrl: string = baseUrl + '/quote/eventflow-' + flowId + '/details';
      this.globalObjectsService.setActiveEventInstanceId = true;
      this.router.navigate([redirectUrl], para);
    } catch (error) {
      console.log('--FAILED TO TRIGGER--', error);
    }
  }

  /**
   * Get Anytime Sable Flow ID
   */
  public getAnyTimeSableFlowId(product): any {
    let anytimeSableFlowId: any = null;
    try {
      if (product?.employeeBenefitTypes?.length > 0) {
        anytimeSableFlowId = this._getAnyTimeSableFlowId(product.employeeBenefitTypes[0]);
      } else {
        anytimeSableFlowId = this._getAnyTimeSableFlowId(product);
      }
    } catch { }
    return anytimeSableFlowId;
  }

  private _getAnyTimeSableFlowId(product): any {
    let anytimeSableFlowId = null;
    if (product && product?.anytimeEntityInformationType && product?.anytimeEntityInformationType?.sableFlow_RecordID) {
      anytimeSableFlowId = product.anytimeEntityInformationType.sableFlow_RecordID;
    }
    return anytimeSableFlowId;
  }

  public openEnrolmentSummary(isWithinTheFlow: boolean, effectiveDate: string = null, activeEventId: string = null, currentAdapterName: string = ""): void {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'show-popup-right',
      size: 'l',
      ariaLabelledBy: 'app-modal-anchor'
    };
    ngbModalOptions.container = '#app-modal-anchor';
    const modalRef = this.modalService.open(EnrolmentSummaryComponent, ngbModalOptions);
    modalRef.componentInstance.isWithinTheFlow = isWithinTheFlow;
    modalRef.componentInstance.effectiveDate = effectiveDate;
    modalRef.componentInstance.activeEventId = activeEventId;
    modalRef.componentInstance.currentAdapterName = currentAdapterName;
  }

  public deleteBeneficiaryConfirmation(beneficiaryId: string) {
    let url = this.apiBaseUrl + 'employee/DeleteBeneficiaryConfirmation' + '?Beneficiary_RecordID=' + beneficiaryId;
    return this.http.get<AllocationType[]>(url);
  }

  public deleteBeneficiary(beneficiaryId: string) {
    let url = this.apiBaseUrl + 'employee/DeleteBeneficiary/' + beneficiaryId;;
    return this.http.post<SaveResult>(url, null);
  }

  public setManualDeferalValue(benefitInformationType, event) {
    benefitInformationType.benefitPlanInformationTypes.forEach((benefitPlanInformationType) => {
      if (benefitPlanInformationType.recordID == event.recordID && benefitPlanInformationType.isSelected) {
        benefitPlanInformationType.isManualDeferral = event.isManualDeferral;
      }
    });
  }

  public get displayManualAllocationPromptOnBenefitFlowCompletion(): boolean { return this.uiConfig.uiConfiguration.displayManualAllocationPromptOnBenefitFlowCompletion }

  public clearSableSessions() {
    sessionStorage.removeItem('activeEventIdInMatrix');
    sessionStorage.removeItem('activeEventFlowId');
    sessionStorage.removeItem('activeEventInstanceId');
    sessionStorage.removeItem('activeSableContext');
  }
}
