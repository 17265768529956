import { Component, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { CategoryType } from 'src/app/shared/models/categoryType.model';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { BootstrapRequestContext } from 'tbs-typings';
@Component({
  selector: 'app-footer-v2',
  templateUrl: './footer-v2.component.html'
})
export class FooterV2Component extends TBSBaseComponent implements OnInit, OnDestroy {
  uiConfig: UIConfigurationType;
  resourcestrings = {};
  baseUrl: string;
  categories: CategoryType[];
  privacyPolicyUrl: string;
  @Input() FooterStyleSwitch: boolean;
  enableOneTrustOptionalCookies = false;
  enableOneTrust = false;

  constructor(
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private uiconfigrationService: UiconfigrationService,
    private globalojectService: GlobalObjectsService,
    private injector: Injector) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Footer.FAQS',
      'Aon.Footer.NeedHelp',
      'Aon.Footer.Help',
      'Aon.Footer.Copyright',
      'Aon.Footer.TermsandConditions',
      'Aon.Footer.Privacypolicy',
      'Aon.Footer.Products',
      'Aon.Footer.Tagline',
      'Aon.Footer.Description',
      'Aon.Footer.ClientLogoAltText',
      'Aon.GlobalHelp.PageHeading',
      'Aon.Login.ContactUs',
      'Aon.Nice.ChatUnavailable',
      'Aon.Category.CategoryTitle',
      'Aon.Footer.Tagline',
      'Aon.Footer.OnlineSecurity'
    ]);
    this.enableOneTrustOptionalCookies = bootstrapRequestContext.enableOneTrustOptionalCookies;
    this.enableOneTrust = bootstrapRequestContext.enableOneTrust;
  }

  ngOnInit(): void {

    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
    this.baseUrl = this.helperService.getBaseUrl();
    this.privacyPolicyUrl = this.helperService.getPrivacyPolicyUrl();
    if (!sessionStorage.getItem('uiConfigGuest')) {
      this.uiconfigrationService.getDefaultUIConfigrationFromService()
        .pipe(take(1))
        .subscribe(du => {
          if (du) {
            sessionStorage.setItem('uiConfigGuest', JSON.stringify(du));
          }
        });
    }
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.globalojectService.Categories.subscribe(r => {
      this.categories = r;
    });
  }
  ngOnDestroy() {
    this.signalUnsubscribe();
  }
  showLightFooter(): boolean {
    //When showLightFooter enabled, if user is on quote pages/benefit pages then we will show app-footer-light.
    //When showLightFooter enabled, if user not on quote pages/benefit pages then we will show app-footer.
    //When showLightFooter not enabled, irrespective of user flow we will show app-footer.
    if (this.uiConfig.uiConfiguration?.showLightFooter) {
      return this.helperService.isQuoteRoute() || this.helperService.isBenefitRoute();
    }
  }

}
