import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, tap } from 'rxjs';
import { BootstrapRequestContext } from 'tbs-typings';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { SaveResult } from '../../shared/models/saveResult.model';
import { UIConfigurationType } from '../../shared/models/uiconfigration.interface';
import { AccountRegistrationForm } from '../../shared/models/validateAccountRegistrationFormRequest.model';
import { EmployeeServiceCommsPreference } from '../profile/models/employeeServiceCommsPreference.model';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
@Injectable({
  providedIn: 'root'
})
export class AccountService {
  apiBaseUrl: string;
  uiConfig: UIConfigurationType;
  privacyPolicyAgreed: BehaviorSubject<Boolean>;
  termsAgreed: BehaviorSubject<Boolean>;
  participantConsent = new BehaviorSubject<Boolean>(false);
  cacheSelfRegisteredFlag = false;

  constructor(
    private http: HttpClient,
    private uiconfigrationService: UiconfigrationService,
    private globalObjectsService: GlobalObjectsService,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.globalObjectsService.uiConfigs.subscribe((uiconfigration => this.uiConfig = uiconfigration));
  }

  public ForgotPassword(userName: string, email: string, dob: string, recaptchaToken: string) {
    const requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/forgotpassword';
    return this.http.post<boolean>(requestUrl, { UserName: userName, Email: email, Dob: dob, UIConfigId: this.uiConfig.id, RecaptchaToken: recaptchaToken });
  }

  public IsPasswordContainsUserName(token: string, password: string) {
    const requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/isPasswordContainsUserName';
    return this.http.post<boolean>(requestUrl, { UserIdentity: token, Password: password });
  }
  public GetPasswordStrengthOption() {
    const requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/getPasswordStrengthOption';
    return this.http.get(requestUrl, { responseType: 'text' });
  }

  public ForgotUserName(email: string, recaptchaToken: string) {
    const requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/forgotusername';
    return this.http.post<boolean>(requestUrl, { Email: email, UIConfigId: this.uiConfig.id, RecaptchaToken: recaptchaToken });
  }

  public ValidateResetPasswordLink(identity: string) {
    const requestUrl = this.apiBaseUrl + 'validateResetPasswordLink';
    return this.http.post<SaveResult>(requestUrl, {
      UserIdentity: identity,
    });
  }

  public ResetPassword(identity: string, newPassword: string) {
    const requestUrl = this.apiBaseUrl + this.bootstrapRequestContext.siteName + '/resettemppassword';
    return this.http.post<SaveResult>(requestUrl, {
      UserIdentity: identity,
      Password: newPassword,
      UIConfigId: this.uiConfig.id
    });
  }

  public FirstTimeUserResetPassword(newPassword: string) {
    const requestUrl = this.apiBaseUrl + 'firsttimeuserresettemppassword';
    return this.http.post<SaveResult>(requestUrl, { UserIdentity: '', Password: newPassword, UIConfigId: this.uiConfig.id });
  }

  public SetTermsAccepted(boolValue: boolean) {
    const requestUrl = this.apiBaseUrl + 'termsaccepted/' + boolValue;
    return this.http.post<SaveResult>(requestUrl, {});
  }

  public SetPrivacyPolicyAccepted(boolValue: boolean) {
    const requestUrl = this.apiBaseUrl + 'privacypolicyaccepted/' + boolValue;
    return this.http.post<SaveResult>(requestUrl, {});
  }

  public SetOtherinfoCollectionCompleted(boolValue: boolean) {
    const requestUrl = this.apiBaseUrl + 'otherinfocollectioncompleted/' + boolValue;
    return this.http.post<SaveResult>(requestUrl, {});
  }

  public getRegistrationForm(registrationFormId: string) {
    const requestUrl = this.apiBaseUrl + 'registrationform/' + registrationFormId;

    return this.http.get<AccountRegistrationForm>(requestUrl);
  }

  public validateRegistrationForm(registrationForm: AccountRegistrationForm) {
    const requestUrl = this.apiBaseUrl + 'registrationform/validate';

    return this.http.post<AccountRegistrationForm>(requestUrl, { asOfDate: '', ...registrationForm });
  }

  public saveRegistrationForm(registrationForm: AccountRegistrationForm) {
    const requestUrl = this.apiBaseUrl + 'registrationform/save';

    return this.http.post<SaveResult>(requestUrl, { asOfDate: '', ...registrationForm });
  }

  public getEmployeeServiceCommsPreferences() {
    const employeeServiceCommsPreferencesUrl = this.apiBaseUrl + 'employeeservicecommspreferences';
    return this.http.get<EmployeeServiceCommsPreference[]>(employeeServiceCommsPreferencesUrl);
  }

  public saveContactPreferences(serviceCommsPreferenceModel: EmployeeServiceCommsPreference, uiConfigId: string) {
    const contactPreferencesUrl = this.apiBaseUrl + 'contactpreferences/save/' + uiConfigId;
    return this.http.post<SaveResult>(contactPreferencesUrl, serviceCommsPreferenceModel);
  }

  public updateSensitiveDataScreening(SensitiveDataScreeningEnabled: boolean): Observable<SaveResult> {
    return this.http.post<SaveResult>(this.apiBaseUrl + 'updateSensitiveDataScreening/' + SensitiveDataScreeningEnabled, {});
  }

  public setPrivacyPolicyAndTerms(uiConfigId: string, boolValue: boolean) {
    const contactPreferencesUrl = this.apiBaseUrl + 'privacypolicyandterms/' + uiConfigId + '/' + boolValue;
    return this.http.post<SaveResult>(contactPreferencesUrl, null);
  }

  public setAllContactPreferencesFlags(uiConfigId: string, boolValue: boolean) {
    const contactPreferencesUrl = this.apiBaseUrl + 'contactpreferences/all/' + uiConfigId + '/' + boolValue;
    return this.http.post<SaveResult>(contactPreferencesUrl, null);
  }

  public isSelfRegistered(): Observable<boolean> {
    const requestUrl = this.apiBaseUrl + 'isselfregistered';
    return this.http.get<boolean>(requestUrl).pipe(tap(isSelfRegistered => {
      this.cacheSelfRegisteredFlag = isSelfRegistered;
    }));
  }

  public processExternalServiceToken(token: string) {
    const contactPreferencesUrl = this.apiBaseUrl + 'processExternalServiceToken/' + token;
    return this.http.post<SaveResult>(contactPreferencesUrl, {});
  }

  public updateSensitiveScreeningEnabledFlagInSession(SensitiveDataScreeningEnabled: boolean): void {
    let CustomSettings = JSON.parse(sessionStorage.getItem("Id_CustomSettings"));
    CustomSettings.SensitiveDataScreeningEnabled = SensitiveDataScreeningEnabled;
    sessionStorage.setItem("Id_CustomSettings", JSON.stringify(CustomSettings));
  }
}
