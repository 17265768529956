import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml'
})

export class StripHtmlPipe implements PipeTransform {
  transform(value: string, whiteListTags: string[] = []): any {
    if (whiteListTags.length > 0) {
      let divElement = document.createElement("div");
      const removeUnwantedSpaces = (string) => {
        string = string.replaceAll('&nbsp;', "");
        string = string.replaceAll(/\s+/g, ' ');
        return string.trim();
      }
      divElement.innerHTML = value;
      divElement.querySelectorAll('*').forEach((element: any) => {
        element.removeAttribute('class');
        element.removeAttribute('style');
        if (!whiteListTags.includes(element.tagName)) {
          element.replaceWith('');
        }
      })
      return removeUnwantedSpaces(divElement.innerHTML);
    }
    else {
      const tmp = document.createElement('DIV');
      tmp.innerHTML = value;
      return tmp.textContent || tmp.innerText || '';
    }
  }






}
