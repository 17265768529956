import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { PaymentAgreementComponent } from 'src/app/modules/benefit/benefit-select/payment/payment-agreement/payment-agreement.component';
import { BootstrapRequestContext } from 'tbs-typings';
import {
  EmployeePaymentMethodsWithDetailsType,
  EmployeePaymentsType,
  EmployeePaymentTransactionGroup,
  EmployeePaymentTransactionWithHistoryGroup,
  EmployeePreferredEmail
} from '../models/benefitReview.model';
import { PaymentCompleteTemplate, PaymentProviderCode, PaymentProviderPaymentTypeCode, SessionStorageKey } from '../models/constants';
import {
  AgreementType,
  EmployeePaymentCreditTransaction,
  EmployeePaymentMethodType,
  EmployeePaymentMethodTypeRequest,
  EmployeePaymentSaveResult,
  EmployeePaymentUpdate,
  EmployeeReadyPurchases,
  PaymentAccountBalance,
  PaymentProviderSessionType,
  PaymentRecurrenceType,
  PaymentRemediationRequestType,
  PaymentSaveResultType,
  PaymentSettings,
  ProcessPaymentOrderRequest,
  ProviderPaymentType,
  ProvidersType,
  SaveAgreementRequestType,
  QuoteCompleteState,
  AddressType
} from '../models/payment.model';
import { Product } from '../models/product.model';
import { SaveResult } from '../models/saveResult.model';
import { GlobalObjectsService } from './global-objects.service';
import { HelperService } from './helper.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ResourceStringsObject } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentApiBaseUrl: string = null;
  employeeApiBaseUrl: string = null;
  paymentSettingsSubject = new BehaviorSubject<PaymentSettings>(null);
  providerPaymentTypeSelectedSubject = new BehaviorSubject<string>(null);
  employeePaymentsTypeSubject = new BehaviorSubject<EmployeePaymentsType>(null);
  employeePaymentMethodAddedSubject = new BehaviorSubject<EmployeePaymentMethodType>(null);
  agreementInstance: PaymentAgreementComponent;

  constructor(
    private http: HttpClient,
    public helperService: HelperService,
    private router: Router,
    private globalObjectService: GlobalObjectsService,
    private modalService: NgbModal,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.paymentApiBaseUrl = bootstrapRequestContext.apiBaseUrl + 'Payment';
    this.employeeApiBaseUrl = bootstrapRequestContext.apiBaseUrl + 'Employee';
  }

  getPaymentDetails() {
    return this.http.get('assets/json/payment.json');
  }

  getPaymentSettings(): Observable<PaymentSettings> {
    return this.http.get<PaymentSettings>(`${this.paymentApiBaseUrl}/Settings`);
  }

  checkEmployeePaymentStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${this.paymentApiBaseUrl}/Status`);
  }

  checkAutoRenewalStatus(): Observable<boolean> {
    return this.http.get<boolean>(`${this.paymentApiBaseUrl}/Status/AutoRenewal`);
  }

  processPaymentOrder(param: ProcessPaymentOrderRequest): Observable<PaymentSaveResultType> {
    return this.http.post<PaymentSaveResultType>(`${this.paymentApiBaseUrl}/ProcessOrder`, param);
  }

  startPaymentOrder(param: ProcessPaymentOrderRequest): Observable<PaymentSaveResultType> {
    return this.http.post<PaymentSaveResultType>(`${this.paymentApiBaseUrl}/StartOrder`, param);
  }

  stopPaymentOrder(param: ProcessPaymentOrderRequest): Observable<PaymentSaveResultType> {
    return this.http.post<PaymentSaveResultType>(`${this.paymentApiBaseUrl}/StopOrder`, param);
  }

  processPaymentRemediation(param: PaymentRemediationRequestType): Observable<PaymentSaveResultType> {
    return this.http.post<PaymentSaveResultType>(`${this.paymentApiBaseUrl}/ProcessRemediation`, param);
  }

  processPaymentRecurrence(param: PaymentRecurrenceType): Observable<boolean> {
    return this.http.post<boolean>(`${this.paymentApiBaseUrl}/ProcessRecurrence`, param);
  }

  markPaymentPaid(param: EmployeePaymentUpdate): Observable<SaveResult> {
    return this.http.post<SaveResult>(`${this.paymentApiBaseUrl}/MarkPaid`, param);
  }

  updatePaymentGracePeriod(param: EmployeePaymentUpdate): Observable<SaveResult> {
    return this.http.put<SaveResult>(`${this.paymentApiBaseUrl}/GracePeriod`, param);
  }

  getPaymentAccountBalance(lookupPaymentProviderPaymentTypeId: string): Observable<PaymentAccountBalance> {
    return this.http.get<PaymentAccountBalance>(`${this.paymentApiBaseUrl}/AccountBalance/${lookupPaymentProviderPaymentTypeId}`);
  }

  getEmployeePurchasesForCheckout(employeeBenefits: number[]): Observable<EmployeeReadyPurchases> {
    return this.http.post<EmployeeReadyPurchases>(`${this.paymentApiBaseUrl}/ReadyToCheckout`, employeeBenefits);
  }

  //targetPaymentDate: yyyy-MM-dd
  cancelPoliciesWithFailedPayments(targetPaymentDate: string): Observable<boolean> {
    return this.http.post<boolean>(`${this.paymentApiBaseUrl}/Failed/CancelPolicies/${targetPaymentDate}`, null);
  }

  getPaymentProviders(lineId: string | null): Observable<ProvidersType> {
    if (lineId == null) {
      return this.http.get<ProvidersType>(`${this.paymentApiBaseUrl}/Providers`);
    } else {
      return this.http.get<ProvidersType>(`${this.paymentApiBaseUrl}/Providers/${lineId}}`);
    }
  }

  getPaymentProviderSession(lookupPaymentProviderPaymentTypeId: string): Observable<PaymentProviderSessionType> {
    return this.http.get<PaymentProviderSessionType>(`${this.paymentApiBaseUrl}/Provider/${lookupPaymentProviderPaymentTypeId}/Session`);
  }

  getEmployeePaymentMethods(employeeBenefits: number[]): Observable<EmployeePaymentsType> {
    return this.http.post<EmployeePaymentsType>(`${this.paymentApiBaseUrl}/EmployeePaymentMethods`, employeeBenefits);
  }

  getEmployeePaymentMethodsForSwitch(employeeBenefits: number[]): Observable<EmployeePaymentsType> {
    return this.http.post<EmployeePaymentsType>(`${this.paymentApiBaseUrl}/EmployeePaymentMethods/Switch`, employeeBenefits);
  }

  getEmployeePaymentMethodsForManagement(): Observable<EmployeePaymentMethodsWithDetailsType> {
    return this.http.get<EmployeePaymentMethodsWithDetailsType>(`${this.paymentApiBaseUrl}/EmployeePaymentMethods/Management`);
  }

  saveEmployeePaymentType(param: EmployeePaymentMethodTypeRequest): Observable<EmployeePaymentSaveResult> {
    return this.http.post<EmployeePaymentSaveResult>(`${this.paymentApiBaseUrl}/EmployeePaymentMethod`, param);
  }

  switchEmployeePaymentMethod(employeePaymentMethodId: string, employeeBenefits: number[]): Observable<SaveResult> {
    return this.http.post<SaveResult>(`${this.paymentApiBaseUrl}/EmployeePaymentMethod/${employeePaymentMethodId}/Switch`, employeeBenefits);
  }

  deleteEmployeePaymentMethod(employeePaymentMethodId: string): Observable<SaveResult> {
    return this.http.delete<SaveResult>(`${this.paymentApiBaseUrl}/EmployeePaymentMethod/${employeePaymentMethodId}`);
  }

  getEmployeeRemediationPaymentTypes(employeePaymentScheduleDateId: string): Observable<EmployeePaymentsType> {
    return this.http.get<EmployeePaymentsType>(`${this.paymentApiBaseUrl}/EmployeePaymentTypes/Remediation/${employeePaymentScheduleDateId}`);
  }

  getEmployeePaymentTransactions(lineId: string | null): Observable<EmployeePaymentTransactionGroup> {
    if (lineId == null) {
      return this.http.get<EmployeePaymentTransactionGroup>(`${this.paymentApiBaseUrl}/Transactions`);
    } else {
      return this.http.get<EmployeePaymentTransactionGroup>(`${this.paymentApiBaseUrl}/Transactions/${lineId}}`);
    }
  }

  getEmployeePaymentTransactionsWithHistory(lineId: string | null): Observable<EmployeePaymentTransactionWithHistoryGroup> {
    if (lineId == null) {
      return this.http.get<EmployeePaymentTransactionWithHistoryGroup>(`${this.paymentApiBaseUrl}/Transactions/History`);
    } else {
      return this.http.get<EmployeePaymentTransactionWithHistoryGroup>(`${this.paymentApiBaseUrl}/Transactions/History/${lineId}}`);
    }
  }

  saveEmployeeEventPaymentTransactionForCredit(param: EmployeePaymentCreditTransaction): Observable<SaveResult> {
    return this.http.post<SaveResult>(`${this.paymentApiBaseUrl}/Transactions/Credit`, param);
  }

  updateEmployeeEventPaymentTransactionForCredit(param: EmployeePaymentCreditTransaction): Observable<SaveResult> {
    return this.http.put<SaveResult>(`${this.paymentApiBaseUrl}/Transactions/Credit`, param);
  }

  deleteEmployeeEventPaymentTransactionForCredit(employeeEventPaymentTransactionId: string): Observable<SaveResult> {
    return this.http.delete<SaveResult>(`${this.paymentApiBaseUrl}/Transaction/Credit/${employeeEventPaymentTransactionId}`);
  }

  getAgreementForPurchase(lookupPaymentProviderPaymentTypeId: string, employeeBenefitIds: number[]): Observable<AgreementType> {
    let qs = `lookupPaymentProviderPaymentTypeId=${lookupPaymentProviderPaymentTypeId}`;
    if ((employeeBenefitIds || []).length > 0) {
      let ebs = employeeBenefitIds.map((x) => `employeeBenefitIds=${x}`).join('&');
      qs = `${qs}&${ebs}`;
    }

    return this.http.get<AgreementType>(`${this.paymentApiBaseUrl}/GetAgreementForPurchase?${qs}`);
  }

  saveAgreement(param: SaveAgreementRequestType): Observable<SaveResult> {
    return this.http.post<SaveResult>(`${this.paymentApiBaseUrl}/SaveAgreement`, param);
  }

  detectCardType(creditCardNumber: string): string {
    const re = {
      electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
      maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
      dankort: /^(5019)\d+$/,
      interpayment: /^(636)\d+$/,
      unionpay: /^(62|88)\d+$/,
      visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
      mastercard: /^5[1-5][0-9]{14}$/,
      amex: /^3[47][0-9]{13}$/,
      diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
      discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
      jcb: /^(?:2131|1800|35\d{3})\d{11}$/
    };

    for (let key in re) {
      if (re[key].test(creditCardNumber)) {
        return key;
      }
    }
  }

  checkEmployeeBenefitGracePeriod(): Observable<boolean> {
    return this.http.get<boolean>(`${this.bootstrapRequestContext.apiBaseUrl}CheckEmployeeBenefitGracePeriod`);
  }

  isPayrollDeduction(epm: EmployeePaymentMethodType | ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      if (epm instanceof EmployeePaymentMethodType) {
        result =
          epm &&
          epm.providerCode != undefined &&
          epm.providerCode == PaymentProviderCode.AON &&
          epm.code != undefined &&
          epm.code == PaymentProviderPaymentTypeCode.AON_PAYROLLDEDUCTION;
      } else {
        let ppt = epm as ProviderPaymentType;
        result = ppt.providerCode == PaymentProviderCode.AON && ppt.code == PaymentProviderPaymentTypeCode.AON_PAYROLLDEDUCTION;
      }
    }

    return result;
  }

  isCreditCard(epm: EmployeePaymentMethodType | ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      if (epm instanceof EmployeePaymentMethodType) {
        result =
          epm &&
          ((epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.INGENICO_VISA) ||
            (epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.INGENICO_MASTERCARD) ||
            (epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.INGENICO_AMEX) ||
            (epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.INGENICO_DISCOVER) ||
            (epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.MY2C2P_MASTERCARD) ||
            (epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.MY2C2P_VISA));
      } else {
        let ppt = epm as ProviderPaymentType;
        result =
          ppt.code == PaymentProviderPaymentTypeCode.INGENICO_VISA ||
          ppt.code == PaymentProviderPaymentTypeCode.INGENICO_MASTERCARD ||
          ppt.code == PaymentProviderPaymentTypeCode.INGENICO_AMEX ||
          ppt.code == PaymentProviderPaymentTypeCode.INGENICO_DISCOVER ||
          ppt.code == PaymentProviderPaymentTypeCode.MY2C2P_MASTERCARD ||
          ppt.code == PaymentProviderPaymentTypeCode.MY2C2P_VISA;
      }
    }

    return result;
  }

  isACH(epm: EmployeePaymentMethodType | ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      if (epm instanceof EmployeePaymentMethodType) {
        result = epm && epm.code !== undefined && epm.code == PaymentProviderPaymentTypeCode.INGENICO_ACH;
      } else {
        let ppt = epm as ProviderPaymentType;
        result = ppt.code == PaymentProviderPaymentTypeCode.INGENICO_ACH;
      }
    }

    return result;
  }

  isIngenico(epm: EmployeePaymentMethodType | ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      if (epm instanceof EmployeePaymentMethodType) {
        result = epm.providerCode !== undefined && epm.providerCode == PaymentProviderCode.INGENICO;
      } else {
        let ppt = epm as ProviderPaymentType;
        result = ppt.providerCode == PaymentProviderCode.INGENICO;
      }
    }

    return result;
  }

  isIngenicoVisa(epm: ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      let ppt = epm as ProviderPaymentType;
      result = ppt.code == PaymentProviderPaymentTypeCode.INGENICO_VISA;
    }
    return result;
  }

  isMy2C2P(epm: EmployeePaymentMethodType | ProviderPaymentType): boolean {
    let result = false;
    if (epm) {
      if (epm instanceof EmployeePaymentMethodType) {
        result = epm.providerCode !== undefined && epm.providerCode == PaymentProviderCode.MY2C2P;
      } else {
        let ppt = epm as ProviderPaymentType;
        result = ppt.providerCode == PaymentProviderCode.MY2C2P;
      }
    }

    return result;
  }

  validateACHCardNumber(cardNumber: string): boolean {
    if (!this.helperService.isEmptyText(cardNumber)) {
      return true;
    }
    return false;
  }

  gotoPaymentComplete(lineId: string | string[], products: Product[], data: PaymentSaveResultType, epm: EmployeePaymentMethodType, isRemediation: boolean = false): void {
    let paymentCompleteUrl = '/benefit/paymentconfirm';
    let isUsAdhocTemplate = false;

    if (lineId && products) {
      let isUsVb = this.helperService.IsUSVB();
      let idArray: string[];
      if (!Array.isArray(lineId)) {
        idArray = [lineId];
      } else {
        idArray = [...lineId];
      }

      //For US VB, it supports single product checkout only.
      //If it has product using us-adhoc payment complete template, it's supposed to being only one line id there.
      //So it's safe to pick the 1st line id for the us-adhoc payment complete url
      if (idArray.length > 0) {
        isUsAdhocTemplate = idArray.some((ld) => {
          let p = products.find((x) => x.line_GUID == ld);
          return (
            p &&
            (p.paymentCompleteTemplate == PaymentCompleteTemplate.USAdhoc || (p.paymentCompleteTemplate == PaymentCompleteTemplate.Default && isUsVb))
          );
        });

        if (isUsAdhocTemplate) {
          paymentCompleteUrl = `/quote/${idArray[0]}/complete`;
        }
      }
    }

    if (data && data.didSave) {
      this.globalObjectService.reloadShoppingCart.next(true);

      let baseUrl = this.helperService.getBaseUrl();
      //the quote-complete page checks history.state.processResult, so need keep the variable name as 'processResult'
      if (isUsAdhocTemplate) {
        let processResult = { paymentResult: data, paymentMethod: epm } as QuoteCompleteState;
        this.router.navigate([baseUrl + paymentCompleteUrl], { state: { processResult } });
      } else {
        let keepRemediationData = false;
        if (!data.paymentSuccessful && isRemediation) {
          keepRemediationData = true;
        }
        this.helperService.clearPaymentSessionStorage(keepRemediationData);
        sessionStorage.setItem(SessionStorageKey.PaymentConfirmData, JSON.stringify(data));
        this.router.navigate([baseUrl + paymentCompleteUrl]);
      }
    }
  }

  openAgreementModal(agreementType: AgreementType, employeeBenefitIds: number[], callbackFunc: Function = null) {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      windowClass: 'checkout-tc'
    };
    const modalRef = this.modalService.open(PaymentAgreementComponent, ngbModalOptions);
    this.agreementInstance = modalRef.componentInstance as PaymentAgreementComponent;
    this.agreementInstance.agreementType = agreementType;
    this.agreementInstance.employeeBenefitIds = employeeBenefitIds;
    modalRef.componentInstance.afterSumbit = () => {
      modalRef.close();
    };

    modalRef.result.then(
      (data: any) => {
        if (callbackFunc != null) {
          callbackFunc(data);
        }
      },
      (reason) => {
        /*Leave empty or handle reject*/
        /*This is used to avoid the JS error when calling dismiss to close a modal*/
      }
    );
  }

  showCardName(epm: EmployeePaymentMethodType, resStrings: ResourceStringsObject): string {
    let getString = (key: string): string => {
      if (resStrings && key && key in resStrings) {
        return resStrings[key];
      }

      return key;
    };

    let nullAsEmpty = (data: string | null): string => {
      if (!data) return '';
      return data;
    };

    if (this.isPayrollDeduction(epm)) {
      return getString('Aon.Payment.PayrollDeduction');
    } else {
      let number = nullAsEmpty(epm.maskedCCNumber);
      let prefix = this.isACH(epm) ? getString('Aon.Payment.AccountEndingIn') : getString('Aon.Payment.CardEndingIn');
      let result = `${prefix} ${number.replace(/\d(?=\d{4})/g, '*')}`;
      return result;
    }
  }

  getEmployeePreferredEmail(): Observable<EmployeePreferredEmail> {
    let employeePreferredEmail = this.bootstrapRequestContext.apiBaseUrl + 'EmployeePreferredEmail';
    return this.http.get<EmployeePreferredEmail>(employeePreferredEmail);
  }

  getClientIpAddress(): Observable<AddressType> {
    return this.http.get<AddressType>("https://api.ipify.org?format=json");
  }

  getPaymentReturnUrl() {
    let returnURL =
      window.location.protocol +
      '//' +
      window.location.hostname +
      (window.location.port ? ':' + window.location.port : '') +
      '/paymentinterim/BenefitPayment';
    if (this.helperService.IsVanityURLForMultiTenantEnabled()) {
      returnURL = returnURL + '/' + this.helperService.getClientCode();
    }
    return returnURL;
  }
}
