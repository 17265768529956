import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SableFlowAdapterService, SableFlowEventBusService, SableFlowModel } from '@aon/sable-flow';
import { ErrorActivityComponent } from '../../../modules/quote/error-activity/error-activity.component';

@Injectable({
  providedIn: 'root'
})
export class KenticoErrorActivityAdapterService implements SableFlowAdapterService {
  instance: ErrorActivityComponent;
  customInteractions: Array<SableFlowModel.CustomInteractions> = [];
  constructor(private busservice: SableFlowEventBusService
  ) { }

  render(vcRef: ViewContainerRef, activityData: any, entityInstances: any, flow: any): Observable<any> {
    vcRef.clear();
    const componentRef = vcRef.createComponent<ErrorActivityComponent>(ErrorActivityComponent, { index: 0 });
    this.instance = componentRef.instance;
    this.instance.activityData = activityData;
    if (activityData.Response != undefined && activityData.Response != null && activityData.Response.Data != undefined && activityData.Response.Data != null
      && activityData.Response.Data.Previous_EmployeeSableFlowInstance_RecordID != undefined && activityData.Response.Data.Previous_EmployeeSableFlowInstance_RecordID != null
      && activityData.Response.Data.Previous_EmployeeBenefitID != undefined && activityData.Response.Data.Previous_EmployeeBenefitID != null) {
      const goToInstruction = {
        SableInstance_RecordID: activityData.Response.Data.Previous_EmployeeSableFlowInstance_RecordID
      }
      componentRef.instance.resumeExistingPurchase = () => {
        this.busservice.next({ flag: true, saveData: {}, goToInstruction: goToInstruction, additionalContextData: { line_RecordID: flow.additionalContextData.line_RecordID, EmployeeBenefitID: activityData.Response.Data.Previous_EmployeeBenefitID } });

      }
    } else {
      componentRef.instance.resumeExistingPurchase = () => {
        this.busservice.next({ flag: true, saveData: {}, goToInstruction: null, additionalContextData: flow.additionalContextData });
      }

    }


    componentRef.instance.callSableProcess = () => {
      this.busservice.next({ flag: true, saveData: {}, goToInstruction: null, additionalContextData: flow.additionalContextData });
    }

    return of(true);
  }

  next(): Observable<any> {
    let returnObj = {};
    returnObj['DidSave'] = true;
    return of(returnObj);
  }

  previous(): Observable<boolean> {
    return of(true);
  }

  validationCheck(): Observable<boolean> {
    return of(true);
  }
  final(): void {
  }
}
