<header>
  <app-aon-skip-links></app-aon-skip-links>
</header>
<ng-container *ngIf="isInsideAndroidApp">
  <hr class="mobile-app-separator" aria-hidden="true">
</ng-container>
<app-toasts-container class="toast-container-fluid" aria-live="polite" aria-atomic="true"></app-toasts-container>
<div id="app-header-sleeve-print" class="app-header-sleeve {{siteTheme}}"
  [ngClass]="[siteType === 'OLB' ? (darkModeEnabled ? 'dark-mode' : 'light-mode') : 'dark-mode', clientBrandingModeActive ? 'client-branding-active' : '', enableHighContrastMode ? 'high-contrast-enabled' : '']">
  @if (showDelegateBar) {
  <div class="delegate-sleeve">
    <app-masthead-delegate-bar [delegateEmployees]="delegateEmployees"></app-masthead-delegate-bar>
  </div>
  }
  @if (showDelegateMastHead) {
  <app-masthead-delegate></app-masthead-delegate>
  }
  @else {
  <app-masthead></app-masthead>
  }
</div>
<app-idle-timeout></app-idle-timeout>
<app-external-link-popup></app-external-link-popup>
<div role="main" aria-label="mainContent" id="app-modal-anchor" class="main app-content-sleeve {{siteTheme}}"
  [ngClass]="[siteType === 'OLB' ? (darkModeEnabled ? 'dark-mode' : 'light-mode') : 'dark-mode', clientBrandingModeActive ? 'client-branding-active' : '',skinnyFooterActive || showLightFooter()? 'skinny-footer-active' : '', enableHighContrastMode ? 'high-contrast-enabled' : '', helperService.concealTheSensitiveData ? 'sensitive-data-control-active' : '' ]"
  T3ModalAccess
  (click)="elementClicked($event)">
  <aon-spin [noDarkSpin]="true"></aon-spin>
  <router-outlet id="main-app-content"></router-outlet>
</div>
<div [ngClass]="{'nic-chat-fab-container' : uiConfig.uiConfiguration?.enableWebChat}">
  <div *ngIf="showNicChat() && !showChatUnavailable" class="chat-available-div">
    <app-nic-chat></app-nic-chat>
  </div>
</div>
<div class="app-footer-sleeve {{siteTheme}}"
  [ngClass]="[siteType === 'OLB' ? (darkModeEnabled ? 'dark-mode' : 'light-mode') : 'dark-mode', clientBrandingModeActive ? 'client-branding-active' : '', enableHighContrastMode ? 'high-contrast-enabled' : '']">
  <app-footer *ngIf="!isCategorizationEnabled" [FooterStyleSwitch]="FooterStyleSwitch"></app-footer>
  <app-footer-v2 *ngIf="isCategorizationEnabled" [FooterStyleSwitch]="FooterStyleSwitch"></app-footer-v2>
</div>
<ngx-ui-loader></ngx-ui-loader>