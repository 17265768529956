import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { GeneralPageModel } from "../../shared/models/generalPage.model"
import { participantDocument } from '../../shared/models/participantDocument.model';
import { PurchaseValidationTypeModel } from '../../shared/models/purchaseValidationType.model';
import { AllAvailableBenefits, EmployeeBenefitsType } from '../../shared/models/benefitReview.model';
import { EmployeeEventDetail, ActiveEventsType, EmployeeEvent, ActiveEventsByScheme, EnrolmentStatementType, ESigningURLType } from '../../shared/models/employeeEvent.model';
import { CommitEventSaveResultType, SaveResult } from '../../shared/models/saveResult.model';
import { FollowUpItem } from '../../modules/benefit/benefit.model';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { Participant } from 'src/app/shared/models/participant.model';
import { KeyValuePair } from 'src/app/shared/models/core-typing.model';
import { map } from 'rxjs/operators';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { EnrolmentStatementMetaDataType } from 'src/app/shared/models/historic-statements.model';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BenefitInfoModalComponent } from 'src/app/modules/benefit/benefit-info-modal/benefit-info-modal.component';
import { JwtHelper } from 'src/app/core/guards/jwt.helper';

export enum AddEditLifeStyleSelect {
  AddDependent = 1,
  EditDependent = 2,
  RemoveParticipant = 3
}

@Injectable()
export class BenefitService {

  apiBaseUrl: string;
  invokeAddEditDependentComponentFunction = new EventEmitter();
  invokeMakeFurtherChangesComponentFunction = new EventEmitter();
  invokeLifeStyleChangeComponentFunction = new EventEmitter();
  subsVar: Subscription;
  subsVarparticipant: Subscription;
  fromLifeStyleChange: boolean = false;
  participantData: Participant;
  dependantActionType: number;
  termsAgreed = new BehaviorSubject<Boolean>(false);
  isFollowupsSaved = new BehaviorSubject<Boolean>(false);
  private activeEventsSubject$ = new BehaviorSubject<ActiveEventsType>(undefined);

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
  }
  onLifestyleChangeComponentClick() {
    this.invokeAddEditDependentComponentFunction.emit();
  }


  onLifestyleReviewComponentClick() {
    this.invokeMakeFurtherChangesComponentFunction.emit();
  }

  onAddeditSaveComponentClick(data = false, recordID = "") {
    data ? this.invokeLifeStyleChangeComponentFunction.emit({ deleteParticipant: true, recordID: recordID }) : this.invokeLifeStyleChangeComponentFunction.emit();
  }
  public getBenefitContent(currentCulture: string, path: object) {
    let benefitStatmentUrl = this.apiBaseUrl + currentCulture + '/page';
    return this.http.post<GeneralPageModel>(benefitStatmentUrl, path);

  }

  getBenefitOverview(languageCode: string) {
    let requestUrl = this.apiBaseUrl + '/' + languageCode + '/benefitoverview/BenefitOverview';
    return this.http.get<any>(requestUrl);
  }

  getParticipantDocuments(benefitIds: number[]) {
    let requestUrl = this.apiBaseUrl + 'ParticipantDocuments';
    return this.http.post<participantDocument[]>(requestUrl, { EmployeeBenefitIds: benefitIds });
  }

  getGlobalCartBenefits() {
    const globalCartBenefitssurl = this.apiBaseUrl + 'globalcartbenefits';
    return this.http.get<EmployeeBenefitsType>(globalCartBenefitssurl);
  }

  checkRemainingProducts(productIds: string[]) {
    const checkremainingproductsurl = this.apiBaseUrl + 'checkremainingproducts';
    return this.http.post<boolean>(checkremainingproductsurl, productIds);
  }

  validatePurchases(benefitIds: number[]) {
    let requestUrl = this.apiBaseUrl + 'benefits/validate';
    return this.http.post<PurchaseValidationTypeModel[]>(requestUrl, benefitIds);
  }

  isOpenLifeEvents(lookupRecId: Array<number>, lsOpenCount: number = 0): boolean {
    const lifeStyleLookupRecId = [4, 22, 23, 24, 25];
    lookupRecId?.forEach(x => {
      if (lifeStyleLookupRecId.includes(x)) {
        lsOpenCount++;
      }
    })

    return lsOpenCount > 0 ? true : false;
  }

  private _activeEventAPIIsLoading = false;
  getActiveEvents(requestAdditionalParam: any = {
    ignoreEmployeeEventId: ''
  }) {
    this._activeEventAPIIsLoading = true;
    let requestUrl = this.apiBaseUrl + 'activeevents';
    if (requestAdditionalParam?.ignoreEmployeeEventId) {
      requestUrl += "?ignoreEmployeeEventId=" + requestAdditionalParam?.ignoreEmployeeEventId;
    }
    return this.http.get<ActiveEventsType>(requestUrl).pipe(map(activeEvents => {
      this._activeEventAPIIsLoading = false;
      if (sessionStorage.getItem('currentUser') != null) {
        const jwtHelper = new JwtHelper();
        const user = JSON.parse(sessionStorage.getItem('currentUser'));
        let isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
        if (!((isImpersonation == 'False' && !activeEvents.hasAdminEvent) || isImpersonation == 'True')) {
          activeEvents.activeEventsByScheme = [];
        }
      }
      this.activeEventsSubject$.next(activeEvents);
      return activeEvents;
    }));
  }

  public get activeEvents() {
    let subject: Observable<ActiveEventsType> = this.activeEventsSubject$;
    if (!this.activeEventsSubject$.value && !this._activeEventAPIIsLoading) {
      subject = this.getActiveEvents();
    }
    return {
      value: this.activeEventsSubject$.value,
      subject: subject,
      reset: () => this.activeEventsSubject$.next(undefined)
    };
  }

  getActiveEventForReviewById(eventGuid: string, isWithinTheFlow: boolean, effectiveDate: string, taxCode: string = "", currentAdapterName: string = ""): Observable<EmployeeEventDetail> {
    let requestUrl = taxCode != "" ? this.apiBaseUrl + 'activeevent/' + eventGuid + '/review?taxCode=' + taxCode : this.apiBaseUrl + 'activeevent/' + eventGuid + '/review';
    if (currentAdapterName != "") {
      requestUrl += requestUrl.includes("?") ? '&activityName=' + currentAdapterName : '?activityName=' + currentAdapterName;
    }

    if (!isWithinTheFlow) {
      requestUrl = this.apiBaseUrl + 'SummaryPanelData?taxCode=' + taxCode + '&effectiveDate=' + effectiveDate
    }
    return this.http.get<EmployeeEventDetail>(requestUrl);
  }

  commitEvent(eventGuid: string) {
    let requestUrl = this.apiBaseUrl + 'activeevent/' + eventGuid + '/commit';
    return this.http.get<CommitEventSaveResultType>(requestUrl);
  }

  getFollowUpItems(eventGuid: string) {
    let requestUrl = this.apiBaseUrl + 'followupitems/' + eventGuid;
    return this.http.get<FollowUpItem[]>(requestUrl, { params: { 'fetchAll': false } });
  }

  getTermsAndCondition(benefitIds: number[]) {
    let requestUrl = this.apiBaseUrl + 'purchasefollowupitems';
    return this.http.get<FollowUpItem[]>(requestUrl, { params: { 'employeeBenefitIds': benefitIds, 'fetchAll': true } });
  }

  validateEvent(eventGuid: string) {
    let requestUrl = this.apiBaseUrl + 'activeevent/' + eventGuid + '/validate';
    return this.http.get<PurchaseValidationTypeModel[]>(requestUrl);
  }

  discardMidYearEvent(eventGuid: string) {
    let requestUrl = this.apiBaseUrl + 'discardEvent/' + eventGuid;
    return this.http.get<SaveResult>(requestUrl);
  }

  getAllAvailableEmployeeBenefits() {
    let requestUrl = this.apiBaseUrl + 'allAvailableBenefits';
    return this.http.get<AllAvailableBenefits>(requestUrl);
  }

  getSsoBenefits() {
    let requestUrl = this.apiBaseUrl + 'ssobenefits';
    return this.http.get<KeyValuePair<string, string>[]>(requestUrl);
  }

  isActiveEnrolmentEventEmployeeEventType(): boolean {
    return this.activeEvents.value?.activeEventsByScheme?.some(x => x?.activeEnrolmentEventsType?.employeeEventTypes.length !== 0);
  }

  loadActiveEventsForSignPostWidget(skipOpenLifeEventsCheck: boolean = false, requestAdditionalParam: any = {}): Observable<{
    noOfEvents: number,
    lifeStyleEvents: ActiveEventsByScheme[]
  }> {
    let lifeEvents: ActiveEventsByScheme[] = [];
    let noOfEvents: number = 0;
    return this.getActiveEvents(requestAdditionalParam).pipe(map(data => {
      if (data) {
        this.activeEvents.value?.activeEventsByScheme?.forEach(((scheme) => {
          let _events: Array<EmployeeEvent> = [];
          scheme?.activeEnrolmentEventsType?.employeeEventTypes?.forEach((events => {
            if ((this.isOpenLifeEvents([events?.lookupEventType_RecordID]) || skipOpenLifeEventsCheck) &&
              ((!events.isStatusSubmitted && !events.eventSubmitDate && !events.isDirty) || (events.isDirty && !events.canDiscard) || (!events.isDirty && !events.isStatusSubmitted && events.eventSubmitDate && !events.canDiscard))) {
              _events.push(events);
            }
          }));

          // If It has an open event
          if (_events?.length) {
            noOfEvents += _events.length;
            lifeEvents.push({
              schemeName: scheme.schemeName,
              scheme_RecordID: scheme.scheme_RecordID,
              schemeDescription: scheme.schemeDescription,
              activeEnrolmentEventsType: {
                employeeEventTypes: _events
              }
            });
          }
        }));
      }
      return {
        noOfEvents: noOfEvents,
        lifeStyleEvents: lifeEvents
      };
    }))
  }

  /**
   * Cache the input data to the session
   **/
  cacheFrequencyViewState(isPerPay: boolean, cacheRetentionPaths: Array<string> = []): void {
    sessionStorage.setItem(SessionStorageKey.CachedBenefitReviewData, JSON.stringify({
      isPerPay: isPerPay,
      retentionPath: cacheRetentionPaths
    }));
  }

  /**
   * Get Historic Enrolment Statement Meta Data
   */
  getEmployeeEnrolmentStatementMetaData(): Observable<EnrolmentStatementMetaDataType> {
    let requestUrl = this.apiBaseUrl + 'employee/EnrolmentStatementMetaData';
    return this.http.get<EnrolmentStatementMetaDataType>(requestUrl);
  }

  /**
   * Get Enrolment Statement Data based on recordID
   */
  getEmployeeEnrolmentStatement(Enrolment_RecordID: string): Observable<EnrolmentStatementType> {
    let requestUrl = this.apiBaseUrl + 'employee/EnrolmentStatement?Enrolment_RecordID=' + Enrolment_RecordID;
    return this.http.get<EnrolmentStatementType>(requestUrl);
  }

  public openBenefitInfoPopup(lineId: string, lineName: string): void {
    let ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      ariaLabelledBy: 'app-modal-anchor',
      fullscreen: true
    };
    ngbModalOptions.container = '#app-modal-anchor';
    let modalRef = this.modalService.open(BenefitInfoModalComponent, ngbModalOptions);
    modalRef.componentInstance.removeRoleDialog = true;
    modalRef.componentInstance.benefitId = lineId;
    modalRef.componentInstance.benefitName = lineName;
  }

  getESigningURL(employeeESigningRecordID: string, statementRecordID: string) {
    let requestUrl = this.apiBaseUrl + 'getESigningURL';
    return this.http.post<ESigningURLType>(requestUrl, { EmployeeESigningRecordID: employeeESigningRecordID, StatementRecordID: statementRecordID });

  }

  syncESigningStatus(employeeESigningRecordID: string) {
    let requestUrl = this.apiBaseUrl + 'syncESigningStatus/' + employeeESigningRecordID;
    return this.http.get<SaveResult>(requestUrl);

  }

  transformNegativeCost(transformNegativeCost: boolean, cost: number): number {
    return transformNegativeCost ? Math.abs(cost) : cost;
  }

  getTaxInclusiveCosts(employeeBenefitsByScheme: EmployeeBenefitsType[]) {
    // To Avoid Data cloning
    let employeeBenefits: EmployeeBenefitsType[] = employeeBenefitsByScheme?.length ? JSON.parse(JSON.stringify(employeeBenefitsByScheme)) : [];
    employeeBenefits?.map(employeeBenefitByScheme => {

      employeeBenefitByScheme?.employeeBenefitGroups?.map(employeeBenefitGroup => {
        employeeBenefitGroup?.employeeBenefitsByProducts?.map(employeeBenefitByProduct => {
          employeeBenefitByProduct?.employeeBenefitTypes?.map(employeeBenefitType => {

            employeeBenefitType?.taxes?.forEach(tax => {
              employeeBenefitType.employeeCost_PayPeriod += !isNaN(tax?.employeePayPeriodTaxAmount) ? tax?.employeePayPeriodTaxAmount : 0;
              employeeBenefitType.employeeCost_Annual += !isNaN(tax?.employeeAnnualTaxAmount) ? tax?.employeeAnnualTaxAmount : 0;
              employeeBenefitType.employerCost_PayPeriod += !isNaN(tax?.employerPayPeriodTaxAmount) ? tax?.employerPayPeriodTaxAmount : 0;
              employeeBenefitType.employerCost_Annual += !isNaN(tax?.employerAnnualTaxAmount) ? tax?.employerAnnualTaxAmount : 0;
            });

            employeeBenefitType?.adjustmentType?.taxAdjustments?.forEach(taxAdjustment => {
              employeeBenefitType.adjustmentType.employeeCost += !isNaN(taxAdjustment?.employeeTaxAmount) ? taxAdjustment?.employeeTaxAmount : 0;
              employeeBenefitType.adjustmentType.employerCost += !isNaN(taxAdjustment?.employerTaxAmount) ? taxAdjustment?.employerTaxAmount : 0;
            });
          });
        });
      })
    });

    return employeeBenefits;
  }
}
