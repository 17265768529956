import { Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AddOnInformationType } from '../models/benefitReview.model';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { GlobalObjectsService } from '../../shared/services/global-objects.service'
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from '../models/uiconfigration.interface';

enum benefitPlanNameHeadingTagLevels { h3 = 3, h4 = 4, h5 = 5 }

@Component({
  selector: 'app-coverage-display-info',
  templateUrl: './coverage-display-info.component.html',
  styles: [
  ]
})
export class CoverageDisplayInfoComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  @Input() product: any;
  benefitPlanNameHeadingTagLevels = benefitPlanNameHeadingTagLevels;
  @Input() benefitPlanNameHeading: {
    tagLevel: benefitPlanNameHeadingTagLevels,
    class?: string
  };
  @Input() ShowDataByAmountType: string;
  @Input() fromBenefitStatement: boolean = false;
  @Output() benefitInfoPopup = new EventEmitter();
  @Input() isProfileDisplay = false;
  private uiConfig: UIConfigurationType;
  addOnWithIsCoverage: AddOnInformationType[] = [];
  addOnNotWithIsCoverage: AddOnInformationType[] = [];

  constructor(
    private injector: Injector,
    private readonly globalService: GlobalObjectsService,
    private readonly uiConfigurationService: UiconfigrationService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.BenefitDetails.PlanDetails',
      'Aon.AddOns.label'
    ]);
  }

  ngOnInit(): void {
    this.uiConfig = this.uiConfigurationService.getUIConfiguration();
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
    if (this.product.addOnGroups) {
      this.product.addOnGroups.forEach(addOnGroup => {
        if (addOnGroup) {
          addOnGroup.addOns.forEach(addOn => {
            if (addOn && addOnGroup.isCoverageOption) {
              this.addOnWithIsCoverage.push(addOn);
            } else {
              this.addOnNotWithIsCoverage.push(addOn);
            }
          });
        }
      })
    }
    this.globalService.Products.subscribe(data => {
      this.checkForPlanDetails(data);
    });
  }
  checkForPlanDetails(data) {
    this.product.showPlanDetails = !this.isProfileDisplay || (this.isProfileDisplay && !this.uiConfig?.uiConfiguration?.hideBenefitLearnMoreLinkFromProfile);
    data.forEach(productDetail => {
      if (productDetail.recordID == this.product.line_RecordID && productDetail.hideProductDetails) {
        this.product.showPlanDetails = false;
      }
    });
  }
  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  openBenefitInfoPopup(line_RecordID, name, event) {
    event.preventDefault();
    setTimeout(() => {
      this.benefitInfoPopup.emit({ line_RecordID: line_RecordID, name: name, event: event })
    }, 200);
  }

}
