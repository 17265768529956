import { HttpClient } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { BootstrapRequestContext } from 'tbs-typings';
import { WebVitals } from '../models/webVitals.model';

declare global {
  interface Window { dataLayer: any[]; heap:any; }
}

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  apiBaseUrl: string; 

  webVitals: WebVitals;

  constructor(
    private http: HttpClient,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) {
    this.apiBaseUrl = this.bootstrapRequestContext.apiBaseUrl;
    this.webVitals = { URL:null, CLS: 0, INP: 0, LCP: 0, TTFB: 0};
  }

  public trackSableStep(sideData: any): boolean {
    try {
      const sableForm_RecordID = sideData.liveActivity.activityData.SableForm_RecordID;
      const line_RecordID = sideData.additionalContextData.line_RecordID;
      const name = sideData.additionalContextData.sableFlow_Name;
      const sableActivity_RecordID = sideData.liveActivity.sableActivity_RecordID;
      const sableInstance_RecordID = sideData.liveActivity.sableInstance_RecordID;
      const title = sideData.liveActivity.title;
      const step = sideData.liveActivity.step;
      const isLastStep = sideData.liveActivity.isLastStep;
      const error = sideData.error;
      if (window.dataLayer) {
        window.dataLayer.push({
          "event": 'event-sable-process',
          "line_RecordID": line_RecordID,
          "sableInstance_RecordID": sableInstance_RecordID,
          "sable-flow-name": name,
          "sableForm_RecordID": sableForm_RecordID,
          "sableActivity_RecordID": sableActivity_RecordID,
          "title": title,
          "step": step,
          "isLastStep": isLastStep,
          "error": error
        });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public trackCustomEvent(eventObject: any) {
    if (this.bootstrapRequestContext.enableGoogleAnalytics) {
      this.getDataLayer().push(eventObject);
    }
    if (window.heap) {
      if (eventObject.event) {
        this.trackHeapEvent(eventObject.event, eventObject);
      }
      if (eventObject['product-english-name']) {
        this.addEventProperties({ 'product-english-name': eventObject['product-english-name'] });
      }
    }
  }

  public recordPageView(eventObject: any) {
    this.getDataLayer().push(eventObject);
  }

  public trackPageView(pageDetails: any, sensitiveQueryString: boolean = false) {
    if (!this.bootstrapRequestContext.logPageView || !this.getDataLayerValue("navigationStart")) {
      return;
    } else {
      this.postPageView(pageDetails, sensitiveQueryString).subscribe(
        success => { },
        error => { console.log("pageview log error :" + error + pageDetails.routerurl); }
      );
    }
  }

  public trackEvent(eventDetails: any) {
    if (this.bootstrapRequestContext.logPageView) {
      this.postEvent(eventDetails).subscribe(
        success => { },
        error => { console.log("event log error :" + error + eventDetails.routerurl); }
      );
    }
  }

  private postEvent(eventDetails: any) {
    const requestUrl = this.apiBaseUrl + 'pageview';
    let pageViewModel = {
      host: window.location.origin,
      pageTitle: eventDetails.routertitle,
      pagePath: eventDetails.routerurl,
      queryString: window.location.search,
      tid: this.getDataLayerValue("TID"),
      pageLoadingTime: eventDetails['content-loading-time'],
      regionalAffliationCode: this.getDataLayerValue('RegionalAffliationCode'),
      uiCulture: null,
      isGhost: null
    };
    
    if (pageViewModel.tid) {
      pageViewModel.isGhost = this.getDataLayerValue('IsGhost') == "true";
    }
    pageViewModel.uiCulture = JSON.parse(localStorage.getItem('SelectedLanguage')).cultureCode;

    return this.http.post<any>(requestUrl, pageViewModel);
  }

  private postPageView(pageDetails: any, sensitiveQueryString: boolean = false) {
    const requestUrl = this.apiBaseUrl + 'pageview';
    let pageLoadingTime = this.navigationElapsedTime();

    if (window.location.search && pageDetails.routerurl && pageDetails.routerurl.includes(window.location.search)) {
      pageDetails.routerurl = pageDetails.routerurl.slice(0, -(window.location.search.length))
    }

    let pageViewModel = {
      pageTitle: this.getDataLayerValue("routertitle"),
      pagePath: pageDetails.routerurl,
      queryString: sensitiveQueryString? null: window.location.search,
      tid: this.getDataLayerValue("TID"),
      pageLoadingTime: pageLoadingTime,
      regionalAffliationCode: this.getDataLayerValue('RegionalAffliationCode'),
      uiCulture: null,
      isGhost: null,
      isSoftNavigation: true
    };

    if (pageDetails.event == 'app-loaded') {
      pageViewModel.pageLoadingTime = pageDetails['content-loading-time'];
    } else {

      let navigationAppLoadingTime = this.getDataLayerValue("pre-navigation-time");
      if (navigationAppLoadingTime != null && navigationAppLoadingTime != "0") {
        pageViewModel.pageLoadingTime = pageViewModel.pageLoadingTime + Number(navigationAppLoadingTime);
        this.setDataLayerValue("pre-navigation-time", "0");
        pageViewModel.isSoftNavigation = false; //pre-nav time means it's a full page load/reload with entire app.
      }
      
      let navigationEventTime = this.getDataLayerValue("pre-navigation-event-time");
      if (navigationEventTime != null && navigationEventTime != "0") {
        pageViewModel.pageLoadingTime = pageViewModel.pageLoadingTime + Number(navigationEventTime);
        this.setDataLayerValue("pre-navigation-event-time", "0");
      }
    }
    if (pageViewModel.tid) {
      pageViewModel.isGhost = this.getDataLayerValue('IsGhost') == "true";
    }
    pageViewModel.uiCulture = JSON.parse(localStorage.getItem('SelectedLanguage')).cultureCode;
    return this.http.post<any>(requestUrl, pageViewModel);
  }

  public trackWebVital(metric) {
    switch (metric.name) {
      case "TTFB": {
        this.webVitals.TTFB = metric.value;
        if (this.webVitals.URL != null && this.getDataLayerValue("routerurl")) {
          //reset and log last page web vital when next page got hit. 
          this.logWebVital().subscribe(
            success => { },
            error => { console.log("webvital log error :" + metric.name + error); }
          );
          this.webVitals = { URL: null, CLS: 0, INP: 0, LCP: 0, TTFB: 0 };
        }
        this.webVitals.URL = this.getDataLayerValue("routerurl");
        break;
      }
      case "CLS": {
        this.webVitals.CLS = metric.value;
        break;
      }
      case "INP": {
        this.webVitals.INP = metric.value;
        break;
      }
      case "LCP": {
        this.webVitals.LCP = metric.value;
        break;
      }
      default: {
        //statements; 
        break;
      }
    }
  }

  public logWebVital(){
    const requestUrl = this.apiBaseUrl + 'webvitals';

    let webVitalModel = {
      host: window.location.origin,
      pagePath: this.webVitals.URL,
      queryString: window.location.search,
      ttfb: this.webVitals.TTFB,
      cls: this.webVitals.CLS,
      lcp: this.webVitals.LCP,
      inp: this.webVitals.INP,
    };
    return this.http.post<any>(requestUrl, webVitalModel);
  }

  public getDataLayer() {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    return window.dataLayer;
  }

  public enableGoogleAnalytics(): boolean {
    return this.bootstrapRequestContext.enableGoogleAnalytics;
  }

  public getDataLayerValue(dataLayerKey: string): string {
    let resultValue = null;
    if (window.dataLayer) {
      window.dataLayer.forEach(singleDataLayer => {
        if (dataLayerKey in singleDataLayer) {
          resultValue = singleDataLayer[dataLayerKey];
        }
      });
    }
    return resultValue;
  }

  public setDataLayerValue(dataLayerKey: string, dataLayerValue: string) {
    this.trackCustomEvent({
      "event": "set-datalayer-value",
      [dataLayerKey]: dataLayerValue
    });
  }

  public verifyDataLayerValue(dataLayerKey: string, dataLayerValue: string): boolean {
    let foundMatch = false;
    if (window.dataLayer) {
      window.dataLayer.forEach(singleDataLayer => {
        if (dataLayerKey in singleDataLayer && singleDataLayer[dataLayerKey] == dataLayerValue) {
          foundMatch = true;
        }
      });
    }
    return foundMatch;
  }

  public navigationElapsedTime(): number {
    let navigationStartTime = this.getDataLayerValue("navigationStart");
    let navigationElapsedTime = 0;
    let currentNavTime: number = Date.now();
    if (currentNavTime == 0) { return 0; }
    if (navigationStartTime != "" && navigationStartTime != "0") {
      navigationElapsedTime = currentNavTime - Number(navigationStartTime);
    } else {
      navigationElapsedTime = currentNavTime - Number(this.getDataLayerValue("app-load-timestamp"));
    }
    return navigationElapsedTime;
  }

  public pageViewLogged(): boolean {
    return this.getDataLayerValue("pageViewLogged").toString() == "true";
  }

  public updateDataLayer(dataLayerKey: string, dataLayerValue: string): string {
    if (this.enableGoogleAnalytics && window.dataLayer) {
      let existingVal = this.getDataLayerValue(dataLayerKey);
      if (dataLayerValue) {
        if (existingVal != dataLayerValue) {
          this.setDataLayerValue(dataLayerKey, dataLayerValue);
        }
        return dataLayerValue;
      } else {
        return existingVal;
      }
    } else {
      return null;
    }
  }

  public getDataLayerValueByEventKey(eventKey: string, dataLayerKey: string): string {
    let resultValue = null;
    if (window.dataLayer) {

      for (let index = window.dataLayer.length - 1; index >= 0; index--) {
        const singleDataLayer = window.dataLayer[index];
        if (dataLayerKey in singleDataLayer) {
          if ("eventKey" in singleDataLayer && singleDataLayer["eventKey"] == eventKey) {
            resultValue = singleDataLayer[dataLayerKey];
            return resultValue;
          }
        }
      }
    }
    return resultValue;
  }

  public identifyUser(userIdentity: string) {
    if (window.heap) {
      window.heap.identify(userIdentity);
    }
  }

  public resetIdentity() {
    //this method resets a user's identity to a random anonymous user ID on Heap.
    if (window.heap) {
      window.heap.resetIdentity();
    }
  }

  public addUserProperties(userProperties: any) {
    if (window.heap) {
      window.heap.addUserProperties(userProperties);
    }
  }

  public addEventProperties(userProperties: any) {
    if (window.heap) {
      window.heap.addEventProperties(userProperties);
    }
  }

  public trackHeapEvent(eventName: string, eventDetails: any) {
    if (window.heap) {
      var merged_event_details = { ...eventDetails, ...{ sessionID: this.getSessionID() } };
      window.heap.track(eventName, merged_event_details);
    }
  }

  public getSessionID() {
    if (window.heap) {
      return window.heap.getSessionId();
    }
    return null;
  }

}
