
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicFieldBase } from '../../shared/models/dynamicFieldBase.model';

@Injectable()
export class DynamicControlService {
  constructor() { }

  toFormGroup(fields: DynamicFieldBase<string>[]) {
    const group: any = {};

    fields.forEach(field => {
      group[field.key] = field.required ? new FormControl(field.value || '', Validators.required) : new FormControl(field.value || '');
    });
    return new FormGroup(group);
  }
}
