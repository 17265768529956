import { Component, Input, OnInit } from '@angular/core';
import { SableDataViewFlowAdapterService } from '@aon/sable-dataview-flow-adapter';
import { SableFlowAdapterFactory } from '@aon/sable-flow';
import { SableFormsFlowAdapterService } from '@aon/sable-forms-flow-adapter';
import { GlobalObjectsService } from '../../services/global-objects.service';
import { KenticoFlowAdapterService } from '../../../shared/sable/adapter/kentico-auth-flow-adapter.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KenticoContentAdapterService } from '../adapter/kentico-content-adapter.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { KenticoErrorActivityAdapterService } from '../adapter/kentico-error-adapter.service';
@Component({
  selector: 'app-shared-sable-flow',
  templateUrl: './shared-sable-flow.component.html'
})
export class SharedSableFlowComponent implements OnInit {
  @Input() flowID: string;
  @Input() additionalContextData: any;

  loading: true;
  constructor(
    private adapterFactory: SableFlowAdapterFactory,
    private adapterService: SableFormsFlowAdapterService,
    private viewAdapterService: SableDataViewFlowAdapterService,
    private authenticationService: KenticoFlowAdapterService,
    private globalojectService: GlobalObjectsService,
    private contentService: KenticoContentAdapterService,
    public activeModal: NgbActiveModal,
    private ngxService: NgxUiLoaderService,
    private errorService: KenticoErrorActivityAdapterService,


  ) {
    adapterFactory.register('SableForm', adapterService);
    adapterFactory.register('SableView', viewAdapterService);
    adapterFactory.register('Content', contentService);
    adapterFactory.register('Authentication', authenticationService);
    adapterFactory.register('ErrorActivity', errorService);

  }

  ngOnInit(): void {
    this.globalojectService.sableInstance_RecordID = null;
  }

  handleSpinner($event) {
    setTimeout(() => {
      this.loading = $event;
      if (this.loading) {
        this.ngxService.start();
      }
      if (!this.loading) {
        this.ngxService.stop();

      }
    });
  }

  handleLiveActivity($event) {
    if ($event.additionalContextData) {
      this.additionalContextData = $event.additionalContextData;
      this.globalojectService.additionalContextData = this.additionalContextData;

    }
  }

}
