import { Injectable } from '@angular/core';
import { of, Observable, map } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { HelperService } from '../services/helper.service';
import { ResourceString } from '../models';

//This service is designed to handle the resource strings which are currently implemented in helper.service
@Injectable({
  providedIn: 'root'
})
export class ResourceStringsService {
  readonly commonStringKeys: string[] = [
    'Aon.Alert.Title',
    'Aon.Alert.Description',
    'Aon.BenefitDetails.CoveredTitle',
    'Aon.BenefitMatrix.BenefitsTable.Title.Review',
    'Aon.BenefitMatrix.IntroText.OpenEnrolment.ToEnrol',
    'Aon.BenefitMatrix.IntroText.OpenEnrolment.YouHave',
    'Aon.BenefitMatrix.IntroText.OpenEnrolment.OneDayToEnrol',
    'Aon.BenefitMatrix.IntroText.OpenEnrolment.LastDayToEnrol',
    'Aon.BenefitMatrix.MustSubmitInfo',
    'Aon.Common.About',
    'Aon.Common.AddEditDependents',
    'Aon.Common.All',
    'Aon.Common.Back',
    'Aon.Common.BannerTitle.Caution',
    'Aon.Common.BannerTitle.Error',
    'Aon.Common.BannerTitle.Error.Plural',
    'Aon.Common.BannerTitle.Info',
    'Aon.Common.BannerTitle.MultiInstance.Info',
    'Aon.Common.BannerTitle.MultiInstance.message',
    'Aon.Profile.MultiPet',
    'Aon.Profile.SinglePet',
    'Aon.Common.BannerTitle.Info.Plural',
    'Aon.BannerAlert.Close',
    'Aon.Common.BannerTitle.Success',
    'Aon.Common.BaseSalary',
    'Aon.Common.Brochure',
    'Aon.Common.Cancel',
    'Aon.Common.ChangePwdSuccessfully',
    'Aon.Common.Citizenship',
    'Aon.Common.Confirm',
    'Aon.Common.ConfirmDelete',
    'Aon.Common.ConfirmDeleteQuote',
    'Aon.Common.Continue',
    'Aon.Common.CultureShortName',
    'Aon.Common.DateOfHire',
    'Aon.Common.Day',
    'Aon.TRS.Total',
    'Aon.Common.Delete',
    'Aon.Common.DOB',
    'Aon.Common.Download',
    'Aon.Common.Edit',
    'Aon.Common.Selected',
    'Aon.Common.EmailLabel',
    'Aon.Common.EmploymentStatus',
    'Aon.Common.Error',
    'Aon.Common.ErrorBannerTitle',
    'Aon.Common.ErrorIntro',
    'Aon.Common.ErrorTitle',
    'Aon.Common.FaqsTitle',
    'Aon.Common.FlexiPointBalance',
    'Aon.Common.FlexiPointsDescription',
    'Aon.Common.FlexiPointsNotAvailable',
    'Aon.Common.Gender',
    'Aon.Common.GetAQuote',
    'Aon.Common.GetAQuote.NextGenOLB',
    'Aon.Common.HelpsTitle',
    'Aon.Common.KeepWorking',
    'Aon.Common.LearnMore',
    'Aon.Common.Loading',
    'Aon.Common.MaritalStatus',
    'Aon.Common.Month',
    'Aon.Common.Name',
    'Aon.Common.NationalID',
    'Aon.Common.NoResultToDisplay',
    'Aon.Common.NotOnFile',
    'Aon.Common.OK',
    'Aon.Common.Per',
    'Aon.Common.PersonalEmail',
    'Aon.Common.PhoneNumber',
    'Aon.Common.PlaceOfBirth',
    'Aon.Common.Print',
    'Aon.Common.PrivacyPolicy',
    'Aon.Common.ResendEmail',
    'Aon.Common.ResetPurchase',
    'Aon.Common.ResetPurchaseSuccess',
    'Aon.Common.ResetPurchaseWarningMsg',
    'Aon.Common.Save',
    'Aon.Common.Skip',
    'Aon.Common.Self',
    'Aon.Common.ShopNow',
    'Aon.Common.Submit',
    'Aon.Common.SubTotal',
    'Aon.Common.Success',
    'Aon.Common.SuccessMessage',
    'Aon.Common.TermsOfUse',
    'Aon.Common.TimeoutInfoPrefix',
    'Aon.Common.TimeoutInfoSuffix',
    'Aon.Common.TimeoutTitle',
    'Aon.Common.Today',
    'Aon.Common.WorkEmail',
    'Aon.Common.Year',
    'Aon.Common.Proceed',
    'Aon.Common.TryAgain',
    'Aon.Common.Yes',
    'Aon.Common.No',
    'Aon.PayFrequency.Monthly',
    'Aon.PayFrequency.Quarterly',
    'Aon.PayFrequency.SemiAnnual',
    'Aon.PayFrequency.Annual',
    'Aon.PayFrequency.OneTime',
    'Aon.PayFrequency.Daily',
    'Aon.PayFrequency.Weekly',
    'Aon.PayFrequency.BiWeekly',
    'Aon.PayFrequency.SemiMonthly',
    'Aon.PayFrequency.FourWeekly',
    'Aon.PayFrequency.PerPay',
    'Aon.Frequency.Day',
    'Aon.Frequency.Week',
    'Aon.Frequency.Month',
    'Aon.Frequency.Year',
    'Aon.Common.PleaseChoose',
    'Aon.Common.Loading',
    'Aon.Common.AddDependents',
    'Aon.Common.Done',
    'Aon.Common.AddEditDependentsDesc',
    'Aon.Common.About',
    'Aon.Error.DateNotBeFuture',
    'Aon.PayrollDeduction.Title',
    'Aon.PayrollDeduction.Description',
    'Aon.PayrollDeduction.ToolTipText',
    'Aon.DirectPay.Title',
    'Aon.DirectPay.Description',
    'Aon.DirectPay.ToolTipText',
    'Aon.DirectPay.Label',
    'Aon.Dependents.Header',
    'Aon.DeletePopup.Title',
    'Aon.DeletePopup.Description',
    'Aon.DeletePopup.DropDependantPolicies.Description',
    'System.Common.Cancel',
    'Aon.Common.Total',
    'Aon.Common.LastUpdated',
    'Aon.Common.Your',
    'Aon.Common.Select',
    'Aon.Common.DeSelect',
    'Aon.Common.Warning',
    'Aon.Login.PasswordVisibilityControl',
    'Aon.Common.NoQuotesReadyForCheckout',
    'Aon.Common.PageLoading',
    'Aon.Common.PageLoaded',
    'Aon.Common.PayrollID',
    'Aon.Arialabel.Info',
    'Aon.Common.MFALabel',
    'Aon.MFA.Email',
    'Aon.MFA.Phone',
    'Aon.MFA.ChooseOption',
    'Aon.Common.Selected',
    'Aon.MFA.UpdateSuccess',
    'Aon.MFA.CountryPhoneCode',
    'Aon.Common.YesDeselect',
    'Aon.Common.NoDeselect',
    'Aon.Benefit.AmountType.Day',
    'Aon.Benefit.AmountType.Days',
    'Aon.Benefit.AmountType.Hour',
    'Aon.Benefit.AmountType.Hours',
    'Aon.Error.ContactUs',
    'Aon.Common.VideoContentLoading',
    'Aon.Common.VideoContentLoaded',
    'Aon.Common.IsMedicareEligible',
    'Aon.Common.IsDisabled',
    'Aon.EventBroadcast.ModalHeader',
    'Aon.AdditionalEvents.Signpost.Widget.Heading.ToDo',
    'Aon.Common.SkipToMainContent',
    'Aon.Common.SystemID',
    'Aon.Common.OtherID',
    'Aon.Common.EmployeeNumberID',
    'Aon.Common.JobLevel',
    'Aon.Common.JobStatus',
    'Aon.Common.JobType',
    'Aon.Common.PayrollGroup',
    'Aon.Common.PayType',
    'Aon.Common.BonusAmount',
    'Aon.Common.BonusFrequency',
    'Aon.Common.PensionSalaryAmount',
    'Aon.Common.ReferenceSalaryAmount',
    'Aon.Common.MisCompensation1',
    'Aon.Common.MisCompensation2',
    'Aon.Common.OperatingUnit',
    'Aon.Common.Division',
    'Aon.Common.Company',
    'Aon.Common.HolidayEntitlement',
    'Aon.Common.Days',
    'Aon.Common.Hours',
    'Aon.Common.WeeklyHours',
    'Aon.Common.DeductionCapacity',
    'Aon.Common.GrossMonthlySalary',
    'Aon.Common.SalaryBandLevel',
    'Aon.Modal.CloseButton.AriaLabel',
    'Aon.Common.Optional',
    'Aon.Common.Required',
    'Aon.Common.PasswordExpirationWarningMessage',
    'Aon.Common.Preferred',
    'Aon.Help.JumpToSection',
    'Aon.Pattern.Fail.Message',
    'Aon.Common.Gender.Melded',
    'Aon.Common.NotAvailable',
    'Aon.Common.GetStarted',
    'Aon.Common.ContinueShopping',
    'Aon.Savedquote.Info.Title',
    'Aon.Savedquote.Info.Description',
    'Aon.Savedquote.Info.Yes',
    'Aon.Savedquote.Info.No',
    'Aon.Common.Option',
    'Aon.Common.Option.Plural'
  ];

  readonly aonLocaleStringKeys: string[] = ['AON_LOCALE.calendar.placeholders.day',
    'AON_LOCALE.calendar.placeholders.month',
    'AON_LOCALE.calendar.placeholders.year',
    'AON_LOCALE.calendar.patterns.d',
    'AON_LOCALE.calendar.messages.today',
    'AON_LOCALE.calendar.messages.toggle',
    'AON_LOCALE.calendar.messages.prevButtonTitle',
    'AON_LOCALE.calendar.messages.nextButtonTitle'];

  readonly footerStringKeys: string[] = ['Aon.Footer.FAQS',
    'Aon.Footer.NeedHelp',
    'Aon.Footer.Help',
    'Aon.Footer.Copyright',
    'Aon.Footer.TermsandConditions',
    'Aon.Footer.Privacypolicy',
    'Aon.Footer.Products',
    'Aon.Footer.Tagline',
    'Aon.Footer.Description',
    'Aon.Footer.ClientLogoAltText',
    'Aon.GlobalHelp.PageHeading',
    'Aon.Login.ContactUs',
    'Aon.Nice.ChatUnavailable',
    'Aon.Privacypolicy.Description',
    'Aon.TermsandConditions.Description',
    'Aon.Help.Description',
    'Aon.OneTrust.ChooseOptionalCookiesUs',
    'Aon.OneTrust.ChooseOptionalCookiesEu',
    'Aon.Footer.OnlineSecurity',
    'Aon.OnlineSecurity.Description'
  ];

  readonly mastheadStringKeys: string[] = ['Aon.Navigation.Products',
    'Aon.Navigation.Resources',
    'Aon.Navigation.Help',
    'Aon.Profile.Benefits',
    'Aon.Navigation.Login',
    'Aon.Navigation.GetQuote',
    'Aon.Navigation.SelectProductStartQuote',
    'Aon.Navigation.LogoLabel',
    'Aon.Navigation.ProductsIntro',
    'Aon.Navigation.Benefits',
    'Aon.Navigation.AboutMe',
    'Aon.Navigation.SavedQuotes',
    'Aon.Navigation.Account',
    'Aon.Navigation.Logout',
    'Aon.Navigation.Faqs',
    'Aon.Profile.DependentsAndBeneficiaries',
    'Aon.Profile.Documents',
    'Aon.Profile.Payment',
    'Aon.Navigation.OurValues',
    'Aon.Cart.DueToday',
    'Aon.ReadyToPurchase.EESalesTax',
    'Aon.ReadyToPurchase.NtlTaxBen',
    'Aon.ReadyToPurchase.ERSalesTax',
    'Aon.ReadyToPurchase.ProvTaxBen',
    'Aon.ReadyToPurchase.LifeImputedInc',
    'Aon.ReadyToPurchase.DPImputedInc',
    'Aon.ReadyToPurchase.StateImputedInc',
    'Aon.ReadyToPurchase.SalesTax',
    'Aon.Common.Profile.BenefitMenu',
    'Aon.ReadyToPurchase.POSDisc',
    'Aon.ReadyToPurchase.ContinueToPurchase',
    'Aon.PayFrequency.Annual',
    'Aon.Common.OK',
    'Aon.Common.Cancel',
    'Aon.Common.ConfirmDeleteQuote',
    'Aon.Profile.DeleteQuoteTitle',
    'Aon.ReadyToPurchase.ReviewInfo',
    'Aon.Cart.EmptyHeading',
    'Aon.Cart.EmptyNotice',
    'Aon.BenefitMatrix.ViewBenefitInformation',
    'Aon.MastHeadBenefit.Description',
    'Aon.MastHeadBenefit.ViewAll',
    'Aon.Category.CategoryTitle',
    'Aon.Category.CategoryIntro',
    'Aon.Profile.LifeEvents',
    'Aon.Logo.Label',
    'Aon.Help.Description',
    'Aon.Arialabel.Home',
    'Aon.Arialabel.Inbox',
    'Aon.Arialabel.Help',
    'Aon.Arialabel.FAQ',
    'Aon.Arialabel.Toggle',
    'Aon.Arialabel.Login',
    'Aon.Common.NoEligibleProductsTitle',
    'Aon.Common.NoEligibleProductsInfo',
    'Aon.Navigation.Profile',
    'Aon.Navigation.Profile.SwitchProfile',
    'Aon.Delegate.Acting.As'
  ];

  readonly mastheadCartStringKeys: string[] = ['Aon.ReadyToPurchase.EESalesTax',
    'Aon.ReadyToPurchase.NtlTaxBen',
    'Aon.ReadyToPurchase.ERSalesTax',
    'Aon.ReadyToPurchase.ProvTaxBen',
    'Aon.ReadyToPurchase.LifeImputedInc',
    'Aon.ReadyToPurchase.DPImputedInc',
    'Aon.ReadyToPurchase.StateImputedInc',
    'Aon.ReadyToPurchase.SalesTax',
    'Aon.ReadyToPurchase.POSDisc',
    'Aon.Cart.DueToday',
    'Aon.PayFrequency.Annual',
    'Aon.ReadyToPurchase.ContinueToPurchase',
    'Aon.ReadyToPurchase.ReviewInfo',
    'Aon.Cart.EmptyHeading',
    'Aon.Cart.EmptyNotice',
    'Aon.Cart.For',
    'Aon.Cart.youCovered',
    'Aon.Cart.youAndSpouse',
    'Aon.Cart.youAndPartner',
    'Aon.Cart.youFamilyAndParent',
    'Aon.Cart.youSpouseAndParent',
    'Aon.Cart.youPartnerAndChild',
    'Aon.Cart.youPartnerAndChildren',
    'Aon.Cart.youPartnerAndParent',
    'Aon.Cart.youParentAndChild',
    'Aon.Cart.youParentAndChildren',
    'Aon.Cart.youAndParent',
    'Aon.Cart.youAndChild',
    'Aon.Cart.youAndChildren',
    'Aon.Cart.youAndFamily',
    'Aon.Profile.DeleteQuoteTitle',
    'Aon.Common.ConfirmDeleteQuote',
    'Aon.Common.OK',
    'Aon.Common.Cancel',
    'Aon.Common.Edit',
    'Aon.Common.Delete'
  ];

  readonly homeStringKeys: string[] = ['Aon.Home.ProductsHeader',
    'Aon.Common.LearnMore',
    'Aon.Home.SelectProduct',
    'Aon.Home.FaqsHeader',
    'Aon.Home.ExpandedValueProdHeader',
    'Aon.Common.GetAQuote',
    'Aon.ProductCategory.PersonalFinance',
    'Aon.ProductCategory.PersonalProperty',
    'Aon.ProductCategory.Lifestyle',
    'Aon.ProductCategory.FinancialWellness',
    'Aon.ProductCategory.HealthWellbeing',
    'Aon.ProductCategory.FinancialWellbeing',
    'Aon.ProductCategory.PersonalProtection',
    'Aon.Home.SelfRegistrationBanner',
    'Aon.Registration.Title',
    'Aon.AutoRenewalBanner.Message',
    'Aon.EndBenefitGracePeriod.Message',
    'Aon.Profile.ActiveBenefits',
    'Aon.Payment.PaymentPage',
    'Aon.Payment.Alert',
    'Aon.Benefit.PromoDiscountAvailable',
    'Aon.PromoCode.Message',
    'Aon.Category.BrowseCategory',
    'Aon.Category.Categories.Header',
    'Aon.Category.Categories.SubHeader',
    'Aon.Category.Categories.View',
    'Aon.Category.CategoryTitle',
    'Aon.Home.SeeAllFaqs',
    'Aon.Preferences.HideSensitiveData.BannerTitle',
    'Aon.Preferences.HideSensitiveData.BannerMessage',
    'Aon.Link.ClickHere'
  ];
  readonly homeFaqsStringKeys: string[] = [
    'Aon.Common.FaqsTitle',
    'Aon.Navigation.ReturnToTop',
    'Aon.Breadcrumb.Home',
    'Aon.Breadcrumb.FAQ',
    'Aon.Help.SelectATopic',
    'Aon.Help.JumpToSection',
    'Aon.Common.Selected'
  ];
  readonly homeHelpStringKeys: string[] = [
    'Aon.Common.HelpsTitle',
    'Aon.Navigation.ReturnToTop',
    'Aon.Breadcrumb.Home',
    'Aon.Breadcrumb.Help',
    'Aon.Help.MyBenefits',
    'Aon.Help.OnlineBenefitsHelp',
    'Aon.Help.SelectATopic',
    'Aon.Help.SelectACategory',
    'Aon.Help.JumpToSection',
    'Aon.Common.Selected',
    'Aon.Help.ContactUs'
  ];
  readonly homeHelpIndexStringKeys = [
    'Aon.Help.MyBenefits',
    'Aon.Help.OnlineBenefitsHelp'
  ];
  readonly homeHeroStringKeys: string[] = ['Aon.ActiveEvent.Enrolment.Incomplete',
    'Aon.System.Welcome',
    'Aon.ActiveEvent.Enrolment.OneDayLeftToEnrol',
    'Aon.BenefitMatrix.IntroText.OpenEnrolment.LastDayToEnrol',
    'Aon.ActiveEvent.Enrolment.Complete',
    'Aon.ActiveEvent.DaysLeftToEnrol',
    'Aon.ActiveEvent.OneDayLeftToEnrol',
    'Aon.ActiveEvent.Benefit.Complete',
    'Aon.ActiveEvent.Incomplete',
    'Aon.ActiveEvent.Complete',
    'Aon.ActiveEvent.DiscardNote',
    'Aon.ActiveEvent.Enrol',
    'Aon.ActiveEvent.ContinueEnrolment',
    'Aon.ActiveEvent.MakeFurtherChanges',
    'Aon.ActiveEvent.viewEnrol',
    'Aon.ActiveEvent.CancelEvent',
    'Aon.ActiveEvent.Benefit.InComplete',
    'Aon.Participant.WidgetButton',
    'Aon.Participant.WidgetLink',
    'Aon.Home.SelectProduct',
    'Aon.Delegate.Hero.WelcomeNote'
  ];
  readonly homeActiveBenefits: string[] = [
    'Aon.ActiveBenefitWidget.Heading',
    'Aon.ActiveBenefitWidget.Enroled',
    'Aon.ActiveBenefitWidget.SubHeading',
    'Aon.ActiveBenefitWidget.LockoutText',
    'Aon.ActiveBenefitWidget.Policies',
    'Aon.ActiveBenefitWidget.DepText',
    'Aon.ActiveBenefitWidget.Participants',
    'Aon.ActiveBenefitWidget.Beneficiaries',
    'Aon.ActiveBenefitWidget.ButtonText',
    'Aon.Tag.Pending',
    'Aon.Tooltip.PendingEOI',
    'Aon.Arialabel.Info',
    'Aon.ActiveBenefitWidget.TotalAnnualContributions',
    'Aon.ActiveBenefitWidget.ExploreBenefits',
    'Aon.AddOns.label',
    'Aon.ActiveBenefitWidget.ViewBenefitsStatement',
    'Aon.Tag.Anytime',
    'Aon.Tag.Lifestyle'
  ];
  readonly homeActiveEvents: string[] = [
    'Aon.System.Welcome',
    'Aon.ActiveEvent.SubTitle',
    'Aon.QueuedEvents.BannerTitle',
    'Aon.QueuedEvents.Message',
    'Aon.QueuedEvents.WithoutScheme.Message'
  ];
  readonly homeClaimWidget: string[] = [
    'Aon.Claim.Summary.Status.Tip',
    'Aon.Arialabel.Info',
    'Aon.Claim.Widget.AccountBalance',
    'Aon.Claim.Widget.PendingBalance',
    'Aon.Claim.Summary.Select.Account',
    'Aon.Claims.Widget.Heading',
    'Aon.Claims.UnlimitedBalance',
    'Aon.Claim.Breadcrumb',
    'Aon.Claim.ViewAccountSummary'
  ]
  readonly homeLifeStyle: string[] = [
    'Aon.Participant.WidgetButton',
    'Aon.Participant.WidgetLink'
  ];
  readonly homePensionEntry: string[] = [
    'Aon.Pension.LearnMore',
  ];
  readonly homePensionInfo: string[] = [
    'Aon.Pension.Advisory.Error',
  ];
  readonly homeWellOneFinancial: string[] = [
    'Aon.WellOneWidget.TotalRangeMax',
    'Aon.WellOneWidget.Button.DownloadApp',
    'Aon.Tag.Latest',
    'Aon.WellOneWidget.LastUpdated'
  ];
  readonly homeWellOneHealthScore: string[] = [
    'Aon.WellOneWidget.SelfControl',
    'Aon.WellOneWidget.Activity',
    'Aon.WellOneWidget.Mindfulness',
    'Aon.WellOneWidget.Sleep',
    'Aon.WellOneWidget.MentalWellbeing',
    'Aon.WellOneWidget.PhysicalHealth',
    'Aon.WellOneWidget.Nutrition',
    'Aon.WellOneWidget.Description.SelfControl',
    'Aon.WellOneWidget.Description.Activity',
    'Aon.WellOneWidget.Description.Mindfulness',
    'Aon.WellOneWidget.Description.Sleep',
    'Aon.WellOneWidget.Description.MentalWellbeing',
    'Aon.WellOneWidget.Description.PhysicalHealth',
    'Aon.WellOneWidget.Description.Nutrition',
    'Aon.WellOneWidget.TotalRangeMax',
    'Aon.WellOneWidget.RangeMax',
    'Aon.WellOneWidget.Button.ViewDetails',
    'Aon.WellOneWidget.Button.DownloadApp',
    'Aon.Tag.Latest',
    'Aon.WellOneWidget.LastUpdated'
  ];
  readonly homeWellOneNoData: string[] = ['Aon.Common.BannerTitle.Error'];
  readonly homeWellOneSignup: string[] = ['Aon.Modal.CloseButton.AriaLabel']
  readonly homeSummaryStringKeys: string[] = [
    ...this.commonStringKeys,
    ...this.homeStringKeys,
    ...this.homeFaqsStringKeys,
    ...this.homeHelpStringKeys,
    ...this.homeHelpIndexStringKeys,
    ...this.homeHeroStringKeys,
    ...this.homeActiveBenefits,
    ...this.homeActiveEvents,
    ...this.homeClaimWidget,
    ...this.homeLifeStyle,
    ...this.homePensionEntry,
    ...this.homePensionInfo,
    ...this.homeWellOneFinancial,
    ...this.homeWellOneHealthScore,
    ...this.homeWellOneNoData,
    ...this.homeWellOneSignup
  ].filter(//distinct the list
    (value, i, arr) => arr.findIndex(t => t === value) === i
  );
  constructor(private helperService: HelperService) { }

  //preload common resource strings for global values (no product overrides and no role overrides)
  preloadCommonStrings() {
    let stringKeys: string[] = [...this.commonStringKeys, ...this.aonLocaleStringKeys];
    let preloadStringsForHome = true; //this could be a AonSettings option if needed
    if (preloadStringsForHome) {
      stringKeys = [
        ...this.commonStringKeys,
        ...this.aonLocaleStringKeys,
        ...this.footerStringKeys,
        ...this.mastheadStringKeys,
        ...this.mastheadCartStringKeys,
        ...this.homeSummaryStringKeys
      ].filter(//distinct the list
        (value, i, arr) => arr.findIndex(t => t === value) === i
      );
    }

    this.helperService
      .loadResourceStringAsObservable(stringKeys)
      .pipe(
        catchError(() => {
          return of([]);
        }),
        take(1)
      )
      .subscribe((res) => {
        this.helperService.getResourceStringsWithAdditionalParamAsObject(null, stringKeys, res, true);
      });
  }

  getAonLocaleStringsAsObservable(): Observable<ResourceString[]> {
    return this.helperService.loadResourceStringAsObservable(this.aonLocaleStringKeys)
      .pipe(
        catchError(() => {
          return of([]);
        }),
        take(1)
      );
  }

  preloadAllResourceStringsByRolesPerSite(culture: string): Observable<ResourceString[]> {
    return this.helperService.getAllResourceStringsByRolesPerSite(culture)
      .pipe(
        map((data) => {
          this.helperService.rolesLevelUiStrings = data;
          return data;
        }),
        take(1)
      );
  }
}
