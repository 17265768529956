import { Component, Input, OnInit } from '@angular/core';
import { SableDataViewStateService } from '@aon/sable-dataview';

@Component({
  selector: 'lib-dataview-input-label-value-only',
  templateUrl: './sable-dataview-input-label-value-only.component.html'
})
export class SableDataviewInputLabelValueOnlyComponent implements OnInit {
  @Input() data: any;

  options: any;

  constructor(private viewState: SableDataViewStateService) { }

  ngOnInit(): void {
    this.options = this.data;
  }

  labelValueText(data) {
    if (data.value == 1) {
      return data.trueText;
    }
    if (data.value == 0) {
      return data.falseText;
    }
    return data.value;
  }
  ngOnDestroy() {

  }
}
