import { Injectable, ViewContainerRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SableFlowAdapterService, SableFlowEventBusService, SableFlowModel } from '@aon/sable-flow';
import { ContentComponent } from '../../../modules/quote/content/content.component';

@Injectable({
  providedIn: 'root'
})
export class KenticoContentAdapterService implements SableFlowAdapterService {
  instance: ContentComponent;
  customInteractions: Array<SableFlowModel.CustomInteractions> = [];
  constructor(private busservice: SableFlowEventBusService
  ) { }

  render(vcRef: ViewContainerRef, data: any): Observable<any> {
    vcRef.clear();

    const componentRef = vcRef.createComponent<ContentComponent>(ContentComponent, { index: 0 });
    componentRef.instance.data = data.ContentUIKey;
    this.instance = componentRef.instance;
    return of(true);
  }

  validationCheck(): Observable<boolean> {
    return of(true);
  }

  next(): Observable<any> {
    let returnObj = {};
    returnObj['DidSave'] = true;
    return of(returnObj);
  }
  previous(): Observable<boolean> {
    return of(true);
  }
  final(): void {

  }

  cancelEnrolment() {
    if (sessionStorage.getItem('activeEventFlowId')) {
      sessionStorage.removeItem('activeSableContext');
      this.busservice.next({
        flag: true,
        saveData: null,
        goToInstruction: {
          SableInstance_RecordID: sessionStorage.getItem('activeEventInstanceId')
        },
        additionalContextData: {
          employeeEvent_RecordID: sessionStorage.getItem('activeEventIdInMatrix'),
          parent_SableInstance_RecordID: sessionStorage.getItem('activeEventInstanceId')
        }
      });
      return;
    }
  }
}
