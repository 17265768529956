@if (resourceStrings!=undefined) {
  <div class="shopping-cart-container">
    <div class="vb-cart-sleeve cart-dropdown-menu">
      @if (quotes!=null && quotes.length>0) {
        <div class="cart-item">
          @for (quote of quotes; track quote; let ipqIndex = $index) {
            <div [ngClass]="{'m-t-28':ipqIndex !==0}">
              <div class="row">
                <div class="col-8">
                  <p class="quote-name xl m-r-10 m-b-8" [innerHTML]="quote.name | safeHtml"></p>
                </div>
                <div class="col-4 align-right">
                  <i tabindex="0" class="edit-quote edit-contact-preference fal fa-edit m-r-10 focus-visibility" role="button"
                  (click)="goToQuote(quote)" [attr.aria-label]="resourceStrings['Aon.Common.Edit']"></i>
                  <i tabindex="0" class="delete-quote fal fa-trash-alt focus-visibility" role="button"
                    (click)="confirmDelete(quote, confirmDeleteQuoteContent)"
                  [attr.aria-label]="resourceStrings['Aon.Common.Delete']"></i>
                </div>
              </div>
              @if (quote.benefitPlanName!=null && quote.employeeCost_PayPeriod!=null) {
                <div class="row">
                  <div class="col-7">
                    <div class="position-relative qContent" tooltip-placement="bottom-left" tooltip-popup-close-delay="0"
                      [ngbTooltip]="quote.benefitPrinciple ? quote.benefitPlanName + quote.benefitPrinciple : quote.benefitPlanName"
                      tooltip-class="tooltip-light">
                      <p class="employee-cost">
                        <span [innerHTML]="quote.benefitPlanName | safeHtml"></span>
                        @if (quote.benefitPrinciple) {
                          <span [innerHTML]="quote.benefitPrinciple | safeHtml"></span>
                        }
                      </p>
                    </div>
                  </div>
                  <div class="col-5 align-right">
                    <p class="employee-cost" [innerHTML]="quote.employeeCost_PayPeriod | currency: quote.currencyCode"></p>
                  </div>
                </div>
              }
              @if (quote.taxes!=null) {
                <div class="row">
                  @for (tax of quote.taxes; track tax) {
                    <div class="col-7">
                      @if (tax.recordID == 1) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.EESalesTax'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 2) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.NtlTaxBen'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 3) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.ERSalesTax'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 4) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.ProvTaxBen'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 5) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.LifeImputedInc'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 6) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.DPImputedInc'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 7) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.StateImputedInc'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 10) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.SalesTax'] | safeHtml"></span></span>
                      }
                      @if (tax.recordID == 11) {
                        <span class="sm break-word d-inline"><span class="d-inline cart-inverse-color"
                        [innerHTML]="resourceStrings['Aon.ReadyToPurchase.POSDisc'] | safeHtml"></span></span>
                      }
                    </div>
                    <div class="col-5 align-right">
                      <span class="cart-inverse-color sm"
                      [innerHTML]="tax.employeePayPeriodTaxAmount| currency: quote.currencyCode"></span>
                    </div>
                  }
                </div>
              }
            </div>
          }
          <div class="divider m-t-20 m-b-20" id="{{'quote-detail-above-divider-tax'}}"></div>
          <div>
            <div class="row">
              <div class="col-5">
                <p class="m-b-0">{{ resourceStrings['Aon.Cart.DueToday']}}</p>
              </div>
              <div class="col-7 align-right">
                <h5 class="cost-copy cart-inverse-color m-b-0">{{totalCost_Today | currency: currencyCode }}</h5>
              </div>
            </div>
            <button type="button" class="continue-to-purchase inverse-button m-t-20 m-b-12" (click)="goToCheckout()"
            [innerHTML]="resourceStrings['Aon.ReadyToPurchase.ContinueToPurchase'] | safeHtml"></button>
            <span><em class="sm" [innerHTML]="resourceStrings['Aon.ReadyToPurchase.ReviewInfo'] | safeHtml"></em></span>
          </div>
        </div>
      }
      @if (quotes==null || quotes.length==0) {
        <div>
          <h5>{{resourceStrings['Aon.Cart.EmptyHeading']}}</h5>
          <div class="alert contextual p-l-0">
            <div class="d-flex">
              <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
              <span>{{resourceStrings['Aon.Cart.EmptyNotice']}}</span>
            </div>
          </div>
        </div>
      }
    </div>
  </div>
}
<ng-template #confirmDeleteQuoteContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title" [innerHTML]="resourceStrings['Aon.Profile.DeleteQuoteTitle'] | safeHtml"
    id="modal-basic-title"></h4>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
      (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div>{{resourceStrings['Aon.Common.ConfirmDeleteQuote']}}</div>
  </div>
  <div class="button-sleeve">
    <button type="button" class="keep-working confirm-ok prominent m-t-32" (click)="deleteQuote()"
    [innerHTML]="resourceStrings['Aon.Common.OK'] | safeHtml"></button>
    <button type="button" class="keep-working confirm-cancel ghost m-t-32" (click)="modal.dismiss('Cross click')"
    [innerHTML]="resourceStrings['Aon.Common.Cancel'] | safeHtml"></button>
  </div>
</ng-template>