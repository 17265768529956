<div class="basic-card password-rules">
  <div>
    <label class="m-b-8" [innerHTML]="resourceStrings['Aon.Account.PasswordRequirements'] | safeHtml"></label>
    <div class="row">
      <i [class]="this.resultIcon(regexValidation(regexRuleCharLength, NewPassword), NewPasswordTouched) "
        aria-hidden="true"></i>
      <p class="sm col-10"
        [innerHTML]="resourceStrings['Aon.Password.PasswordLength'] | textTransform:{passwordLength: passwordLength} | safeHtml">
      </p>
    </div>

    <div class="row">
      <i [class]="this.resultIcon(regexValidation(regexRuleUpperCase, NewPassword), NewPasswordTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.OneUpperCase'] | safeHtml"></p>
    </div>

    <div class="row">
      <i [class]="this.resultIcon(regexValidation(regexRuleLowerCase, NewPassword), NewPasswordTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.OneLowerCase'] | safeHtml"></p>
    </div>

    <div class="row">
      <i [class]="this.resultIcon(regexValidation(regexRuleOneNumber, NewPassword), NewPasswordTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.OneNumber'] | safeHtml"></p>
    </div>

    <div class="row">
      <i [class]="this.resultIcon(regexValidation(regexRuleSpecialChar, NewPassword), NewPasswordTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.OneSpecialChar'] | safeHtml"></p>
    </div>
    <div class="row">
      <i [class]="this.resultIcon(!regexValidation(regexRulerepetitiveChar, NewPassword), NewPasswordTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.RepetitiveChar'] | safeHtml"></p>
    </div>
    <div class="row">
      <i [class]="this.resultIcon(!isSequential(NewPassword), NewPasswordTouched)" aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Password.SequentialChar'] | safeHtml"></p>
    </div>
    <div class="row">
      <i [class]="this.resultIcon((NewPasswordRepeat == NewPassword), NewPasswordTouched || NewPasswordRepeatTouched)"
        aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Account.PasswordRepeatMatch'] | safeHtml"></p>
    </div>
    <div class="row m-b-0">
      <i *ngIf="!isGuest" [class]="this.usernamevalidation(NewPasswordTouched)" aria-hidden="true"></i>
      <i *ngIf="isGuest" [class]="cssClassForContainsUserNameValidation" aria-hidden="true"></i>
      <p class="sm col-10" [innerHTML]="resourceStrings['Aon.Account.PasswordShouldnotContainUsername'] | safeHtml"></p>
    </div>
  </div>
</div>
<div *ngIf="IsResetSuccess">
  <div class="alert contextual success"><i class="fas fa-check-circle"
      aria-hidden="true"></i>{{resourceStrings['Aon.Account.ResetPasswordSuccess']}}</div>
</div>
<div class="form-group new-password">
  <label for="newPassword"
    [ngClass]="{'error': showNewPasswordRequiredAlert && aonInputNewPassword?.invalid && (aonInputNewPassword?.dirty || aonInputNewPassword?.touched) && aonInputNewPassword?.errors?.['required']}">{{resourceStrings['Aon.Account.NewPassword']}}</label>
  <div
    *ngIf="showNewPasswordRequiredAlert && aonInputNewPassword?.invalid && (aonInputNewPassword?.dirty || aonInputNewPassword?.touched) && aonInputNewPassword?.errors?.['required']"
    class="alert contextual error">
    <div role="alert" role="alert">
      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
    </div>
  </div>
  <aon-input-password [id]="'newPassword'" [(ngModel)]="NewPassword" (keyup)="PasswordChangedSubject.next($event)"
    [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']" #aonInputNewPassword="ngModel" required
    [ngClass]="{'error': showNewPasswordRequiredAlert && aonInputNewPassword?.invalid && (aonInputNewPassword?.dirty || aonInputNewPassword?.touched) && aonInputNewPassword?.errors?.['required']}"></aon-input-password>

</div>
<div class="form-group repeat-new-password">
  <label for="reEnterNewPassword">{{resourceStrings['Aon.Account.ReEnterNewPassword']}}</label>
  <aon-input-password [id]="'reEnterNewPassword'" [(ngModel)]="NewPasswordRepeat"
    (keyup)="RepeatPasswordChangedSubject.next($event)"
    [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>

</div>