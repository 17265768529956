export class Banner {
  type: BannerType;
  message: string;
  data: Object;
  dismissible: boolean;
  closed: boolean;
  iconClass: string;
  style: BannerStyle;
}

export enum BannerType {
  Info = "info",
  Alert = "alert",
  Promo = 'promo'
}

export enum BannerStyle {
  LightBlue = 'banner_light_blue',
  DarkBlue = 'banner_dark_blue'
}

