<div class="masthead-products-sleeve-category">
  <div class="row">
    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
      <h3 class="m-b-8 d-block d-sm-none focus-visibility"
      [innerHTML]="resourcestrings['Aon.Category.CategoryTitle'] | safeHtml" tabindex="0"></h3>
      <h3 class="m-b-16 d-none d-sm-block focus-visibility"
      [innerHTML]="resourcestrings['Aon.Category.CategoryTitle'] | safeHtml" tabindex="0"></h3>

      <p class="xl d-none d-sm-block m-b-0 focus-visibility"
      [innerHTML]="resourcestrings['Aon.Category.CategoryIntro'] | safeHtml" tabindex="0"></p>
      <p class="d-block d-sm-none m-b-20 focus-visibility"
      [innerHTML]="resourcestrings['Aon.Category.CategoryIntro'] | safeHtml" tabindex="0"></p>

    </div>

    @for (category of categories; track category) {
      <div class="col-xs-12 col-sm-6 col-md-6 col-lg-3 col-xl-3">
        <div class="category-column">
          <div class="d-flex align-items-center m-b-12">
            <div class="category-icon" (click)="goToCategory(category); hidePopup();"
              [ngStyle]="{'background-image': 'url(' + category.image + ')'}">
            </div>
            <h5 class="m-b-0 d-none d-sm-block">
              <a (click)="goToCategory(category); hidePopup();"
                (keydown.enter)="goToCategory(category); hidePopup();"
              [innerHTML]="category.itemTitle | safeHtml" class="p-0" tabindex="0"></a>
            </h5>
            <p class="lg m-b-0 d-block d-sm-none">
              <a (click)="goToCategory(category); hidePopup();"
                (keydown.enter)="goToCategory(category); hidePopup();"
              [innerHTML]="category.itemTitle | safeHtml" class="p-0" tabindex="0"></a>
            </p>
          </div>
          <p class="lg m-b-20 d-none d-sm-block focus-visibility" [innerHTML]="category.itemSummary | safeHtml"
            tabindex="0">
          </p>
          <p class="m-l-ss m-b-12 d-block d-sm-none focus-visibility"
            [innerHTML]="category.itemSummary | safeHtml" tabindex="0">
          </p>
          <!-- Show all the External Products Related to a Category -->
          @for (product of category.products; track product; let last = $last) {
            <div class="category-products"
              [ngClass]="{'m-b-16': last !== true}">
              <p class="m-b-0 d-none d-sm-block">
                <a (click)="goToProduct(product); hidePopup();"
                  (keydown.enter)="goToProduct(product); hidePopup();"
                [innerHTML]="product.itemTitle | safeHtml" class="p-0" tabindex="0"></a>
              </p>
              <p class="m-b-0 m-l-ss sm d-block d-sm-none">
                <a (click)="goToProduct(product); hidePopup();"
                  (keydown.enter)="goToProduct(product); hidePopup();"
                [innerHTML]="product.itemTitle | safeHtml" class="p-0" tabindex="0"></a>
              </p>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>