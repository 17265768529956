import { Component, Injector, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { GlobalObjectsService } from '../services/global-objects.service';

@Component({
  selector: 'aon-spin',
  templateUrl: './aon-spin.component.html'
})
export class AonSpinComponent extends TBSBaseComponent implements OnInit {

  @Input() IsLoading: boolean = false;
  @Input() LoadingText: string = '';
  @Input() DarkSpin: boolean = false;
  @Input() noDarkSpin: boolean = false;

  constructor(private injector: Injector, public globalObjService: GlobalObjectsService) {
    super(injector);
  }

  ngOnInit() {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res);
      });
  }
}
