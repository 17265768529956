<div id="benefit-info-modal" [attr.role]="removeRoleDialog ? null : 'alertdialog'" [attr.aria-label]="benefitName"
  (keydown.esc)="handlekeyDownEsc($event)">
  <div class="modal-only">
    <div class="modal-nav-bar">
      <div class="page-content-sleeve">
        <span tabindex="0" class="focus-visibility" (click)="onClose()" (keydown.enter)="onClose()">
          <i class="m-r-8 fa-light fa-chevron-left"></i>
          <a role="button" class="close" [innerHTML]="resourceStrings['Aon.BenefitDetails.GoBack']"
            [attr.aria-label]="resourceStrings['Aon.BenefitDetails.GoBack']">
          </a>
        </span>
      </div>
    </div>
  </div>
  <div class="col-12 d-block d-sm-none sticky-dropdown container m-t-20" *ngIf="showJumpToSection">
    <div class="m-b-32">
      <select class="dropdown" #selectListTopic (change)="setActive(selectListTopic.value)">
        <optgroup label="{{resourceStrings['Aon.Help.JumpToSection']}}">
          <ng-container>
            <ng-container *ngFor="let content of productdetails.items.contentItemsList; let sectionIndex = index">
              <option [value]="sectionIndex"
                [attr.aria-label]="content.contentItemTitle + ' ' + resourceStrings['Aon.Common.Selected']"
                [selected]="sectionIndex === active">
                {{content.contentItemTitle}}
              </option>
            </ng-container>
            <option *ngIf="faqs?.length" [value]="productdetails.items.contentItemsList.length"
              [attr.aria-label]="faqTitle + ' ' + resourceStrings['Aon.Common.Selected']"
              [selected]="productdetails.items.contentItemsList.length === active">
              {{faqTitle}}
            </option>
          </ng-container>
        </optgroup>
      </select>
    </div>
  </div>
  <!-- Accessibility Loading and Loaded screen announcement -->
  <aon-spin [noDarkSpin]="true"></aon-spin>
  <div class="page T3" id="product-details" *ngIf="productdetails" [ngClass]="currentProduct?.theme">
    <div class="pd-content-sleeve-outer">
      <div class="page-content-sleeve benefit-information">
        <div *ngIf="productdetails.items" class="row benefit-information">
          <div class="col-md-3 d-none d-sm-block" id="nav-target">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-pills flex-column sidebar">
              <li [class.m-t-4]="sectionIndex === 0" id="content-title{{sectionIndex}}" class="nav-item"
                *ngFor="let content of productdetails.items.contentItemsList; let sectionIndex = index; let last = last"
                [ngbNavItem]="sectionIndex" [domId]="sectionIndex?.toString()">
                <a ngbNavLink class="nav-link product-details-nav-link info-modal-section-{{sectionIndex}}" role="tab">
                  <span class="theme-color">
                    {{ content.contentItemTitle }}
                  </span>
                </a>
                <ng-template ngbNavContent>
                  <section class="m-b-30" data-spy="1" id="{{ getSectionId(content.contentItemID) }}" SpyScrollView>
                    <div id="{{sectionIndex+'-panel'}}">
                      <div *ngIf="sectionIndex == 0">
                        <h2 class="theme-color h3" id="title-{{ content.contentItemID }}">{{ content.contentItemTitle }}
                        </h2>
                      </div>
                      <div *ngIf="sectionIndex != 0 && !last">
                        <h2 class="theme-color h3" id="title-{{ content.contentItemID}}">{{ content.contentItemTitle }}
                        </h2>
                      </div>
                      <div *ngIf="last && sectionIndex != 0">
                        <div class="row justify-content-between">
                          <div class="col-md-12 col-10">
                            <h2 class="theme-color h3" id="title-{{ content.contentItemID}}">
                              {{content.contentItemTitle}}</h2>
                          </div>
                        </div>
                      </div>

                      <div class="m-l-4" *ngIf="content != null && content.contentItemBody != null"
                        [innerHTML]="content.contentItemBody | safeHtml"></div>
                      <div class="divider m-t-30"></div>
                    </div>
                  </section>
                </ng-template>
              </li>

              <li *ngIf="faqs?.length" id="faq-title" class="nav-item"
                [ngbNavItem]="productdetails.items.contentItemsList.length"
                [domId]="productdetails.items.contentItemsList.length?.toString()">
                <a ngbNavLink
                  class="nav-link product-details-nav-link info-modal-section-{{productdetails.items.contentItemsList.length}}"
                  role="tab">
                  <span class="theme-color">
                    {{faqTitle}}
                  </span>
                </a>
                <ng-template ngbNavContent>
                  <section data-spy="1" class="m-b-30" *ngIf="faqs?.length" id="{{ getSectionId('FAQ') }}">
                    <div id="{{productdetails.items.contentItemsList.length+'-panel'}}">
                      <div class="row justify-content-between">
                        <div class="col-md-12">
                          <div class="theme-color" id="title-FAQ">
                            <div class="row">
                              <div class="col-md-8 col-12 m-auto">
                                <app-faq [faqTitle]="faqTitle" [faqs]="faqs" [resourcestrings]="resourceStrings">
                                </app-faq>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </ng-template>
              </li>
            </ul>
          </div>
          <div class="col-md-9 col-12" id="product-detail-content-sleeve">
            <div class="modal-header">
              <h1 class="modal-title" aria-describedby="benefitName" aria-labelledby="benefitName"
                [innerHTML]="benefitName | striphtml"></h1>
            </div>
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>