<div class="benefit-save-alert-modal" (keydown.esc)="close($event)">
  <div class="modal-header">
    <h1 aria-hidden="true" class="modal-title theme-color h3"
      [innerHTML]="resourceStrings['Aon.DeletePopup.Title'] | safeHtml">
    </h1>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
      (click)="close($event)" (keyup.enter)="close($event)">
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body p-b-20">
    @if(triggerDeselectionWarning){
    <div [innerHTML]="resourceStrings['Aon.DeletePopup.DropDependantPolicies.Description'] | safeHtml"></div>

    }@else{
    <div [innerHTML]="resourceStrings['Aon.DeletePopup.Description'] | safeHtml"></div>

    }

  </div>
  <div class="button-sleeve">
    <button (click)="save()" class="prominent" [innerHTML]="resourceStrings['Aon.Common.Continue'] | safeHtml"></button>
    <button (click)="close($event)" (keyup.enter)="close($event)" class="ghost"
      [innerHTML]="resourceStrings['System.Common.Cancel'] | safeHtml"></button>
  </div>
</div>
