import { HttpClient } from '@angular/common/http';
import { ElementRef, inject, Inject, Injectable, Injector } from '@angular/core';
import { Params, UrlTree, Router, NavigationEnd } from '@angular/router';
import JSEncrypt from 'jsencrypt';
import { BootstrapRequestContext } from 'tbs-typings';
import { LanguageSelectionData, ResourceString, ResourceStringsObject } from '../../shared/models';
import { BehaviorSubject, Observable, of, Subject, defer, fromEvent } from 'rxjs';
import { EmployeeBenefitsType } from '../models/benefitReview.model';
import { RolesRoutingPaths } from '../models/rolesRoutingPaths.model';
import { Constants, SessionStorageKey, RouteRootPrefix, ProfileTabFragements } from '../models/constants';
import { filter, map } from 'rxjs/operators';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { RecursiveCapitalize, RecursiveUncapitalize } from '../models/core-typing.model';
import { HttpCacheService, HttpRequestCache, IHttpCacheOptions } from '../../core/cache/http-request-cache';
import { AonSettings } from '../models/aonSettings.model';
import { CustomSableDataViewConfig, DataViewInfo } from '../models/sable.model';
import { NamedRoute } from '../parameterized-text/parameterized-text.model';
import { Banner, BannerType } from '../models/aon-banners.model';
import { AON_LOCALE, AonLocaleService } from '@aon/aon-angular-common';
import { JwtHelper } from '../../core/guards/jwt.helper';
import { GlobalObjectsService } from './global-objects.service';
import { PreviousPageURL } from '../../shared/models/previous-page-url.model';
import { Product } from '../../shared/models/product.model';
import { formatNumber, parseNumber } from '@progress/kendo-intl';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MetaDataService } from './metadata.service';
import { CustomLoggedOffSettings } from '../models/customLoggedOffSettings.model';
import { ShowDataByAmountType } from '../models/helper.service.model';

@Injectable({ providedIn: 'root' })
export class HelperService {
  private readonly refreshSubject = new Subject();
  narBarCollapseState = new BehaviorSubject(true);
  claimSableFlow_RecordID = new Subject<string>();
  externalLinkURL = new Subject<string>();
  apiBaseUrl: string;
  customMaxFindingCount = 30;
  accordionTimeOut: any;
  accordionFindingCount: number = 0;
  collapseTimeOut: any;
  collapseFindingCount: number = 0;
  siteType: string;
  isTimeout = false;
  allowAnonymousAccess = false;
  enableReCaptCha: boolean;
  captChaSrc: string;
  captChaSiteKey: string;
  selectedESA = new Subject<string>();
  cultureCode: string;
  formatOptions: any = { style: 'currency', currency: '', currencyDisplay: 'symbol' };
  AMOUNT_TYPE_PERCENT_SALARY = 1;
  AMOUNT_TYPE_CURRENCY = 2;
  AMOUNT_TYPE_VOUCHER = 3;
  AMOUNT_TYPE_DAYS = 4;
  AMOUNT_TYPE_HOURS = 5;
  AMOUNT_TYPE_VOUCHERS = 7;
  AMOUNT_TYPE_CUSTOMTYPE = 8;
  curActiveId: string = '';
  navigationUrlPaths = {
    currentURL: "",
    previousURL: ""
  }

  constructor(private http: HttpClient,
    private uiconfigrationService: UiconfigrationService,
    private httpCache: HttpCacheService,
    private globalObjService: GlobalObjectsService,
    private router: Router,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private localeService: AonLocaleService,
    private modalService: NgbModal,
    @Inject(AON_LOCALE) private AON_LOCALE,
    private metaDataService: MetaDataService
  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;
    this.siteType = bootstrapRequestContext.siteType;
    this.allowAnonymousAccess = bootstrapRequestContext.allowAnonymousAccess;
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.navigationUrlPaths.previousURL = this.navigationUrlPaths.currentURL;
      this.navigationUrlPaths.currentURL = event.url;
    });
  }

  uiStringsCacheDic = new Map<string, string>();
  rolesLevelUiStrings: ResourceString[];

  getClientCode(): string {
    return sessionStorage.getItem('clientCode');
  }

  isEnableReCaptCha(): boolean {
    return this.bootstrapRequestContext.enableReCaptCha;
  }

  getCaptChaSrc(): string {
    return this.bootstrapRequestContext.captChaSrc;
  }

  getCaptChaSiteKey(): string {
    return this.bootstrapRequestContext.captChaSiteKey;
  }

  //Add this method to resolve the flicker issue exist on the quote flow pages.
  //VB Asia and VB US site need a default 3-6-3 page layout (Sable Steps(3)-Content(6)-Quote Summary(3)), while OLB needs the default layout (col-9)
  //If it's fixed layout, the page will be always 9 columns width.
  //Otherwise, it should be 3-6-3 page layout, but still will check the hide progress bar and hide quote summary flags.
  IsFixedLayout() {
    return this.IsOLB();
  }

  ShowEventsActiveBenefits() {
    return this.IsOLB();
  }

  AllowAnonymousAccess() {
    return this.allowAnonymousAccess;
  }

  IsOLB() {
    return this.siteType == 'OLB';
  }

  IsVBAsia() {
    return this.siteType == 'Asia VB';
  }

  IsUSVB() {
    return this.siteType == 'US VB';
  }

  IsVanityURLForMultiTenantEnabled(): boolean {
    if (sessionStorage.getItem('enableVanityURLForMultiTenant') != null && sessionStorage.getItem('enableVanityURLForMultiTenant') == 'true') {
      return true;
    } else {
      return false;
    }
  }

  getBaseUrl(): string {
    if (this.IsVanityURLForMultiTenantEnabled()) {
      if (sessionStorage.getItem('clientCode') != null && sessionStorage.getItem('clientCode') != '') {
        return '/' + sessionStorage.getItem('clientCode');
      }
    } else {
      return '';
    }
  }
  IsValidClientCode(): boolean {
    if (this.IsVanityURLForMultiTenantEnabled()) {
      return (
        sessionStorage.getItem('clientCode') != 'null' && sessionStorage.getItem('clientCode') != null && sessionStorage.getItem('clientCode') != ''
      );
    } else {
      return true;
    }
  }

  getRSAKey(): string {
    return this.bootstrapRequestContext.rsaKey;
  }

  encrypt(plainText: string): string | false {
    let encryptFn = new JSEncrypt({});
    encryptFn.setPublicKey(this.getRSAKey());
    let encryptedText = encryptFn.encrypt(plainText);

    encryptedText = encodeURI(encryptedText.toString()).replace(/\+/g, '%2B');

    return encryptedText;
  }
  isLoggedOn(): boolean {
    return sessionStorage.getItem('currentUser') != null;
  }
  isGuestUserCreated(): boolean {
    return localStorage.getItem('currentGuest') != null;
  }
  getCampaignKey(): string {
    return this.getUrlParameter('campaignKey');
  }

  getUrlParameter(name: string): string {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }

  appendUrlParameter(originalUrl: string, name: string, param: string): string {
    let resultUrl = new URL(originalUrl);
    resultUrl.searchParams.append(name, param);
    return resultUrl.toString();
  }

  toLower(params: Params): Params {
    const lowerParams: Params = {};
    for (const key in params) {
      lowerParams[key.toLowerCase()] = params[key];
    }

    return lowerParams;
  }

  getCurrentLanguageCode(): LanguageSelectionData {
    return JSON.parse(localStorage.getItem('SelectedLanguage'));
  }

  getStringByKey(key: string): string {
    if (this.uiStringsCacheDic.get(key)) {
      return this.uiStringsCacheDic.get(key);
    } else {
      return '';
    }
  }

  isStringKeyCached(key: string, originalStringKey: string, roles: string[]): boolean {
    if (this.uiStringsCacheDic.get(key)) {
      return true;
    }
    if (roles && roles.length > 0) {
      let rolesLowerCase = roles.map(v => v.toLowerCase());
      let cached = false;
      this.rolesLevelUiStrings?.forEach(stringObject => {
        if (stringObject.key == originalStringKey.toLowerCase() && rolesLowerCase.includes(stringObject.role)) {
          this.uiStringsCacheDic.set(key, stringObject.value);
          cached = true;
        }
      });
      if (cached) {
        return true;
      }
      else if (this.uiStringsCacheDic.get(originalStringKey)) {//if not find in role based list, check the common key and setup the roles sepcail key based on common key value
        this.uiStringsCacheDic.set(key, this.uiStringsCacheDic.get(originalStringKey));
        return true;
      }
    }
    return false;
  }

  setStringByKey(key: string, data: string) {
    if (this.uiStringsCacheDic.get(key)) {
      return;
    } else {
      this.uiStringsCacheDic.set(key, data);
    }
  }

  resetStringsByKeys(additionalParam: string, keys: string[]) {
    keys.forEach((item) => {
      // uiStringsCacheDic is cached on both global and product level
      // For example, 'Aon.PayFrequency.Monthly' will be stored as
      // 'Aon.PayFrequency.Monthly' and 'Aon.PayFrequency.Monthly|productID'
      let key = additionalParam == null ? item : item + '|' + additionalParam;
      if (this.uiStringsCacheDic.get(key)) {
        this.uiStringsCacheDic.delete(key);
      }
    });
  }

  resetStringCache() {
    this.uiStringsCacheDic = new Map<string, string>();
  }

  replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  loadResourceString(stringKeys: string[]): any {
    let curLanguageCode: LanguageSelectionData = this.getCurrentLanguageCode();

    let stringKeysToQuery: string[] = [];
    let resourcestrings = new ResourceStringsObject();

    stringKeys.forEach((item) => {
      let stringValue = this.getStringByKey(item);
      if (stringValue != '') {
        resourcestrings[item] = stringValue;
      } else {
        stringKeysToQuery.push(item);
      }
    });

    if (stringKeysToQuery.length > 0) {
      const requestUrl = this.apiBaseUrl + curLanguageCode.cultureCode + '/uiresourcestrings';
      this.http.post<ResourceString[]>(requestUrl, stringKeysToQuery).subscribe((data: ResourceString[]) => {
        if (data != null && data.length > 0) {
          for (let res of data) {
            resourcestrings[res.key] = res.value;
            this.setStringByKey(res.key, res.value);
          }
        }
      });
    }

    return resourcestrings;
  }

  loadResourceStringWithMacro(stringKeys: string[]): any {
    let curLanguageCode: LanguageSelectionData = this.getCurrentLanguageCode();

    let stringKeysToQuery: string[] = [];
    let resourcestrings = new ResourceStringsObject();

    stringKeys.forEach((item) => {
      let stringValue = this.getStringByKey(item);
      if (stringValue != '') {
        resourcestrings[item] = stringValue;
      } else {
        stringKeysToQuery.push(item);
      }
    });

    if (stringKeysToQuery.length > 0) {
      const requestUrl = this.apiBaseUrl + curLanguageCode.cultureCode + '/uiresourcestrings';
      this.http.post<ResourceString[]>(requestUrl, stringKeysToQuery).subscribe((data: ResourceString[]) => {
        if (data != null && data.length > 0) {
          for (let res of data) {
            res.value = this.applyResourceStringMacros(res.value);
            resourcestrings[res.key] = res.value;
            this.setStringByKey(res.key, res.value);
          }
        }
      });
    }

    return resourcestrings;
  }

  loadResourceStringAsObservable(stringKeys: string[]): Observable<ResourceString[]> {
    return this.loadResourceStringWithAdditionalParamAsObservable(null, stringKeys);
  }

  loadResourceStringWithAdditionalParamAsObservable(
    additionalParam: string,
    stringKeys: string[],
    roles: string[] = null
  ): Observable<ResourceString[]> {
    let keysToQuery: string[] = [];
    let cachedStrings: ResourceString[] = [];
    let curLanguageCode: LanguageSelectionData = this.getCurrentLanguageCode();

    stringKeys.forEach((item) => {
      let stringKey = additionalParam == null ? item : item + '|' + additionalParam;
      let isCached = this.isStringKeyCached(stringKey, item, roles);

      if (!isCached) {
        keysToQuery.push(item);
      } else {
        let stringValue = this.getStringByKey(stringKey);
        cachedStrings.push({
          culture: curLanguageCode.cultureCode,
          key: item,
          value: stringValue
        } as ResourceString);
      }
    });

    if (keysToQuery.length > 0) {
      let requestUrl = null;
      if (additionalParam == null || roles) {
        requestUrl = this.apiBaseUrl + curLanguageCode.cultureCode + '/uiresourcestrings';
        if (!sessionStorage.getItem('currentUser') && !sessionStorage.getItem('currentGuest')) {
          requestUrl += "?tenantRecordId=" + this.bootstrapRequestContext.tenantID;
        }
      } else {
        requestUrl = this.apiBaseUrl + curLanguageCode.cultureCode + '/' + additionalParam + '/uiresourcestrings';
      }
      let request = this.getResourceStringFromServer(requestUrl, keysToQuery);
      return request.pipe(map((res) => {
        res?.filter(x => x?.value?.includes("<script>") || x?.value?.includes("<style>"))?.forEach(x => {
          let string = x.value;
          string = string?.replaceAll(/<script>.+<\/script>/gi, "");
          string = string?.replaceAll(/<style>.+<\/style>/gi, "");
          x.value = string;
        })
        return res.concat(cachedStrings);
      }));
    } else {
      return of(cachedStrings);
    }
  }
  @HttpRequestCache<HelperService>(function () {
    return {
      storage: this.httpCache,
      refreshSubject: this.refreshSubject
    } as IHttpCacheOptions;
  })
  getResourceStringFromServer(url: string, keys: string[]): Observable<ResourceString[]> {
    return this.http.post<ResourceString[]>(url, keys);
  }

  getResourceStringsByRolesFromServer(languageCode: string, keys: string[], roles: string[]) {
    let keysAndRoles = {
      StringKeys: keys,
      Roles: roles
    };
    let requestUrl = this.apiBaseUrl + languageCode + '/uiresourcestrings/roles';
    return this.http.post<ResourceString[]>(requestUrl, keysAndRoles);
  }

  getAllResourceStringsByRolesPerSite(languageCode: string): Observable<ResourceString[]> {
    let requestUrl = this.apiBaseUrl + languageCode + '/uiresourcestrings/rolespersite?tenantRecordId=' + this.bootstrapRequestContext.tenantID;
    return this.http.get<ResourceString[]>(requestUrl);
  }

  getResouceStrings(keys: string[], data: ResourceString[], resolveMacros: boolean): ResourceString[] {
    return this.getResourceStringsWithAdditionalParam(null, keys, data, resolveMacros);
  }
  //additionalParam: ProductID/CombinedRolesString
  getResourceStringsWithAdditionalParam(additionalParam: string, keys: string[], data: ResourceString[], resolveMacros: boolean): ResourceString[] {
    let result: ResourceString[] = [];
    let hasLocalization = data && data.length > 0;
    let curLanguageCode: LanguageSelectionData = this.getCurrentLanguageCode();

    keys.forEach((k) => {
      let res: ResourceString = null;
      if (hasLocalization) {
        res = data.find((d) => d.key == k);
      }

      if (res) {
        if (resolveMacros) {
          res.value = this.applyResourceStringMacros(res.value);
        }
        if (additionalParam != null) {
          this.setStringByKey(res.key + '|' + additionalParam, res.value);
        } else {
          this.setStringByKey(res.key, res.value);
        }
        result.push(res);
      } else {
        result.push(<ResourceString>{
          culture: curLanguageCode.cultureCode,
          key: k,
          value: k
        });
      }
    });

    return result;
  }
  //additionalParam: ProductID/CombinedRolesString
  getResourceStringsWithAdditionalParamAsObject(
    additionalParam: string,
    keys: string[],
    data: ResourceString[],
    resolveMacros: boolean
  ): ResourceStringsObject {
    let strings = this.getResourceStringsWithAdditionalParam(additionalParam, keys, data, resolveMacros);
    let result = new ResourceStringsObject();
    strings.forEach((k) => {
      result[k.key] = k.value;
    });

    return result;
  }
  getResouceStringsAsObject(keys: string[], data: ResourceString[], resolveMacros: boolean): ResourceStringsObject {
    return this.getResourceStringsWithAdditionalParamAsObject(null, keys, data, resolveMacros);
  }

  applyResourceStringMacros(resourceString) {
    return this.replaceAll(resourceString, '{{sitename}}', this.bootstrapRequestContext.siteName);
  }

  enabledRolesForUiStrings(): boolean {
    if (this.isLoggedOn() && this.bootstrapRequestContext.checkRoleRoutingPaths.length > 0) {
      if (sessionStorage.getItem(SessionStorageKey.EnabledRolesForUiStrings)) {
        return sessionStorage.getItem(SessionStorageKey.EnabledRolesForUiStrings) == Constants.True;
      } else {
        let jwtHelper = new JwtHelper();
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
        if (currentUser && currentUser.id_token) {
          let idToken = jwtHelper.decodeToken(currentUser.id_token);
          if (idToken.RolesForUIStrings && JSON.parse(idToken.RolesForUIStrings).length > 0) {
            let model = new RolesRoutingPaths();
            model.paths = this.bootstrapRequestContext.checkRoleRoutingPaths;
            model.roles = JSON.parse(idToken.RolesForUIStrings);
            model.combinedRolesString = this.getCombinedRolesString(model.roles);
            sessionStorage.setItem(SessionStorageKey.EnabledRolesForUiStrings, Constants.True);
            sessionStorage.setItem(SessionStorageKey.RolesRoutingPathsForUiStrings, JSON.stringify(model));
            return true;
          }
        }
        sessionStorage.setItem(SessionStorageKey.EnabledRolesForUiStrings, Constants.False);
        return false;
      }
    }
    return false;
  }

  getCombinedRolesString(roles: string[]): string {
    let rolesStr = '';
    roles.forEach((role) => {
      rolesStr += '%' + role;
    });
    return rolesStr;
  }

  getRolesRoutingPathsForUiStrings(): RolesRoutingPaths {
    if (sessionStorage.getItem(SessionStorageKey.RolesRoutingPathsForUiStrings)) {
      return JSON.parse(sessionStorage.getItem(SessionStorageKey.RolesRoutingPathsForUiStrings));
    }
    return null;
  }

  scrollToProperElementById(id) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToProperElementByClass(className) {
    let el = document.getElementsByClassName(className)[0];
    el.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToThePageTop() {
    let el = document.getElementById('nav');
    el.scrollIntoView({ behavior: 'smooth' });
  }

  scrollToThePageTopInstantly() {
    let el = document.getElementById('nav');
    el.scrollIntoView({ behavior: 'auto' });
  }

  getVaueByKeyFromJsonData(jsonDataObj: any, key: string): string {
    let keys = Object.keys(jsonDataObj);
    let returnValue: string;
    if (keys.length == 0) return;

    for (let item of keys) {
      if (item == key) {
        returnValue = jsonDataObj[item];
        break;
      }
      if (
        jsonDataObj[item] != null &&
        typeof jsonDataObj[item] != 'string' &&
        typeof jsonDataObj[item] != 'number' &&
        typeof jsonDataObj[item] != 'boolean'
      ) {
        let childKeys = Object.keys(jsonDataObj[item]);
        if (childKeys.length > 0) {
          returnValue = this.getVaueByKeyFromJsonData(jsonDataObj[item], key);
          if (returnValue != '') break;
        }
      }
    }
    if (returnValue == undefined) returnValue = '';
    return returnValue;
  }

  labelValueText(data, htmlElement: HTMLElement, options = null) {
    let hasCurrencyData: boolean;
    this.cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    let dateFormat = this.getLocaleDateFormat();
    let currencySymbol = this.getLocaleCurrencySymbol();
    let currencyAmount = data?.value;
    // Data Parsing
    if (data.value && this.globalObjService.fromEnrolmentSummary) {
      (() => {
        let dataValueArray: Array<string>;
        data.value = String(data.value);
        dataValueArray = data.value.split(' ');
        if (dataValueArray.length > 1) {
          currencySymbol = dataValueArray[0] ? dataValueArray[0] : null;
          currencyAmount = dataValueArray[1] ? dataValueArray[1] : null;
          hasCurrencyData = true;
        } else {
          currencyAmount = parseInt(dataValueArray[0]) ? dataValueArray[0] : '';
          data.value = parseInt(data.value) ? data.value : '';
        }
      })()
    }
    if (data.value == 1 && data.trueText) {
      return data.trueText;
    }
    if (data.value == 0 && data.falseText) {
      return data.falseText;
    }
    if (currencySymbol && (options?.showLocaleCurrencySymbol || (hasCurrencyData && this.globalObjService.fromEnrolmentSummary))) {
      // TODO: Need to get the code from the sable data view and we have to switch the below logic to use the formatNumber()
      let formatOptions = { ...this.formatOptions };
      let cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
      currencyAmount = Number(currencyAmount);
      const getFormattedValue = (overrideConcealTheSensitiveData = false) => {
        if (!overrideConcealTheSensitiveData && this.concealTheSensitiveData) {
          currencyAmount = 0;
          formatOptions.minimumFractionDigits = 0;
        }

        let formattedValue;
        if (!hasCurrencyData) {
          formattedValue = this.formatNumber(data.value, this.getCurrencyCode(null), overrideConcealTheSensitiveData);
        } else {
          formatOptions.currencyDisplay = "code";
          formatOptions.currency = currencySymbol;
          formattedValue = formatNumber(currencyAmount, formatOptions, cultureCode);
        }

        if (!overrideConcealTheSensitiveData && this.concealTheSensitiveData) {
          formattedValue = formattedValue.replace("0", "-");
        }

        return formattedValue;
      }

      return (() => {
        if (this.concealTheSensitiveData && this.isElementInsideKendoPDFExport(htmlElement)) {
          return this.getThePreProcessedKendoPDFExportHTMLString(getFormattedValue(), getFormattedValue(true));
        }
  
        return getFormattedValue();
      })()
    }
    if (!hasCurrencyData && this.globalObjService.fromEnrolmentSummary) {
      return data.value;
    }
    if (options?.showPercentageSymbol) {
      return parseFloat(data.value).toFixed(2) + '%';
    }
    if (data.dataFormat == 'Date') {
      let date = data.value.split('/');
      let newDate = Number(date[2]) + '-' + ('0' + Number(date[1])).slice(-2) + '-' + ('0' + Number(date[0])).slice(-2);
      return this.formatDate(newDate, dateFormat);
    }
    return data.value;
  }

  getThePreProcessedKendoPDFExportHTMLString(concealedData: string, unConcealedData: string) {
    return "<span pre-processed-kendo-pdf-export-currency-concealed-content><span class='no-export'>" + concealedData + "</span><span class='hidden-content-wrapper'>" + unConcealedData + "</span></span>"
  }

  ensureCustomAccordion(elementRef: ElementRef): void {
    this.accordionFindingCount += 1;
    if (this.accordionFindingCount <= this.customMaxFindingCount) {
      if (
        elementRef.nativeElement.querySelector('.custom-accordion') == undefined ||
        elementRef.nativeElement.querySelector('.custom-accordion') == null
      ) {
        this.accordionTimeOut = setTimeout(() => {
          this.ensureCustomAccordion(elementRef);
        }, 500);
        return;
      } else {
        const customAccordions = elementRef.nativeElement.querySelectorAll('.custom-accordion');
        customAccordions.forEach(function (accordionItem) {
          this.addEventListenerToCustomAccordion(accordionItem);
        }, this);
      }
    }
  }

  addEventListenerToCustomAccordion(accordionItem: any): void {
    const accordionHeaders = accordionItem.querySelectorAll('.accordion-header');
    accordionHeaders.forEach(function (headerItem) {
      headerItem.querySelector('.header-controls').innerHTML = '<i class="fal fa-plus"></i>';
      headerItem.nextElementSibling.style.display = 'none';
      'keypress click'.split(' ').forEach(function (e) {
        headerItem.addEventListener(e, function (event) {
          const bodyItem: HTMLElement = headerItem.nextElementSibling;
          if (bodyItem.style.display == '' || bodyItem.style.display == 'block') {
            headerItem.classList.remove('expanded');
            headerItem.classList.add('collapsed');
            headerItem.querySelector('.header-controls').innerHTML = '<i class="fal fa-plus"></i>';
            bodyItem.style.display = 'none';
          } else {
            headerItem.classList.remove('collapsed');
            headerItem.classList.add('expanded');
            headerItem.querySelector('.header-controls').innerHTML = '<i class="fal fa-minus"></i>';
            bodyItem.style.display = 'block';
          }
        });
      });
    }, this);
  }

  focusTheFirstInteractiveElement(elementRef: ElementRef): void {
    try {
      this._checkTheAncestorFirstRoleElement(elementRef.nativeElement);
    } catch { }
  }

  _checkTheAncestorFirstRoleElement(element: any): void {
    let roleMainElement: any = element.querySelector('[role="dialog"]') ? element.querySelector('[role="dialog"]') : element.querySelector('[role="main"]');
    if (roleMainElement) {
      roleMainElement.setAttribute('tabindex', '-1');
      roleMainElement.focus();
    } else if (element?.parentElement) {
      this._checkTheAncestorFirstRoleElement(element.parentElement);
    }
  }

  ensureCustomeCollapse(elementRef: ElementRef): void {
    this.collapseFindingCount += 1;
    if (this.collapseFindingCount < this.customMaxFindingCount) {
      if (
        elementRef.nativeElement.querySelector('.custom-collapse') == undefined ||
        elementRef.nativeElement.querySelector('.custom-collapse') == null
      ) {
        setTimeout(() => {
          this.ensureCustomeCollapse(elementRef);
        }, 500);
        return;
      } else {
        this.addEventListenerToCustomCollapse();
      }
    }
  }

  addEventListenerToCustomCollapse(): void {
    const accordionHeaders = document.querySelectorAll('.custom-collapse');
    accordionHeaders.forEach(function (headerItem: HTMLElement) {
      'keypress click'.split(' ').forEach(function (e) {
        headerItem.addEventListener(e, function (event) {
          let targetElementId = headerItem.getAttribute('data-target').replace('#', '');
          let bodyItem: HTMLElement = document.getElementById(targetElementId);
          if (bodyItem.style.display == '' || bodyItem.style.display == 'block') {
            bodyItem.style.display = 'none';
          } else {
            bodyItem.style.display = 'block';
          }
        });
      });
    }, this);
  }

  getCleanedContactNo(originalContactNo: string) {
    if (originalContactNo) {
      return originalContactNo.replace(/\D/g, '');
    }
  }

  removeEmptySpace(data: string) {
    return data.replace(/[^A-Z0-9]/gi, '');
  }

  daysDifference(startDate, endDate): number {
    let diff = endDate.getTime() - startDate.getTime();
    if (diff < 0) {
      return Math.ceil(diff * 0.0000000115740741);
    } else {
      return Math.floor(diff * 0.0000000115740741);
    }
  }

  monthsDifference(startDate, endDate): number {
    let years = this.yearsDifference(startDate, endDate);
    let months = years * 12 + (endDate.getMonth() - startDate.getMonth());
    return months;
  }

  yearsDifference(startDate, endDate): number {
    let yearsDiff = endDate.getFullYear() - startDate.getFullYear();
    return yearsDiff;
  }

  weeksDifference(startDate, endDate): number {
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7;
    return Math.abs(Math.round(diff));
  }

  formatDate(originalDate: string | number | Date, format?: string): string {
    return this.localeService.formatDate(originalDate, format);
  }

  getISODate(date: string): string {
    return date.substring(0, 10);
  }

  getEmployeeBenefitIDs(ebt: EmployeeBenefitsType): number[] {
    let result: number[] = [];
    if (ebt && ebt.employeeBenefitGroups) {
      ebt.employeeBenefitGroups.forEach((x) => {
        x.employeeBenefitsByProducts.forEach((y) => {
          if (y.employeeBenefitTypes) {
            result = result.concat(y.employeeBenefitTypes.map((k) => k.employeeBenefitID));
          }
        });
      });
    }

    //distinct items
    result = result.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return result;
  }

  closeInfo(sectionId: string): void {
    document.getElementById(sectionId).style.display = "none";
  }
  resetCloseInfo(sectionId: string): void {
    if (document.getElementById(sectionId) != null) {
      document.getElementById(sectionId).style.removeProperty("display");

    }
  }

  clearPaymentSessionStorage(keepRemediationData: boolean = false) {
    sessionStorage.removeItem(SessionStorageKey.ReadyToCheckoutPurchases);
    if (!keepRemediationData) {
      sessionStorage.removeItem(SessionStorageKey.RemediationFailedTransaction);
    }
    sessionStorage.removeItem(SessionStorageKey.StartPaymentResult);
    sessionStorage.removeItem(SessionStorageKey.PaymentProviderPaymentTypeRecordID);
    sessionStorage.removeItem(SessionStorageKey.PaymentConfirmData);
    sessionStorage.removeItem(SessionStorageKey.PaymentFailedAlert);
  }
  toCamel(o): any {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(
        function (value) {
          if (typeof value === 'object') {
            value = this.toCamel(value);
          }
          return value;
        }.bind(this)
      );
    } else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];
          if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
            value = this.toCamel(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }
  toPascal(o): any {
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(
        function (value) {
          if (typeof value === 'object') {
            value = this.toPascal(value);
          }
          return value;
        }.bind(this)
      );
    } else {
      newO = {};
      for (origKey in o) {
        if (Object.prototype.hasOwnProperty.call(o, origKey)) {
          newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
          value = o[origKey];
          if (value instanceof Array || (value !== null && value !== undefined && value.constructor === Object)) {
            value = this.toPascal(value);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  capitalize<T>(data: T): RecursiveCapitalize<T> {
    let result = <RecursiveCapitalize<T>>{};
    const capitalizeName = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    };

    for (const p in data) {
      if (data[p] === null) {
        result[capitalizeName(p)] = null;
      } else if (Array.isArray(data[p])) {
        result[capitalizeName(p)] = (data[p] as unknown as []).map((o) => this.capitalize(o));
      } else if (typeof data[p] === 'object') {
        result[capitalizeName(p)] = this.capitalize(data[p]);
      } else {
        result[capitalizeName(p)] = data[p];
      }
    }

    return result;
  }

  uncapitalize<T>(data: T): RecursiveUncapitalize<T> {
    let result = <RecursiveUncapitalize<T>>{};
    const uncapitalizeName = (s) => {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toLowerCase() + s.slice(1);
    };

    for (const p in data) {
      if (data[p] === null) {
        result[uncapitalizeName(p)] = null;
      } else if (Array.isArray(data[p])) {
        result[uncapitalizeName(p)] = (data[p] as unknown as []).map((o) => this.uncapitalize(o));
      } else if (typeof data[p] === 'object') {
        result[uncapitalizeName(p)] = this.uncapitalize(data[p]);
      } else {
        result[uncapitalizeName(p)] = data[p];
      }
    }

    return result;
  }

  unique(value, index, self): boolean {
    return self.indexOf(value) === index;
  }

  compareDateAscending(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  compareDateDescending(date1: Date, date2: Date): number {
    {
      // With Date object we can compare dates them using the >, <, <= or >=.
      // The ==, !=, ===, and !== operators require to use date.getTime(),
      // so we need to create a new instance of Date with 'new Date()'
      let d1 = new Date(date1);
      let d2 = new Date(date2);

      // Check if the dates are equal
      let same = d1.getTime() === d2.getTime();
      if (same) return 0;

      // Check if the first is greater than second
      if (d1 > d2) return -1;

      // Check if the first is less than second
      if (d1 < d2) return 1;
    }
  }

  randomGuid(): string {
    class Guid {
      static newGuid() {
        return 'xxxxxxxx-xxxx-xxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          let r = (Math.random() * 16) | 0,
            v = c == 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      }
    }
    return Guid.newGuid();
  }

  isGuid(guid: string): boolean {
    if (guid) {
      let regex = /^\{?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\}?$/;

      let test = guid.match(regex);
      return test === null ? false : true;
    }

    return false;
  }

  isSameProduct(currentUrl: string, nextUrl: string): boolean {
    let regex = '([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})';
    let nextProductId = '';
    const matchsArray = nextUrl.match(regex);
    if (matchsArray && matchsArray.length > 0) {
      nextProductId = matchsArray[0];
    }
    if (nextProductId == '' || currentUrl.indexOf(nextProductId) == -1) {
      return false;
    } else {
      return true;
    }
  }

  isValidNationalID(nationalID: string, nationalIDValidationKey: string) {
    let isValid = true;
    switch (nationalIDValidationKey) {
      case 'Validation_NationalID_HK':
        isValid = this.isValidHKID(nationalID);
        break;
      case 'Validation_NationalID_TH':
        isValid = this.isValidTHID(nationalID);
        break;
    }
    return isValid;
  }

  isValidHKID(value) {
    if (value.length > 3 && value.substr(value.length - 1, 1) == ')' && value.substr(value.length - 3, 1) == '(') {
      value = value.replace('(', '').replace(')', '');
    }
    let firstD = 0,
      secondD = 0,
      sum = 0;
    let HKIDArray = {
      A: 10,
      B: 11,
      C: 12,
      D: 13,
      E: 14,
      F: 15,
      G: 16,
      H: 17,
      I: 18,
      J: 19,
      K: 20,
      L: 21,
      M: 22,
      N: 23,
      O: 24,
      P: 25,
      Q: 26,
      R: 27,
      S: 28,
      T: 29,
      U: 30,
      V: 31,
      W: 32,
      X: 33,
      Y: 34,
      Z: 35
    };
    let re = /^[A-Za-z]+$/;
    //STEP 1: FIND THE VALUE FOR THE FIRST 2 CHARACTERS OF THE HKID
    if (value.length == 8) {
      if (!re.test(value.substr(0, 1))) return false;
      if (!parseInt(value.substr(1, 6))) return false;
      firstD = typeof HKIDArray[value.substr(0, 1).toUpperCase()] != 'undefined' ? HKIDArray[value.substr(0, 1).toUpperCase()] : 0;
      secondD = value.substr(1, 1);
      sum = (36 * 9) + (firstD * 8) + (secondD * 7); // FIRST 2 DIGITS
    } else if (value.length == 9) {
      if (!re.test(value.substr(0, 2))) return false;
      if (!parseInt(value.substr(2, 6))) return false;
      firstD = typeof HKIDArray[value.substr(0, 1).toUpperCase()] != 'undefined' ? HKIDArray[value.substr(0, 1).toUpperCase()] : 0;
      secondD = typeof HKIDArray[value.substr(1, 1).toUpperCase()] != 'undefined' ? HKIDArray[value.substr(1, 1).toUpperCase()] : 0;
      sum = (firstD * 9) + (secondD * 8); // FIRST 2 DIGITS
    } else {
      return false;
    }

    if (firstD === 0) {
      return false;
    }

    //STEP 2: MULTIPLY THE NEXT 7 DIGITS BY  7 or 6, 5, 4, 3, 2 RESPECTIVELY.
    // NEXT 6 or 7 DIGITS. STARTING FROM THE RIGHT.
    for (let j = 2; j < value.length - 1; j++) {
      sum += value.substring(j, j + 1) * (value.length - j);
    }

    // STEP 3: FIND THE REMAINDER WHEN DIVIDED BY 11.
    let remainder = (11 - (sum % 11)) % 11;

    // IF REMAINDER EQUAL TO 10 THEN CHECK DIGIT is 'A'
    let strRemainder = remainder === 10 ? 'A' : remainder;

    // STEP 4: AS LONG AS THE REMAINDER IS SAME AS LAST DIGIT, IT PASSES THE VALIDATION.
    return strRemainder.toString() === value.substring(value.length - 1, value.length);
  }

  isValidTHID(value) {
    let re = /^[0-9]+$/;
    if (value && value.length == 13) {
      return re.test(value);
    } else {
      return false;
    }
  }

  isExpressCheckoutEnabled(): boolean {
    return this.bootstrapRequestContext.enableExpressCheckout;
  }

  isSingleProductCheckOutEnabled(): boolean {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.enableSingleProductCheckout;
  }

  getCurrencyCode(code: any) {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (uiConfig.uiConfiguration.overrideCurrencySetting || code == "" || code == null || typeof code == 'undefined') {
      return this.AON_LOCALE?.currency?.code;
    } else {
      return code;
    }
  }


  //* sends a request to the specified url from a form. this will change the window location.
  // * @param {string} path the path to send the post request to
  //* @param {object} params the parameters to add to the url
  //* @param {string} [method=post] the method to use on the form

  downloadFile(path, formparam, method = 'post') {

    const userAccessToken = JSON.parse(sessionStorage.getItem('currentUser'));
    formparam.userToken = userAccessToken.access_token;
    const formvalue = JSON.stringify(formparam);
    const params = { downloadModelData: formvalue };
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement('form');
    form.method = method;
    form.action = path;
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
    }
    if (sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) == 'true') {
      form.setAttribute('target', '_self');
    }

    document.body.appendChild(form);
    form.submit();
    form.remove();
  }

  downLoadFileByBase64(response: any) {
    const filename: string = response.document_File_Name;
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:${response.document_MIMEType};headers=Content-Disposition: attachment; filename=${filename};base64,${response.base64}`;
    downloadLink.setAttribute('download', filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }

  isFromAndroidApp() {
    return sessionStorage.getItem(SessionStorageKey.IsFromAndroidApp) == 'true';
  }
  forceProxyForKendoPdfExport() {
    if (this.isFromAndroidApp()) {
      return false;
    }
    return true;
  }

  ShowDataByAmountType(Value, plan: ShowDataByAmountType.plan, line: ShowDataByAmountType.line = undefined): any {
    const currencyCode = this.getCurrencyCode(plan?.currencyCode || line?.currencyCode);
    this.cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    let choice = '';
    let AmountType_RecordID = plan.amountType_RecordID;
    if (AmountType_RecordID != 0) {
      if (AmountType_RecordID == this.AMOUNT_TYPE_PERCENT_SALARY) {
        choice += parseFloat(Value) + '%';
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_CURRENCY) {
        choice += this.formatNumber(Value, currencyCode);
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_VOUCHER) {
        choice += `${Value} ${(plan.amountTypeCaption || plan.currencySymbol)}`;
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_DAYS) {
        choice += (Number(Value) === 1) ? `${Math.round(Value)} ${this.getStringByKey('Aon.Benefit.AmountType.Day')}` : `${Math.round(Value)} ${this.getStringByKey('Aon.Benefit.AmountType.Days')}`;
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_HOURS) {
        choice += (Number(Value) === 1) ? `${Math.round(Value)} ${this.getStringByKey('Aon.Benefit.AmountType.Hour')}` : `${Math.round(Value)} ${this.getStringByKey('Aon.Benefit.AmountType.Hours')}`;
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_VOUCHERS) {
        choice += `${Math.round(Value)} ${plan.amountTypeCaption}`;
      } else if (AmountType_RecordID == this.AMOUNT_TYPE_CUSTOMTYPE) {
        choice += `${Value} ${plan.customAmountTypeValue}`;
      }
    } else {
      choice += this.formatNumber(Value, currencyCode);
    }
    return choice;
  }


  getLocaleCurrencySymbol() {
    return this.AON_LOCALE?.currency?.symbol;
  }

  getLocaleDateFormat() {
    return this.AON_LOCALE?.calendar?.patterns?.d;
  }

  currencyAmountValue(value: any, currencyCode: string, amountType: string = '2', overrideCurrencyFromUIConfigSetting = true, overrideConcealTheSensitiveData = false): string {
    if (amountType == '0') {
      return value;
    }

    if (overrideCurrencyFromUIConfigSetting || currencyCode == "" || currencyCode == null || typeof currencyCode == 'undefined') {
      currencyCode = this.getCurrencyCode(currencyCode);
    }

    if (amountType == '1') {
      return parseFloat(value) + '%';
    }

    if (amountType == '2' || amountType == '3') {
      return this.formatNumber(value, currencyCode, overrideConcealTheSensitiveData);
    }

    if (amountType == '4' || amountType == '5' || amountType == '7' || amountType == '8') {
      const symbol: string = this.globalObjService?.currencies[currencyCode] && this.globalObjService?.currencies[currencyCode] != "" ? this.globalObjService.currencies[currencyCode] : currencyCode;
      return parseFloat(value) + symbol;
    }
  }

  isElementInsideKendoPDFExport(element: HTMLElement): boolean {
    const isElementInsideKendoPDFExport = element?.parentElement?.closest('kendo-pdf-export');
    return isElementInsideKendoPDFExport && isElementInsideKendoPDFExport != null
  }

  getCurrencySymbolBasedOnTheCurrencyCode(currencyCode: string): string {
    let symbol: string = "";
    try {
      const cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
      if (this.globalObjService.currencies[currencyCode]) {
        symbol = this.globalObjService.currencies[currencyCode];
      } else {
        symbol = formatNumber(0, { style: 'currency', currency: currencyCode, currencyDisplay: 'symbol', minimumFractionDigits: 0 }, cultureCode).replace("0", "");
      }
    } catch { }
    return symbol;
  }

  getCurrencySymbol(symbol: string): string {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (uiConfig.uiConfiguration.overrideCurrencySetting || symbol == null || typeof symbol == 'undefined') {
      return this.AON_LOCALE?.currency?.symbol;
    } else {
      return symbol;
    }
  }

  public formatNumber(value, code = "", overrideConcealTheSensitiveData = false): string {
    const cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    let formattedValue: string = value;
    let customCurrencySymbol: string = "";
    try {
      let formatOptions = { ...this.formatOptions };
      let uiConfig = this.uiconfigrationService.getUIConfiguration();
      formatOptions.currency = code;
      formatOptions.currencyDisplay = "symbol";

      if (uiConfig?.uiConfiguration?.overrideCurrencySetting && !uiConfig?.uiConfiguration?.showCurrencyCodeInsteadOfSymbol) {
        customCurrencySymbol = this.getCurrencySymbol(null);
      }

      if (uiConfig?.uiConfiguration?.showCurrencyCodeInsteadOfSymbol) {
        formatOptions.currencyDisplay = "code";
      } else if (this.globalObjService.currencies[code]) {
        customCurrencySymbol = this.globalObjService.currencies[code];
      }
      formatOptions = this.updateFormatOptionsToSupportCustomCurrencyFormats(formatOptions, customCurrencySymbol);
      if (!overrideConcealTheSensitiveData && this.concealTheSensitiveData) {
        value = 0;
        formatOptions.minimumFractionDigits = 0;
        formattedValue = formatNumber(value, formatOptions, cultureCode)?.replace("0", "-");
      } else {
        formattedValue = formatNumber(value, formatOptions, cultureCode);
      }
    } catch { }
    return formattedValue;
  }

  public parseNumberByCulture(value): number {
    const cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    let numberValue = parseNumber(value, cultureCode);
    return numberValue;
  }

  updateFormatOptionsToSupportCustomCurrencyFormats(formatOptions: { style: string, currency: string, currencyDisplay: string }, customCurrencySymbol: string = "") {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (!uiConfig?.uiConfiguration?.showCurrencyCodeInsteadOfSymbol && customCurrencySymbol && customCurrencySymbol != "") {
      formatOptions.currencyDisplay = "code";
      formatOptions.currency = customCurrencySymbol;
    }
    return formatOptions;
  }

  openPop(popover: NgbPopover): void {
    popover.isOpen() ? popover.close() : popover.open();
  }

  public getAonSettings(): Observable<AonSettings> {
    let settings = {
      paymentSetting: this.bootstrapRequestContext.paymentSetting,
      quoteSummaryTemplate: this.bootstrapRequestContext.quoteSummaryTemplate,
      thailandPaymentEnabled: this.bootstrapRequestContext.thailandPaymentEnabled,
      siteType: this.bootstrapRequestContext.siteType,
      allowAnonymousAccess: this.bootstrapRequestContext.allowAnonymousAccess,
      showAonLogo: this.bootstrapRequestContext.showAonLogo,
      showVBBanners: this.bootstrapRequestContext.showVBBanners,
      enableExpressCheckout: this.bootstrapRequestContext.enableExpressCheckout,
      cardStatementDescriptor: this.bootstrapRequestContext.cardStatementDescriptor,
      blockUserIfNoProductsAvailable: this.bootstrapRequestContext.blockUserIfNoProductsAvailable,
      verifyPersonalEmailWithOTP: this.bootstrapRequestContext.verifyPersonalEmailWithOTP,
      enableServiceNow: this.bootstrapRequestContext.enableServiceNow
    } as AonSettings;

    return of(settings);
  }

  public getBanner(type: BannerType, message: string, data: Object, dismissible: boolean = true): Banner {
    let banner = new Banner();
    banner.dismissible = dismissible;
    banner.type = type;
    banner.message = message;
    banner.data = data;

    return banner;
  }

  public getNamedRoute(title: string, path: string, fragment: string = null) {
    let route = new NamedRoute();
    route.path = path;
    route.title = title;
    route.fragment = fragment;

    return route;
  }

  mapSableView(viewId: string, data: any) {
    let dataJSON = '';
    if (typeof data === 'string') {
      dataJSON = data;
    } else {
      dataJSON = JSON.stringify(data);
    }

    let dvi = {
      dataJSON: dataJSON,
      uIKey: viewId,
      recordID: Constants.GuidEmpty,
      errorMessage: null
    } as DataViewInfo;

    let url = 'mapsableview';
    let urlCall = this.apiBaseUrl + url;
    return this.http.post<any>(urlCall, dvi).pipe(
      map((result) => {
        return result;
      })
    );
  }

  getViewConfig(key?: string) {
    const data = {
      GenericDataViewKey: key
    };
    const requestUrl = this.apiBaseUrl + 'sableviewinfo';
    return this.http.post<any>(requestUrl, data);
  }

  getTextValidationPattern1() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.enableInputTextValidation ? uiConfig.uiConfiguration.inputTextRegex1 : '';
  }

  getTextValidationPattern2() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.enableInputTextValidation
      ? uiConfig.uiConfiguration.inputTextRegex2
        ? uiConfig.uiConfiguration.inputTextRegex2
        : uiConfig.uiConfiguration.inputTextRegex1
      : '';
  }

  getPhoneNumberValidationPattern() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.enablePhoneNumberValidation ? uiConfig.uiConfiguration.inputPhoneNumberRegex : '';
  }

  getShowParticipantDemographicForEmployeeSelf() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.showParticipantDemographicForEmployeeSelf;
  }

  getEnforceAtLeastOneEmailAddress() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.enforceAtLeastOneEmailAddress;
  }

  isShoppingCartEnabled(): boolean {
    let headMenuObject = JSON.parse(this.bootstrapRequestContext.mastheadMenu);
    return !!headMenuObject.cart;
  }

  isActiveBenefitsEnabled(): boolean {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    return uiConfig.uiConfiguration.showMyBenefits;
  }

  isEmptyText(text: string) {
    return (text || '').length == 0;
  }

  reverseString(data: string): string {
    return (data || '').split('').reverse().join('');
  }

  extractPreviousPageInfoFromRouter(url: UrlTree, previousPageInfo: PreviousPageURL): void {
    previousPageInfo.fragement = url.fragment;
    previousPageInfo.queryParams = url.queryParams;
    previousPageInfo.rootPath = url.toString().split('#')[0].split('?')[0];
  }

  setPreviousPageUrlAndName(previousPageInfo: PreviousPageURL, products: Product[], resourcestrings: ResourceStringsObject): PreviousPageURL {
    if (previousPageInfo.rootPath) {
      let rootPaths = previousPageInfo.rootPath.substring(this.getBaseUrl().length + 1).split('/');
      let productName = rootPaths[0];

      if (productName === RouteRootPrefix.Quote || productName === RouteRootPrefix.Product) {
        previousPageInfo.previousPageName = products.find(x => x.line_GUID == rootPaths[1]).itemTitle;
      }
      else if (productName === RouteRootPrefix.Profile) {
        previousPageInfo.previousPageName = this.DecideProfilePageTabName(previousPageInfo.fragement, resourcestrings);
      }
      else {
        previousPageInfo.previousPageName = rootPaths.pop();
      }

      sessionStorage.setItem(SessionStorageKey.PreviousPageInfo, JSON.stringify(previousPageInfo));
    }
    else {
      previousPageInfo = JSON.parse(sessionStorage.getItem(SessionStorageKey.PreviousPageInfo));
    }
    return previousPageInfo;
  }

  private DecideProfilePageTabName(fragementName: string, resourcestrings: ResourceStringsObject): string {
    let profilePageTabName = '';

    switch (fragementName) {
      case ProfileTabFragements.AboutMe:
        profilePageTabName = resourcestrings['Aon.Profile.AboutMe'];
        break;

      case ProfileTabFragements.Benefits:
        profilePageTabName = resourcestrings['Aon.Profile.Benefits'];
        break;
      case ProfileTabFragements.LifeEvents:
        profilePageTabName = resourcestrings['Aon.Profile.LifeEvents'];
        break;
      case ProfileTabFragements.SavedQuotes:
        profilePageTabName = resourcestrings['Aon.Profile.SavedQuotes'];
        break;
      case ProfileTabFragements.Account:
        profilePageTabName = resourcestrings['Aon.Profile.Account'];
        break;
      case ProfileTabFragements.DependentsAndBeneficiaries:
        profilePageTabName = resourcestrings['Aon.Profile.DependentsAndBeneficiaries'];
        break;
      case ProfileTabFragements.Documents:
        profilePageTabName = resourcestrings['Aon.Profile.Documents'];
        break;
      case ProfileTabFragements.Payment:
        profilePageTabName = resourcestrings['Aon.Profile.Payment'];
        break;

      default:
        break;
    }
    return profilePageTabName;
  }

  public trackTheReferrerPath(lastActivePath: string, activePath: string): void {
    /**
     * Track Path for the quote flow
     */
    try {
      if (activePath.includes('/quote') && !lastActivePath.includes('/quote') && !sessionStorage.getItem(SessionStorageKey.ReferrerPathForTheQuoteFlow)) {
        if (this.IsVanityURLForMultiTenantEnabled()) {
          if (sessionStorage.getItem('clientCode') != null && sessionStorage.getItem('clientCode') != '') {
            lastActivePath = lastActivePath.replace('/' + sessionStorage.getItem('clientCode'), "");
          }
        }
        sessionStorage.setItem(SessionStorageKey.ReferrerPathForTheQuoteFlow, lastActivePath);
      }
    } catch { }
  }

  public removeTheReferrerPath(activePath: string): void {
    try {
      if (!activePath.includes('/quote')) {
        sessionStorage.removeItem(SessionStorageKey.ReferrerPathForTheQuoteFlow);
      }
    } catch { }
  }

  public routeToReferrer(referrerPathKey: string): void {
    try {
      let path: string = sessionStorage.getItem(referrerPathKey);
      let baseUrl: string = this.getBaseUrl();
      let pathParams;
      if (path && path != '') {
        this.globalObjService.stopBenefitSaveAlertTrigger = true;
        this.globalObjService.suppressNavigation = true;
        if (path.includes('#')) {
          pathParams = path.split("#")
          this.router.navigate([baseUrl + pathParams[0]], { fragment: pathParams[1] });
        } else if (path.includes("?")) {
          const routingPath = path.split("?")[0];
          try {
            let _queryParams: any = {};
            path.split("?")[1].split("&").map(x => x.split("=")).forEach(params => {
              _queryParams[params[0]] = params[1];
            });
            this.router.navigate([baseUrl + routingPath], { queryParams: _queryParams })
          } catch {
            this.router.navigate([baseUrl + routingPath]);
          }
        } else {
          this.router.navigate([baseUrl + path]);
        }
      }
    } catch { }
  }

  public getSafeClassName(inputTitle: string) {
    return inputTitle.replace(/[^a-z0-9]/gi, '-').toLowerCase();
  }

  private scrollToSectionInterval;
  public _scrollIntoView(target): Observable<any> {
    try {
      if (this.scrollToSectionInterval) {
        clearInterval(this.scrollToSectionInterval);
      }
      target.scrollIntoView();
      this.scrollToSectionInterval = setInterval(() => {
        if (this.scrollToSectionInterval) {
          target.scrollIntoView();
        }
      }, 1000);

      return defer(() => new Promise((resolve, reject) => {
        try {
          const scroller = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setTimeout(() => {
                  resolve(true);
                }, 100);
                clearInterval(this.scrollToSectionInterval);
                scroller.unobserve(target);
              }
            });
          });
          scroller.observe(target);
        } catch {
          reject(false);
        }
      }));
    } catch { }
    return of(null);
  }

  public getUTMParameters(): string[] {
    let utm_params = [];
    const urlParams = new URLSearchParams(window.location.search);
    for (const [name, value] of urlParams) {
      if (name.toLowerCase() == 'utm_campaign') { utm_params["utm_campaign"] = value; }
      if (name.toLowerCase() == 'utm_content') { utm_params["utm_content"] = value; }
      if (name.toLowerCase() == 'utm_medium') { utm_params["utm_medium"] = value; }
      if (name.toLowerCase() == 'utm_source') { utm_params["utm_source"] = value; }
      if (name.toLowerCase() == 'utm_term') { utm_params["utm_term"] = value; }
    }
    return utm_params;
  }

  public openExteralLink(externalLinkURL: string) {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (!uiConfig.uiConfiguration.showExternalLinkPopup) {
      this.openNewWindow(externalLinkURL, '_blank');
    } else {
      this.externalLinkURL.next(externalLinkURL);
    }
  }

  public openNewWindow(url: string, target: string, features?: string): void {
    if (sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) == 'true') {
      window.location.href = url;
    }
    else {
      window.open(url, target, features);
    }
  }

  public logoutRedirection(): void {
    const baseUrl: string = this.getBaseUrl();
    let customLoggedOffSettings: CustomLoggedOffSettings = null;
    if (sessionStorage.getItem('CustomLoggedOffSettings')) {
      customLoggedOffSettings = JSON.parse(sessionStorage.getItem('CustomLoggedOffSettings'));
    }
    if (customLoggedOffSettings) {
      if (customLoggedOffSettings.enableCustomLoggedOffPage) {
        this.router.navigate([baseUrl + '/loggedoff'], { queryParams: { pagekey: customLoggedOffSettings.customLoggedOffPagePath } });
      }
      else { // customLoggedOffSettings is available but enableCustomLoggedOffPage is off
        if (!this.AllowAnonymousAccess()) {
          this.narBarCollapseState.next(true);
          const isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
          if (isFromMobileApp) {
            this.router.navigate([baseUrl + '/login'], { queryParams: { timestamp: Date.now() } });
          } else {
            this.router.navigate([baseUrl + '/login']);
          }
        }
        else {
          this.allowAnonymousAccessIsTrue(baseUrl);
        }
      }
    }
    else // when customLoggedOffSettings is not available
    {
      if (!this.AllowAnonymousAccess()) {
        this.narBarCollapseState.next(true);
        const isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';

        let uiConfig = this.uiconfigrationService.getUIConfiguration();
        if (uiConfig?.uiConfiguration?.loginByMitIDOnly) {
          window.location.href = "/SSO/SignOutMitid";
        } else {
          if (isFromMobileApp) {
            this.router.navigate([baseUrl + '/login'], { queryParams: { timestamp: Date.now() } });
          } else {
            this.router.navigate([baseUrl + '/login']);
          }
        }
        this.globalObjService.logoutProfile = true;
      } else {
        this.allowAnonymousAccessIsTrue(baseUrl);
      }
    }
    this.globalObjService.compactEmployeeInformation.next(null);
  }
  allowAnonymousAccessIsTrue(baseUrl) {
    this.router.navigate([baseUrl + '/']);
    this.globalObjService.logoutProfile = true;
    this.globalObjService.showClientLogo.next(false);
  }
  public isExternalUrl(url) {
    let isExternal = false;
    if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
      isExternal = true;
    }
    return isExternal;
  }

  public setCustomLoggedOffSetting() {
    sessionStorage.removeItem('CustomLoggedOffSettings');
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (uiConfig.uiConfiguration.enableCustomLoggedOffPage) {
      const customLoggedOffSettings: CustomLoggedOffSettings = {
        enableCustomLoggedOffPage: uiConfig.uiConfiguration.enableCustomLoggedOffPage,
        customLoggedOffPagePath: uiConfig.uiConfiguration.customLoggedOffPagePath
      };
      sessionStorage.setItem('CustomLoggedOffSettings', JSON.stringify(customLoggedOffSettings));
    }
  }

  checkStatus(participant) {
    participant.isComplete = true;
    participant.correspondenceTypes.forEach((p, index) => {
      if (p.requiredForEnrolment) {
        if (p.documents == null || p.documents.length == 0) {
          participant.isComplete = false;
          this.curActiveId = 'c' + index;
          return participant;
        }
        p.documents.forEach(d => {
          if (d.document_FileName == null || d.document_RecordID == '00000000-0000-0000-0000-000000000000') {
            participant.isComplete = false;

          }
        });
      }
      if (!participant.isComplete) {
        this.curActiveId = 'c' + index;
      }
    });
    participant.isOpen = !participant.isComplete;
    return participant;
  };
  enableNextButton(documentsData: any[]) {
    let documentUploadCompleted = true;
    documentsData.forEach(data => {
      if (!data.isComplete) {
        documentUploadCompleted = false;
      }
    });
    return documentUploadCompleted;
  }

  /**
   * Method to listen the keydown events. If any event activeElement reaches the last focus target element then it would be trapped to the first interactive elemnt in the targetted element to avoid going focus on the background elements.
   */
  trapTheFocusWithinTheTargetedElement(targetElement, event: any): any {
    if (event.key !== 'Tab')
      return;
    let isTabKeyForwardDirection = event.shiftKey === false;
    let isTabKeyReverseDirection = event.shiftKey === true;
    let interactiveElements = targetElement.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
    let filterLastInteractiveElementInTheReverse: Array<any> = Object.values(interactiveElements).filter(el => this.isInViewport(el));
    const firstInteractiveElement = filterLastInteractiveElementInTheReverse[0];
    const lastInteractiveElement = filterLastInteractiveElementInTheReverse[filterLastInteractiveElementInTheReverse.length - 1];
    const isFocusTrapInTheTargetedElement = filterLastInteractiveElementInTheReverse?.includes(document.activeElement);
    if (isTabKeyForwardDirection) {
      if (lastInteractiveElement == document.activeElement || !isFocusTrapInTheTargetedElement) {
        event.preventDefault();
        firstInteractiveElement?.focus();
      }
    } else if (isTabKeyReverseDirection) {
      if (firstInteractiveElement == document.activeElement || !isFocusTrapInTheTargetedElement) {
        event.preventDefault();
        lastInteractiveElement?.focus();
      }
    }
  }

  public isQuoteRoute() {
    let clientCode = this.getClientCode();
    let prefix = '';
    if (clientCode && clientCode != "null") {
      prefix = `/${clientCode}`;
    }

    let result = this.router.url.startsWith(prefix + '/quote');
    return result;
  }

  public isBenefitRoute() {
    let clientCode = this.getClientCode();
    let prefix = '';
    if (clientCode && clientCode != "null") {
      prefix = `/${clientCode}`;
    }

    let result = this.router.url.startsWith(prefix + '/benefit');
    return result;
  }

  public isStackedLine(productId) {
    let isStackedLine = false;
    if (sessionStorage.getItem('IsStackedLine.' + productId) != null) {
      isStackedLine = sessionStorage.getItem('IsStackedLine.' + productId) === Constants.True;
    }
    return isStackedLine;
  }

  public useAmountForDisplay(productId) {
    let useAmountForDisplay = false;
    if (sessionStorage.getItem('UseAmountForDisplay.' + productId) != null) {
      useAmountForDisplay = sessionStorage.getItem('UseAmountForDisplay.' + productId) === Constants.True;
    }
    return useAmountForDisplay;
  }

  public isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return rect.top > 0 || rect.left > 0 || rect.bottom > 0 || rect.right > 0;
  }

  public getPrivacyPolicyUrl() {
    let uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (uiConfig.uiConfiguration.customPrivacyPolicyTargetURL) {
      return uiConfig.uiConfiguration.customPrivacyPolicyTargetURL;
    } else {
      const baseUrl = this.getBaseUrl();
      const privacyPolicyUrl = window.location.origin + baseUrl + '/home/privacy-policy';
      return privacyPolicyUrl;
    }
  }

  public splashPageCheckAndNav() {
    if (this.isLoggedOn()) {
      //Getting User Type
      let jwtHelper = new JwtHelper();
      let user = JSON.parse(sessionStorage.getItem('currentUser'));
      let isImpersonation = jwtHelper.decodeToken(user.id_token).IsImpersonation;
      //Checking if Splash Page is Enable from UI Config
      let showSplashPage = this.uiconfigrationService.getUIConfiguration()?.uiConfiguration?.showSplashPage;
      if (showSplashPage && isImpersonation == 'False') {
        this.router.navigate([this.getBaseUrl() + '/splashscreen']);
      }
    }
  }

  public consentIsRequired(dOB, relationship_Object) {

    if (dOB != null && dOB != "" && relationship_Object != null) {
      const ConsentRequired = relationship_Object.displayConsentRequired;
      const ConsentAge_Years = relationship_Object.consentAge_Years;
      const ConsentAge_Months = relationship_Object.consentAge_Months;
      if (ConsentRequired && (ConsentAge_Years >= 0 && ConsentAge_Months >= 0)) {
        const dob = new Date(dOB);
        dob.setFullYear(dob.getFullYear() + ConsentAge_Years);
        dob.setMonth(dob.getMonth() + ConsentAge_Months);
        dob.setHours(0, 0, 0, 0);
        const now = new Date();
        now.setHours(0, 0, 0, 0)
        if (dob >= now) {
          return true;
        }
        else {
          return false;
        }
      }
      else {

        return false;
      }
    }
    else {

      return false;
    }
  }

  /**
   * Kendo PDF Currency symbol issues fix by returning the supported classes to override the font family
   */
  private cacheTheUnicodeClassesToAvoidIteration: { [key: string]: string } = {};
  public getPdfUnicodeCharacterOverrideClasses(inputString: string): string {
    if (this.cacheTheUnicodeClassesToAvoidIteration[inputString]) {
      return this.cacheTheUnicodeClassesToAvoidIteration[inputString];
    }

    let overridePdfFontClasses = "";
    const unicodeNumber = [3647];
    inputString?.split("")?.forEach(x => {
      if (unicodeNumber.includes(x?.charCodeAt(0))) {
        overridePdfFontClasses = "pdf-currency-font pdf-font-" + x.charCodeAt(0);
      }
    })
    this.cacheTheUnicodeClassesToAvoidIteration[inputString] = overridePdfFontClasses;
    return overridePdfFontClasses;
  }

  public get showSensitiveDataScreeningControlSection(): boolean {
    return this.uiconfigrationService.getUIConfiguration()?.uiConfiguration?.enableSensitiveDataScreeningControl ?? false;
  }

  public get SensitiveDataScreeningEnabled(): boolean {
    try {
      if (JSON.parse(sessionStorage.getItem('Id_CustomSettings')).SensitiveDataScreeningEnabled) {
        return true;
      }
    } catch { }
    return false;
  }

  public get concealTheSensitiveData(): boolean {
    if (this.isQuoteRoute()) {
      return false;
    }

    return this.SensitiveDataScreeningEnabled && this.showSensitiveDataScreeningControlSection;
  }

  public clearBrowserHistory() {
    history.pushState(null, '');
    fromEvent(window, 'popstate').subscribe(() => {
      history.pushState(null, '');
    });
  }

  public createSableDataConfig(mergeData: any): CustomSableDataViewConfig {
    let customSableDataViewConfig: CustomSableDataViewConfig = new CustomSableDataViewConfig();
    Object.assign(customSableDataViewConfig, mergeData);
    return customSableDataViewConfig;
  }

  public get breadcrumbPreviousPageNavigationPath(): string {
    if (this.router.getCurrentNavigation()?.previousNavigation) {
      return this.router.getCurrentNavigation().previousNavigation.finalUrl.toString().split('#')[0].split('?')[0];
    }

    if (this.navigationUrlPaths.previousURL != "") {
      return this.navigationUrlPaths.previousURL;
    }

    if (document.referrer?.includes(this.getBaseUrl()) && !window.location.href?.includes(document.referrer)) {
      const urlPrefix = window.location.protocol + '//' + window.location.host + this.getBaseUrl();
      return document.referrer.replace(urlPrefix, "");
    }

    return "/";
  }

  public isContentOverflowHidden(content: HTMLElement, defaultHeightDiff = 1): boolean { return (content.scrollHeight - content.clientHeight) > defaultHeightDiff || (content.scrollWidth - content.clientWidth) > defaultHeightDiff }
}
