import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SableDataViewControl, SableDataViewBuilderService } from '@aon/sable-dataview';
import { NgbAccordionDirective } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from 'src/app/shared/services/helper.service';
import { AppFlowContainerDirective } from '../../app-flow-container.directive';


@Component({
  selector: 'lib-dataview-accordion',
  templateUrl: './sable-dataview-accordion.component.html'
})
export class SableDataViewAccordionComponent implements SableDataViewControl {
  @ViewChild(AppFlowContainerDirective, { static: false }) controlHost: AppFlowContainerDirective;
  @ViewChild(NgbAccordionDirective) private accordion: NgbAccordionDirective;
  openById = {};
  relatedDataLinkInfo: any = {
    status: false,
    data: {}
  }
  pdfUnicodeCharacterOverrideClasses: string = "";
  Accordion(event) {
    this.openById[event] = this.accordion.isExpanded(event);
    this.bindConfigData(this.data)
  }
  @Input() data: any;
  htmlElement: HTMLElement;

  constructor(private viewBuilder: SableDataViewBuilderService,
    public helperService: HelperService, readonly elementRef: ElementRef) {
    this.htmlElement = elementRef.nativeElement;
  }


  ngOnInit() {
    this.data.ID = new Date().getMilliseconds();
    if (this.data.displayType == 'panel') {
      this.bindConfigData(this.data)
    }
    this.checkRelatedDataLink();
    this.pdfUnicodeCharacterOverrideClasses = this.helperService.getPdfUnicodeCharacterOverrideClasses(this.helperService.labelValueText(this.data, this.htmlElement));
  }

  ngOnDestroy() {

  }
  bindConfigData(config) {
    setTimeout(() => {
      const vcRef = this.controlHost?.viewContainerRef;
      if (vcRef) { this.viewBuilder.buildView(vcRef, config) }
    });
  }

  /**
   * Check for the related data link
   */
  checkRelatedDataLink(): void {
    if (this.data?.items?.length > 0) {
      this.data.items.forEach((item: any) => {
        if (item?.relatedDataLinkPointer != "" && item?.relatedDataLinkTitle != "") {
          this.relatedDataLinkInfo.status = true;
          this.relatedDataLinkInfo.data = {
            relatedDataLinkIcon: item.relatedDataLinkIcon,
            relatedDataLinkPointer: item.relatedDataLinkPointer,
            relatedDataLinkTitle: item.relatedDataLinkTitle
          };
        }
      })
    }
  }

  // ngAfterViewInit(): void {
  //   this.accordion.expand('p'+this.data.ID);
  // }

}
