<div class="benefit-save-alert-modal">
  <div class="modal-header">
    <h3 class="modal-title theme-color" tabindex="0"
      [innerHTML]="resourceStrings['Aon.DeselectPopup.Title'] | textTransform : {BenefitName:BenefitName}| safeHtml">
    </h3>
    <button type="button" class="close" tabindex="1"
      [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']" (click)="close()">
      <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
  </div>
  <div class="modal-body p-b-20">
    <div [innerHTML]="resourceStrings['Aon.DeselectPopup.Description'] | safeHtml"></div>
  </div>
  <div class="button-sleeve">
    <button (click)="save()" class="prominent"
      [innerHTML]="resourceStrings['Aon.Common.YesDeselect'] | safeHtml"></button>
    <button (click)="close()" class="ghost" [innerHTML]="resourceStrings['Aon.Common.NoDeselect'] | safeHtml"></button>
  </div>
</div>