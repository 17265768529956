<div class="doc-upload-outer-sleeve">
  <div class="modal-header">
    <div>
      <h1 id="docs-modal-title" [innerHTML]="resourceStrings['Aon.UploadDocument.AlmostDone']"></h1>
      <p id="docs-modal-intro" [innerHTML]="resourceStrings['Aon.UploadDocument.AlmostDoneDesc']"></p>
    </div>
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']"
      (click)="activeModal.close()">
      <span aria-hidden="true"><i class="fal fa-times" aria-hidden="true"></i></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="basic-card edit-state checkout-upload">
      <h2 class="m-b-16 h3">{{resourceStrings['AON.UploadDocument.Checkout.Instructions']}}</h2>
      <p class="strong m-b-8">{{resourceStrings['AON.UploadDocument.Options']}}</p>
      <div class="m-b-20" [innerHTML]="resourceStrings['Aon.UploadDocument.Options.Des']"></div>

      <p class="strong m-b-8">{{resourceStrings['AON.UploadDocument.Checkout.Desc']}}</p>
      <p class="m-b-20" [innerHTML]="resourceStrings['Aon.UploadDocument.Formats']"></p>
      <p class="m-b-20" [innerHTML]="resourceStrings['AON.UploadDocument.FileSizeError']"></p>
      <div *ngIf="validateError" class="basic-content-panel-error m-b-40">
        <i class="fas fa-exclamation-triangle"
          aria-hidden="true"></i><span>{{resourceStrings['AON.UploadDocument.SaveError']}}</span>
      </div>
      <p class="sm m-b-28" [innerHTML]="resourceStrings['Aon.UploadDocument.Checkout.Instructions.Des']"></p>

      <h3>{{resourceStrings['AON.UploadDocument.DocumentsNeeded']}}</h3>
      <div class="document-collapse-section">
        <div class="divider m-b-20"></div>
        <div *ngFor="let participant of documentsData; let i = index;">
          <div class="d-flex m-b-20 align-items-center" [ngClass]="{'m-t-20' : i!==0 }">
            <span class="h5 m-b-0"> {{ participant.firstName + ' '+ participant.lastName + ' '+ '(' +
              (participant.relationship_RecordID == null? resourceStrings['Aon.Profile.Me'] :
              participant.relationshipType_Name) +')' }}</span>
            <span class="m-t-0 m-b-0 strong tag green-tag-wrapper"
              *ngIf="participant.isComplete">{{resourceStrings['Aon.UnderWriting.Complete']}}</span>
            <span class="m-t-0 m-b-0 strong tag red-tag-wrapper"
              *ngIf="!participant.isComplete">{{resourceStrings['Aon.Profile.Incomplete']}}</span>
          </div>
          <div *ngIf="participant.correspondenceTypes != null && participant.correspondenceTypes.length > 0">
            <app-participant-documents [index]="i" [participantData]="participant.correspondenceTypes"
              [participantId]="participant.employeeRelationship_RecordID"
              (updateParticipant)="updateParticipant($event)"></app-participant-documents>
          </div>
          <div class="divider m-t-20"></div>
        </div>

      </div>
    </div>
    <div class="button-sleeve m-t-32">
      <button type="button" (click)="save()" [disabled]="!enableNextButton()" class="prominent">
        <ng-container [ngSwitch]="_triggeredFrom">
          <ng-container *ngSwitchCase="'app-benefit-details'">{{resourceStrings['Aon.Common.Continue']}}</ng-container>
          <ng-container
            *ngSwitchDefault>{{resourceStrings['Aon.UploadDocument.ContinueToTermsAndConditions']}}</ng-container>
        </ng-container>
      </button>
      <button type="button" class="ghost" (click)="cancel()">{{resourceStrings['Aon.Common.Cancel']}}</button>
    </div>
  </div>
</div>