import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionStorageKey } from 'src/app/shared/models/constants';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styles: [
  ]
})
export class CallbackComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  isFromBrowseBenefits: boolean = false;
  interactionIncomplete: boolean = false;
  lineObject: any;
  constructor(private injector: Injector,
    private route: ActivatedRoute,
    private globalObjectsService: GlobalObjectsService,
    private router: Router) {
    super(injector);
    if (window.opener && window.opener != null && !window.opener.closed) {
      let fullUrl = this.baseUrl + '/callback'
      window.opener.location.href = fullUrl;
      window.close();
    }
    if (!sessionStorage.getItem('currentUser')) {
      this.router.navigate([this.baseUrl + '/login']);
    }
  }

  ngOnInit(): void {
    if (sessionStorage.getItem(SessionStorageKey.CallbackObject)) {
      sessionStorage.setItem(SessionStorageKey.InTwoWaySSOProcess, 'true');
      this.route.queryParams.subscribe(queryParams => {
        this.interactionIncomplete = (queryParams['InteractionIncomplete'] == 'true');
        if (this.interactionIncomplete) {
          sessionStorage.setItem(SessionStorageKey.SsoInteractionIncomplete, 'true');
        }
        else {
          sessionStorage.setItem(SessionStorageKey.SsoInteractionIncomplete, 'false');
        }
      });

      let callbackObjectStr = sessionStorage.getItem(SessionStorageKey.CallbackObject);
      if (sessionStorage.getItem('isFromBrowseBenefits') == 'true') {
        let ossoState = JSON.parse(callbackObjectStr);
        window.location.href = ossoState.locationHref;
      }
      else {
        this.setupSessions(callbackObjectStr);
        this.goToSableFlowFn(this.lineObject);
      }

    }
    else {
      this.router.navigate([this.baseUrl + '/home']);
    }
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

  private setupSessions(callbackObjectStr: string): void {
    let ossoState = JSON.parse(callbackObjectStr);
    if (ossoState.activeEventIdInMatrix) {
      sessionStorage.setItem('activeEventIdInMatrix', ossoState.activeEventIdInMatrix);
    }
    if (ossoState.activeEventFlowId) {
      sessionStorage.setItem('activeEventFlowId', ossoState.activeEventFlowId);
    }
    if (ossoState.activeSableContext) {
      sessionStorage.setItem('activeSableContext', JSON.stringify(ossoState.activeSableContext));
    }
    if (ossoState.additionalContextData) {
      sessionStorage.setItem('additionalContextData', JSON.stringify(ossoState.additionalContextData));
    }
    if (ossoState.activeEventSubInstanceId) {
      sessionStorage.setItem('activeEventSubInstanceId', ossoState.activeEventSubInstanceId);
    }
    if (ossoState.activeEvent) {
      sessionStorage.setItem(SessionStorageKey.ActiveEvent, JSON.stringify(ossoState.activeEvent));
    }
    sessionStorage.setItem('isFromBrowseBenefits', JSON.stringify(ossoState.isFromBrowseBenefits));
    this.lineObject = JSON.parse(JSON.stringify(ossoState.benefitFlowObject));
  }

  private goToSableFlowFn(line) {
    sessionStorage.setItem(SessionStorageKey.InTwoWaySSOProcess, 'true');
    this.goToSableFlow(line);
  }

  private goToSableFlow(line: any): void {
    this.setSubFlowSessions(line);
    this.globalObjectsService.goToInstruction = { "SableInstance_RecordID": sessionStorage.getItem('activeEventSubInstanceId') };
    this.globalObjectsService.flowInTwoWaySsoProcess = true;
    this.router.navigate([this.baseUrl + '/quote/eventflow-' + sessionStorage.getItem('activeEventFlowId') + '/details/' + sessionStorage.getItem('activeEventSubInstanceId')]);
  }

  private setSubFlowSessions(line: any) {
    if (line.sableFlow_RecordID) {
      sessionStorage.setItem('activeEventSubFlowId', line.sableFlow_RecordID);
    }
    if (line.entityInformationType?.sableInstance_RecordID || line.sableInstance_RecordID) {
      sessionStorage.setItem('activeEventSubInstanceId', (line.entityInformationType?.sableInstance_RecordID || line.sableInstance_RecordID));
    }
  }
}
