<table class="sable-table {{options.cssClass}}" aria-label="">
  <thead class="sable-thead">
    <ng-container *ngFor="let row of options.items">
      <tr class="sable-header-row {{row.cssClass}}" *ngIf="row.displayType=='tableHeader'">
        <th class="sable-header-cell {{row.cssClass}}" scope="col" role="columnheader"
          *ngIf="(row.label != null && row.label != '')">{{row.label}}</th>
        <ng-container *ngFor="let item of row.items">
          <th class="{{item.cssClass}}" scope="col" role="columnheader">
            {{item.label}}
            <div class="{{item.footerCssClass}}" *ngIf="item.footerContent!=null || item.footerCssClass != null">
              {{item.footerContent}}
            </div>
          </th>
        </ng-container>
      </tr>
    </ng-container>
  </thead>

  <tbody class="sable-tbody">
    <ng-container *ngFor="let row of options.items">
      <tr *ngIf="row.displayType=='tableRow' && row.items != null && row.items.length > 0"
        class="sable-body-row {{row.cssClass}}">
        <th class="sable-header-cell {{row.cssClass}}"
          *ngIf="(row.label != null && row.label != '') && (row.title == null || row.title == '' )"
          [class.with-desc]="row.title != null && row.title != ''" [title]="row.title" scope="row">{{row.label}}</th>
        <th class="sable-header-cell {{row.cssClass}}"
          *ngIf="(row.label != null && row.label != '') && (row.title != null && row.title != '')"
          [class.with-desc]="row.title != null && row.title != ''"
          [ngClass]="{'tooltip': row.title != null && row.title != ''}" scope="row">
          {{row.label}}
          <span class="tooltiptext" *ngIf="row.title != null && row.title != ''">{{row.title}}</span>
        </th>
        <ng-container *ngFor="let item of row.items">
          <td class="{{item.cssClass}}" [attr.aria-label]="item.css_aria_label">
            {{item.label}}
            <div class="{{item.footerCssClass}}"
              *ngIf="(item.footerContent!=null && item.footerContent != '') && item.footerCssClass != null">
              {{item.footerContent}}
            </div>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>
</table>