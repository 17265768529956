<div class="center">
  <div class="modal-header">
    <button type="button" class="close" [attr.aria-label]="resourceStrings['Aon.Modal.CloseButton.AriaLabel']" (click)="close()">
      <span aria-hidden="true"><i class="fal fa-times"></i></span>
    </button>
  </div>
  <div class="modal-body p-b-20 text-center">
    <h1 class="h3" [innerHTML]="resourceStrings['Aon.ScanQRCode.Title'] | safeHtml"></h1>
    <a href="{{value}}"><kendo-qrcode value="{{value}}"></kendo-qrcode></a>
  </div>
</div>
