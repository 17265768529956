import { Component, Injector, Input, OnInit } from '@angular/core';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent'
import { takeUntil } from 'rxjs';


@Component({
  selector: 'app-fund-allocation-display',
  templateUrl: './fund-allocation-display.component.html',
})

export class FundAllocationDisplayComponent extends TBSBaseComponent implements OnInit {
  @Input()  EmployeeElectionFundAllocation: any;
  constructor(
    private readonly injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Funds.AllocatedFunds'
    ]);
  }
  ngOnInit(): void {
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        this.getResouceStringsAsObject(res);
      });
    this.EmployeeElectionFundAllocation = this.helperService.toPascal(this.EmployeeElectionFundAllocation);
  }

  
  getDisplayedList() {
    
    if (this.EmployeeElectionFundAllocation?.length)
      return this.EmployeeElectionFundAllocation.map((list) => list.Name + ": " + list.AllocationPercentage +"%").join(", ");
    return "";
  }
}
