import { SableDataViewConfig, SableDataViewItemList } from "@aon/sable-dataview";
import { SummaryModel } from "../../modules/quote/quote.model";
import { SableSummary } from "./benefitReview.model";

export class SableActivity {
  recordID: string;
  activityData: string;
  name: string;
  title: string;
  step: number;
  sableInstance_RecordID: string;
  sableActivity_RecordID: string;
  sableStep_RecordID: string | null;
  includeInSummary: boolean;
  displayHeader: string;
  displayDescription: string;
  summaryUIKey: string;
  isLastStep: boolean;
  componentAdapterName: string;
  hideNextStep: boolean;
}

export class SableFlow {
  recordID: string;
  name: string;
  key: string;
}

export class SableResult {
  liveActivity: SableActivity;
  error: string;
}

export class SableForm {
  recordID: string;
  name: string;
  description: string;
  template_RecordID: string;
  template: any;
}

export interface DataViewInfo {
  dataJSON: string;
  uIKey: string;
  recordID: string;
  errorMessage: string;
}

export interface SableSummaryGroup {
  recordID: string;
  sableSummaries: SableSummary[];
}

export interface SableSummaryModelGroup {
  recordID: string;
  summarylist: SummaryModel[]
};
export interface SableFormField {
  fieldPath: string;
  info: string;
  postText: string;
  preText: string;
}

export class CustomSableDataViewConfig implements SableDataViewConfig {
  name: string;
  sableViewInstanceKey: string;
  items: SableDataViewItemList[];
  GenericDataView_RecordID?: string;
}

export enum LookupSableActivityType {
  TermAction = 1,
  QualifyingQuestionsAction = 2,
  CoverageAction = 3,
  EOIQuestionsAction = 4,
  EOIResultsAction = 5,
  FollowUpAction = 6,
  ReviewPurchase = 7,
  AddOnAction = 8,
  EmployeeFormAction = 60,
  DataExchangeAction = 61,
  DataViewAction = 62,
  AuthenticationAction = 63,
  StartPurchaseAction = 64,
  KenticoContentAction = 65,
  SableFormStartEntity = 66,
  SableFormEndEntity = 67,
  SableFormViewAction = 68,
  EndPurchaseAction = 69,
  SableEntityIntersectAction = 70,
  ParticipantAction = 9,
  BeneficiariesAction = 10,
  ErrorAction = 99,
  EnrolmentEvent = 100,
  EnrolmentEventReview = 101,
  RegisterDependants = 102,
  StartEvent = 103,
  EndFlowAction = 104,
  StartAnytimeEventAction = 109,
  DocumentUpload = 110,
  ClaimEmployeeSpendingAccount = 111,
  EntityDocument = 112,
  ClaimDetails = 113,
  ClaimReceiptBreakdown = 114,
  ClaimReview = 115,
  ParticipantAdditionalDataActivity = 116,
  SSOCoverageActivity = 201,
  LifeStyleSelect = 106,
  LifeStyleChange = 107,
  LifeStyleReview = 108,
  ManualPaymentAllocationActivity = 204
}