import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SableDataViewStateService } from '@aon/sable-dataview';
import { HelperService } from "src/app/shared/services/helper.service";
import { ChartComponent, SeriesItemComponent, SeriesType, AxisLabelContentArgs } from '@progress/kendo-angular-charts';
import { fromEvent, Observable, Subscription } from 'rxjs';
@Component({
  selector: 'app-sable-dataview-chart',
  templateUrl: './sable-dataview-chart.component.html'
})
export class SableDataviewChartComponent implements OnInit {
  @Input() data: any;

  options: any;
  charttypes = {
    Pie: "Pie",
    Donut: "Donut",
    Column: "Column",
    Bar: "Bar",
    StackedBar: "StackedBar",
    DonutPie: "Donut Pie Chart",
    DonutNew: "Donut Pie Chart New",
    SingleLineBar: "Single Line Bar",
    ConcentricCircles: "Concentric Circles",
    StackedBarMultiColumns: 'StackedBar Multiple Columns'
  };
  currencySymbol: string
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  chartType: SeriesType;
  displayTotalRewardPercentage: boolean;
  displayTotalRewardPercentageAndCurrency: boolean;
  showTRSImage: boolean;
  showLegends: boolean;
  displayTotalOnTab: boolean;
  summaryTotal: string;
  summaryTotalKey: string;
  animateChart = true;
  showLegendsBelowTheChart: boolean = false;
  holeSize: number = 90;
  cultureCode: string;
  @ViewChild("chart")
  public chart: ChartComponent;
  @ViewChild("series")
  public series: SeriesItemComponent;
  constructor(private viewState: SableDataViewStateService,
    private helperService: HelperService
  ) {

  }
  ngOnInit(): void {
    this.cultureCode = JSON.parse(localStorage.getItem('SelectedLanguage'))['cultureCode'];
    this.summaryTotalKey = this.data.summaryTotalKey;
    this.summaryTotal = this.data.summaryTotal;
    this.displayTotalOnTab = this.data.displayTotalOnTab !== undefined ? this.data.displayTotalOnTab : true;
    this.displayTotalRewardPercentage = this.data.displayTotalRewardPercentage;
    this.displayTotalRewardPercentageAndCurrency = this.data.displayTotalRewardPercentageAndCurrency;
    this.showLegends = this.data.showLegends;
    this.chartType = this.getCurrentPageType(this.data.chartType);
    this.currencySymbol = this.helperService.getLocaleCurrencySymbol();
    this.showTRSImage = this.data.showTRSImage;
    this.showLegendsBelowTheChart = this.data?.showLegendsBelowTheChart ? true : false;
    this.data.items.forEach(item => {
      item.displayTotalRewardPercentage = this.displayTotalRewardPercentage
      item.displayTotalRewardPercentageAndCurrency = this.displayTotalRewardPercentageAndCurrency
      item.currencySymbol = this.currencySymbol;
      item.active = true
    });
    this.options = this.data;
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      const w = event.target as Window;
      this.setHoleSize(w.innerWidth);
    })
  }
  ngOnDestroy(): void {
  }

  axisLabelContent = (e: AxisLabelContentArgs): string => {
    let returnString: Array<string> = [];
    let limitStringSize = 13;
    let returnStringIndex: number = 0;
    e.value.split(" ").forEach((text: string) => {
      returnString[returnStringIndex] = returnString[returnStringIndex] ? returnString[returnStringIndex] : ""
      text = text.match(new RegExp(".{1," + limitStringSize + "}", 'g')).join(" \n ")

      if ((returnString[returnStringIndex] + text.split("\n")[0]).length <= limitStringSize)
        returnString[returnStringIndex] += text + " "
      else {
        returnStringIndex++
        returnString[returnStringIndex] = "\n" + text + " "
      }
    })

    return returnString.join(" ").trim();
  };

  getItemColor(e): string {
    return e.dataItem.color;
  }
  public toggleSeries(item: any, index: number): void {
    this.animateChart = false;
    this.series.togglePointVisibility(index);
    item.active = !item.active;
  }
  public toggleSeriesHighlight(value: boolean, category: any): void {
    this.chart.toggleHighlight(value, p => p.category === category);
  }
  getCurrentPageType(type: string): SeriesType {
    let currentChartype: SeriesType;
    switch (type) {
      case this.charttypes.Pie:
        currentChartype = 'pie';
        break;
      case this.charttypes.Donut:
        currentChartype = 'donut';
        break;
      case this.charttypes.Column:
        currentChartype = 'column';
        break;
      case this.charttypes.Bar:
        currentChartype = 'column';
        break;
      case this.charttypes.DonutPie:
        currentChartype = 'donut';
        break;
      case this.charttypes.DonutNew:
        currentChartype = 'donut';
        break;
      case this.charttypes.SingleLineBar:
        currentChartype = 'bar';
        break;
      case this.charttypes.ConcentricCircles:
        currentChartype = 'pie';
        break;
      case this.charttypes.StackedBar:
        currentChartype = 'column';
        break;
      case this.charttypes.StackedBarMultiColumns:
        currentChartype = 'column';
        break;
      default:
    }
    if (!currentChartype) {
      currentChartype = 'pie';
    }
    return currentChartype;
  }
  getFormattedValue(value) {
    let percentageFormat: string = (parseFloat(value) / Number(this.summaryTotal) * 100).toFixed(2) + '%';
    let currencyFormat: string = this.helperService.currencyAmountValue(value, this.helperService.getCurrencyCode(null), '2');
    if (this.displayTotalRewardPercentageAndCurrency) {
      if (this.displayTotalOnTab) {
        return currencyFormat + ' | ' + percentageFormat;
      } else {
        return currencyFormat;
      }
    }
    if (this.displayTotalRewardPercentage && this.displayTotalOnTab) {
      return percentageFormat;
    } else {
      return currencyFormat;
    }
  }
  setHoleSize(width): void {
    if (width < 576) { this.holeSize = 100 }
    else if (width < 1200 && width >= 992) { this.holeSize = 120 }
    else if (width < 992 && width >= 576) { this.holeSize = 120 }
    else { this.holeSize = 90 }
  }
}
