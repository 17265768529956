<div class="sable-label-pair  d-flex  m-b-2 {{data.cssClass}}"
  *ngIf="data.label || helperService.labelValueText(this.data, htmlElement, this.options)">
  <!-- If Both Label & Title -->
  <div class="icon-col"></div>
  <div class="d-flex sable-label-value-sleeve">
    <p class="sable-label d-flex m-r-5" [class.with-desc]="data.title != null && data.title != ''"
      *ngIf="data.label && data.title">
      <span class="m-b-0 m-r-8" [innerHTML]="data.label"></span>
      <ng-template #popContent>
        <div [innerHTML]="data.title | safeHtml"></div>
      </ng-template>
      <i class="fal fa-info-circle focus-visibility lg" (keyup.enter)="openTooltip(popover)" container="body"
        tabindex="0" placement="bottom" #popover="ngbPopover" popoverClass="popover-gray" [ngbPopover]="popContent"
        [attr.aria-label]="(data.label | striphtml) +' '+ resourceStrings['Aon.Arialabel.Info']" role="button"></i>
    </p>
    <!-- If Label & Not Title -->
    <p class="sable-label d-flex m-r-5" *ngIf="data.label && !data.title"
      [class.with-desc]="data.title != null && data.title != ''" [innerHTML]="data.label"></p>
    <p class="sable-value d-flex m-r-5"
      *ngIf="helperService.labelValueText(this.data, htmlElement, this.options) !== ''">
      <span
        class="sable-label-value-item {{pdfUnicodeCharacterOverrideClasses}}">{{helperService.labelValueText(this.data,
        htmlElement, this.options)}}</span>
    </p>
  </div>
</div>