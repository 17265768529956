<div class="vb-global-error-page">
  <div class="row error-data">
    <div class="section-1">
      <img src="../../../assets/images/vb-global-error-page/pebbles7.svg" alt="" />
    </div>
    <div class="col-md-6 section-2 text-center">
      <img class="m-b-20" src="../../../assets/images/vb-global-error-page/Error_Icon.svg" alt="" />
      <h2>{{resourceStrings['Aon.Common.GlobalError']}}</h2>
      <p class="xl m-b-20">{{resourceStrings['Aon.Product.Unavailable']}}</p>
    </div>
    <div class="section-3">
      <img src="../../../assets/images/vb-global-error-page/pebbles8.svg" alt="" />
    </div>
  </div>
</div>