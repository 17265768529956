import { Component, OnInit, Injector, Input, Output, EventEmitter } from '@angular/core';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { CategoryType } from 'src/app/shared/models/categoryType.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-masthead-products-v2',
  templateUrl: './masthead-products-v2.component.html'
})
export class MastheadProductsV2Component extends TBSBaseComponent implements OnInit {
  @Input() categories: CategoryType[];
  @Input() resourcestrings;
  @Output('hideProductPopUp') hideProductPopUp: EventEmitter<any> = new EventEmitter();
  @Output('hideMobileNavBar') hideMobileNavBar: EventEmitter<any> = new EventEmitter();
  baseUrl: string;
  campaignKey: string;

  constructor(private injector: Injector,
    private router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.baseUrl = this.helperService.getBaseUrl();
    this.campaignKey = this.helperService.getCampaignKey();
  }

  // Go to Category
  public goToCategory(category): void {
    // this.analyticsService.trackCustomEvent({
    //   "event": "masthead-product-goto",
    //   "product-english-name": product?.gaTag
    // });
    this.router.navigate([this.baseUrl + '/category', category.lineGroup_RecordID], { queryParams: { campaignKey: this.campaignKey } });
  }

  // Go to Product
  public goToProduct(product): void {
    // this.analyticsService.trackCustomEvent({
    //   "event": "masthead-product-goto",
    //   "product-english-name": product?.gaTag
    // });
    this.router.navigate([this.baseUrl + '/product', product.line_GUID], { queryParams: { campaignKey: this.campaignKey } });
  }

  public hidePopup() {
    this.hideProductPopUp.emit();
    this.hideMobileNavBar.emit();
  }
}
