import { Component, Injector, OnInit } from '@angular/core';
import { TBSBaseComponent } from '../../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-qrcode-popup',
  templateUrl: './qrcode-popup.component.html',
  styles: [
  ]
})
export class QrcodePopupComponent extends TBSBaseComponent implements OnInit {

  value: string;
  constructor(
    public modalService: NgbActiveModal,
    private injector: Injector,
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Modal.CloseButton.AriaLabel',
      'Aon.ScanQRCode.Title'
    ]);
  }
    ngOnInit(): void {
      this.loadResourceStringAsObservable()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
          this.getResouceStringsAsObject(res);
        });
    }
  close() {
    this.modalService.close(false);
  }
}
