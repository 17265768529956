import { Injectable, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SableDataViewDataService } from '@aon/sable-dataview';
import { BootstrapRequestContext } from 'tbs-typings';
import { JackalEngine } from '@aon/jackal';
import { AuthenticationService } from '../../core/authentication/authentication.service';

@Injectable()
export class AppViewDataService implements SableDataViewDataService {
  products = [];
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    }),
    body: JSON.stringify({ "GenericDataView_RecordId": "5c2131b5-8032-1leb-a97c-000d39228116", "EmployeeBenefitId": 0 })
  };
  jackalEngine = new JackalEngine();
  constructor(
    private httpClient: HttpClient,
    private authenticationService: AuthenticationService,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext
  ) { }

  private apiBaseUrl = this.bootstrapRequestContext.apiBaseUrl;

  getViewConfig(viewId) {
    let genericDataViewQuery: any = {
      GenericDataView_RecordID: viewId,
      Data: null
    };
    let url = 'sableviewdata';
    let urlCall = this.apiBaseUrl + url;
    return this.httpClient.post<any>(urlCall, genericDataViewQuery)
      .pipe(map(result => {
        return result;
      }));
  }

  handleData(data: any): void {
    data['isAuthenticated'] = this.authenticationService.isLogin;
    if (!this.isEmpty(data.cssClassCalc)) {
      data.cssClass = this.jackalEngine.EvaluateParameter(data.cssClassCalc, data)
    }
    if (!this.isEmpty(data.css_aria_label)) {
      data.css_aria_label = this.jackalEngine.EvaluateParameter(data.css_aria_label, data)
    }

    //#845681
    //footerContent is a field of Aon.SableTable, US Auto/Home use it to display different pay frequency text based on certain condition
    //i.e. Display "per month" for pre-Auth user and display "per pay" for post-Auth user
    //The feasible solution is to make it JACKAL-lized. Example: IfElse(isAuthenticated, "per pay", "per month")
    //If it's successfully evaluated by the jackal, display the evaludated result otherwise display the original content

    if (!this.isEmpty(data.footerContent)) {
      const jackContent = this.jackalEngine.EvaluateParameter(data.footerContent, data);
      if (jackContent != null) data.footerContent = jackContent;
    }

    if (data.items != null) {
      data.items.forEach(dataItem => {
        this.handleData(dataItem);
      });
    }

  }
  private isEmpty(str): boolean {
    return (!str || str.length === 0);
  }

}



