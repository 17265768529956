import { Pipe, PipeTransform } from '@angular/core';
import { AonLocaleService } from '@aon/aon-angular-common';

@Pipe({
  name: 'customdate'
})
export class DateFormatPipe implements PipeTransform {
  constructor(private localeService: AonLocaleService) { }

  transform(value: any): string {
    return this.localeService.formatDate(value);
  }
}
