import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { HelperService } from 'src/app/shared/services/helper.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable, take } from 'rxjs';
import { UiconfigrationService } from 'src/app/core/services/uiconfigration.service';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { GlobalObjectsService } from 'src/app/shared/services/global-objects.service';
import { BenefitSaveAlertComponent } from '../../modules/benefit/benefit-save-alert/benefit-save-alert.component';
import { CommonService } from '../services';
import { SessionStorageKey } from '../../shared/models/constants';
import { DelegateNoAccessAlertComponent } from '../../shared/delegate-no-access-alert/delegate-no-access-alert.component';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}
@Injectable({ providedIn: 'root' })
export class AuthGuard {
  uiConfig: UIConfigurationType;
  modalOption: NgbModalOptions = {};
  baseUrl: string;
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private helperService: HelperService,
    private modalService: NgbModal,
    private activatedRoute: ActivatedRoute,
    private uiconfigrationService: UiconfigrationService,
    private globalObjectsService: GlobalObjectsService,
    private commonService: CommonService
  ) {
    this.baseUrl = this.helperService.getBaseUrl();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.indexOf('/open/productspage') >= 0) {
      if (route.queryParams.productid === undefined) {
        this.router.navigate([this.baseUrl + '/home'], { queryParams: route.queryParams });
      } else {
        this.router.navigate([this.baseUrl + '/product/' + route.queryParams.productid], { queryParams: route.queryParams });
      }
      return false;
    }

    const curSelDelegateEmployee = sessionStorage.getItem(SessionStorageKey.ActDelegateEmployee);
    if (curSelDelegateEmployee != null) {
      if (state.url.indexOf('/home/home-delegate') < 0 && state.url.indexOf('/claim') < 0) {

        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          ariaLabelledBy: 'delegate-no-access-alert-modal-title'
        };
        ngbModalOptions.container = '#app-modal-anchor';
        this.modalService.open(DelegateNoAccessAlertComponent, ngbModalOptions).result.then(
          (result) => {
            this.router.navigate([this.baseUrl + '/home/home-delegate']);
          }
        );
        return false;
      }
    }

    if (this.authenticationService.isLogin) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([this.baseUrl + '/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    if (currentState.url === "/inbox") {
      this.commonService.GetEmployeeMessageCount().pipe(take(1)).subscribe(data => {
        if (data.unreadCount != 0) {
          this.commonService.unreadCount = true;
        }
        else {
          this.commonService.unreadCount = false;
        }
      });
      return true;
    }
    if (this.helperService.isTimeout) {
      return true;
    }
    if (this.uiConfig.uiConfiguration.showAlertPopup) {
      const canProceed = component.canDeactivate ? component.canDeactivate() : false;
      if (currentState.url == nextState.url || nextState.url.includes('/benefit') || nextState.url.includes('/quote') || nextState.url.includes('/claim') || this.globalObjectsService.suppressNavigation || canProceed) {
        return true;
      } else {
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          ariaLabelledBy: 'leave-flow-alert-modal-title'
        };
        ngbModalOptions.container = '#app-modal-anchor';
        if (!this.globalObjectsService.stopBenefitSaveAlertTrigger) {
          // in case single product check out is enable then, saved quotes popup will be shown from payment page aslo
          if (!currentState.url.includes('/payment') || this.helperService.isSingleProductCheckOutEnabled()) {
            const modalRef = this.modalService.open(BenefitSaveAlertComponent, ngbModalOptions);
            return modalRef.result;
          }
        } else {
          this.globalObjectsService.stopBenefitSaveAlertTrigger = false;
          return true;
        }
      }
    } else {
      return true;
    }
  }
}

