<div class="masthead-products-sleeve">
  <h4 class="col-md-7 m-b-28 d-xl-block d-lg-block d-md-none d-none focus-visibility"
    [innerHTML]="resourcestrings['Aon.Navigation.ProductsIntro'] | safeHtml">
  </h4>

  <p class="d-block d-md-block d-lg-none d-xl-none masthead-content-sleeve focus-visibility"
    [innerHTML]="resourcestrings['Aon.Navigation.ProductsIntro'] | safeHtml">
  </p>

  <div class="row">
    @for (product of products; track product) {
      <div class="product-content col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12">
        <div>
          <div class="masthead-product-contents-sleeve" [ngStyle]="{'background-image': 'url(' + product.image + ')'}"
            (click)="goToProduct(product)">
            <h5 class="product-name m-b-0 m-t-16 d-none d-lg-block d-xl-block d-md-none focus-visibility">
              <a (keydown.enter)="goToProduct(product)" href="javascript:void(0);" [innerHTML]="product.itemTitle | safeHtml" class="p-0 title focus-visibility"
              tabindex="0"></a>
            </h5>
            <p class="product-name lg d-block d-md-block d-xl-none d-lg-none m-b-0 focus-visibility">
              <a (keydown.enter)="goToProduct(product)" [innerHTML]="product.itemTitle | safeHtml" class="p-0"
              tabindex="0" [attr.aria-label]="product.itemSummary"></a>
            </p>
            <p class="lg d-xl-block d-lg-none d-md-none d-none focus-visibility">
              <a (keydown.enter)="goToProduct(product)" class="p-0" [innerHTML]="product.itemSummary | safeHtml">
              </a>
            </p>
            <p class="d-block d-md-block d-lg-block d-xl-none focus-visibility" tabindex="0">
              <a (keydown.enter)="goToProduct(product)" class="p-0" [innerHTML]="product.itemSummary | safeHtml"
                tabindex="0">
              </a>
            </p>
          </div>
        </div>
      </div>
    }
  </div>
</div>