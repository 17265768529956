<div *ngIf="pageContentAvailable ; else pageNotFound" class="page" id="client-content-page">
  <div class="page-content-sleeve container-fluid">
    <div class="row" *ngIf="clientContent && clientContent.content">
      <h1 class="client-content-header col-12">{{clientContent.content.contentItemTitle}}</h1>
      <div class="client-content-body col-12" [innerHTML]="clientContent.content.contentItemBody | safeHtml">
      </div>
      <div class="button-container m-t-32 col-12" *ngIf="clientContent.content.ctAsList.length>0">
        <ng-container *ngFor="let list of clientContent.content.ctAsList">
          <app-aon-cta [fragment]="list.linkUrl.split('#')[1]" [callToAction]="list"
            [redirectUrl]="list.linkUrl.includes('#') ? list.linkUrl.split('#')[0] : list.linkUrl"
            (ctaCustomFunc)="ctaCustomFn.ctaCustomFuncHandler($event)"></app-aon-cta>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-template #pageNotFound><app-page-not-found></app-page-not-found></ng-template>