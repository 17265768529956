import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TBSBaseComponent } from '../models/tbsBaseComponent';
import { takeUntil } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UiconfigrationService } from '../../core/services/uiconfigration.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { GlobalObjectsService } from '../services/global-objects.service';
import { QuoteService } from '../../modules/quote/quote.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-delegate-no-access-alert',
  templateUrl: './delegate-no-access-alert.component.html',
})
export class DelegateNoAccessAlertComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  constructor(
    public modalService: NgbActiveModal,
    public activeModal: NgbActiveModal,
    private uiconfigrationService: UiconfigrationService,
    private globalObjectsService: GlobalObjectsService,
    private authenticationService: AuthenticationService,
    private quoteService: QuoteService,
    private scroller: ViewportScroller,
    private injector: Injector
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Delegate.NoDelegateAccessTitle',
      'Aon.Modal.CloseButton.AriaLabel',
      'Aon.Delegate.NoDelegateAccess',
      'Aon.Common.OK'
    ]);
  }

  ngOnInit(): void {

    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => {
        this.getResouceStringsAsObject(res)
      });
  }
  close() {
    this.modalService.close(false);
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }
}
