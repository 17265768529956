import { Component, HostListener, Inject, Injector, ViewChild } from '@angular/core';
import { BootstrapRequestContext } from 'tbs-typings';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { AuthenticationService } from './core/authentication/authentication.service'
import { JwtHelper } from './core/guards/jwt.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HelperService } from './shared/services/helper.service';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { GlobalObjectsService } from './shared/services/global-objects.service';
import { UiconfigrationService } from './core/services/uiconfigration.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UIConfigurationType } from 'src/app/shared/models/uiconfigration.interface';
import { CommonService } from 'src/app/core/services';
import { DOCUMENT } from '@angular/common';
import { SessionStorageKey } from './shared/models/constants';
import { Subscription } from 'rxjs';
import { TBSBaseComponent } from './shared/models/tbsBaseComponent';
import { onLCP, onINP, onCLS, onTTFB } from 'web-vitals/attribution';
import { DelegateForEmployeeType } from './shared/models/actAsDelegateForType.model';
import { ExternalLinkPopupComponent } from './shared/external-link-popup/external-link-popup.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent extends TBSBaseComponent {
  baseUrl: string;
  siteName = '';
  FooterStyleSwitch = false;
  closeResult: string;
  siteTheme: string;
  darkModeEnabled: boolean;
  isTempPassword = false;
  IsTermsAccepted = true;
  IsPrivacyPolicyAccepted = true;
  IsMarketingPreferenceCompleted = true;
  IsOtherInfoCollectionCompleted = true;
  enableFirstTimeUser = false;
  enableTermsAcceptance = false;
  enablePrivacyPolicyAcceptance = false;
  enableMarketingPreference = false;
  enableCollectOtherInfo = false;
  clientBrandingColour: string;
  supportedByExternalService = true;
  defaultPageTitle: string;
  clientBrandingModeActive = false;
  clientBrandingColourRGB: any;
  skinnyFooterActive = false;
  uiConfig: UIConfigurationType;
  isCategorizationEnabled: boolean = false;
  enableHighContrastMode = false;
  isExpressCheckoutEnabled = false;
  siteType: string;
  isInsideAndroidApp: boolean = false;
  private showChatSub: Subscription;
  showChatUnavailable = false;
  lastNavigationStart: number = Date.now();
  showDelegateBar: boolean = false;
  delegateEmployees: DelegateForEmployeeType[];
  showDelegateMastHead: boolean = false;
  @ViewChild(ExternalLinkPopupComponent) ExternalLinkConfirmComponent;

  constructor(@Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    private breadcrumbService: BreadcrumbService,//Do not revmove, otherwise there will be breadcrumb refresh issue - Oliver
    private title: Title,
    public helperService: HelperService,
    private uiconfigrationService: UiconfigrationService,
    private globalObjectsService: GlobalObjectsService,
    protected commonService: CommonService
  ) {
    super(injector);
    this.addResourceStringKeys([
      'Aon.Nice.ChatUnavailable'
    ]);
    if (sessionStorage.getItem(SessionStorageKey.isInsideAndroidApp) == 'true') {
      this.isInsideAndroidApp = true;
    }
    let root = document.documentElement;
    this.setBrandStyle(root);
    this.helperService.getAonSettings().subscribe((res) => {
      this.isExpressCheckoutEnabled = res.enableExpressCheckout;
    })
    this.baseUrl = this.helperService.getBaseUrl();
    let curLanguageCode = JSON.parse(localStorage.getItem('SelectedLanguage'));
    let currentCulture = curLanguageCode.cultureCode;
    if (this.document.documentElement.lang.toLowerCase() != currentCulture.toLowerCase()) {
      this.document.documentElement.lang = currentCulture;
    }
    this.loadResourceStringAsObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(res => this.getResouceStringsAsObject(res));
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        let child = this.activatedRoute.firstChild;
        if (!child) {
          return { 'title': this.activatedRoute.snapshot.data.title || this.defaultPageTitle, 'event': event };
        }
        while (child.firstChild) {
          child = child.firstChild;
        }
        return { 'title': child.snapshot.data.title || this.defaultPageTitle, 'event': event };
      })
    ).subscribe(data => {
      this.title.setTitle(data.title);
      if (this.notFirstDestinationPath(data)) {
        this.firstDestinationPageValidate(data);
      }
    });
    this.setupDelegate();

    this.globalObjectsService.delegateEmployeeSelfSelected
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dess => {
        if (dess != null) {
          this.showDelegateMastHead = !dess;
        }
      })


    this.helperService.externalLinkURL.subscribe((externalLinkURL) => {
      this.ExternalLinkConfirmComponent.openmodal(externalLinkURL);
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.lastNavigationStart = Date.now();

        let child = this.activatedRoute.firstChild;
        let childTitle = null;
        if (!child) {
          childTitle = this.activatedRoute.snapshot.data.title;
        } else {
          while (child.firstChild) {
            child = child.firstChild;
          }
          childTitle = child.snapshot.data.title;
        }
        
        if (event.url != this.router.url) {
          this.trackAnalyticsNavigation(event.url, childTitle || this.defaultPageTitle);
        }

        this.helperService.trackTheReferrerPath(this.router.url, event.url);
        if (this.helperService.IsVanityURLForMultiTenantEnabled()) {
          if (event.url != this.router.url && this.authenticationService.isLogin) {
            let jwtHelper = new JwtHelper();
            let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
            if (currentUser && currentUser.id_token) {
              let idToken = jwtHelper.decodeToken(currentUser.id_token);
              if (idToken.ClientCode && event.url.toLowerCase().indexOf(idToken.ClientCode.toLowerCase()) < 0) {
                this.authenticationService.logout().subscribe(() => {
                  if (!this.helperService.AllowAnonymousAccess()) {
                    this.helperService.narBarCollapseState.next(true);
                    const isFromMobileApp = sessionStorage.getItem(SessionStorageKey.IsFromMobileApp) === 'true';
                    if (isFromMobileApp) {
                      this.router.navigate([this.baseUrl + '/login'], { queryParams: { timestamp: Date.now() } });
                    } else {
                      this.router.navigate([this.baseUrl + '/login']);
                    }
                    this.globalObjectsService.logoutProfile = true;
                  }
                  else {
                    this.router.navigate([this.baseUrl + '/']);
                    this.globalObjectsService.logoutProfile = true;
                    this.globalObjectsService.showClientLogo.next(false);
                  }
                });
              }
            }
          }
        }
      }
      if (event instanceof NavigationEnd) {
        this.globalObjectsService.stopBenefitSaveAlertTrigger = false;
        this.FooterStyleSwitch = false;
        this.helperService.removeTheReferrerPath(event.url);
        const requiredMatches = [
          '/login',
          '/account/forgotpassword',
          '/account/forgotusername',
          '/account/registration',
          '/account/firsttimeuser',
          '/account/contactpreferences',
          '/account/otherinfo',
          '/account/setnewpassword'
        ]

        if (this.helperService.siteType == "OLB" && this.darkModeEnabled || this.helperService.siteType != "OLB" || this.helperService.siteType == "OLB" && !this.darkModeEnabled && this.enableHighContrastMode) {
          for (let item of requiredMatches) {
            if (event.url.indexOf(item) != -1) {
              this.FooterStyleSwitch = true;
            }
          }
        }
        const mainHeader = document.getElementById('main-app-content');
        if (mainHeader) {
          mainHeader.focus();
        }
        /**
         * Check and Remove the Cached Benefit Reveiw Data
         */
        this.commonService.checkAndRemoveTheCachedBenefitReviewData();
        // Remove Client Content Cache
        this.checkAndRemoveTheClientContentCache();

      }
    });

    this.commonService.resetClientBrandStyle.subscribe(rc => {
      if (rc === true) {
        this.setBrandStyle(root);
        this.commonService.resetClientBrandStyle.next(false);
      }
    });
    this.siteName = this.bootstrapRequestContext.siteName;

    // It checks categorization enabled or not
    this.globalObjectsService.CategorizationEnabled
      .subscribe((isCategorizationEnabled) => {
        this.isCategorizationEnabled = isCategorizationEnabled;
      });
    this.siteType = this.helperService.siteType;
    this.showChatSub = this.commonService.showChatUnavailable.subscribe(sc => {
      this.showChatUnavailable = sc;
    }
    );

    this.sendToAnalytics = (metric) => { this.analyticsService.trackWebVital(metric); };
    onTTFB(this.sendToAnalytics, { reportSoftNavs: true });
    onCLS(this.sendToAnalytics, { reportSoftNavs: true });
    onLCP(this.sendToAnalytics, { reportSoftNavs: true });
    onINP(this.sendToAnalytics, { reportSoftNavs: true });

    if (performance.timing) {
      let loadTime = performance.timing.loadEventEnd - performance.timing.navigationStart;
      let appLoadTime = Date.now() - performance.timing.navigationStart;
      const ssoTime = this.helperService.getUrlParameter("time");
      if (ssoTime) {
        //Include SSO processing time if login from SSO
        loadTime = loadTime + Number(ssoTime);
        appLoadTime = appLoadTime + Number(ssoTime);
      }
      let pageDetails = {
        'event': 'app-loaded',
        'app-load-timestamp': Date.now(),
        'app-loading-time': loadTime,
        'pre-navigation-time': appLoadTime,
        'content-loading-time': appLoadTime,
        'pageViewLogged': true
      };
      this.analyticsService.recordPageView(pageDetails);
    }
  }

  private checkAndRemoveTheClientContentCache(): void {
    try {
      let sessionData = sessionStorage.getItem(SessionStorageKey.ClientContentPageCallToActions);
      if (sessionData && this.router.url != "/client-content-page") {
        sessionStorage.removeItem(SessionStorageKey.ClientContentPageCallToActions);
      }
    } catch { }
  }

  private setBrandStyle(root: HTMLElement) {
    let uiConfigType = this.uiconfigrationService.getUIConfiguration();
    this.uiConfig = this.uiconfigrationService.getUIConfiguration();
    this.skinnyFooterActive = this.uiConfig.uiConfiguration?.showSkinnyFooter;
    this.enableHighContrastMode = this.uiConfig.uiConfiguration?.enableHighContrastMode;
    this.clientBrandingColour = uiConfigType?.uiConfiguration?.clientPrimaryBrandColour;
    this.clientBrandingColourRGB = this.hexToRgb(this.clientBrandingColour);
    this.siteTheme = uiConfigType?.uiConfiguration?.presetTheme;
    this.darkModeEnabled = uiConfigType?.uiConfiguration?.enableDarkMode;
    let reg = /^#([0-9a-f]{3}){1,2}$/i;
    if (reg.test(this.clientBrandingColour)) {
      this.clientBrandingModeActive = true;
      root.style.setProperty('--client-branding-hexcode', this.clientBrandingColour);
      root.style.setProperty('--client-branding-rgb', this.clientBrandingColourRGB);
    } else {
      this.clientBrandingModeActive = false;
    }
  }

  private hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? parseInt(result[1], 16) + "," + parseInt(result[2], 16) + "," + parseInt(result[3], 16) : null;
  }


  private firstDestinationPageValidate(data: { title: any; event: NavigationEnd; }) {
    this.baseUrl = this.helperService.getBaseUrl();
    if (sessionStorage.getItem("Id_CustomSettings") != null && this.authenticationService.isLogin) {
      let idCustomSettings = JSON.parse(sessionStorage.getItem("Id_CustomSettings"));
      this.setFirstUserJourneyFlags(idCustomSettings);
      this.ensureFirstDestinationPage(data.event);
    } else {
      this.authenticationService.currentUser.subscribe(user => {
        let jwtHelper = new JwtHelper();
        if (user && user.id_token) {
          let parsedToken = jwtHelper.decodeToken(user.id_token);
          let customSettings = (parsedToken.UserCustomSettings != null) ? JSON.parse(parsedToken.UserCustomSettings) : null;
          this.setFirstUserJourneyFlags(customSettings);
          this.ensureFirstDestinationPage(data.event);
        }
      });
    }
  }

  private notFirstDestinationPath(data: { title: any; event: NavigationEnd; }) {
    return !data.event.url.includes('/account/contactpreferences') && (!data.title?.includes('First Time User')) && (!data.title?.includes('Login')) && (!data.event.url.includes('/account/otherinfo')) && (!data.event.url.includes('/help'));
  }

  private setFirstUserJourneyFlags(idCustomSettings) {
    if (this.authenticationService.getUIConfig()?.uiConfiguration != null) {
      this.enableTermsAcceptance = this.authenticationService.getUIConfig()?.uiConfiguration?.enableTermsAcceptance;
      this.enablePrivacyPolicyAcceptance = this.authenticationService.getUIConfig()?.uiConfiguration?.enablePrivacyPolicyAcceptance;
      this.enableMarketingPreference = this.authenticationService.getUIConfig()?.uiConfiguration?.enableMarketingConsent && !this.authenticationService.getUIConfig()?.uiConfiguration?.disableMarketingConsentDuringLogin;
      this.enableCollectOtherInfo = this.authenticationService.getUIConfig()?.uiConfiguration?.enableCollectOtherInfo;
      this.enableFirstTimeUser = this.authenticationService.getUIConfig()?.uiConfiguration?.enableFirstTimeUser;
    }
    if (idCustomSettings != null) {
      this.isTempPassword = idCustomSettings.IsTemporaryPassword;
      this.IsOtherInfoCollectionCompleted = idCustomSettings.IsOtherInfoCollectionCompleted;
      this.IsTermsAccepted = idCustomSettings.IsTermsAccepted;
      this.IsPrivacyPolicyAccepted = idCustomSettings.IsPrivacyPolicyAccepted;
      this.IsMarketingPreferenceCompleted = idCustomSettings.MarketingPreferenceCompleted;
      this.supportedByExternalService = true;
      if (sessionStorage.getItem("SupportedByExternalService")) {
        this.supportedByExternalService = JSON.parse(sessionStorage.getItem("SupportedByExternalService"));
      }
    }

  }

  private ensureFirstDestinationPage(event: NavigationEnd): void {
    const isExpressLoginInFlow = JSON.parse(sessionStorage.getItem(SessionStorageKey.IsExpressLoginInFlow));
    if (this.authenticationService.isLogin && (!this.isExpressCheckoutEnabled || !isExpressLoginInFlow)) {
      if (this.enableFirstTimeUser && this.isTempPassword && !this.authenticationService.isSso) {
        this.authenticationService.hasHighPriorityPage.next(true);
        this.router.navigate([this.baseUrl + '/account/firsttimeuser']);
      }
      else if ((!this.IsTermsAccepted && this.enableTermsAcceptance)
        || (!this.IsPrivacyPolicyAccepted && this.enablePrivacyPolicyAcceptance)
        || (!this.IsMarketingPreferenceCompleted && (this.enableMarketingPreference && this.supportedByExternalService))) {
        this.authenticationService.hasHighPriorityPage.next(true);
        this.router.navigate([this.baseUrl + '/account/contactpreferences']);
      }
      else if (!this.IsOtherInfoCollectionCompleted && this.enableCollectOtherInfo) {
        this.authenticationService.hasHighPriorityPage.next(true);
        this.router.navigate([this.baseUrl + '/account/otherinfo']);
      }

    }
  }

  private trackAnalyticsNavigation(routerurl, pageTitle) {
    let navigationEventCode = 'router-navigtion';
    if (routerurl.startsWith('/product')) {
      navigationEventCode = 'product-navigtion';
    }
    this.analyticsService.recordPageView({
      event: navigationEventCode,
      routerurl: routerurl,
      routertitle: pageTitle,
      navigationStart: this.lastNavigationStart,
      pageViewLogged: false
    });
  }

  private setupDelegate() {
    this.authenticationService.currentUser.subscribe(user => {
      let jwtHelper = new JwtHelper();
      if (user && user.id_token) {
        let parsedToken = jwtHelper.decodeToken(user.id_token);
        let delegateEmployeeSettings = (parsedToken.DelegateEmployeeSettings != null) ? JSON.parse(parsedToken.DelegateEmployeeSettings) : null;
        if (delegateEmployeeSettings) {
          delegateEmployeeSettings = this.helperService.toCamel(delegateEmployeeSettings);
          this.delegateEmployees = delegateEmployeeSettings.delegateForEmployees;
          if (this.delegateEmployees.length > 0) {
            this.showDelegateBar = true;
          } else {
            this.showDelegateBar = false;
          }
        } else {
          this.showDelegateBar = false;
        }
      }
    })
  }

  showLightFooter(): boolean {
    //When showLightFooter enabled, if user is on quote pages/benefit pages then we will show app-footer-light.
    //When showLightFooter enabled, if user not on quote pages/benefit pages then we will show app-footer.
    //When showLightFooter not enabled, irrespective of user flow we will show app-footer.
    if (this.uiConfig.uiConfiguration?.showLightFooter) {
      return this.helperService.isQuoteRoute() || this.helperService.isBenefitRoute();
    }
  }

  @HostListener('keydown.shift.tab', ['$event'])
  @HostListener('keydown.tab', ['$event'])
  listenKeyDownTabEvent(event: any): void {
    if (this.modalService.hasOpenModals()) {
      const getTheTargetElement = () => {
        let targettedElement = document.querySelector("ngb-modal-window");
        try {
          let listOfModalWindow = document.querySelectorAll("ngb-modal-window");
          if (listOfModalWindow.length > 1) {
            // setting the last modal window if it's having more than 1
            targettedElement = listOfModalWindow[listOfModalWindow.length - 1];
          }
        } catch { }
        return targettedElement;
      }
      this.helperService.trapTheFocusWithinTheTargetedElement(getTheTargetElement(), event);
    }
  }

  showNicChat(): boolean {
    if (!this.bootstrapRequestContext.enableWebChat && !this.bootstrapRequestContext.enableCxoneNiceWebChat) {
      return false;
    }
    if (this.helperService.AllowAnonymousAccess()) {
      return true;
    }
    if (this.helperService.isLoggedOn()) {
      return true;
    }
    return false;
  }

  sendToAnalytics(metric) { };

  elementClicked(evt) {
    if (this.uiConfig.uiConfiguration?.showExternalLinkPopup) {
      const href = evt.target.getAttribute('href');
      if (href && this.helperService.isExternalUrl(href)) {
        evt.preventDefault();
        this.ExternalLinkConfirmComponent.openmodal(href);
      }
    }
  }

  ngOnDestroy(): void {
    this.showChatSub.unsubscribe();
  }
}
