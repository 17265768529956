export class ChildComponentOptions {
  constructor(preloadProductId: boolean = true, isHeader: boolean = false, isFooter: boolean = false, preloadCategoryId: boolean = true) {
    this.preloadProductId = preloadProductId;
    this.isHeader = isHeader;
    this.isFooter = isFooter;
    this.preloadCategoryId = preloadCategoryId;
  }
  preloadProductId: boolean;
  preloadCategoryId: boolean;
  isHeader: boolean;
  isFooter: boolean;
}
