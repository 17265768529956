import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { LanguageSelectionData } from 'src/app/shared/models';
import { HelperService } from 'src/app/shared/services/helper.service';
import { HomeService } from '../../home/home.service';
import { GlobalObjectsService } from '../../../shared/services/global-objects.service';
import { ActivatedRoute } from '@angular/router';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { CTACustomFunctionService } from 'src/app/shared/services/ctacustom-function.service';
import { SessionStorageKey } from '../../../shared/models/constants';
import { CTAModel } from 'src/app/shared/models/callToAction.model';

@Component({
  selector: 'app-client-content-page',
  templateUrl: './client-content-page.component.html'
})
export class ClientContentPageComponent extends TBSBaseComponent implements OnInit, OnDestroy {

  campaignKey: string;
  clientContent: any;
  contentKey: string;
  callToAction: CTAModel.CallToActionFields;
  pageContentAvailable: boolean = true;

  constructor(public helperService: HelperService,
    private homeService: HomeService,
    private globalojectService: GlobalObjectsService,
    private route: ActivatedRoute,
    public ctaCustomFn: CTACustomFunctionService,
    private injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {

    this.globalojectService.CTAs
      .subscribe(callToAction => {
        if (!callToAction) {
          try {
            this.callToAction = JSON.parse(window.atob(sessionStorage.getItem(SessionStorageKey.ClientContentPageCallToActions)));
          } catch { }
        } else {
          this.callToAction = callToAction;
        }
      });
    let key;
    this.route.queryParams.subscribe(queryParams => {
      key = queryParams['ContentItemKey'];
    });
    this.fetchClientContent(key);
  }

  fetchClientContent(key: string = null) {
    let curLanguageCode: LanguageSelectionData = JSON.parse(localStorage.getItem('SelectedLanguage'));
    this.campaignKey = this.helperService.getCampaignKey();
    this.contentKey = key ? key : this.callToAction?.additionalParameter;
    this.homeService.getClientContent(curLanguageCode.cultureCode, "/client-content-page", this.campaignKey, this.contentKey).subscribe(cc => {
      if (cc.content) {
        this.clientContent = cc;
      } else {
        this.pageContentAvailable = false;
      }
    });
  }

  ngOnDestroy(): void {
    this.signalUnsubscribe();
    this.pageContentAvailable = true;
  }
}
