import { ElectionResults, LineTaxHeaderType } from './benefitReview.model';
import { SaveResult } from './saveResult.model';

//Request Types
export class EmployeePaymentMethodType {
  recordID: string;
  lookupPaymentProviderPaymentType_RecordID: string;
  name: string;
  billingName: string;
  billingStreet: string;
  billingCity: string;
  billingState: string;
  billingZIP: string;
  billingHouseNumber: string;
  billingAdditionalInfo: string;
  billingPhoneCountry: any;
  billingPhone: string;
  billingPhoneNumber: string;
  country_RecordID: string;
  expirationMonth: number;
  expirationYear: number;
  maskedCCNumber: string;
  encryptedPaymentDetails: string;

  code: string;
  paymentTypeName: string;
  cardExpiry?: string;
  providerCode?: string;
  bankCode?: string;
  firstName?: string;
  lastName?: string;
}

export class ProcessPaymentOrderRequest {
  lookupPaymentProviderPaymentType_RecordID: string;
  employeePaymentMethod_RecordID: string;
  transactionGroupID: string;
  paymentID: string;
  employeeBenefits: number[];
  returnURL: string;
  encryptedPaymentDetails: string;
  cardStatementDescriptor: string;
}

export class PaymentRemediationRequestType {
  lookupPaymentProviderPaymentType_RecordID: string;
  employeePaymentMethod_RecordID: string;
  employeePaymentScheduleDate_RecordID: string;
  encryptedPaymentDetails: string;
}

export class PaymentRecurrenceType {
  targetPaymentDate: string; //yyyy-MM-dd
}

export class EmployeePaymentUpdate {
  employeePaymentScheduleId: string;
  gracePeriodEndDate: string;
  comment: string;
}

export class EmployeePaymentCreditTransaction {
  recordID: string;
  merchantTransactionReference: string;
  creditRecordDate: string;
  creditAmount: number;
  employeeBenefitID: number;
}

export class EmployeePaymentMethodTypeRequest extends EmployeePaymentMethodType {
  currencyId: string;
  correlationID?: string;
  returnURL: string;

  //3DSV2 releated attributes, only for Ingenico
  //ThreeDSecure related data
  isThreeDSecure: boolean = false;
  //CustomerDevice Data
  acceptHeader: string;
  ipAddress: string;
  timezoneOffsetUtcMinutes: string;
  userAgent: string;
  colorDepth: number;
  javaEnabled: boolean;
  javaScriptEnabled: boolean;
  screenHeight: string;
  screenWidth: string;
  //This need to be passed back from Kentico for 3dsv2 in redierct case
  paymentID: string;
  cardStatementDescriptor: string
}

export class PaymentSettings {
  locale: string;
  countryCode: string;
  currencyCode: string;
}

export class IngenicoPaymentRequestDetails {
  countryCode: string;
  locale: string;
  currency: string;
  isRecurring: boolean;
  totalAmount: string;
}

export class IngenicoPaymentSessionDetails {
  clientSessionId: string;
  customerId: string;
  clientApiUrl: string;
  assetUrl: string;

  constructor(data: PaymentProviderSessionType) {
    if (data != null) {
      this.clientSessionId = data.paymentProviderSessionID;
      this.customerId = data.paymentProviderCustomerID;
      this.clientApiUrl = data.paymentProviderPortalUrl;
      this.assetUrl = data.paymentProviderApiUrl;
    }
  }
}

//Response Types
export interface PaymentResultType {
  employeeEvent_RecordID: string;
  employeeBenefitID: number | null;
  electionTransactionResults: ElectionResults[];
}

export interface PaymentSaveResultType extends SaveResult {
  transactionGroupID: string;
  paymentID: string;
  paymentSuccessful: boolean;
  hasPolicyPlacementFailure: boolean;
  redirectURL: string;
  paymentResults: PaymentResultType[];
  employeeBenefits: any; //JToken
  recommendedLines: string[]
}

export interface PaymentAccountBalance {
  amount: number;
  status: number;
}

export interface EmployeeReadyPurchases {
  totalCostToday: number | null;
  effectiveDate: string | null;
  taxHeaders: LineTaxHeaderType[];
}

export interface ProviderPaymentType {
  name: string;
  code: string;
  recordID: string;
  primary_PaymentType_RecordID: string | null;
  managedExternally: boolean;
  provider_RecordID: string;
  providerCode: string;
}

export interface PaymentProviderType {
  provider_RecordID: string;
  name: string;
  code: string;
  paymentTypes: ProviderPaymentType[];
}

export interface ProvidersType {
  error: string;
  providers: PaymentProviderType[];
}

export interface PaymentProviderSessionType {
  paymentProviderApiUrl: string;
  paymentProviderPortalUrl: string;
  paymentProviderSessionID: string;
  paymentProviderCustomerID: string;
  correlationID: string;
  error: string;
}

export interface EmployeePaymentSaveResult extends SaveResult {
  employeePaymentMethod: EmployeePaymentMethodType;
  correlationID: string;
  returnURL: string;
  redirectUrl: string;
}

export class IngenicoEncryptedPaymentDetailsType {
  details: string; //Encrypted payment details
  error: string;
}

export class IngenicoPaymentRequestType {
  request: any; //PaymentRequest
  error: string;
}

export interface SaveAgreementRequestType {
  agreementRecordID: string;
  assent: boolean;
  employeeBenefitIDs: number[];
}

export interface AgreementType {
  recordID: string;
  agreementText: string;
  name: string;
  title: string;
  content: string;
  isRecurring: boolean;
  assent: boolean;
}

export interface QuoteCompleteState {
  paymentResult: PaymentSaveResultType;
  paymentMethod: EmployeePaymentMethodType;
}

export interface AddressType {
  ip: string;
}
