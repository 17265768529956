<footer class="page-footer"
  [ngClass]="{'secondary': FooterStyleSwitch,'skinny-footer': uiConfig.uiConfiguration.showSkinnyFooter || showLightFooter()}">
  @if (!showLightFooter()) {
    @if (!uiConfig.uiConfiguration.showSkinnyFooter) {
      <div class="page-content-sleeve">
        <div class="row">
          <div class="col-sm-12 col-md-3 p-r-20">
            @if (!uiConfig.uiConfiguration.hideLogo) {
              <div>
                @if (!uiConfig.uiConfiguration.hideLogo) {
                  <svg class="brand-logo m-b-0 m-r-20" viewBox="0 0 74 28"
                    title="aon-logo" version="1.1" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title id="brand-logo-title" [innerHTML]="resourcestrings['Aon.Footer.ClientLogoAltText'] | safeHtml">
                    </title>
                    <g id="Global" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Footer-/-Desktop-and-Laptop-/-Inverse" transform="translate(-20.000000, -30.000000)" fill="none">
                        <g id="Aon-logo-red" transform="translate(20.000000, 30.000000)">
                          <polygon id="Fill-1"
                            points="11.9038653 0 0 27.4279828 5.67490413 27.4279828 7.63934736 22.7116063 17.7436081 22.7116063 15.6213406 17.8772082 9.65305459 17.8772082 14.115281 7.16448797 22.5555764 27.4279828 28.2305621 27.4279828 16.3266968 0">
                          </polygon>
                          <polygon id="Fill-2"
                            points="68.7943041 3.91976771 68.7943041 17.8727385 57.5404954 3.91976771 53.0051886 3.91976771 53.0051886 27.4279176 58.2108612 27.4279176 58.2108612 12.9272517 69.9090247 27.4279176 74.0000583 27.4279176 74.0000583 3.91976771">
                          </polygon>
                          <path
                            d="M38.6263695,22.7942459 C34.7001747,22.7942459 31.5060071,19.5999967 31.5060071,15.6738834 C31.5060071,11.747607 34.7001747,8.55343945 38.6263695,8.55343945 C42.5525644,8.55343945 45.7468135,11.747607 45.7468135,15.6738834 C45.7468135,19.5999967 42.5525644,22.7942459 38.6263695,22.7942459 M38.6263695,3.3476853 C31.8296488,3.3476853 26.300253,8.87716273 26.300253,15.6738834 C26.300253,22.4705226 31.8296488,28 38.6263695,28 C45.4230902,28 50.9524861,22.4705226 50.9524861,15.6738834 C50.9524861,8.87716273 45.4230902,3.3476853 38.6263695,3.3476853"
                          id="Fill-3"></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                }
              </div>
            }
            <label class="text-uppercase" [innerHTML]="resourceStrings['Aon.Footer.Tagline'] | safeHtml"></label>
            <p class="xs m-b-20" [innerHTML]="resourceStrings['Aon.Footer.Description'] | safeHtml"></p>
            @if (helperService.IsValidClientCode()) {
              <ul class="list-unstyled">
                <li>
                  @if (privacyPolicyUrl != PRIVACY_POLICY_ROUTING_URL) {
                    <a href="{{privacyPolicyUrl}}" class="xs"
                    target="_blank" [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml" rel="noopener"></a>
                  }
                  @if (privacyPolicyUrl == PRIVACY_POLICY_ROUTING_URL) {
                    <a
                      [routerLink]="[baseUrl+PRIVACY_POLICY_ROUTING_URL]" class="xs"
                    [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml" rel="noopener"></a>
                  }
                </li>
                <li>
                  <a [routerLink]="[baseUrl+'/home/terms-conditions']" class="xs"
                  [innerHTML]="resourceStrings['Aon.Footer.TermsandConditions'] | safeHtml"></a>
                </li>
                <li>
                  <a [routerLink]="[baseUrl+'/home/online-security']" class="xs"
                  [innerHTML]="resourceStrings['Aon.Footer.OnlineSecurity'] | safeHtml"></a>
                </li>
                @if (enableOneTrust && enableOneTrustOptionalCookies) {
                  <li>
                    <!-- Begin OneTrust Footer Link for US site visitors -->
                    <a class="optanon-toggle-display USFooterLink xs" tabindex="0" href="javascript:void(0);"
                    [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesUs'] | safeHtml"></a>
                    <!-- End OneTrust Footer Link for US site visitors -->
                    <!-- Begin OneTrust Footer Link for EU Site visitors -->
                    <a class="optanon-toggle-display EUFooterLink xs" tabindex="0" href="javascript:void(0);"
                    [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesEu'] | safeHtml"></a>
                    <!-- End OneTrust Footer Link for EU Site visitors -->
                  </li>
                }
              </ul>
            }
            <p class="xs footer-copyright-icon m-b-30" [innerHTML]="resourceStrings['Aon.Footer.Copyright'] | safeHtml">
            </p>
          </div>
          @if (!uiConfig.uiConfiguration.hideProducts && helperService.IsValidClientCode()) {
            <div class="col-sm-12 col-md-3"
              >
              <p class="text-uppercase h6" [innerHTML]="resourceStrings['Aon.Footer.Products'] | safeHtml"></p>
              @if (products.length) {
                <ul class="list-unstyled">
                  @for (product of products; track product; let i = $index) {
                    <li class="m-b-16">
                      <a [routerLink]="[baseUrl+'/product',product.line_GUID]" [innerHTML]="product.itemTitle | safeHtml"></a>
                    </li>
                  }
                </ul>
              }
            </div>
          }
          @if (!uiConfig.uiConfiguration.hideFAQ) {
            <div class="col-sm-12 col-md-3">
              <p class="text-uppercase h6" [innerHTML]="resourceStrings['Aon.Footer.FAQS'] | safeHtml"></p>
              @if (helperService.IsValidClientCode()) {
                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="[baseUrl+'/home/faqs']" [innerHTML]="resourceStrings['Aon.Footer.FAQS'] | safeHtml"></a>
                  </li>
                </ul>
              }
            </div>
          }
          @if (!uiConfig.uiConfiguration.hideHelp) {
            <div class="col-sm-12 col-md-3 footer-desktop">
              <p class="text-uppercase h6" [innerHTML]="resourceStrings['Aon.Footer.NeedHelp'] | safeHtml"></p>
              @if (helperService.IsValidClientCode()) {
                <ul class="list-unstyled">
                  <li>
                    <a [routerLink]="[baseUrl+'/home/help']" [innerHTML]="resourceStrings['Aon.Footer.Help'] | safeHtml"></a>
                  </li>
                </ul>
              }
            </div>
          }
        </div>
      </div>
    }
    <!-- wip for skinny footer -->
    @if (uiConfig.uiConfiguration.showSkinnyFooter) {
      <div class="skinny-footer-div flex-column align-items-start">
        <div class="d-flex align-items-end m-b-20 flex-wrap">
          @if (!uiConfig.uiConfiguration.hideLogo) {
            <svg class="brand-logo m-b-0 m-r-20" viewBox="0 0 74 28"
              version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <title id="brand-logo-title" [innerHTML]="resourceStrings['Aon.Footer.ClientLogoAltText'] | safeHtml"></title>
              <g id="Global" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Footer-/-Desktop-and-Laptop-/-Inverse" transform="translate(-20.000000, -30.000000)" fill="none">
                  <g id="Aon-logo-red" transform="translate(20.000000, 30.000000)">
                    <polygon id="Fill-1"
                      points="11.9038653 0 0 27.4279828 5.67490413 27.4279828 7.63934736 22.7116063 17.7436081 22.7116063 15.6213406 17.8772082 9.65305459 17.8772082 14.115281 7.16448797 22.5555764 27.4279828 28.2305621 27.4279828 16.3266968 0">
                    </polygon>
                    <polygon id="Fill-2"
                      points="68.7943041 3.91976771 68.7943041 17.8727385 57.5404954 3.91976771 53.0051886 3.91976771 53.0051886 27.4279176 58.2108612 27.4279176 58.2108612 12.9272517 69.9090247 27.4279176 74.0000583 27.4279176 74.0000583 3.91976771">
                    </polygon>
                    <path
                      d="M38.6263695,22.7942459 C34.7001747,22.7942459 31.5060071,19.5999967 31.5060071,15.6738834 C31.5060071,11.747607 34.7001747,8.55343945 38.6263695,8.55343945 C42.5525644,8.55343945 45.7468135,11.747607 45.7468135,15.6738834 C45.7468135,19.5999967 42.5525644,22.7942459 38.6263695,22.7942459 M38.6263695,3.3476853 C31.8296488,3.3476853 26.300253,8.87716273 26.300253,15.6738834 C26.300253,22.4705226 31.8296488,28 38.6263695,28 C45.4230902,28 50.9524861,22.4705226 50.9524861,15.6738834 C50.9524861,8.87716273 45.4230902,3.3476853 38.6263695,3.3476853"
                    id="Fill-3"></path>
                  </g>
                </g>
              </g>
            </svg>
          }
          @if (!uiConfig.uiConfiguration.hideHelp) {
            <a class="sm" [routerLink]="[baseUrl+'/home/help']"
              [attr.aria-label]="resourceStrings['Aon.Login.ContactUs']+' '+resourceStrings['Aon.Help.Description']"
            [innerHTML]="resourceStrings['Aon.Login.ContactUs'] | safeHtml"></a>
          }
        </div>
        <div class="d-flex align-items-end flex-wrap">
          <ul role="list">
            @if (privacyPolicyUrl != PRIVACY_POLICY_ROUTING_URL) {
              <li role="listitem">
                <a href="{{privacyPolicyUrl}}" class="xs" target="_blank"
                  [attr.aria-label]="resourceStrings['Aon.Footer.Privacypolicy'] +' '+ resourceStrings['Aon.Privacypolicy.Description']"
                [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml"></a>
              </li>
            }
            @if (privacyPolicyUrl == PRIVACY_POLICY_ROUTING_URL) {
              <li role="listitem">
                <a [routerLink]="[baseUrl+PRIVACY_POLICY_ROUTING_URL]" class="xs"
                  [attr.aria-label]="resourceStrings['Aon.Footer.Privacypolicy'] +' '+ resourceStrings['Aon.Privacypolicy.Description']"
                [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml"></a>
              </li>
            }
            <li role="listitem">
              <a [routerLink]="[baseUrl+'/home/terms-conditions']" class="xs"
                [attr.aria-label]="resourceStrings['Aon.Footer.TermsandConditions'] +' '+ resourceStrings['Aon.TermsandConditions.Description']"
              [innerHTML]="resourceStrings['Aon.Footer.TermsandConditions'] | safeHtml"></a>
            </li>
            <li role="listitem">
              <a [routerLink]="[baseUrl+'/home/online-security']" class="xs"
                [attr.aria-label]="resourceStrings['Aon.Footer.OnlineSecurity'] +' '+ resourceStrings['Aon.OnlineSecurity.Description']"
              [innerHTML]="resourceStrings['Aon.Footer.OnlineSecurity'] | safeHtml"></a>
            </li>
            <!-- Begin OneTrust Footer Link for US site visitors -->
            @if (enableOneTrust && enableOneTrustOptionalCookies) {
              <li role="listitem">
                <a class="optanon-toggle-display USFooterLink xs" tabindex="0" href="javascript:void(0);"
                [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesUs'] | safeHtml"></a>
              </li>
            }
            <!-- End OneTrust Footer Link for US site visitors -->
            <!-- Begin OneTrust Footer Link for EU Site visitors -->
            @if (enableOneTrust && enableOneTrustOptionalCookies) {
              <li role="listitem">
                <a class="optanon-toggle-display EUFooterLink xs" tabindex="0" href="javascript:void(0);"
                [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesEu'] | safeHtml"></a>
              </li>
            }
            <!-- End OneTrust Footer Link for EU Site visitors -->
          </ul>
          <p class="xs footer-copyright-icon m-b-0" [innerHTML]="resourceStrings['Aon.Footer.Copyright'] | safeHtml"></p>
        </div>
      </div>
    }
  }

  @if (showLightFooter()) {
    <div class="skinny-footer-div light-footer">
      <div class="d-flex align-items-end flex-wrap">
        @if (!uiConfig.uiConfiguration.hideLogo) {
          <svg class="brand-logo m-b-0 m-r-20" viewBox="0 0 74 28"
            version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <title id="brand-logo-title" [innerHTML]="resourceStrings['Aon.Footer.ClientLogoAltText'] | safeHtml">
            </title>
            <g id="Global" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Footer-/-Desktop-and-Laptop-/-Inverse" transform="translate(-20.000000, -30.000000)" fill="none">
                <g id="Aon-logo-red" transform="translate(20.000000, 30.000000)">
                  <polygon id="Fill-1"
                    points="11.9038653 0 0 27.4279828 5.67490413 27.4279828 7.63934736 22.7116063 17.7436081 22.7116063 15.6213406 17.8772082 9.65305459 17.8772082 14.115281 7.16448797 22.5555764 27.4279828 28.2305621 27.4279828 16.3266968 0">
                  </polygon>
                  <polygon id="Fill-2"
                    points="68.7943041 3.91976771 68.7943041 17.8727385 57.5404954 3.91976771 53.0051886 3.91976771 53.0051886 27.4279176 58.2108612 27.4279176 58.2108612 12.9272517 69.9090247 27.4279176 74.0000583 27.4279176 74.0000583 3.91976771">
                  </polygon>
                  <path
                    d="M38.6263695,22.7942459 C34.7001747,22.7942459 31.5060071,19.5999967 31.5060071,15.6738834 C31.5060071,11.747607 34.7001747,8.55343945 38.6263695,8.55343945 C42.5525644,8.55343945 45.7468135,11.747607 45.7468135,15.6738834 C45.7468135,19.5999967 42.5525644,22.7942459 38.6263695,22.7942459 M38.6263695,3.3476853 C31.8296488,3.3476853 26.300253,8.87716273 26.300253,15.6738834 C26.300253,22.4705226 31.8296488,28 38.6263695,28 C45.4230902,28 50.9524861,22.4705226 50.9524861,15.6738834 C50.9524861,8.87716273 45.4230902,3.3476853 38.6263695,3.3476853"
                  id="Fill-3"></path>
                </g>
              </g>
            </g>
          </svg>
        }
        <ul role="list">
          @if (privacyPolicyUrl != PRIVACY_POLICY_ROUTING_URL) {
            <li role="listitem">
              <a href="{{privacyPolicyUrl}}" class="xs light-footer-a" target="_blank"
                [attr.aria-label]="resourceStrings['Aon.Footer.Privacypolicy'] +' '+ resourceStrings['Aon.Privacypolicy.Description']"
              [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml"></a>
            </li>
          }
          @if (privacyPolicyUrl == PRIVACY_POLICY_ROUTING_URL) {
            <li role="listitem">
              <a [routerLink]="[baseUrl+PRIVACY_POLICY_ROUTING_URL]" class="xs light-footer-a"
                [attr.aria-label]="resourceStrings['Aon.Footer.Privacypolicy'] +' '+ resourceStrings['Aon.Privacypolicy.Description']"
              [innerHTML]="resourceStrings['Aon.Footer.Privacypolicy'] | safeHtml"></a>
            </li>
          }
          <li role="listitem">
            <a [routerLink]="[baseUrl+'/home/terms-conditions']" class="xs light-footer-a"
              [attr.aria-label]="resourceStrings['Aon.Footer.TermsandConditions'] +' '+ resourceStrings['Aon.TermsandConditions.Description']"
            [innerHTML]="resourceStrings['Aon.Footer.TermsandConditions'] | safeHtml"></a>
          </li>
          <li role="listitem">
            <a [routerLink]="[baseUrl+'/home/online-security']" class="xs light-footer-a"
              [attr.aria-label]="resourceStrings['Aon.Footer.OnlineSecurity'] +' '+ resourceStrings['Aon.OnlineSecurity.Description']"
            [innerHTML]="resourceStrings['Aon.Footer.OnlineSecurity'] | safeHtml"></a>
          </li>
          <!-- Begin OneTrust Footer Link for US site visitors -->
          @if (enableOneTrust && enableOneTrustOptionalCookies) {
            <li role="listitem">
              <a class="optanon-toggle-display USFooterLink xs" tabindex="0" href="javascript:void(0);"
              [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesUs'] | safeHtml"></a>
            </li>
          }
          <!-- End OneTrust Footer Link for US site visitors -->
          <!-- Begin OneTrust Footer Link for EU Site visitors -->
          @if (enableOneTrust && enableOneTrustOptionalCookies) {
            <li role="listitem">
              <a class="optanon-toggle-display EUFooterLink xs" tabindex="0" href="javascript:void(0);"
              [innerHTML]="resourceStrings['Aon.OneTrust.ChooseOptionalCookiesEu'] | safeHtml"></a>
            </li>
          }
          <!-- End OneTrust Footer Link for EU Site visitors -->
        </ul>
        <p class="xs footer-copyright-icon" [innerHTML]="resourceStrings['Aon.Footer.Copyright'] | safeHtml"> </p>
      </div>
    </div>
  }
</footer>
