import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SableEntityIntersectFlowDataService } from '@aon/sable-entity-intersect-flow-adapter';
import { HttpClient } from '@angular/common/http';
import { BootstrapRequestContext } from 'tbs-typings';
import { GlobalObjectsService } from '../services/global-objects.service';

@Injectable({
  providedIn: 'root'
})
export class KenticoSableEntityIntersectFlowDataService implements SableEntityIntersectFlowDataService {
  private apiBaseUrl: string;


  constructor(
    private httpClient: HttpClient,
    @Inject('BootstrapRequestContext') private bootstrapRequestContext: BootstrapRequestContext,
    private globalojectService: GlobalObjectsService

  ) {
    this.apiBaseUrl = bootstrapRequestContext.apiBaseUrl;

  }
  getEntityIntersect(activityData: any): Observable<any> {
    let url = this.apiBaseUrl + 'GetSableEntityIntersect';

    return this.httpClient.post<any>(url, {
      ActivityData: activityData,
      EmployeeBenefitId: this.globalojectService.additionalContextData.employeeBenefitID,
      EmployeeRelationshipId: this.globalojectService.additionalContextData.employeeRelationship_RecordID,
    });
  }
  saveEntityIntersect(data: any, activityData: any): Observable<boolean> {
    let url = this.apiBaseUrl + 'SaveSableEntityIntersect';

    const parameter = {
      ActivityData: activityData,
      Data: data,
      EmployeeBenefitId: this.globalojectService.additionalContextData.employeeBenefitID,
      EmployeeRelationshipId: this.globalojectService.additionalContextData.employeeRelationship_RecordID,
    }

    return this.httpClient.post<boolean>(url, parameter)
      .pipe(map(result => {
        return result["didSave"];
      }));

  }



}
