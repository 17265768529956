import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PrivacyPolicyComponent } from './footer/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './footer/terms-conditions/terms-conditions.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MastheadInboxComponent } from './masthead/masthead-inbox/masthead-inbox.component';
import { GridModule } from '@progress/kendo-angular-grid';
import { NicChatComponent } from './nic-chat/nic-chat.component';
import { OnlineSecurityComponent } from './footer/online-security/online-security.component';
import { BreadcrumbComponent, BreadcrumbItemDirective } from 'xng-breadcrumb';

const routes: Routes = [];

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    MastheadInboxComponent,
    NicChatComponent,
    OnlineSecurityComponent],
  exports: [
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    NicChatComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    SharedModule,
    NgbModule,
    GridModule,
    BreadcrumbComponent, BreadcrumbItemDirective,
  ]
})
export class CoreModule { }
