import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { TBSBaseComponent } from 'src/app/shared/models/tbsBaseComponent';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sso-router',
  templateUrl: './sso-router.component.html',
  styles: [
  ]
})
export class SsoRouterComponent extends TBSBaseComponent implements OnInit, OnDestroy {
  isFromBrowseBenefits = false;
  lineObject: any;
  constructor(
    private injector: Injector,
    private router: Router
  ) {
    super(injector);
    if (window.opener && window.opener != null && !window.opener.closed) {
      let fullUrl = this.baseUrl + '/ssorouter'
      window.opener.location.href = fullUrl;
      window.close();
    }
  }

  ngOnInit(): void {
    //remove duplicate code
  }
  ngOnDestroy(): void {
    this.signalUnsubscribe();
  }

}
