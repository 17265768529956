<div *ngIf="faqs" class="faqs-content">
  <h2 class="faq-header focus-visibility" *ngIf="(!faqTitle && !faqPage)">{{resourcestrings['Aon.Home.FaqsHeader']}}
  </h2>
  <h2 class="faq-header focus-visibility" *ngIf="(faqTitle && !faqPage)">{{faqTitle | striphtml}}</h2>
  <!--changed below h4 to divs for removing heading level accessibility issues-->
  <div *ngIf="(!faqTitle && faqPage)" class="h4 faq-header focus-visibility">{{resourcestrings['Aon.Home.FaqsHeader']}}</div>
  <div *ngIf="(faqTitle && faqPage)" class="h4 faq-header focus-visibility">{{faqTitle | striphtml}}</div>
  <div class="faq-sleeve focus-visibility" WatchDomTree (dom-changed)="domChanged()">
    <div ngbAccordion>
      <div [ngbAccordionItem]="'p' + faq.faqID" class="expansion-panel" #accordion="ngbAccordionItem" ngbAccordionItem
        *ngFor="let faq of faqs">
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="header-sleeve focus-visibility" (click)="faqclick(faq)">
            <span class="header-text">{{faq.question}}</span>
            <span class="header-controls">
              <i *ngIf="!accordion.collapsed" class="fal fa-minus" aria-hidden="true"></i>
              <i *ngIf="accordion.collapsed" class="fal fa-plus" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <p class="expansion-panel-text" [innerHTML]="faq.answer | safeHtml"></p>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>