<ng-template #content let-modal>
    <div class="custom-modal time-out">
        <div class="modal-header">
            <h3 class="modal-title" id="modal-basic-title"
                [innerHTML]="resourceStrings['Aon.Common.RedirectInfoTitle'] | safeHtml"></h3>
        </div>
        <div class="modal-body">
            <div>{{resourceStrings['Aon.Common.RedirectInfoText']}}</div>
        </div>
    </div>
</ng-template>