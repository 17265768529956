export enum MFAOption {
  Email,
  SMS
}

export class OktaInfoModel {
  isResend: boolean;
  isNewChannel: boolean;
  userEmail: string;
  countryCodeName: string;
  countryCode: string;
  phoneNumber: string;
  otpCode: string;
  inLoginProcess: boolean;
  employeeRecordID: string;
  option: MFAOption;
  step: MFAStep;
  useSameEmailForMarketingConsent: boolean;
}

export enum MFAStep {
  SelectOption,
  EnterCode,
  NoAuthInfo,
  EnterSsnNumber,
  FirstTimeUser,
  SelectEmail,
  AddNewEmail,
  EnterCodeForAddNewEmail,
  AddNewPhone,
  EnterCodeForAddNewPhone,
  VerifyNewChannelCodes
}

export class MFAData {
  mfaEmail: string;
  mfaPhone: string;
  mfaCountryPhoneCode: string;
  mfaCountryCode_RecordID: string;
  mfaUniqueIdeIndex: string;
  inputIdentifier: string;
  preferenceEmail: MFAPreferenceEmail;
  verificationMethod: MFAVerificationMethod;
  step: MFAStep;
  inLoginProcess: boolean;
  employee_RecordID: string;
}

export enum MFAPreferenceEmail {
  WorkEmail,
  PersonalEmail,
  AlternateEmail,
  MFAEmail,
  NewEmail
}

export enum MFAVerificationMethod {
  WorkEmail,
  PersonalEmail,
  MFAEmail,
  MFAPhone
}
