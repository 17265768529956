<div class="login-main d-lg-none"
  [ngStyle]="{'content': (loginImagePath !== '' && !mfaInProgress) ? 'url(' + loginImagePath + ')' : 'none'}">
</div>
<div *ngIf="showSsoErrorMsg && this.uiConfig.uiConfiguration.enableExternalLoginPage else Login_Content">
  <div class="alert banner error">
    <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorLoginToSite'] | safeHtml"></span>
    <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorEmployerMessage'] | safeHtml"></span>
    <a href="javascript:void(0)" (click)="callExternalApi()">{{resourceStrings['Aon.Navigation.Login']}}</a>
    <span [innerHTML]="resourceStrings['Aon.SSO.SsoLoginErrorVisitAgain'] | safeHtml">
    </span>
  </div>
</div>
<ng-template #Login_Content>
  <div class="d-none d-lg-block login inverse"
    [ngStyle]="{'background-image': loginImagePath !== '' ? 'url(' + loginImagePath + ')' : 'none'}"
    *ngIf="((this.uiConfig === undefined) || !this.uiConfig.uiConfiguration.enableExternalLoginPage || this.isMonitor)&&!mfaInProgress">
    <ng-container *ngTemplateOutlet="Login; context: {idName: ''}"></ng-container>
  </div>
  <div class="d-lg-none d-block login inverse"
    *ngIf="((this.uiConfig === undefined) || !this.uiConfig.uiConfiguration.enableExternalLoginPage  || this.isMonitor)&&!mfaInProgress">
    <ng-container *ngTemplateOutlet="Login; context: {idName: 'mv'}"></ng-container>
  </div>
  <div *ngIf="mfaInProgress" class="mfa inverse"
    [ngStyle]="{'background-image': (loginImagePath !== '' && !isMobileView) ? 'url(' + loginImagePath + ')' : 'none'}">
    <div class="mfa-sleeve">
      <app-mfa-authentication [inLoginProcess]="true" (afterPassedVerify)="afterSubmitSuccess()"
        (backToLogIn)="backToLogIn()"></app-mfa-authentication>
    </div>
  </div>
</ng-template>
<ng-template #Login let-idName="idName">
  <div class="login-sleeve">
    <div class="page-content-sleeve">
      <div class="row" *ngIf="!isLoginFunctionProvided(resourceStrings['Aon.Common.NoAccountFunctionProvided'])">
        <div class="col-12">
          <div class="alert banner info" role="alert" aria-live="polite">
            <div class="title">
              <div class="title-content">
                <i class="fas fa-bell" aria-hidden="true"></i>
                {{resourceStrings['Aon.Common.BannerTitle.Info']}}
              </div>
            </div>
            <p [innerHTML]="resourceStrings['Aon.Common.NoAccountFunctionProvided'] | striphtml"></p>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isLoginFunctionProvided(resourceStrings['Aon.Common.NoAccountFunctionProvided'])">
        <div class="container col-12 col-md-6">
          <ng-container *ngIf="isNormalLogin()">
            <div class="row">
              <div class="col-12">
                <div class="img-sleeve">
                  <img src="/assets/images/account/curves4.svg" alt="" />
                </div>
                <h1 class="m-b-32">{{resourceStrings['Aon.Login.LoginTitle']}}</h1>
              </div>
            </div>

            <div class="row" *ngIf="isFromForgotPassword || submitted && error || showSsoErrorMsg">
              <div class="col-12">
                <div AlertBannerCloseButtonFocus class="alert banner success" id="{{idName}}change-successful-message"
                  *ngIf="isFromForgotPassword" role="alert" aria-live="polite">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-check-circle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Success']}}
                    </div>
                    <i class="fal fa-times focus-visibility" tabindex="0" role="button"
                      (keydown.enter)="helperService.closeInfo('change-successful-message')"
                      (click)="helperService.closeInfo('change-successful-message')"
                      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="resourceStrings['Aon.Common.ChangePwdSuccessfully'] | safeHtml"
                    [attr.aria-label]="resourceStrings['Aon.Common.ChangePwdSuccessfully']"></p>
                </div>
                <div AlertBannerCloseButtonFocus class="alert banner error" id="{{idName}}submit-error-message"
                  *ngIf="submitted && error" role="alert">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.Common.BannerTitle.Error']}}
                    </div>
                    <i id="submit-error-message-closebtn" class="fal fa-times focus-visibility" tabindex="0"
                      role="button" (keydown.enter)="helperService.closeInfo('submit-error-message')"
                      (click)="helperService.closeInfo('submit-error-message')"
                      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="error | safeHtml" [attr.aria-label]="resourceStrings['Aon.Common.BannerTitle.Error']">
                  </p>
                </div>
                <div AlertBannerCloseButtonFocus class="alert banner error" id="{{idName}}sso-error-message"
                  *ngIf="showSsoErrorMsg" role="alert">
                  <div class="title">
                    <div class="title-content">
                      <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
                      {{resourceStrings['Aon.SSO.SsoLoginError']}}
                    </div>
                    <i id="sso-error-message-closebtn" class="fal fa-times focus-visibility" tabindex="0" role="button"
                      (keydown.enter)="helperService.closeInfo('sso-error-message')"
                      (click)="helperService.closeInfo('sso-error-message')"
                      [attr.aria-label]="resourceStrings['Aon.BannerAlert.Close']"></i>
                  </div>
                  <p [innerHTML]="error | safeHtml" [attr.aria-label]="resourceStrings['Aon.Common.BannerTitle.Error']">
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <form [formGroup]="loginForm" class="col-12" autocomplete="off">
                <div class="form-group" *ngIf="!uiConfig?.uiConfiguration?.loginByMitIDOnly">
                  <label for="{{idName}}username" [ngClass]="{ 'error': isFieldValid('username') }">
                    {{resourceStrings['Aon.Login.UserName']}}
                  </label>
                  <div *ngIf="isFieldValid('username')" class="alert contextual error">
                    <div id="{{idName}}error-message-userName-is-required" *ngIf="f.username.errors.required"
                      role="alert">
                      <i class="fas fa-exclamation-triangle"
                        aria-hidden="true"></i>{{resourceStrings['Aon.Login.UserNameIsRequired']}}
                    </div>
                  </div>
                  <input aria-describedby="UserNameIsRequired" type="text" formControlName="username"
                    id="{{idName}}username" class="m-b-8" [ngClass]="{ 'error': isFieldValid('username') }" />
                  <p class="sm"
                    *ngIf="!isFromForgotPassword && uiConfig?.uiConfiguration?.showForgotUsernameLinkonLoginPage">
                    <a
                      [routerLink]="[baseUrl+'/account/forgotusername']">{{resourceStrings['Aon.Login.ForgotUserName']}}</a>
                  </p>
                </div>
                <div class="form-group" *ngIf="!uiConfig?.uiConfiguration?.loginByMitIDOnly">
                  <label for="{{idName}}password" [ngClass]="{ 'error': isFieldValid('password') }">
                    {{resourceStrings['Aon.Login.Password']}}
                  </label>
                  <div *ngIf="isFieldValid('password')" class="alert contextual error">
                    <div id="{{idName}}error-message-password-is-required" *ngIf="f.password.errors.required"
                      role="alert">
                      <i class="fas fa-exclamation-triangle"
                        aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                    </div>
                  </div>
                  <aon-input-password formControlName="password" [id]="idName+'password'"
                    [aria-describedby]="'PasswordIsRequired'" [ngClass]="{ 'error': isFieldValid('password') }"
                    [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']">
                  </aon-input-password>
                  <p class="sm m-t-8" *ngIf="!isFromForgotPassword">
                    <a
                      [routerLink]="[baseUrl+'/account/forgotpassword']">{{resourceStrings['Aon.Login.Forgotpassword']}}</a>
                  </p>
                </div>
                <div class="form-group m-b-32">
                  <div class="row">
                    <div class="col-md-6">
                      <button id="login-submit-btn" class="login-submit spinner prominent" type="submit"
                        (click)='onSubmit()'>
                        {{resourceStrings['Aon.Login.LoginText']}}
                        <span *ngIf="loading" class="spinner-border spinner-border-sm m-l-4"></span>
                      </button>
                    </div>
                  </div>
                </div>
                <div *ngIf="isLoginSuccess" role="alert" class="visually-hidden">
                  {{resourceStrings['Aon.Login.LoginSuccessMessage']}}
                </div>
                <div class="row" *ngIf="isExpressCheckoutEnabled()">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm">
                      {{resourceStrings['Aon.Login.NoPassword']}} <a href="javascript:void(0);"
                        (click)='goToExpressLogin()'>{{resourceStrings['Aon.Login.LoginWithOTP']}}</a>
                    </p>
                  </div>
                </div>
                <div *ngIf="uiConfig.uiConfiguration.enableCustomLoginLinks && customLoginLinks">
                  <ng-container *ngFor="let cstmLink of customLoginLinks; let cstmLoginLinkIndex = index">
                    <div class="aon-custom-login-links" *ngIf="cstmLink.contentItems">
                      <div id="{{idName}}customLoginLinks-{{cstmLinkIndex}}"
                        *ngFor="let cstmLinkContentItem of cstmLink.contentItems; let cstmLinkIndex = index">
                        <p class="sm" *ngIf="cstmLinkContentItem?.contentItemBody && cstmLinkIndex <=1"
                          [innerHTML]="cstmLinkContentItem?.contentItemBody | striphtml: whiteListTagsForCustomLogIn">
                        </p>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="row" *ngIf="!isFromForgotPassword && !uiConfig?.uiConfiguration?.loginByMitIDOnly">
                  <div *ngIf="uiConfig.uiConfiguration.selfRegistrationEnabled"
                    class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm">
                      {{resourceStrings['Aon.Login.IsNewUser']}} <a
                        [routerLink]="[baseUrl+'/account/registration']">{{resourceStrings['Aon.Login.CreateAccount']}}</a>
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 d-flex col-12 align-items-baseline">
                    <p class="sm m-b-0">
                      {{resourceStrings['Aon.Account.NeedHelp']}} <a
                        [routerLink]="[baseUrl+'/home/help']">{{resourceStrings['Aon.Login.ContactUs']}}</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </ng-container>
          <ng-container *ngIf="!isNormalLogin()">
            <div class="express-login">
              <div class="alert contextual info col-12">
                <div class="message"><i class="fas fa-bell" aria-hidden="true"></i>
                  <p>{{resourceStrings['Aon.Login.GuestLoginInfo']}}</p>
                </div>
              </div>
              <div class="email-info m-b-20 col-12">
                {{resourceStrings['Aon.Login.GuestLoginEnterEmailToReceiveOTP']}}
              </div>
              <div class="">
                <form [formGroup]="loginForm">
                  <div class="form-group col-12 col-lg-6">
                    <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress">
                      <label for="{{idName}}username"
                        [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }">{{resourceStrings['Aon.Account.Email']}}</label>
                      <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)"
                        class="alert contextual error">
                        <div *ngIf="f.username.errors.required" role="alert">
                          <i class="fas fa-exclamation-triangle"
                            aria-hidden="true"></i>{{resourceStrings['Aon.Account.EmailIsRequired']}}
                        </div>
                        <div *ngIf="!f.username.errors.required">
                          <i class="fas fa-exclamation-triangle"
                            aria-hidden="true"></i>{{resourceStrings['Aon.Account.InvalidEmail']}}
                        </div>
                      </div>
                      <input type="text" formControlName="username" id="{{idName}}username" class="m-b-8"
                        [ngClass]="{ 'error': f.username.invalid && (f.username.dirty || f.username.touched) }" />
                    </ng-container>

                    <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
                      <div>
                        <label>{{resourceStrings['Aon.Account.Email']}}<i
                            class="edit-email fal fa-edit m-l-10 focus-visibility" role="button" tabindex="0"
                            (keyup.enter)="editEmail()" (click)="editEmail()"
                            [attr.aria-label]="resourceStrings['Aon.Common.Edit']"></i></label>
                        <span>{{f.username.value}}</span>
                      </div>
                    </ng-container>
                  </div>
                  <div class="form-group m-b-20 col-12 col-lg-6"
                    *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
                    <label for="{{idName}}password"
                      [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }">{{resourceStrings['Aon.Login.OneTimePassword']}}</label>
                    <div *ngIf="f.password.invalid && (f.password.dirty || f.password.touched)"
                      class="alert contextual error">
                      <div id="{{idName}}PasswordIsRequired" *ngIf="f.password.errors.required" role="alert">
                        <i class="fas fa-exclamation-triangle"
                          aria-hidden="true"></i>{{resourceStrings['Aon.Login.PasswordIsRequired']}}
                      </div>
                    </div>
                    <aon-input-password formControlName="password" [id]="idName+'password'"
                      [ngClass]="{ 'error': f.password.invalid && (f.password.dirty || f.password.touched) }"
                      [aria-describedby]="'PasswordIsRequired'"
                      [aria-label]="resourceStrings['Aon.Login.PasswordVisibilityControl']"></aon-input-password>
                    <div class="d-flex align-items-center m-t-8 resend-code">
                      <a href="javascript:void(0);" (click)="getOTP()" role="button" class="login-get-otp m-r-8 btn"
                        [ngClass]="isResendCodeButtonDisabled? 'disabled':''">{{resourceStrings['Aon.Login.ResendCode']}}</a><span>{{countDownTimerForDisplay}}</span>
                    </div>
                  </div>
                  <ng-container *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
                    <div tabindex="0" (click)="acceptPrivacyPolicy()" class="checkbox-wrapper p-l-15 p-r-15"
                      *ngIf="showPrivacyPolicySection">
                      <input type="checkbox" [disabled]="!isPrivacyPolicyLinkClicked"
                        [checked]="isPrivacyPolicyAccepted"
                        [attr.aria-label]="resourceStrings['Aon.Login.PrivacyPolicyReadClaim']" />
                      <label class="secondary">
                        <span class="check-toggle" [ngClass]="{'error':(!isPrivacyPolicyAccepted)}"></span>
                        <span class="label content">{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimBeforeLink']}}<a
                            href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()"
                            target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a>{{resourceStrings['Aon.Login.PrivacyPolicyReadClaimAfterLink']}}<a
                            href="{{privacyPolicyUrl}}" (click)="readPrivacyPolicy()"
                            target="_blank">{{resourceStrings['Aon.Registration.PrivacyPolicy']}}</a></span>
                      </label>
                    </div>
                    <div
                      *ngIf="showMarketingPreferenceSection && employeeServiceCommsPreferences?.length > 0 && uiConfig.uiConfiguration.enableMarketingConsent"
                      (click)="isMarketingSelectionSel=!isMarketingSelectionSel" tabindex="0"
                      class="checkbox-wrapper p-l-15 p-r-15">
                      <input type="checkbox" [checked]="isMarketingSelectionSel"
                        [attr.aria-label]="resourceStrings['Aon.Login.MarketingSelection']" />
                      <label class="secondary">
                        <span class="check-toggle" [ngClass]="{'error':(!isMarketingSelectionSel)}"></span>
                        <span class="label content"
                          [innerHTML]="resourceStrings['Aon.Login.MarketingSelection'] | safeHtml"></span>
                      </label>
                    </div>
                  </ng-container>
                  <div *ngIf="submitted && error" class="col-12 col-lg-6">
                    <div *ngIf="error" class="alert contextual error mt-3 mb-2" role="alert"><i
                        class="fas fa-exclamation-triangle" aria-hidden="true"></i>{{error}}</div>
                  </div>
                </form>
              </div>
              <div class="m-b-40 col-12 col-lg-6">
                <div *ngIf="curExpressLoginStep == localExpressLoginSteps.ProvideEmailAddress">
                  <button class="login-submit login-generate-password spinner"
                    [disabled]="f.username.pristine || (f.username.invalid && (f.username.dirty || f.username.touched))"
                    (click)='getOTP()'>
                    {{resourceStrings['Aon.Login.GeneratePassword']}}
                    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
                <div *ngIf="curExpressLoginStep == localExpressLoginSteps.GenerateOTP">
                  <button id="login-submit-verify-email-btn" class="login-submit login-verify-email spinner"
                    [disabled]="loading || !isPrivacyPolicyAccepted" (click)='onSubmit()'>
                    {{resourceStrings['Aon.Login.VerifyEmail']}}
                    <span *ngIf="loading" class="spinner-border spinner-border-sm"></span>
                  </button>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div>
                  <p class="sm" id="{{idName}}not-registered">{{resourceStrings['Aon.Login.HaveAccount']}} <a
                      id="{{idName}}go-to-login" href="javascript:void(0);" (click)="goToLogin()"
                      aria-labelledby="registered-go-to-login">{{resourceStrings['Aon.Login.LoginText']}}</a></p>
                </div>
                <div>
                  <p class="sm" id="{{idName}}need-help">{{resourceStrings['Aon.Account.NeedHelp']}} <a
                      id="{{idName}}contact-help" [routerLink]="[baseUrl+'/home/help']"
                      aria-labelledby="need-help">{{resourceStrings['Aon.Login.ContactHelpDesk']}}</a></p>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>