import { Injectable } from '@angular/core';
import { DefaultSableDataViewComponentFactory } from "@aon/sable-dataview";

import { SableDataViewAccordionComponent } from './sable-view/sable-dataview-accordion/sable-dataview-accordion.component';
import { SableDataviewCardComponent } from './sable-view/sable-dataview-card/sable-dataview-card.component';
import { SableDataviewInputLabelValueOnlyComponent } from './sable-view/sable-dataview-input-label-value-only/sable-dataview-input-label-value-only.component';
import { SableDataViewInputLabelComponent } from './sable-view/sable-dataview-input-label/sable-dataview-input-label.component';
import { SableDataViewOrderedListComponent } from './sable-view/sable-dataview-orderedlist/sable-dataview-orderedlist.component';
import { SableDataviewTableComponent } from './sable-view/sable-dataview-table/sable-dataview-table.component';
import { SableDataViewUnorderedListComponent } from './sable-view/sable-dataview-unorderedList/sable-dataview-unorderedList.component';
import { SableDataviewChartComponent } from './sable-view/sable-dataview-chart/sable-dataview-chart.component';

@Injectable({ providedIn: 'root' })
export class AppDataViewComponentFactory extends DefaultSableDataViewComponentFactory {
  public getComponentFactory(name: string): any {
    switch (name) {
      case 'accordion':
        return SableDataViewAccordionComponent;
      case 'panel':
        return SableDataViewAccordionComponent;
      case 'labelValuePair':
        return SableDataViewInputLabelComponent;
      case 'valueOnly':
        return SableDataviewInputLabelValueOnlyComponent;
      case 'table':
        return SableDataviewTableComponent;
      case 'card':
        return SableDataviewCardComponent;
      case 'orderedList':
        return SableDataViewOrderedListComponent;
      case 'unorderedList':
        return SableDataViewUnorderedListComponent;
      case 'chart':
        return SableDataviewChartComponent;
      default:
        return super.getComponentFactory(name);
    }
  }
}
